import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { InputField } from '../../shared/input/InputField';
import { device } from '../../../styles/device';
import { Formik } from 'formik';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { Button } from '../../shared/button/Button';
import { Modal } from '../../shared/modal/Modal';
import * as Yup from 'yup';
import { DefaultLanguage } from './DefaultLanguage';
import { FormLabel } from '../../shared/input/FormLabel';
import { Type } from '../questions/edit-question/Type';
import { SelectInput } from '../../shared/input/SelectInput';
import { userHasAccess } from '../../../helpers/permissions';
import history from '../../../history';

const numberRegex = /^(?=[^A-Za-z]+$)[0-9 -]*$/;

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This is a required field.'),
  email: Yup.string()
    .email('This is not a valid email format.')
    .required('This is a required field.'),
  phone: Yup.string().matches(numberRegex, 'Only numbers are allowed.'),
});

export const PersonalDetails = ({ user, setUser }) => {
  const [payload, setPayload] = useState({});
  const [optionsPayload, setOpitonsPayload] = useState({});
  const [consultants, setConsultants] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');
  const [deleteCandidate, setDeleteCandidate] = useState(false);
  const [defaultLanguage, setDefaultLanguage] = useState(
    user.defaultLanguage || null
  );
  const [userReadOnly] = useState(
    userHasAccess('CANDIDATES') !== 'crud' ? true : false
  );
  const [isInternal, setIsInternal] = useState(user.isInternalRecruit);
  const { data } = useFetch(payload);
  const { data: optionsData } = useFetch(optionsPayload);

  useEffect(() => {
    // If data.data is string candidate is deleted
    if (data && typeof data.data === 'string') {
      setIsDeleted(true);
      setDeleteCandidate(false);
    } else if (data && data.error) {
      setEmailErrorText(data.error);
      setDeleteCandidate(false);
    } else if (data) {
      setUser(data);
      setEmailErrorText('');
    }
  }, [data, setUser]);

  /**
   * Get name of Project leader or owner if no PL exists
   * @param {Object} project
   * @return {String}
   */
  const managerName = (project) => {
    try {
      const managerIx = project.managers.findIndex(
        (manager) => manager.roleName === 'Project leading consultant'
      );

      return managerIx !== -1
        ? project.managers[managerIx].user.name
        : project.owner.name;
    } catch (error) {
      return '';
    }
  };

  useEffect(() => {
    setOpitonsPayload({
      method: 'get',
      endpoint: '/api/omega/users/options',
    });
  }, []);

  useEffect(() => {
    setOpitonsPayload({
      method: 'get',
      endpoint: '/api/omega/users/options',
    });
  }, []);

  useEffect(() => {
    if (optionsData && optionsData.data) {
      setConsultants(optionsData.data.consultants);
    }
  }, [optionsData]);

  const setUpDeleteModal = () => {
    setShowModal(true);
    setDeleteCandidate(true);
  };

  const handleModalExit = () => {
    return isDeleted ? history.push('/admin/candidates') : setShowModal(false);
  };

  return (
    <>
      {user && (
        <Container>
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              name: user.name,
              email: user.email,
              phone: user.phone,
              position: (user.info && user.info.position) || '',
              consultant:
                (user.info &&
                  user.info.omegaConsultant &&
                  user.info.omegaConsultant._id) ||
                '',
              company: (user.info && user.info.company) || '',
              manager:
                user.activeProjects &&
                user.activeProjects[0] &&
                managerName(user.activeProjects[0]),
            }}
            onSubmit={async (updatedFields) => {
              const newValues = {
                ...updatedFields,
                ...{
                  defaultLanguage: defaultLanguage,
                  isInternalRecruit: isInternal,
                },
              };

              // Cant be empty string in backend to save (objectId or null)
              if (!newValues.position) newValues.position = null;
              if (!newValues.consultant) newValues.consultant = null;
              if (!newValues.company) newValues.company = null;

              const endpoint = `/api/omega/users/${user._id}`;
              const updatedUserObject = { ...user, ...newValues };

              setPayload({
                endpoint,
                method: 'patch',
                data: updatedUserObject,
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              });
              setShowModal(true);
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              touched,
            }) => (
              <Form onSubmit={handleSubmit}>
                <BlockWrapper>
                  <InnerContainer marginBottom>
                    <Title>Personal information</Title>
                    <InputField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type='text'
                      placeholder='Name'
                      value={values.name ? values.name : ''}
                      id='name'
                      label='Name'
                      error={errors.name && touched.name}
                      message={errors.name && touched.name && `${errors.name}`}
                      disabled={userReadOnly}
                    />
                    <InputField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type='email'
                      placeholder='Email'
                      value={values.email ? values.email : ''}
                      id='email'
                      label='Email'
                      error={errors.email && touched.email}
                      message={
                        errors.email && touched.email && `${errors.email}`
                      }
                      disabled={userReadOnly}
                    />
                    <InputField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type='text'
                      placeholder='Phone'
                      value={values.phone ? values.phone : ''}
                      id='phone'
                      label='Phone number'
                      error={errors.phone && touched.phone}
                      message={
                        errors.phone && touched.phone && `${errors.phone}`
                      }
                      disabled={userReadOnly}
                    />
                  </InnerContainer>
                  <InnerContainer>
                    <Title>Process</Title>
                    <InputField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type='text'
                      value={values.position}
                      id='position'
                      label='Position'
                      disabled={userReadOnly}
                    />

                    {consultants.length && (
                      <SelectInput
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type='text'
                        placeholder='Select consultant'
                        value={values.consultant}
                        id='consultant'
                        label='Consultant'
                        data={consultants}
                        disabled={userReadOnly}
                      />
                    )}

                    <InputField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type='text'
                      value={values.company}
                      id='company'
                      label='Company'
                      disabled={userReadOnly}
                    />
                  </InnerContainer>
                </BlockWrapper>

                <BlockWrapper>
                  <InnerContainer>
                    <FormLabel label='Default language' />
                    <DefaultLanguage
                      defaultLanguage={defaultLanguage}
                      setDefaultLanguage={setDefaultLanguage}
                      absolute
                    />

                    <FormLabel label='Internal recruit' />

                    <Type
                      chosenType={isInternal}
                      setChosenType={setIsInternal}
                      yesOrNoOption
                    />
                  </InnerContainer>
                  <InnerContainer></InnerContainer>
                </BlockWrapper>
                <Space />
                <BlockWrapper>
                  {!userReadOnly && (
                    <>
                      <InnerContainer>
                        <ButtonContainer>
                          <Button
                            text='Delete candidate'
                            remove
                            type='button'
                            onClick={() => setUpDeleteModal()}
                          />
                        </ButtonContainer>
                      </InnerContainer>
                      <InnerContainer>
                        <ButtonContainer>
                          <Button
                            text='Save changes'
                            primary
                            type='submit'
                            isDisabled={isSubmitting}
                          />
                        </ButtonContainer>
                      </InnerContainer>
                    </>
                  )}
                </BlockWrapper>
              </Form>
            )}
          </Formik>
          <Modal
            handleExit={() => handleModalExit()}
            title={
              ((deleteCandidate || isDeleted) && 'Delete candidate') ||
              (emailErrorText && 'Error') ||
              'Changes have been saved'
            }
            show={showModal}
            width='40rem'
          >
            {(deleteCandidate && (
              <>
                <ModalText>
                  Are you sure you want to delete {user.name}?
                </ModalText>
                <Flex>
                  <ButtonContainer>
                    <Button
                      text={'Yes'}
                      onClick={() => {
                        setPayload({
                          method: 'delete',
                          endpoint: `/api/omega/users/delete/${user._id}`,
                        });
                      }}
                      remove
                    />
                  </ButtonContainer>

                  <ButtonContainer>
                    <Button
                      text={'No'}
                      onClick={() => setShowModal(false)}
                      primary
                    />
                  </ButtonContainer>
                </Flex>
              </>
            )) || (
              <>
                {emailErrorText && <ModalText>{emailErrorText}</ModalText>}
                {isDeleted && <ModalText>{user.name} is now deleted</ModalText>}
                <Button onClick={() => handleModalExit()} text='OK' primary />
              </>
            )}
          </Modal>
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  margin-top: ${(props) => props.theme.spacing.extraLarge};
  margin-bottom: ${(props) => props.theme.spacing.medium};
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  @media ${device.mobileL} {
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;

//TODO some design fixes here xD
const BlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  // align-items: flex-end;
  &:first-of-type {
    padding-top: ${(props) => props.theme.spacing.extraLarge};
    margin-bottom: ${(props) => props.theme.spacing.standard};
  }
  &:last-of-type {
    padding-bottom: ${(props) => props.theme.spacing.extraLarge};
    margin-bottom: 4rem;
  }
  @media ${device.tablet} {
    flex-direction: column;
    align-items: center;
    &:last-of-type {
      padding-bottom: ${(props) => props.theme.spacing.medium};
    }
  }
`;

const ButtonContainer = styled.div`
  padding-bottom: ${(props) => props.theme.spacing.standard};
  width: 100%;
`;

const InnerContainer = styled.div`
  margin-left: ${(props) => props.theme.spacing.standard};
  margin-right: ${(props) => props.theme.spacing.standard};
  width: 35rem;
  @media ${device.tablet} {
    width: 100%;
    ${(props) =>
      props.marginBottom &&
      css`
        margin-bottom: ${(props) => props.theme.spacing.extraLarge};
      `}
  }
`;

const Form = styled.form``;

const Space = styled.div`
  margin-top: 4rem;
`;

const Title = styled.p`
  font-size: 2rem;
  font-weight: bold;
  line-height: 4rem;
  border-bottom: 1px solid ${(props) => props.theme.color.mediumBlue};
  margin-bottom: 3rem;
`;

const ModalText = styled.p`
  font-size: 1.6rem;
  margin-bottom: 2rem;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;
