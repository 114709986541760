import React from 'react';
import styled from 'styled-components';

export const Footer = () => {
  return <Container>Copyright &copy; Navigio 2014-2023</Container>;
};

const Container = styled.div`
  width: 100%;
  height: 11rem;
  background: ${(props) => props.theme.color.darkBlue};
  color: ${(props) => props.theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'futura-pt', sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 1.8rem;
  line-height: 2.3rem;
  flex-shrink: 0;
`;
