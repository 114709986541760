import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../styles/device';
import { sortArrows } from '../assets/icons';

export const TableHeader = ({ type, onClick }) => {
  const results = true;
  const pointer = true;
  const categories = true;
  const print = true;
  const test = true;
  const textAlignRight = true;

  switch (type) {
    case 'users':
      return (
        <Header>
          <HeaderItem></HeaderItem>
          <HeaderItem
            onClick={() => onClick('info.lastName')}
            pointer={pointer ? 1 : 0}
          >
            Candidate
            <Arrow src={sortArrows}></Arrow>
          </HeaderItem>
          <HeaderItem
            onClick={() => onClick('info.company')}
            pointer={pointer ? 1 : 0}
          >
            Project<Arrow src={sortArrows}></Arrow>
          </HeaderItem>
          <HeaderItem>Test progress</HeaderItem>
          <HeaderItem>Position</HeaderItem>
          {/* <HeaderItem onClick={() => onClick('email')} pointer>
            Email
          </HeaderItem> */}
          <HeaderItem></HeaderItem>
        </Header>
      );
    case 'categories':
      return (
        <Header categories={categories ? 1 : 0}>
          <HeaderItem
            onClick={() => onClick('title')}
            pointer={pointer ? 1 : 0}
          >
            Categories <Arrow src={sortArrows} onClick={() => { }}></Arrow>
          </HeaderItem>
          <HeaderItem>Amount of questions</HeaderItem>
        </Header>
      );
    case 'results':
      return (
        <Header results={results ? 1 : 0}>
          <HeaderItem>Test name</HeaderItem>
          <HeaderItem>Correct answers</HeaderItem>
          <HeaderItem>Stanine Score</HeaderItem>
          <HeaderItem>Completed date</HeaderItem>
          <HeaderItem>I</HeaderItem>
          <HeaderItem>II</HeaderItem>
          <HeaderItem />
        </Header>
      );

    case 'print':
      return (
        <Header print={print ? 1 : 0}>
          <HeaderItem>Test name</HeaderItem>
          <HeaderItem>Completed</HeaderItem>
          <HeaderItem>Time left</HeaderItem>
          <HeaderItem>Result</HeaderItem>
          <HeaderItem>I</HeaderItem>
          <HeaderItem>II</HeaderItem>
        </Header>
      );

    case 'test':
      return (
        <Header test={test ? 1 : 0}>
          <HeaderItem>Title</HeaderItem>
          <HeaderItem>Category</HeaderItem>
          <HeaderItem textAlignRight={textAlignRight ? 1 : 0}>
            Add to order
          </HeaderItem>
        </Header>
      );

    case 'templates':
      return (
        <Header templates>
          <HeaderItem onClick={() => onClick('title')} pointer>
            Title
            <Arrow src={sortArrows}></Arrow>
          </HeaderItem>
          <HeaderItem>Available languages</HeaderItem>
          <HeaderItem onClick={() => onClick('category')} pointer>
            Category
            <Arrow src={sortArrows}></Arrow>
          </HeaderItem>
          <HeaderItem>Questions</HeaderItem>
          <HeaderItem>Time limit</HeaderItem>
          <HeaderItem></HeaderItem>
        </Header>
      );

    case 'projects':
    case 'groups':
      return (
        <Header project>
          <HeaderItem
            onClick={() => onClick('title')}
            pointer={pointer ? 1 : 0}
          >
            Title
            <Arrow src={sortArrows}></Arrow>
          </HeaderItem>
          <HeaderItem>
            Templates
            {/* <Arrow src={sortArrows}></Arrow> */}
          </HeaderItem>
          <HeaderItem>Candidates</HeaderItem>
          <HeaderItem
            onClick={() => onClick('consultant')}
            pointer={pointer ? 1 : 0}
          >
            Consultant
            <Arrow src={sortArrows}></Arrow>
          </HeaderItem>
          {/* <HeaderItem></HeaderItem> */}
        </Header>
      );
    case 'custom-user-permissions':
      return (
        <Header editPermissions>
          <HeaderItem
            onClick={() => onClick('title')}
            pointer={pointer ? 1 : 0}
          >
            Userlist
            <Arrow src={sortArrows}></Arrow>
          </HeaderItem>

          <HeaderItem>Email</HeaderItem>
          <HeaderItem>Role</HeaderItem>
        </Header>
      );

    case 'title-only': //TODO:make it better?
      return (
        <Header titleOnly>
          <HeaderItem
            onClick={() => onClick('title')}
            pointer={pointer ? 1 : 0}
          >
            Title
            <Arrow src={sortArrows}></Arrow>
          </HeaderItem>

          <HeaderItem></HeaderItem>
        </Header>
      );

    default:
      break;
  }
};

const Header = styled.header`
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: 1fr 3fr 2fr 2fr 2fr 1fr;
  ${(props) =>
    props.categories &&
    css`
      grid-template-columns: 4fr 5fr 1fr;
    `}
  ${(props) =>
    props.templates &&
    css`
      grid-template-columns: 5fr 4fr 3fr 3fr 2fr 1fr;
    `} 

    ${(props) =>
      props.project &&
      css`
        grid-template-columns: 3fr 2fr 2fr 3fr 3fr;
      `}

    ${(props) =>
    props.titleOnly &&
    css`
        grid-template-columns: 9fr 1fr;
      `}

      ${(props) =>
        props.editPermissions &&
        css`
          grid-template-columns: 35% 32% 22%;
        `}
    


  height: 7rem;
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  box-shadow: 0px -1px 0px #d6d6d6, 0px 1px 0px #d6d6d6;
  background-color: white;
  font-size: 1.6rem;
  font-weight: 600;
  font-style: italic;
  ${(props) =>
    props.categories &&
    css`
      grid-template-columns: 4fr 5fr 1fr;
    `}
  ${(props) =>
    props.results &&
    css`
      grid-template-columns: 2fr 2fr 2fr 2fr 1fr 1fr;
      box-shadow: none;
    `}

  ${(props) =>
    props.test &&
    css`
      grid-template-columns: 5fr 1fr 1fr;
      box-shadow: none;
    `}

 

  @media ${device.tablet} {
    ${(props) =>
    !props.print &&
    css`
        display: none;
      `}
  }
`;
const HeaderItem = styled.div`
  display: flex;
  align-content: center;
  ${(props) =>
    props.pointer &&
    css`
      cursor: pointer;
    `}
  ${(props) =>
    props.textAlignRight &&
    css`
      display: flex;
      justify-content: flex-end;
    `}
`;

const Arrow = styled.img`
  margin-left: 2.5rem;
`;
