import React from 'react';
import styled from 'styled-components';
import { FormLabel } from './FormLabel';
import { device } from '../../../styles/device';

export const ToggleSwitch = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
}) => {
  return (
    <InputContainer>
      <Input
        name={name}
        id={id}
        type='radio'
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className='radio-button'
      />
      <Label htmlFor={id}>{label}</Label>
    </InputContainer>
  );
};

export const ToggleSwitchGroup = ({ label, children, id, error }) => {
  return (
    <GroupContainer id={id} error={error}>
      {label && <FormLabel label={label} id={id} />}
      <ToggleContainer>{children}</ToggleContainer>
    </GroupContainer>
  );
};

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  height: 6rem;
`;
const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + label {
    background-color: ${(props) => props.theme.color.selectedGrey};
    color: ${(props) => props.theme.color.white};
    border-radius: 0.4rem;
  }
`;
const Label = styled.label`
  display: inline-block;
  height: 6rem;
  cursor: pointer;
  width: 100%;
  color: ${(props) => props.theme.color.black};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  @media ${device.mobileL} {
    font-size: 1.4rem;
  }
`;
const GroupContainer = styled.div`
  width: 100%;
`;
const ToggleContainer = styled.div`
  width: 100%;
  display: flex;
  border-radius: 0.4rem;
  background: ${(props) => props.theme.color.bgGrey};
  margin-bottom: ${(props) => props.theme.spacing.standard};
`;
