import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { FormLabel } from '../../../shared/input/FormLabel';
import { SortingTable } from '../../../shared/sorting-table/SortingTable';
import { useFetch } from '../../../../helpers/hooks/fetchData';

export const TableInput = ({
  content,
  setContent,
  selectedContent,
  setSelectedContent,
  columnWidths = ['100%'],
  customHeaders,
  noMargin,
  scrollOverflow,
  showHeaders,
  searchBar,
  searchBarPlaceholder,
  label,
  endpoint,
  sortSelected,
  disabled,
  padding,
}) => {
  const [searchString, setSearchString] = useState('');
  const [payload, setPayload] = useState({});

  const searchEntered = (searchString) => {
    setSearchString(searchString);
  };

  const { data, isLoading } = useFetch(payload);

  useEffect(() => {
    if (endpoint)
      setPayload({
        endpoint,
        method: 'get',
        params: { searchString },
      });
  }, [searchString, endpoint]);

  const memoizedSortSelected = useCallback(sortSelected, [selectedContent]);

  useEffect(() => {
    if (data && data.data) {
      setContent(memoizedSortSelected(data.data, selectedContent));
    }
  }, [data, setContent, memoizedSortSelected, selectedContent]);
  return (
    ((isLoading !== 'undefined' || (content && selectedContent)) && (
      <Fragment>
        {label && <FormLabel label={label} />}

        <SortingTable
          showHeaders={showHeaders}
          searchBar={!disabled && searchBar}
          searchString={searchString}
          setSearchString={searchEntered}
          searchBarPlaceholder={searchBarPlaceholder || 'Search'}
          data={content}
          setData={setContent}
          selectedData={selectedContent}
          setSelectedData={setSelectedContent}
          columnWidths={columnWidths}
          customHeaders={customHeaders || []}
          noMargin={noMargin}
          scrollOverflow={scrollOverflow}
          disabled={disabled}
          padding={padding}
        />
      </Fragment>
    )) ||
    null
  );
};
