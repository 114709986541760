import React, { useContext } from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';
import { SingleTestRow } from './SingleTestRow';
import { ContentContext } from '../../../helpers/contentContext';

export const TestTable = ({ user }) => {
  const content = useContext(ContentContext) || {};
  const handleClick = (index) => {
    window.location.assign(`/omega/candidate/test/start-test/${index}`);
  };

  return (
    <>
      {user.omegaTests && user.omegaTests.length === 0 ? (
        <NoTestsAvailable>{content.noTestAvailable}</NoTestsAvailable>
      ) : (
        <Container>
          <TitleRow>
            <FormName>{content.tests}</FormName>
            <Status>{content.status}</Status>
          </TitleRow>
          <Divider />

          {user.omegaTests &&
            user.omegaTests.map(
              (test, index) =>
                !test.hidden && (
                  <Wrapper key={index}>
                    <SingleTestRow
                      test={test}
                      index={index}
                      //previousTest={tests[index - 1]}
                      handleClick={handleClick}
                    />
                  </Wrapper>
                )
            )}
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.color.bgGrey};
  border-radius: 0.4rem;
  width: 73rem;
  padding-bottom: 1.5rem;
  @media ${device.tablet} {
    width: 100%;
  }
`;

const Divider = styled.div``;

const TitleRow = styled.div`
  display: flex;
  border-bottom: 1px solid #000;
  flex-direction: row;
  font-weight: 500;
  font-size: 2.2rem;
  padding-bottom: 1.1rem;
  margin: 3rem 3rem 3.5rem 3rem;
  @media ${device.tablet} {
    margin: 2rem;
  }
`;

const FormName = styled.p`
  flex: 1;
`;

const Status = styled.p`
  width: 12rem;
  text-align: center;
`;

const Wrapper = styled.div``;

const NoTestsAvailable = styled.div`
  background: ${(props) => props.theme.color.bgGrey};
  border-radius: 0.4rem;
  width: 73rem;
  font-weight: 500;
  text-align: center;
  font-size: 2.2rem;
  padding: 3rem;
  @media ${device.tablet} {
    width: 100%;
  }
`;
