import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Logout } from '../../../components/shared/Logout';
import { ContentContext } from '../../../helpers/contentContext';

import {
  navigioLogo,
  logOutWhite,
} from '../../../components/shared/assets/icons';
import { device } from '../../../styles/device';
export const Header = ({ user, removeLogOutIcon }) => {
  const [showLogout, setShowLogout] = useState(false);
  const content = useContext(ContentContext) || {};

  const logOutCandidate = () => {
    setShowLogout(true);
  };

  return (
    <Container>
      <NavigioLogoContainer>
        <NavigioLogo src={navigioLogo} />
      </NavigioLogoContainer>
      <LogoutIconContainer
        onClick={() => logOutCandidate()}
        removeLogOutIcon={removeLogOutIcon}
      >
        <LogoutIcon src={logOutWhite} />
      </LogoutIconContainer>
      {user && (
        <CandidateInfoContainer>
          <LoggedInAs>{content.loggedOnAs}</LoggedInAs>
          <Name>{user.name}</Name>
        </CandidateInfoContainer>
      )}
      <Logout showModal={showLogout} setShowModal={setShowLogout} />
    </Container>
  );
};

const Container = styled.div`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  height: 17.5rem;
  background: ${(props) => props.theme.color.darkBlue};
  position: relative;
`;

const NavigioLogoContainer = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  align-items: center;
  padding: 0 6.5rem;
  @media ${device.tablet} {
    padding: 2.5rem;
    align-items: flex-start;
  }
`;

const NavigioLogo = styled.img`
  width: 12rem;
  height: 4.5rem;
`;

const LogoutIconContainer = styled.div`
  position: absolute;
  right: 6.5rem;
  top: 3.3rem;
  cursor: pointer;
  @media ${device.tablet} {
    right: 2.5rem;
    top: 2.5rem;
  }
  ${(props) =>
    props.removeLogOutIcon &&
    css`
      display: none;
    `}
`;

const LogoutIcon = styled.img`
  height: 2.2rem;
  width: 2.2rem;
`;

const LoggedInAs = styled.p`
  &:after {
    content: ' ';
    white-space: pre;
  }
  @media ${device.tablet} {
    &:after {
      content: none;
    }
  }
`;

const CandidateInfoContainer = styled.div`
  color: ${(props) => props.theme.color.white};
  position: absolute;
  right: 6.5rem;
  bottom: 2.5rem;
  font-size: 1.8rem;
  line-height: 2.3rem;
  display: flex;
  flex-direction: row;
  @media ${device.tablet} {
    flex-direction: column;
    text-align: right;
    right: 2.5rem;
  }
`;

const Name = styled.p``;
