import * as React from 'react';
import styled from 'styled-components';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { device } from '../../../styles/device';

export const MainNav = ({ setRoute, routes }) => {
  const { url } = useRouteMatch();
  return (
    <nav>
      <Ul>
        {routes.map((route, index) => {

          return (
            <Li key={index}>
              <StyledLink
                to={`${url}${route.path}`}
                onClick={() => setRoute(route)}
              >
                {route.name}
              </StyledLink>
            </Li>
          );
        })}
      </Ul>
    </nav>
  );
};

const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  background-color: ${(props) => props.theme.color.primary};
  overflow: auto;
  white-space: nowrap;
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
  }
`;
const Li = styled.li`
  line-height: 2.2rem;
  height: 100%;
  cursor: pointer;
`;
const StyledLink = styled(NavLink)`
  font-size: ${(props) => props.theme.size.menu};
  display: flex;
  margin-top: 1.2rem;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 100%;
  color: ${(props) => props.theme.color.white};
  margin-right: ${(props) => props.theme.spacing.extraLarge};
  padding-bottom: ${(props) => props.theme.spacing.standard};
  border-bottom: 3px solid transparent;
  transition: border-bottom 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  &.active {
    border-bottom: 3px solid ${(props) => props.theme.color.white};
  }
`;
