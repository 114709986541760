import React from 'react';
import styled from 'styled-components';
import { useParams, Route, Switch } from 'react-router-dom';

export const Main = ({ route }) => {
  const { child } = useParams();

  const renderChild = () => {
    if (child) {
      const filteredRoutes = route.routes.filter(
        (res) => res.path.indexOf(child) !== -1 || res.isDynamic
      );

      //TODO: Fix to not return undefined or error.
      return filteredRoutes[0] && filteredRoutes[0].component;
    }
  };

  return (
    <Container>
      <Switch>
        <Route component={renderChild()} />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.color.bgGrey};
`;
