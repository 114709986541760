import React, { useState, useEffect } from 'react';
import { SortingTable } from '../../../shared/sorting-table/SortingTable';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { exerciseDark } from '../../../shared/assets/icons';
export const AddExcersises = ({ template }) => {
  const [selectedData, setSelectedData] = useState(
    template.exerciseQuestions &&
      template.exerciseQuestions.map((question) => {
        return {
          _id: question._id,
          title: question.title,
          languages:
            question.availableLanguages &&
            question.availableLanguages.map((lang, index) => {
              return index !== question.availableLanguages.length - 1
                ? `${lang.code}, `
                : lang.code;
            }),
          category: question.category && question.category.title,
        };
      })
  );
  const [payload, setPayload] = useState({ params: { isExercise: true } });
  const [searchString, setSearchString] = useState('');
  const [questions, setQuestions] = useState(null);
  const endpoint = '/api/omega/questions';
  const method = 'get';

  useEffect(() => {
    setPayload({
      endpoint,
      method,
      params: { isExercise: true, searchString: searchString },
    });
  }, [searchString]);
  const { data, isLoading } = useFetch(payload);

  const searchEntered = (s) => {
    setSearchString(s);
  };

  useEffect(() => {
    template.selectedExercises =
      selectedData &&
      selectedData.map((question) => {
        return question._id;
      });
  }, [selectedData, template.selectedExercises]);

  useEffect(() => {
    if (data && data.data) {
      const newQuestions = data.data
        .map((item) => {
          if (
            selectedData.findIndex((selected) => selected._id === item._id) !==
            -1
          ) {
            return false;
          }
          return {
            _id: item._id,
            title: item.title,
            languages:
              item.availableLanguages &&
              item.availableLanguages.map((lang, index) => {
                return index !== item.availableLanguages.length - 1
                  ? `${lang.code}, `
                  : lang.code;
              }),
            category: item.category && item.category.title,
          };
        })
        .filter(Boolean);
      setQuestions(newQuestions);
    }
  }, [data, selectedData]);

  const columnWidths = ['70%', '20%', '10%'];
  const customHeaders = ['Title', 'Languages', 'Category'];
  return (
    <>
      {isLoading !== 'undefined' && (
        <SortingTable
          title='Add exercise questions'
          icon={exerciseDark}
          showHeaders
          searchBar
          searchString={searchString}
          setSearchString={searchEntered}
          searchBarPlaceholder='Search questions'
          data={questions}
          setData={setQuestions}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          columnWidths={columnWidths}
          customHeaders={customHeaders}
          templateQuestions
        />
      )}
    </>
  );
};
