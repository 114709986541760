import React, { useState, useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Button } from '../../../shared/button/Button';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { InputField } from '../../../shared/input/InputField';
import { Modal } from '../../../shared/modal/Modal';
import {
  RadioButton,
  RadioButtonGroup,
} from '../../../shared/input/RadioButton';
import { exerciseWhite } from '../../../shared/assets/icons';
import { exercise } from '../../../shared/assets/icons';
import { regular } from '../../../shared/assets/icons';
import { regularWhite } from '../../../shared/assets/icons';
import { instruction } from '../../../shared/assets/icons';
import { instructionWhite } from '../../../shared/assets/icons';
import { info } from '../../../shared/assets/icons';
import history from '../../../../history';

export const InitNewQuestion = () => {
  const [showModal, setShowModal] = useState(false);
  const [payload, setPayload] = useState({});

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This is a required field.'),
    questionType: Yup.string().required('This selection is required.'),
  });

  const initialValues = {
    title: '',
    questionType: '',
  };

  const { data, hasError } = useFetch(payload);

  useEffect(() => {
    if (data && data.data && !showModal) {
      const newQuestion = data.data;

      history.push(`/admin/questions/edit-question/${newQuestion.slug}`, {
        slug: newQuestion.slug,
      });
    }
  }, [data, showModal]);

  useEffect(() => {
    if (data && data.data) {
      setShowModal(false);
    }
  }, [data]);

  return (
    <>
      <Button add onClick={() => setShowModal(true)} />

      <Modal
        show={showModal}
        handleExit={setShowModal}
        width={'40rem'}
        title='Create new question'
      >
        {!hasError && (
          <>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={async (values, { resetForm }) => {
                const newQuestion = {
                  title: values.title,
                  isExercise: values.questionType === 'isExercise',
                  isInstruction: values.questionType === 'isInstruction',
                  isRegular: values.questionType === 'isRegular',
                };

                setPayload({
                  endpoint: '/api/omega/questions',
                  method: 'post',
                  data: newQuestion,
                  headers: {
                    'Content-Type': 'application/json',
                  },
                });
                resetForm();
              }}
            >
              {({
                touched,
                errors,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <InputField
                    label='Internal title'
                    id='title'
                    type='text'
                    name='title'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    error={errors.title && touched.title}
                    message={errors.title && touched.title && `${errors.title}`}
                    iconAfterLabel={info}
                  />
                  <Margin />
                  <RadioButtonGroup
                    id='questionType'
                    label='Type of question'
                    value={values.questionType}
                    touched={touched.questionType}
                    error={errors.questionType && touched.questionType}
                    message={
                      errors.questionType &&
                      touched.questionType &&
                      `${errors.questionType}`
                    }
                  >
                    <Field
                      component={RadioButton}
                      name='questionType'
                      id='isRegular'
                      label='Regular'
                      icon={regular}
                      iconActive={regularWhite}
                    />
                    <Field
                      component={RadioButton}
                      name='questionType'
                      id='isInstruction'
                      label='Instruction'
                      icon={instruction}
                      iconActive={instructionWhite}
                    />
                    <Field
                      component={RadioButton}
                      name='questionType'
                      id='isExercise'
                      label='Exercise'
                      icon={exercise}
                      iconActive={exerciseWhite}
                    />
                  </RadioButtonGroup>
                  <Button
                    primary
                    type='submit'
                    disabled={isSubmitting}
                    text='Continue'
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
        {hasError && (
          <>
            <h2>Error creating question</h2>
            <Button text='OK' onClick={() => setShowModal(false)} />
          </>
        )}
      </Modal>
    </>
  );
};

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Margin = styled.div`
  height: ${(props) => props.theme.spacing.extraLarge};
`;
