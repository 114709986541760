import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { Formik } from 'formik';
import { InputField } from '../../../shared/input/InputField';
import styled from 'styled-components';
import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { Button } from '../../../shared/button/Button';
import { device } from '../../../../styles/device';
import { InputWrapper } from '../../../shared/input/InputWrapper';
import { CreationProgress } from '../../../shared/progress-bar/CreationProgress';
import { Type } from './Type';
import { AddNewOption } from './AddNewOption';
import { SingleOptionItemWrapper } from './SingleOptionItemWrapper';
import { MultipleOptionsItemWrapper } from './MultipleOptionsItemWrapper';
import { v4 as uuidv4 } from 'uuid';
import { QuestionList } from '../../../shared/drop-downs/QuestionList';

export const SetOptions = ({ question, setPage }) => {
  const [chosenType, setChosenType] = useState(null);
  const [newImages, setNewImages] = useState(null);
  const [options, setOptions] = useState({ options: question.options } || null);
  const [change, setChange] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const fimOptions = ['A', 'B', 'C'];
  const fimRowOptions = ['Left', 'None', 'Right'];

  const validationSchema = yup.object().shape({
    options: yup.lazy((obj) =>
      yup.object(
        mapValues(obj, () =>
          yup.array().of(
            yup.object().shape({
              text: yup.string(),
            })
          )
        )
      )
    ),
  });

  const [chosenQuestion, setChosenQuestion] = useState(null);
  const renderQuestionList = () => {
    if (question.addQuestion) {
      const tempList =
        question.addQuestion.EN && question.addQuestion.EN.questions
          ? question.addQuestion.EN.questions
          : Object.values(question.addQuestion)[0].questions;

      return Object.values(tempList);
    }
  };
  const [questionsList] = useState(renderQuestionList() || []);

  // create addOptions in options if its a new question.
  useEffect(() => {
    if (question.addQuestion) {
      const newOptions = options.options;

      question.availableLanguages.forEach((lang) => {
        if (!newOptions[lang.code] || !Array.isArray(newOptions[lang.code])) {
          newOptions[lang.code] = [];
        }
        questionsList.forEach((question, index) => {
          if (!newOptions[lang.code][index]) {
            newOptions[lang.code].push({
              addOptions: [],
            });
          }
        });
      });
      setOptions({ options: newOptions });
      question.options = newOptions;
    }
  }, [
    options.options,
    question.addQuestion,
    question.availableLanguages,
    question.options,
    questionsList,
  ]);

  const addNewOption = () => {
    if (!chosenType) {
      return setErrorMessage('You need to choose a type');
    }

    setErrorMessage('');
    const newOptions = options.options;
    const newOptionId = uuidv4();

    question.availableLanguages.forEach((lang) => {
      if (!newOptions[lang.code] || !Array.isArray(newOptions[lang.code])) {
        newOptions[lang.code] = [];
      }

      newOptions[lang.code].push({
        type: chosenType.id,
        newOptionId,
        text: '',
        isCorrect: false,
      });
    });

    setOptions({ options: newOptions });
    question.options = newOptions;
    setChange(!change);
  };

  const addNewOption2 = () => {
    if (!chosenType) {
      return setErrorMessage('You need to choose a type');
    }
    if (!chosenQuestion) {
      return setErrorMessage('You need to choose a question');
    }

    let questIndex = -1;
    questionsList.forEach((quest, i) => {
      if (quest === chosenQuestion) {
        questIndex = i;
        return;
      }
    });

    setErrorMessage('');
    const newOptions = options.options;

    const newOptionId = uuidv4();
    question.availableLanguages.forEach((lang) => {
      if (!newOptions[lang.code] || !Array.isArray(newOptions[lang.code])) {
        newOptions[lang.code] = [];
      }

      if (!newOptions[lang.code][questIndex]) {
        newOptions[lang.code].push({
          addOptions: [],
        });
      }
      newOptions[lang.code][questIndex].addOptions.push({
        type: chosenType.id,
        _id: newOptionId,
        text: '',
        isCorrect: false,
      });
    });
    setOptions({ options: newOptions });
    question.options = newOptions;
    setChange(!change);
  };

  if (
    chosenType &&
    chosenType.id === 'fim' &&
    options &&
    options.options &&
    (!options.options['EN'] ||
      (options.options['EN'][0] &&
        options.options['EN'][0].fimRowOptions &&
        options.options['EN'][0].fimRowOptions.A &&
        !options.options['EN'][0].fimRowOptions.A.length) ||
      question.isExercise)
  ) {
    // Set availble lang to eng for fim questions, fim should only have 1 lang.
    // Fim exercise can have more than 1 lang for the description
    const newOptions = options.options;

    question.availableLanguages.forEach((lang) => {
      if (
        !newOptions[lang.code] ||
        !Array.isArray(newOptions[lang.code]) ||
        (newOptions[lang.code] &&
          newOptions[lang.code][0] &&
          newOptions[lang.code][0].fimRowOptions &&
          newOptions[lang.code][0].fimRowOptions.A &&
          !newOptions[lang.code][0].fimRowOptions.A.length)
      ) {
        newOptions[lang.code] = [];

        const fimRowOptionContent = [];

        for (let i = 0; i < 3; i++) {
          fimRowOptionContent[i] = [
            {
              fimRowOptionId: uuidv4(),
              text: 'left',
              isCorrect: false,
            },
            {
              fimRowOptionId: uuidv4(),
              text: 'none',
              isCorrect: false,
            },
            {
              fimRowOptionId: uuidv4(),
              text: 'right',
              isCorrect: false,
            },
          ];
        }

        newOptions[lang.code][0] = {
          type: chosenType.id,
          fimRowOptions: {
            A: fimRowOptionContent[0],
            B: fimRowOptionContent[1],
            C: fimRowOptionContent[2],
          },
        };
      }
    });

    question.options = newOptions;
  }

  const removeOption = (index) => {
    const newOptions = options;
    question.availableLanguages.forEach((lang) => {
      newOptions.options[lang.code].splice(index, 1);
    });
    setOptions(newOptions);
    setChange(!change);
  };

  const removeOption2 = (index, index2) => {
    const newOptions = options;
    question.availableLanguages.forEach((lang) => {
      newOptions.options[lang.code][index].addOptions.splice(index2, 1);
    });
    setOptions(newOptions);
    setChange(!change);
  };

  const editOption = ({ e, lang, index }) => {
    const newOptions = options;
    newOptions.options[lang][index].text = e.target.value;
    setOptions(newOptions);
    setChange(!change);
  };
  const editOption2 = ({ e, lang, index, index2 }) => {
    const newOptions = options;

    newOptions.options[lang][index].addOptions[index2].text = e.target.value;
    setOptions(newOptions);
    setChange(!change);
  };

  const toggleCorrectAnswer = (index) => {
    const newOptions = options;
    question.availableLanguages.forEach((lang) => {
      newOptions.options[lang.code][index].isCorrect = !newOptions.options[
        lang.code
      ][index].isCorrect;
    });
    setOptions(newOptions);
    setChange(!change);
  };

  const toggleCorrectFIMAnswer = (index, fimOption, fimRowOptionIx) => {
    const newOptions = options;
    question.availableLanguages.forEach((lang) => {
      newOptions.options[lang.code][index]['fimRowOptions'][fimOption][
        fimRowOptionIx
      ].isCorrect = !newOptions.options[lang.code][index]['fimRowOptions'][
        fimOption
      ][fimRowOptionIx].isCorrect;
    });
    setOptions(newOptions);
    setChange(!change);
  };

  // Adds new option if new language is added
  const updateOptionValues = (values) => {
    if (
      values.options &&
      values.options.length !== question.availableLanguages.length
    ) {
      for (let i = 0; i < question.availableLanguages.length; i++) {
        const lang = question.availableLanguages[i];
        const code = lang.code;

        if (code && !Object.keys(values.options).includes(code)) {
          let clone;
          if (i) {
            // TODO: update to structuredClone() instead of JSON afther node uptdates
            const stringClone = JSON.stringify(
              values.options[question.availableLanguages[i - 1].code]
            );

            clone = JSON.parse(stringClone);
          }

          if (clone) {
            if (!question.addQuestion) clone.forEach((obj) => (obj.text = ''));

            if (question.addQuestion)
              clone.forEach((obj) => {
                obj.addOptions.forEach((o) => (o.text = ''));
              });

            values.options[code] = clone;
          }
        }
      }
    }
  };

  const [payload, setPayload] = useState({});
  const { data, hasError } = useFetch(payload);

  const imageOptionSelected = (event, id) => {
    if (event.target && event.target.files && event.target.files[0]) {
      const newImage = {};
      newImage[id] =
        event.target && event.target.files && event.target.files[0];

      setNewImages({ ...newImages, ...newImage });
    }
  };

  if (data) {
    window.location.assign('/omega/admin/questions');
    //redirect this to page 3 when exists
    // setPage(3);
  }
  if (hasError) {
    // eslint-disable-next-line no-console
    console.log(hasError);
  }

  return (
    <Container>
      <CreationProgress
        step='second'
        firstStepText='Question'
        secondStepText='Options'
        thirdStepText='Preview'
      />
      <ProgressBarSpacing />
      <Formik
        initialValues={options}
        validationSchema={validationSchema}
        values={options}
        enableReinitialize={true}
        onSubmit={(values) => {
          question = { ...question, ...values };
          const formData = new FormData();
          formData.append('question', JSON.stringify(question));
          formData.append('isStringified', true);

          for (const prop in newImages) {
            formData.append(prop, newImages[prop]);
          }
          setPayload({
            method: 'put',
            endpoint: `/api/omega/questions/${question._id}/`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          //isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <InnerContainer>
              <LeftColumn>
                <InputWrapper
                  errorMsg={errors.title && touched.title && `${errors.title}`}
                  title='Internal title'
                >
                  <InputField
                    id='title'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={question.title}
                    error={errors.title && touched.title}
                    type='text'
                    name='title'
                    placeholder='Internal title'
                    disabled
                  />
                </InputWrapper>
                {question && question.addQuestion ? (
                  <InputWrapper
                    marginTop
                    title='Questions'
                    height='29'
                    errorMsg={errorMessage}
                  >
                    <QuestionList
                      questions={questionsList}
                      setChosenQuestion={setChosenQuestion}
                      chosenQuestion={chosenQuestion}
                    ></QuestionList>
                  </InputWrapper>
                ) : (
                  <></>
                )}

                <InputWrapper
                  marginTop
                  title='Option type'
                  errorMsg={errorMessage}
                >
                  <Type
                    chosenType={chosenType}
                    question={question}
                    setChosenType={setChosenType}
                    optionType
                  />
                </InputWrapper>
                {chosenType && chosenType.id !== 'fim' && (
                  <AddNewOption
                    onClick={() =>
                      question.addQuestion ? addNewOption2() : addNewOption()
                    }
                    marginBottom
                    marginTop
                    errorMessage={errorMessage}
                  />
                )}
              </LeftColumn>

              <Space />
              <RightColumn>
                {question.availableLanguages.length > 0 &&
                  values.options[question.availableLanguages[0].code] &&
                  Array.isArray(
                    values.options[question.availableLanguages[0].code]
                  ) &&
                  values.options[question.availableLanguages[0].code].map(
                    (optionTemplate, index) => {
                      updateOptionValues(values);

                      if (!question.addQuestion) {
                        return !question.type.fim ? (
                          <SingleOptionItemWrapper
                            marginBottom
                            optionIx={index}
                            option={optionTemplate}
                            key={index}
                            id={uuidv4()}
                            availableLanguages={question.availableLanguages}
                            removeOption={removeOption}
                            errors={errors}
                            touched={touched}
                            values={values.options}
                            onChange={handleChange}
                            editOption={editOption}
                            toggleCorrectAnswer={toggleCorrectAnswer}
                            onBlur={handleBlur}
                            optionTitleCounter={`Option ${index + 1}`}
                            imageOptionSelected={imageOptionSelected}
                          />
                        ) : (
                          fimOptions.map((fimOption, fimOptionIx) => {
                            return (
                              <ContainerTest key={fimOptionIx}>
                                <TitleText>{fimOption}</TitleText>
                                {fimRowOptions.map(
                                  (fimRowOption, fimRowOptionIx) => {
                                    return (
                                      <React.Fragment key={fimRowOptionIx}>
                                        <MultipleOptionsItemWrapper
                                          marginBottom
                                          key={fimRowOptionIx}
                                          optionIx={index}
                                          fimOption={fimOption}
                                          fimRowOption={fimRowOption}
                                          finRowOptionIx={fimRowOptionIx}
                                          option={optionTemplate}
                                          id={uuidv4()}
                                          availableLanguages={
                                            question.availableLanguages
                                          }
                                          removeOption={removeOption}
                                          errors={errors}
                                          touched={touched}
                                          values={values.options}
                                          onChange={handleChange}
                                          toggleCorrectAnswer={() =>
                                            toggleCorrectFIMAnswer(
                                              index,
                                              fimOption,
                                              fimRowOptionIx
                                            )
                                          }
                                          onBlur={handleBlur}
                                          optionTitleCounter={fimRowOption}
                                          imageOptionSelected={
                                            imageOptionSelected
                                          }
                                        />
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </ContainerTest>
                            );
                          })
                        );
                      }

                      if (
                        question.addQuestion &&
                        chosenQuestion === questionsList[index]
                      ) {
                        return optionTemplate.addOptions.map((addOption, i) => {
                          return (
                            <SingleOptionItemWrapper
                              marginBottom
                              optionIx={index}
                              addOptionIx={i}
                              option={addOption}
                              key={i}
                              id={uuidv4()}
                              availableLanguages={question.availableLanguages}
                              removeOption={removeOption2}
                              errors={errors}
                              touched={touched}
                              values={values.options}
                              onChange={handleChange}
                              editOption={editOption2}
                              toggleCorrectAnswer={toggleCorrectAnswer}
                              onBlur={handleBlur}
                              optionTitleCounter={`Option ${i + 1}`}
                              imageOptionSelected={imageOptionSelected}
                            />
                          );
                        });
                      } else
                        return <React.Fragment key={index}></React.Fragment>;
                    }
                  )}
              </RightColumn>
            </InnerContainer>
            <FullColumn>
              <ButtonContainer>
                <Button secondary text='Back' onClick={() => setPage(1)} />
                <MarginButton />
                <Button primary type='submit' text='Save' />
              </ButtonContainer>
            </FullColumn>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const TitleText = styled.p`
  padding-left: ${(props) => props.theme.spacing.standard};
  padding-bottom: ${(props) => props.theme.spacing.standard};
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
`;
const ContainerTest = styled.div`
  background: ${(props) => props.theme.color.mediumGrey};
  border: 1px solid transparent;
  padding-top: ${(props) => props.theme.spacing.standard};
  margin-bottom: ${(props) => props.theme.spacing.standard};
  border-radius: 0.4rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const FullColumn = styled.div`
  width: 100%;
  margin-top: 8rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  background: ${(props) => props.theme.color.white};
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  padding: ${(props) => props.theme.spacing.standard};
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

const MarginButton = styled.div`
  width: 6rem;
  @media ${device.mobileL} {
    height: ${(props) => props.theme.spacing.small};
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  height: 100%;
`;

const RightColumn = styled.div`
  flex: 1;
  height: 100%;
`;

const ProgressBarSpacing = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;

const Space = styled.div`
  width: ${(props) => props.theme.spacing.standard};
  @media ${device.tablet} {
    height: ${(props) => props.theme.spacing.standard};
  }
`;
