import React from 'react';
import styled from 'styled-components';
import { downChevronDarkGrey } from '../../shared/assets/icons';

export const DropDownSelect = ({
  isRotated,
  onClick,
  selectedItemText,
  activeText,
}) => {
  return (
    <Container onClick={onClick}>
      <Text activeText={activeText}>{selectedItemText}</Text>
      <ChevronContainer>
        <Icon src={downChevronDarkGrey} isRotated={isRotated} />
      </ChevronContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 6rem;
  background: ${(props) => props.theme.color.bgGrey};
  border-radius: 0.4rem;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  font-size: 1.6rem;
  padding-left: ${(props) => props.theme.spacing.standard};
  color: ${(props) => (props.activeText ? '#000' : '#959595')};
`;

const ChevronContainer = styled.div`
  width: 6rem;
  height: 100%;
  background: #e2e2e2;
  position: absolute;
  top: 0;
  right: 0;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img`
  transition: transform 0.2s ease-in-out;
  transform: ${(props) => (props.isRotated ? 'rotate(180deg)' : '')};
`;
