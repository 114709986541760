import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../shared/button/Button';
import { device } from '../../../styles/device';
import { SortingTable } from '../../shared/sorting-table/SortingTable';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { Modal } from '../../shared/modal/Modal';
import { userHasAccess } from '../../../helpers/permissions';
import { TestStatus } from './TestStatus';
import _ from 'lodash';

export const Tests = ({ user, setUser }) => {
  const filterSelected = (user) => {
    return (
      user.omegaTests &&
      user.omegaTests
        .map((test) => {
          if (test.isDeactivated) return false;

          return (
            (test.template && {
              _id: test.template._id,
              title: test.template.title,
              status: <TestStatus test={test} />,
              languages:
                test.template.availableLanguages &&
                test.template.availableLanguages.map((lang, index) => {
                  return index !== test.template.availableLanguages.length - 1
                    ? `${lang.code}, `
                    : lang.code;
                }),
              category: test.template.category && test.template.category.title,
            }) ||
            (test.title && test.category && test)
          );
        })
        .filter(Boolean)
    );
  };

  const [selectedData, setSelectedData] = useState(filterSelected(user));
  const [payload, setPayload] = useState({});
  const [searchString, setSearchString] = useState('');
  const [templates, setTemplates] = useState(null);
  const [tests, setTests] = useState([]);
  const endpoint = '/api/omega/templates';
  const method = 'get';
  const [showModal, setShowModal] = useState(false);

  const columnWidths = ['40%', '30%', '20%', '20%'];
  const customHeaders = ['Title', 'Status', 'Languages', 'Category'];

  useEffect(() => {
    setPayload({
      endpoint,
      method,
      params: { searchString: searchString },
    });
  }, [searchString]);
  const { data, isLoading, hasError } = useFetch(payload, true);

  const searchEntered = (s) => {
    setSearchString(s);
  };

  useEffect(() => {
    if (data && data.testsSaved && data.user) {
      setShowModal(true);
      setUser(data.user);
      setSelectedData(filterSelected(data.user));
    }

    if (hasError) {
      setShowModal(true);
    }
  }, [data, setUser, hasError]);

  const saveTests = () => {
    const dataToUpdate = _.cloneDeep(selectedData);

    // cant send react component to backend
    dataToUpdate.forEach((test) => {
      delete test.status;
    });

    setPayload({
      method: 'post',
      endpoint: `/api/omega/users/save-tests/${user._id}`,
      data: { selectedData: dataToUpdate },
    });
  };

  useEffect(() => {
    if (data && data.data) {
      setTests(data.data);
    }
  }, [data]);

  useEffect(() => {
    const newTemplates = tests
      .map((item) => {
        //TODO: Add this if restrict wich test is able todo more than once
        // if (
        //   selectedData.findIndex(
        //     (selected) =>
        //       selected._id === item._id &&
        //       !item.title.toLowerCase().includes('omega')
        //   ) !== -1
        // ) {
        //   return false;
        // }
        if (
          item.availableLanguages &&
          user.defaultLanguage &&
          item.availableLanguages.some(
            (language) => language.code === user.defaultLanguage.code
          )
        ) {
          return {
            _id: item._id,
            title: item.title,
            status: '',
            languages:
              item.availableLanguages &&
              item.availableLanguages.map((lang, index) => {
                return index !== item.availableLanguages.length - 1
                  ? `${lang.code}, `
                  : lang.code;
              }),
            category: item.category && item.category.title,
          };
        } else if (
          item.availableLanguages.some((subElement) => subElement.code === 'EN')
        ) {
          return {
            _id: item._id,
            title: item.title,
            status: '',
            languages:
              item.availableLanguages &&
              item.availableLanguages.map((lang, index) => {
                return index !== item.availableLanguages.length - 1
                  ? `${lang.code}, `
                  : lang.code;
              }),
            category: item.category && item.category.title,
          };
        } else return null;
      })
      .filter(Boolean);

    // Put all selected tests in the end of the array
    selectedData.forEach((selected) => {
      const selectedIndex = newTemplates.findIndex(
        (item) => item._id === selected._id
      );

      if (selectedIndex !== -1) {
        const selectedItem = newTemplates.splice(selectedIndex, 1);
        newTemplates.push(selectedItem[0]);
      }
    });

    setTemplates(newTemplates);
  }, [tests, selectedData, user.defaultLanguage]);

  return (
    <>
      <Container>
        {isLoading !== 'undefined' && (
          <SortingTable
            showHeaders
            searchBar
            searchString={searchString}
            setSearchString={searchEntered}
            searchBarPlaceholder='Search templates'
            data={templates}
            setData={setTemplates}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            columnWidths={columnWidths}
            customHeaders={customHeaders}
            disabled={userHasAccess('CANDIDATES') !== 'crud'}
          />
        )}
      </Container>
      <Container>
        <ButtonContainer>
          <ButtonInner>
            <Button onClick={saveTests} text='Save tests' primary />
          </ButtonInner>
        </ButtonContainer>
      </Container>
      <Modal
        handleExit={() => setShowModal(false)}
        title={
          (hasError && 'Failed to update tests') || 'Changes have been saved'
        }
        show={showModal}
        width='40rem'
      >
        <Button onClick={() => setShowModal(false)} text='OK' primary />
      </Modal>
    </>
  );
};

const Container = styled.div`
  margin-top: 8rem;
  margin-bottom: ${(props) => props.theme.spacing.medium};
  @media ${device.mobileL} {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  ${(props) =>
    props.projects &&
    css`
      column-gap: 2rem;
      padding: 0 7rem;
    `}
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  padding-top: ${(props) => props.theme.spacing.extraLarge};
  padding-bottom: ${(props) => props.theme.spacing.extraLarge};

  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
    padding-bottom: ${(props) => props.theme.spacing.medium};
  }
`;

const ButtonInner = styled.div`
  display: flex;
  width: 19.5rem;

  @media ${device.mobileL} {
    width: 100%;
  }
`;
