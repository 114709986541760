import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../../styles/device';

export const Tabs = ({
  tabsObject,
  renderContent,
  currentId,
  setCurrentId,
}) => {
  const [active, setActive] = useState(currentId);

  const setStates = (key) => {
    setCurrentId(key);
    setActive(key ? key : Object.keys(tabsObject)[0]);
  };

  tabsObject = tabsObject || { tab: 'Tab' };

  const renderTabs = () => {
    return Object.entries(tabsObject).map(([key, tab]) => (
      <Tab active={active === key} key={key} onClick={() => setStates(key)}>
        <TabText>{tab}</TabText>
      </Tab>
    ));
  };

  renderContent =
    renderContent ||
    (() => {
      switch (currentId) {
        case 'tab':
          return <div>Tab Content</div>;
        default:
          return null;
      }
    });

  return (
    <Container>
      <InnerContainer>
        <TabsContainer>{renderTabs()}</TabsContainer>
      </InnerContainer>
      <ContentContainer>
        {currentId === 'tab' && (
          <ButtonContainer showInMobile></ButtonContainer>
        )}
        {renderContent()}
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InnerContainer = styled.div`
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};

  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  background: ${(props) => props.theme.color.white};
`;

const TabsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  font-size: 4rem;
  overflow: auto;

  @media ${device.mobileL} {
    justify-content: space-between;
  }
`;

const TabText = styled.p`
  text-align: center;
`;

const Tab = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
  height: 4.5rem;
  min-width: 15rem;
  border-radius: 0.9rem 0.9rem 0rem 0rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-size: 1.6rem;
  color: ${(props) => props.theme.color.textGrey};
  padding: ${(props) => props.theme.spacing.standard};
  padding-right: ${(props) => props.theme.spacing.large};

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.color.white};
      color: ${(props) => props.theme.color.black};
      font-weight: bold;
    `}

  @media ${device.tablet} {
    min-width: 12rem;
  }

  @media ${device.mobileL} {
    clip-path: none;
    height: 4rem;
    min-width: 8rem;
    font-size: 1.4rem;
    padding-top: ${(props) => props.theme.spacing.extraSmall};
    padding-bottom: ${(props) => props.theme.spacing.extraSmall};
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 0.5rem;
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  padding-bottom: ${(props) => props.theme.spacing.small};
  padding-top: ${(props) => props.theme.spacing.small};

  ${(props) =>
    props.showInMobile &&
    css`
      display: none;
    `}

  ${(props) =>
    props.hide &&
    css`
      visibility: hidden;
    `}
    
    @media ${device.mobileL} {
        padding-left: ${(props) => props.theme.spacing.small};
        padding-right: ${(props) => props.theme.spacing.small};
            
    ${(props) =>
    props.hideInMobile &&
    css`
        display: none;
      `}
        ${(props) =>
    props.showInMobile &&
    css`
            display: flex;
          `}
    }
`;
