import React from 'react';
import styled from 'styled-components';

export const VideoInstruction = ({ video }) => {
  return <>{video && <Video controls src={video.src} type={video.type} />}</>;
};

const Video = styled.video`
  margin-bottom: 2rem;
  width: 100%;
  height: auto;
`;
