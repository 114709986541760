import React, { useState } from 'react';
import { Field } from 'formik';
import { FileInput } from '../../../shared/input/FileInput';
import styled, { css } from 'styled-components';
import {
  chevronGrey,
  close,
  correctCheckGrey,
  correctCheckWhite,
  penWithBorderBlack,
  addCameraBlack,
  checkedItemCircleBlack,
  radioButtonBlack,
  dropDownBlack,
} from '../../../shared/assets/icons';
import { Languages } from './Languages';

export const SingleOptionItemWrapper = ({
  marginTop,
  marginBottom,
  id,
  removeOption,
  optionTitleCounter,
  option,
  optionIx,
  addOptionIx,
  availableLanguages,
  toggleCorrectAnswer,
  touched,
  values,
  onChange,
  onBlur,
  editOption,
  imageOptionSelected,
  errors,
}) => {
  const [isActive, setIsActive] = useState(false);
  let type = null;
  if (addOptionIx >= 0) {
    type =
      values[availableLanguages[0].code][optionIx].addOptions[addOptionIx]
        .type === 'text'
        ? 'text'
        : values[availableLanguages[0].code][optionIx].addOptions[addOptionIx]
            .type === 'image'
        ? 'image'
        : values[availableLanguages[0].code][optionIx].addOptions[addOptionIx]
            .type === 'checkbox'
        ? 'checkbox'
        : values[availableLanguages[0].code][optionIx].addOptions[addOptionIx]
            .type === 'radio'
        ? 'radio'
        : values[availableLanguages[0].code][optionIx].addOptions[addOptionIx]
            .type === 'dropdown'
        ? 'dropdown'
        : null;
  } else {
    type =
      values[availableLanguages[0].code][optionIx].type === 'text'
        ? 'text'
        : values[availableLanguages[0].code][optionIx].type === 'image'
        ? 'image'
        : values[availableLanguages[0].code][optionIx].type === 'checkbox'
        ? 'checkbox'
        : values[availableLanguages[0].code][optionIx].type === 'radio'
        ? 'radio'
        : values[availableLanguages[0].code][optionIx].type === 'dropdown'
        ? 'dropdown'
        : null;
  }

  const icon =
    type === 'text'
      ? penWithBorderBlack
      : type === 'image'
      ? addCameraBlack
      : type === 'checkbox'
      ? checkedItemCircleBlack
      : type === 'radio'
      ? radioButtonBlack
      : type === 'dropdown'
      ? dropDownBlack
      : null;

  const toggleAccordion = (e) => {
    if (e.target.id) {
      return;
    } else {
      return setIsActive(!isActive);
    }
  };
  const correctAnswer = values[availableLanguages[0].code][optionIx].isCorrect;

  return (
    <Container
      id={id}
      marginTop={marginTop}
      marginBottom={marginBottom}
      isActive={isActive}
      correctAnswer={correctAnswer}
    >
      <TitleRowContent isActive={isActive}>
        <TitleIcon src={icon} />
        <TitleText>{optionTitleCounter}</TitleText>
        <RowOfIcons>
          {!isActive && correctAnswer && (
            <IconWrapper correctAnswer={correctAnswer}>
              <Icon src={correctCheckWhite} />
            </IconWrapper>
          )}
          {isActive ? (
            <>
              <IconWrapper
                pointer
                onClick={() => toggleCorrectAnswer(optionIx)}
                correctAnswer={
                  values[availableLanguages[0].code][optionIx].isCorrect
                }
              >
                <Icon
                  src={correctAnswer ? correctCheckWhite : correctCheckGrey}
                />
              </IconWrapper>

              <IconWrapper
                onClick={() => removeOption(optionIx, addOptionIx)}
                pointer
              >
                <Icon src={close} />
              </IconWrapper>
            </>
          ) : null}
          <IconWrapper onClick={(e) => toggleAccordion(e)} chevron pointer>
            <Icon src={chevronGrey} isRotated={isActive} chevron />
          </IconWrapper>
        </RowOfIcons>
      </TitleRowContent>
      <HiddenContent isActive={isActive}>
        <HiddenInner>
          {type === 'image' &&
            availableLanguages.map((lang, index) => {
              const id = `imageOptions[${lang.code}][${optionIx}]`;
              return (
                <React.Fragment key={index}>
                  {values[lang.code][optionIx].image &&
                    values[lang.code][optionIx].image.thumbnail && (
                      <img
                        src={values[lang.code][optionIx].image.thumbnail}
                        alt='option'
                      />
                    )}
                  <Field
                    as={FileInput}
                    id={id}
                    onChange={imageOptionSelected}
                    value={values.fileInput || ''}
                    error={errors.fileInput && touched.fileInput}
                    name='fileInput'
                    flag={lang.flag}
                    image
                  />
                </React.Fragment>
              );
            })}

          {type !== 'image' && (
            <Languages
              availableLanguages={availableLanguages}
              id={option.id}
              optionIx={optionIx}
              addOptionIx={addOptionIx}
              errors={errors}
              touched={touched}
              values={values}
              onChange={onChange}
              onBlur={onBlur}
              editOption={editOption}
              type={type}
              option
            />
          )}
        </HiddenInner>
      </HiddenContent>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.color.white};
  border: 1px solid transparent;
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${(props) => props.theme.spacing.medium};
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.standard};
    `}
    border-radius: 0.4rem;
  ${(props) =>
    props.correctAnswer &&
    css`
      border: 1px solid ${(props) => props.theme.color.add};
    `}
`;

const TitleRowContent = styled.div`
  height: 6rem;
  position: relative;
  display: flex;
  align-items: center;
`;

const TitleIcon = styled.img`
  margin-left: ${(props) => props.theme.spacing.standard};
`;

const TitleText = styled.p`
  padding-left: ${(props) => props.theme.spacing.standard};
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
`;

const HiddenContent = styled.div`
  opacity: ${(props) => (props.isActive ? '100%' : '0%')};
  display: ${(props) => (props.isActive ? 'flex' : 'none')};
  width: 100%;
  height: fit-content;
  background: ${(props) => props.theme.color.white};
  overflow: hidden;
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
`;

const HiddenInner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: ${(props) => props.theme.spacing.standard};
  flex-direction: column;
`;

const RowOfIcons = styled.div`
  position: absolute;
  top: -1.6rem;
  right: ${(props) => props.theme.spacing.large};
  display: flex;
  justify-content: flex-end;
`;

const IconWrapper = styled.div`
  box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.12);
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.theme.spacing.small};
  background: ${(props) => props.theme.color.white};
  &:last-child {
    margin-right: 0;
  }
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  ${(props) =>
    props.correctAnswer &&
    css`
      background: ${(props) => props.theme.color.add};
    `}
  ${(props) =>
    props.editMode &&
    css`
      background: ${(props) => props.theme.color.selectedGrey};
    `}
`;

const Icon = styled.img`
  object-fit: cover;
  ${(props) =>
    props.chevron &&
    css`
      transition: transform 0.2s ease-in-out;
      transform: ${(props) => (props.isRotated ? 'rotate(90deg)' : '')};
      height: 1.8rem;
      width: 1rem;
    `}
  ${(props) =>
    props.isCorrect &&
    css`
      width: 1.7rem;
      height: 1.3rem;
      cursor: default;
    `}
`;
