import React, { useState, useEffect, Fragment } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/shared/button/Button';
import { InputField } from '../../../components/shared/input/InputField';
import { Modal } from '../../../components/shared/modal/Modal';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { device } from '../../../styles/device';
import { InputWrapper } from '../../../components/shared/input/InputWrapper';
import {
  LanguageItem,
  LanguageItemWrapper,
} from '../../../components/admin/questions/edit-question/LanguageItem';
import { Languages } from '../../../components/admin/questions/edit-question/Languages';
import { Type } from '../../../components/admin/questions/edit-question/Type';

export const EditFacet = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [payload, setPayload] = useState({});
  const [languagePayload] = useState({ endpoint: '/api/omega/languages/' });
  const [languages, setLanguages] = useState(null);
  const [facet, setFacet] = useState(null);
  const [isHidden, setIsHidden] = useState(false);
  const [chosenLanguage, setChosenLanguage] = useState([]);
  const paramsData = useParams();
  const slug =
    (paramsData && paramsData.slug) ||
    (props.location && props.location.state && props.location.state.slug);
  const endpoint = `/api/omega/facets/slug/${slug}`;

  //TODO fixa så den passar facets beskrivning
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This is a required field.'),
  });

  const { data, isLoading, hasError } = useFetch(payload);
  const languagesData = useFetch(languagePayload);

  const initialValues = facet && {
    title: facet.title,
    titles: facet.titles || {},
    descriptions: facet.descriptions || {},
    standardDeviation: facet.standardDeviation || 5,
    averageValue: facet.averageValue || 15,
  };

  useEffect(() => {
    if (data && data.data) {
      data.data.availableLanguages = data.data.availableLanguages || [];
      setFacet(data.data);
      setIsHidden(data.data.isHidden || false);
    }
  }, [data]);

  useEffect(() => {
    if (languagesData && languagesData.data && languagesData.data.data) {
      setLanguages(languagesData.data.data);
    }
  }, [languagesData]);

  useEffect(() => {
    setPayload({ endpoint, method: 'get' });
  }, [endpoint]);

  const addLanguage = (language, key) => {
    const newChosenLanguage = chosenLanguage;
    const langIx = facet.availableLanguages.findIndex(
      (availableLanguage) => availableLanguage.code === language.code
    );
    const chosenIx = newChosenLanguage.findIndex(
      (chosenKey) => chosenKey === key
    );
    if (langIx === -1) {
      facet.descriptions = facet.descriptions || {};
      if (!facet.descriptions[language.code]) {
        // TODO : fixa text till rätt object
        facet.descriptions[language.code] = { text: '' };
      }
      facet.availableLanguages.push(language);
      newChosenLanguage.push(key);
    } else {
      facet.availableLanguages.splice(langIx, 1);
      newChosenLanguage.splice(chosenIx, 1);
      delete facet.descriptions[language.code];
    }

    setChosenLanguage([...newChosenLanguage]);
  };

  useEffect(() => {
    if ((data && data.data && data.facetSaved) || hasError) {
      setShowModal(true);
    }
  }, [data, hasError]);

  return (
    <>
      <Container>
        <InnerContainer>
          {!isLoading && facet && (
            <>
              <MainTitle>Edit facet</MainTitle>
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={async (values) => {
                  const availableLanguages = facet.availableLanguages.map(
                    (lang) => lang._id
                  );
                  const newFacet = {
                    _id: facet._id,
                    title: values.title,
                    descriptions: values.descriptions,
                    titles: values.titles,
                    standardDeviation: values.standardDeviation,
                    averageValue: values.averageValue,
                    availableLanguages,
                    isHidden,
                  };

                  setPayload({
                    method: 'patch',
                    data: newFacet,
                    endpoint: '/api/omega/facets',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  });
                }}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <InnerForm>
                      <LeftColumn>
                        <InputWrapper title='Title'>
                          <InputField
                            label='Internal title'
                            id='title'
                            type='text'
                            name='title'
                            placeholder=''
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            error={errors.title && touched.title}
                            message={
                              errors.title && touched.title && `${errors.title}`
                            }
                          />
                          {facet.availableLanguages.map((language) => (
                            <Fragment key={language.code}>
                              <Title>{language.language} title</Title>
                              <Field
                                as={InputField}
                                id={`title.${language.language}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.titles[language.code] || ''}
                                error={errors.titles && touched.titles}
                                type='text'
                                name={`titles.${language.code}`}
                                placeholder={`${language.language} title`}
                              />
                            </Fragment>
                          ))}
                        </InputWrapper>
                        <Margin small />
                        <InputWrapper title='Standard deviation'>
                          <InputField
                            id='standardDeviation'
                            type='number'
                            name='standardDeviation'
                            placeholder=''
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.standardDeviation}
                            error={
                              errors.standardDeviation &&
                              touched.standardDeviation
                            }
                            message={
                              errors.standardDeviation &&
                              touched.standardDeviation &&
                              `${errors.standardDeviation}`
                            }
                          />
                        </InputWrapper>
                        <Margin small />
                        <InputWrapper title='Average value'>
                          <InputField
                            id='averageValue'
                            type='number'
                            name='averageValue'
                            placeholder=''
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.averageValue}
                            error={errors.averageValue && touched.averageValue}
                            message={
                              errors.averageValue &&
                              touched.averageValue &&
                              `${errors.averageValue}`
                            }
                          />
                        </InputWrapper>
                        <Margin small />
                        <InputWrapper title='Test facet'>
                          <Type
                            chosenType={isHidden}
                            setChosenType={setIsHidden}
                            yesOrNoOption
                          />
                          {isHidden && (
                            <DynamicValues>
                              <DynamicTitle>Average value:</DynamicTitle>
                              <DynamicValue>
                                {facet.dynamicValues.averageValue}
                              </DynamicValue>
                              <DynamicTitle>Amount of test:</DynamicTitle>
                              <DynamicValue>
                                {facet.dynamicValues.amount}
                              </DynamicValue>
                            </DynamicValues>
                          )}
                        </InputWrapper>
                      </LeftColumn>
                      <RightColumn>
                        <InputWrapper title='Languages'>
                          <LanguageItemWrapper>
                            {languages &&
                              languages.length > 0 &&
                              languages.map((language, key) => (
                                <LanguageItem
                                  key={key}
                                  onClick={() => addLanguage(language, key)}
                                  code={language.code}
                                  flag={language.flag}
                                  active={facet.availableLanguages.some(
                                    (pickedLanguage) =>
                                      pickedLanguage._id === language._id
                                  )}
                                />
                              ))}
                          </LanguageItemWrapper>
                        </InputWrapper>
                        {facet.availableLanguages &&
                          facet.availableLanguages.length > 0 && (
                            <>
                              <InputWrapper>
                                <Languages
                                  availableLanguages={facet.availableLanguages}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  facet
                                />
                              </InputWrapper>
                            </>
                          )}
                      </RightColumn>
                    </InnerForm>
                    <Margin />

                    <Button
                      primary
                      type='submit'
                      disabled={isSubmitting}
                      text={'Save facet'}
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
        </InnerContainer>
      </Container>
      <Modal
        handleExit={() => setShowModal(false)}
        title={(hasError && 'Something went wrong') || 'Facet saved'}
        show={showModal}
        width='40rem'
      >
        <ModalContent>
          <Button onClick={() => setShowModal(false)} text='OK' primary />
        </ModalContent>
      </Modal>
    </>
  );
};

const MainTitle = styled.p`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
`;

const Margin = styled.div`
  height: ${(props) =>
    props.small ? props.theme.spacing.small : props.theme.spacing.extraLarge};
`;

const LeftColumn = styled.div`
  flex: 1;
  height: 100%;
  max-width: 400px;
  width: 100%;
  @media ${device.tablet} {
    max-width: 100%;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 1rem;
  max-width: 400px;
  @media ${device.tablet} {
    max-width: 100%;
  }
`;

const Container = styled.div`
  background: ${(props) => props.theme.color.bgGrey};
  min-height: calc(100vh - 224px);
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${device.laptop} {
    padding: 0 ${(props) => props.theme.spacing.extraLarge};
  }
  @media ${device.mobileL} {
    padding: 0 ${(props) => props.theme.spacing.small};
  }
`;

const InnerContainer = styled.div`
  width: 804px;
`;

const InnerForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Title = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${(props) =>
    props.error
      ? `1px solid ${props.theme.color.error}`
      : `1px solid ${props.theme.color.darkBlue}`};
`;

const ModalContent = styled.div`
  font-size: 4rem;
  flex-direction: column;
`;

const DynamicValues = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  margin-top: 2rem;
  font-size: 1.8rem;
`;

const DynamicTitle = styled.div`
  font-weight: 600;
  margin-top: 1rem;
  font-size: 1.6rem;
`;

const DynamicValue = styled.div`
  margin-top: 1rem;
  font-size: 1.4rem;
`;
