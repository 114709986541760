import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  showCardsDark,
  showListDark,
  showList,
  showCards,
} from '../assets/icons';

export const ViewSwitch = ({ setActiveButton }) => {
  const [listActive, setListActive] = useState(false);
  const [cardsActive, setCardsActive] = useState(true);

  const handleListClick = () => {
    setListActive(true);
    setCardsActive(false);
    setActiveButton('list');
  };
  const handleCardsClick = () => {
    setCardsActive(true);
    setListActive(false);
    setActiveButton('cards');
  };

  return (
    <Container>
      <Icon
        active={cardsActive}
        onClick={handleCardsClick}
        src={cardsActive ? showCardsDark : showCards}
        alt='Cards icon'
        className='cards'
      />
      <Icon
        active={listActive}
        onClick={handleListClick}
        src={listActive ? showListDark : showList}
        alt='List icon'
        className='list'
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;

  margin-left: ${(props) => props.theme.spacing.standard};

  border-radius: 1rem;
  background-color: ${(props) => props.theme.color.darkGrey};
  color: ${(props) => props.theme.color.offGrey};
`;

const Icon = styled.img`
  cursor: pointer;
  padding: 0.8rem;
  transition: background-color 0.2s ease-in-out;

  :first-child {
    margin-right: 0.8rem;
  }
  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.color.shadow};
      border-radius: 0.5rem;
    `}
`;
