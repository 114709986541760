import React, { useState } from 'react';
import styled from 'styled-components';
import { Route, Redirect, Switch } from 'react-router-dom';
import { routes } from '../../components/candidate/navigation/routes';
import { useFetch } from '../../helpers/hooks/fetchData';
import { Header } from '../../components/candidate/navigation/Header';
import { Footer } from '../../components/candidate/navigation/Footer';
import { device } from '../../styles/device';
import { CandidateProfile } from './profile';
import { Test } from './test/test';

export const CandidateIndex = ({ history }) => {
  const pathname = history.location.pathname;
  const mainComponent = `/${pathname.split('/')[2]}`;
  const [deepRoute, setDeepRoute] = useState(pathname.split('/').length > 4);
  const routeIndex = routes.findIndex((r) => r.path === mainComponent);
  const [route] = useState(routes[routeIndex]);

  const endpoint = '/api/omega/users/candidate/fetch-my-info';
  const method = 'get';
  const [payload] = useState({ endpoint, method });
  const { data } = useFetch(payload);

  return (
    <MainContainer>
      <EntireWrapper>
        <Header user={data} />
        <Switch>
          <Route path='/candidate/home'>
            <CandidateProfile data={data} route={route || routes[0]} />
          </Route>

          <Route path='/candidate/test'>
            <Test
              route={route || routes[0]}
              deepRoute={deepRoute}
              setDeepRoute={setDeepRoute}
            />
          </Route>

          <Redirect from='/candidate' to='/candidate/home' />
        </Switch>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </EntireWrapper>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  font-family: 'futura-pt', sans-serif, 'Arial';
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const EntireWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  position: relative;
  min-height: 100vh;
  padding-bottom: 11rem;
  @media ${device.tablet} {
    padding-bottom: 6rem;
  }
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
