import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../styles/device';
import { ListButton } from './ListButton';
import { ContentContext } from '../../../helpers/contentContext';

export const SingleTestRow = ({
  handleClick,
  test,
  index,
  //previousTest
}) => {
  const content = useContext(ContentContext) || {};
  const [testDisabled, setTestDisabled] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [startNew, setStartNew] = useState(false);

  const getPropsForTest = () => {
    if (index || !index) {
      if (!test.isInitialized) {
        setStartNew(true);
        setButtonText(content.start);
      }
      if (test.isInitialized) {
        if (!test.isCompleted) {
          setButtonText(content.continue);
        }
        if (test.isCompleted) {
          setButtonText(content.done);
          setTestDisabled(true);
        }
      }
    }

    /*    if (index > 0) {
      if (previousTest.isCompleted && !test.isInitialized) {
        setTestDisabled(false);
        setButtonText(content.start);
      } else if (!previousTest.isInitialized || !previousTest.isCompleted) {
        setTestDisabled(true);
        setButtonText(content.notStarted);
      } else if (previousTest.isInitialized) {
        if (!test.isInitialized) {
          setStartNew(true);
          setButtonText(content.notStarted);
        }
        if (test.isInitialized) {
          if (!test.isCompleted) {
            setButtonText(content.continue);
          }
          if (test.isCompleted) {
            setButtonText(content.done);
            setTestDisabled(true);
          }
        }
      }
    }*/
  };

  useEffect(() => {
    getPropsForTest();
  });
  return (
    <Container startNew={startNew}>
      <TestName>
        {test.template &&
          ((test.template.titles && test.template.titles[content.langCode]) ||
            test.template.title)}
      </TestName>

      <ListButton
        onClick={!testDisabled ? () => handleClick(index) : null}
        startNew={startNew}
        testDisabled={testDisabled}
        isDisabled={testDisabled ? true : false}
        text={buttonText}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 5.7rem;
  border-radius: 0.4rem;
  background: ${(props) => props.theme.color.bgGrey};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
  margin: 0.5rem 0;
  @media ${device.mobileL} {
    padding: 0 2rem;
  }
  ${(props) =>
    props.startNew &&
    css`
      background: ${(props) => props.theme.color.darkGrey};
      margin: 0 1.5rem;
      padding: 0 1.5rem;
      @media ${device.mobileL} {
        margin: 0;
        padding: 0 2rem;
      }
    `}
`;

const TestName = styled.p`
  font-weight: 500;
  font-size: 2.2rem;
  text-overflow: ellipsis; //TODO: fix so ellipsis work
  @media ${device.mobileL} {
    font-size: 1.8rem;
  }
`;
