import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '../../shared/button/Button';
import { Modal } from '../../shared/modal/Modal';
import { useFetch } from '../../../helpers/hooks/fetchData';

export const ArchiveModal = ({
  title,
  endpoint,
  showModal,
  setShowModal,
  id,
  setObjects,
}) => {
  const [payload, setPayload] = useState({});
  const [isArchived, setIsArchived] = useState(false);

  const { data, hasError } = useFetch(payload, true);

  useEffect(() => {
    if (data && data.data && (data.archived || typeof data.data === 'string')) {
      setIsArchived(true);
      setObjects((objects) => {
        const newObjects = objects.filter((obj) => obj._id !== id);
        return newObjects;
      });
    }
  }, [data, setObjects, id]);

  const deleteObject = () => {
    const method = endpoint.includes('delete') ? 'delete' : 'patch';
    setPayload({ endpoint, method });
  };

  return (
    <>
      <Button
        add
        onClick={() => {
          setShowModal(true);
        }}
      />

      <Modal
        show={showModal}
        handleExit={setShowModal}
        width={'40rem'}
        title={`Delete ${title}`}
      >
        {!isArchived && !hasError && (
          <>
            <Label>Are you sure you want to delete this {title}?</Label>
            <Margin />
            <Button
              type='submit'
              onClick={deleteObject}
              primary
              text='Delete'
            />

            <PaddingBetweenButton />
            <Button
              onClick={() => setShowModal(false)}
              secondary
              text='Cancel'
              type='reset'
            />
          </>
        )}
        {isArchived && (
          <>
            <Label>
              <OkTitle>{title} </OkTitle>
              was deleted
            </Label>
            <Button secondary onClick={() => setShowModal(false)} text='OK' />
          </>
        )}
        {hasError && (
          <>
            <ErrorMessage>Error deleting {title}</ErrorMessage>
            <Button secondary onClick={() => setShowModal(false)} text='OK' />
          </>
        )}
      </Modal>
    </>
  );
};

const Label = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
`;

const OkTitle = styled.span`
  text-transform: capitalize;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 30px;
`;

const Margin = styled.div`
  height: ${(props) => props.theme.spacing.small};
`;
const PaddingBetweenButton = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;
