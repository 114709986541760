import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { switchGrey } from '../../shared/assets/icons';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { UserContext } from '../../../pages/candidate/profile';
import { ContentContext } from '../../../helpers/contentContext';

export const UploadCV = ({ setUpdateCV }) => {
  const content = useContext(ContentContext) || {};
  const [payload, setPayload] = useState({});
  const { data, isLoading } = useFetch(payload);
  const dispatch = useContext(UserContext);

  useEffect(() => {
    const updateState = (data) => {
      dispatch({ type: 'update', payload: { cv: data.data.cvFileName } });
      if (setUpdateCV) {
        setUpdateCV(false);
      }
    };
    if (data) {
      updateState(data);
    }
    if (isLoading) {
      //TODO: add animation for cv upload
    }
  }, [data, isLoading, dispatch, setUpdateCV]);

  const validationSchema = Yup.object().shape({
    file: Yup.string().required(content.errorRequiredField),
  });

  const initialValues = { file: '' };
  const [fileValue, setFileValue] = useState('');
  const [activateBrowse, setActivateBrowse] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const selectFile = (event) => {
    setSelectedFile(
      event.target && event.target.files && event.target.files[0]
    );
  };

  const setLabel = (event) => {
    if (event.target.files[0].name !== '') {
      let string = event.target.files[0].name;
      setActivateBrowse(true);
      if (string.length > 18) {
        setFileValue(string.substring(0, 18) + '...');
      } else {
        setFileValue(string);
      }
    } else {
      return;
    }
  };

  return (
    <Container>
      <ContentContainer>
        <Text>{content.uploadCv}</Text>
        <InputFileContainer>
          <Formik
            validationSchema={validationSchema}
            validateOnChange={true}
            initialValues={initialValues}
            onSubmit={() => {
              const formData = new FormData();
              formData.append('cv', selectedFile);

              setPayload({
                method: 'post',
                endpoint: '/api/omega/users/upload-cv/',
                data: formData,
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });

              setFileValue('');
              setActivateBrowse(false);
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              errors,
              touched,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                {errors.file && touched.file && (
                  <ErrorMessage>{errors.file}</ErrorMessage>
                )}
                <Input
                  name='file'
                  type='file'
                  value={values.file || ''}
                  onChange={(event) => {
                    if (event.target.files[0]) {
                      setFieldValue('file', event.target.files[0]);
                    } else {
                      setFieldValue('file', null);
                    }
                    selectFile(event);
                    setLabel(event);
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  id='file'
                  setFieldValue={setFieldValue}
                  error={errors.file && touched.file}
                  accept='.pdf, .png, .jpeg, .jpg'
                />
                <FileNameTextContainer>
                  <FileNameText>{fileValue ? fileValue : ''}</FileNameText>
                </FileNameTextContainer>
                {fileValue && (
                  <IconContainer
                    onClick={() => setActivateBrowse(!activateBrowse)}
                  >
                    <Icon src={switchGrey} />
                  </IconContainer>
                )}
                <ButtonContainer>
                  {activateBrowse ? (
                    <StyledSubmitButton
                      type='submit'
                      onSubmit={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {content.upload}
                    </StyledSubmitButton>
                  ) : (
                    <StyledBrowseLabel htmlFor='file'>
                      {content.browse}
                    </StyledBrowseLabel>
                  )}
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        </InputFileContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.bgGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9rem;
  @media (max-width: 615px) {
    height: 12rem;
  }
`;

const ContentContainer = styled.div`
  width: 65rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 5.1rem;
  @media ${device.tablet} {
    width: 95%;
  }
  @media (max-width: 615px) {
    width: 42.5rem;
    height: 9rem;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
  @media (max-width: 450px) {
    width: 95%;
  }
`;

const Text = styled.p`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 3.5rem;
  @media (max-width: 615px) {
    font-size: 1.8rem;
    line-height: 2.2rem;
    align-self: flex-start;
    margin-top: 0.5rem;
  }
`;

const InputFileContainer = styled.div`
  position: relative;
  right: 0;
  height: 5.1rem;
  width: 42.5rem;
  background: ${(props) => props.theme.color.white};
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const Input = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const FileNameTextContainer = styled.div`
  width: 100%;
  padding-left: 0.7rem;
`;

const FileNameText = styled.span`
  width: 85%;
  font-size: 2.2rem;
  line-height: 3.5rem;
  color: #686868;
  padding-left: ${(props) => props.theme.spacing.extraSmall};
  @media ${device.mobileL} {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

const ButtonContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledBrowseLabel = styled.label`
  border: 1px solid ${(props) => props.theme.color.darkBlue};
  color: ${(props) => props.theme.color.darkBlue};
  width: 12rem;
  height: 3.5rem;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 0.05em;
  text-transform: lowercase;
  margin-right: 0.7rem;
  cursor: pointer;
  @media ${device.mobileL} {
    font-size: 1.8rem;
    line-height: 3.2rem;
  }
`;
const StyledSubmitButton = styled.button`
  width: 12rem;
  height: 3.5rem;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.2rem;
  letter-spacing: 0.05em;
  text-transform: lowercase;
  margin-right: 0.7rem;
  cursor: pointer;
  background: ${(props) => props.theme.color.darkBlue};
  color: ${(props) => props.theme.color.white};
  @media ${device.mobileL} {
    font-size: 1.7rem;
    line-height: 3.2rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
`;

const ErrorMessage = styled.span`
  color: ${(props) => props.theme.color.error};
  position: absolute;
  top: 0.9rem;
  left: 1.5rem;
  font-size: 1.7rem;
  line-height: 3.2rem;
`;

const Icon = styled.img``;
const IconContainer = styled.div`
  cursor: pointer;
  margin-right: ${(props) => props.theme.spacing.small};
`;
