import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../styles/device';
import { eyeDark } from '../../../../components/shared/assets/icons';
import { InputWrapper } from '../../../shared/input/InputWrapper';
import { InputField } from '../../../shared/input/InputField';

export const PreviewNumberQuestion = ({ values, setNumbers }) => {
  function formatInput(input) {
    const regex = /[ ]+/gi;
    return input.replace(regex, '\t');
  }

  return (
    <InputWrapper title='Number question'>
      <Container>
        <IconContainer>
          <Icon src={eyeDark} />
        </IconContainer>
        <Value>
          <Text>{formatInput(values || 'Ex: 18 37 94 495 700 ?')}</Text>
        </Value>
      </Container>
      <InputField
        id='numbers'
        value={values}
        type='text'
        name='numbers'
        placeholder='Ex: 18 37 94 495 700 ?'
        onChange={(e) => setNumbers(e.target.value)}
      />
    </InputWrapper>
  );
};

const Container = styled.div`
  position: relative;
  border-radius: 0.4rem;
  background: ${(props) => props.theme.color.bgGrey};
  margin-bottom: ${(props) => props.theme.spacing.extraSmall};
  height: 6rem;
`;

const Value = styled.div`
  padding: 2rem 0 2rem 1.5rem;
  word-spacing: ${(props) => props.theme.spacing.standard};
  line-height: 1.9rem;
`;

const IconContainer = styled.div`
  position: absolute;
  top: -1.6rem;
  right: 3rem;
  @media ${device.mobileL} {
    right: 1.5rem;
  }
  border: 0.3rem solid #959595;
  box-sizing: border-box;
  box-shadow: 0px 0.5px 6px rgba(0, 0, 0, 0.09);
  background: ${(props) => props.theme.color.white};
  border-radius: 50%;
  height: 3.2rem;
  width: 3.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = styled.img``;

const Text = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
`;
