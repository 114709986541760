import React, { Component } from 'react';
import styled, { css } from 'styled-components';
// import { Thumbnail } from '../../../components/shared/thumbnail/Thumbnail';

export const TableHeaderToPrint = () => {
  return (
    <Header>
      <HeaderItem>Test name</HeaderItem>
      <HeaderItem>Completed</HeaderItem>
      <HeaderItem>Time left</HeaderItem>
      <HeaderItem>Result</HeaderItem>
      <HeaderItem>I</HeaderItem>
      <HeaderItem>II</HeaderItem>
    </Header>
  );
};

const renderCorrectOrIncorrectRow = (question, title, top) => {
  return (
    <OmegaRow top={top} noOmega>
      <OmegaItem first>{title}</OmegaItem>
      <OmegaItem center>
        {question
          ? question.totalCorrectAnswers + ' / ' + question.totalAnswered
          : '/'}
      </OmegaItem>
      <OmegaItem center>
        {question ? Math.round(question.stanine1) : ''}
      </OmegaItem>
      <OmegaItem center>
        {question ? Math.round(question.stanine2) : ''}
      </OmegaItem>
      <OmegaItem></OmegaItem>
    </OmegaRow>
  );
};

const renderOmegaRow = (categories, title, top) => {
  if (title) {
    const category =
      categories &&
      categories.length &&
      categories.find(
        (_category) => _category.title.toLowerCase() === title.toLowerCase()
      );
    return (
      <OmegaRow top={top}>
        <OmegaItem first>{title}</OmegaItem>
        <OmegaItem center>{category ? category.rp : ''}</OmegaItem>
        <OmegaItem center>
          {category ? Math.round(category.stan) : ''}
        </OmegaItem>
        <OmegaItem></OmegaItem>
      </OmegaRow>
    );
  }

  return <OmegaRow middle />;
};

// Number is for render omega 1 or 2, and index is the count of that test
const renderOmegaHeader = (number, index = 0) => {
  return (
    <OmegaTableHead>
      <HeaderItem first>
        OMEGA {number}
        {index ? ` - ${index + 1}` : ''}
      </HeaderItem>
      <HeaderItem center>RP</HeaderItem>
      <HeaderItem center>Stan</HeaderItem>
      <HeaderItem center>Notes</HeaderItem>
    </OmegaTableHead>
  );
};

// Index is for render omega 1 or 2
const renderOmegaTable = (index, omegaTest) => {
  const test = omegaTest || { categories: [] };
  if (index === 1)
    return (
      <React.Fragment>
        {renderOmegaRow(test.categories, 'Social need', true)}
        {renderOmegaRow(test.categories, 'Self confidence')}
        {renderOmegaRow(test.categories, 'Balance')}
        {renderOmegaRow(test.categories, 'Routine')}
        {renderOmegaRow(test.categories, 'Doubt')}
        {renderOmegaRow(test.categories, 'Security')}
        {renderOmegaRow()}
        {renderOmegaRow(test.categories, 'Thinking')}
        {renderOmegaRow(test.categories, 'Capacity')}
        {renderOmegaRow(test.categories, 'Persistency')}
        {renderOmegaRow(test.categories, 'Contact')}
        {renderOmegaRow(test.categories, 'Trust')}
        {renderOmegaRow(test.categories, 'Impulsiveness')}
      </React.Fragment>
    );

  if (index === 2)
    return (
      <React.Fragment>
        {renderOmegaRow(test.categories, 'Backing', true)}
        {renderOmegaRow(test.categories, 'Empathy')}
        {renderOmegaRow(test.categories, 'Charity')}
        {renderOmegaRow(test.categories, 'Influence')}
        {renderOmegaRow(test.categories, 'Power')}
        {renderOmegaRow(test.categories, 'Self dependence')}
        {renderOmegaRow(test.categories, 'Prestige')}
        {renderOmegaRow(test.categories, 'Competition')}
        {renderOmegaRow()}
        {renderOmegaRow(test.categories, 'Failure avoidance')}
        {renderOmegaRow(test.categories, 'Fighting Spirit')}
        {renderOmegaRow(test.categories, 'Change')}
        {renderOmegaRow(test.categories, 'Cost benefit')}
        {renderOmegaRow(test.categories, 'Challenge')}
        {renderOmegaRow(test.categories, 'Incisiveness')}
        {renderOmegaRow(test.categories, 'Structure')}
        {renderOmegaRow(test.categories, 'Goal dependence')}
      </React.Fragment>
    );
};

const findCorrectOrIncorrect = (items, title) => {
  return items.find(
    (questions) =>
      questions.resultsType === 'correctOrIncorrect' &&
      questions.title &&
      questions.title.toLowerCase() === title
  );
};

const renderPersonalFactorsPage = (items) => {
  const omegaOne = items.filter(
    (questions) =>
      questions.resultsType === 'omega' && questions.title === 'Omega 1'
  );

  const omegaTwo = items.filter(
    (questions) =>
      questions.resultsType === 'omega' && questions.title === 'Omega 2'
  );

  const opposites = findCorrectOrIncorrect(items, 'opposites');
  const analogies = findCorrectOrIncorrect(items, 'analogies');
  const numbersSequences = findCorrectOrIncorrect(items, 'number sequences');
  const figures = findCorrectOrIncorrect(items, 'figures in patterns');

  return (
    <React.Fragment>
      <Table key='table' new>
        <OmegaTableHead noOmega>
          <HeaderItem></HeaderItem>
          <HeaderItem center>RP/QC</HeaderItem>
          <HeaderItem center>Stan I</HeaderItem>
          <HeaderItem center>Stan II</HeaderItem>
          <HeaderItem center>Notes</HeaderItem>
        </OmegaTableHead>
        {renderCorrectOrIncorrectRow(opposites, 'Opposites', true)}
        {renderCorrectOrIncorrectRow(analogies, 'Analogies')}
        {renderCorrectOrIncorrectRow(numbersSequences, 'Number sequences')}
        {renderCorrectOrIncorrectRow(figures, 'Figures in patterns')}
      </Table>
      <Table key='Omega 1' new>
        {renderOmegaHeader(1)}
        {renderOmegaTable(1, omegaOne[0])}
      </Table>

      <Table key='Omega 2' new>
        {renderOmegaHeader(2)}
        {renderOmegaTable(2, omegaTwo[0])}
      </Table>

      <TextArea>Conclusions</TextArea>
      {omegaOne[1] && (
        <InformationContainer newPage>
          <OmegaTitle>
            <div>Extra Omega 1</div>
          </OmegaTitle>
          {omegaOne.map((test, index) =>
            index ? (
              <Table key={`Omega 1 - ${index}`} new>
                {renderOmegaHeader(1, index)}
                {renderOmegaTable(1, test)}
              </Table>
            ) : null
          )}
        </InformationContainer>
      )}
      {omegaTwo[1] && (
        <InformationContainer newPage>
          <OmegaTitle>
            <div>Extra Omega 2</div>
          </OmegaTitle>
          {omegaTwo.map((test, index) =>
            index ? (
              <Table key={`Omega 2 - ${index}`} new>
                {renderOmegaHeader(2, index)}
                {renderOmegaTable(2, test)}
              </Table>
            ) : null
          )}
        </InformationContainer>
      )}
    </React.Fragment>
  );
};

export const CandidateInformationToPrint = ({ user }) => {
  const dateOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  return (
    <div>
      <ProfileContainer>
        {/*<Thumbnail
          image={user.profileImage && user.profileImage.original}
          name={user.name}
          size='5.5'
        />*/}
        <InnerContainer>
          <OmegaTitle>
            <div>Personality factors</div>
            <DateText>
              {new Date().toLocaleDateString('en-GB', dateOptions)}
            </DateText>
          </OmegaTitle>

          <div>
            <SmallTitle>
              Name: <SmallTitleInfo>{user.name}</SmallTitleInfo>
            </SmallTitle>

            <SmallTitle>
              Company: <SmallTitleInfo>{user.info.company}</SmallTitleInfo>
            </SmallTitle>

            <SmallTitle>
              Position: <SmallTitleInfo>{user.info.position}</SmallTitleInfo>
            </SmallTitle>

            <SmallTitle>
              Project Leader:{' '}
              <SmallTitleInfo>
                {user.info.omegaConsultant && user.info.omegaConsultant.name}
              </SmallTitleInfo>
            </SmallTitle>
          </div>
          {/*<CurrentPosition>
            {user.info.oldPosition} - {user.info.oldCompany}
          </CurrentPosition> */}
        </InnerContainer>
      </ProfileContainer>
    </div>
  );
};

const renderQuestions = (question, title, height) => {
  return (
    <InformationItem key={title} minHeight={height ? height : '35px'}>
      <Question>{title}</Question>
      <Answer>
        {question && question.records[0] ? question.records[0].answerValue : ''}
      </Answer>
    </InformationItem>
  );
};

const renderAddQuestions = (answers, amount) => {
  if (answers) {
    return answers.map((answer, index) => {
      return (
        <InformationItem key={answer.answerValue + index}>
          <Answer>
            {//TODO: switch number to text if wanted
            answer.answerValue.number
              ? answer.answerValue.number
              : answer.answerValue.text
              ? answer.answerValue.text
              : answer.answerValue}
          </Answer>
        </InformationItem>
      );
    });
  }
  const array = [];
  for (let i = amount; i > 0; i--) {
    array.push(
      <InformationItem key={i}>
        <Answer></Answer>
      </InformationItem>
    );
  }
  return array;
};

const renderMoreAddQuestions = (records, rowItemAmount, startIndex) => {
  if (startIndex + 1 > records.length) {
    return;
  }
  return records.map((answers, index) => {
    if (startIndex > index) {
      return '';
    }
    return (
      <InfoRow key={index} amount={rowItemAmount}>
        {answers.map((answer, i) => {
          return (
            <InformationItem
              key={
                answer.answerValue.number
                  ? answer.answerValue.number + i
                  : answer.answerValue + i
              }
            >
              <Answer>
                {//TODO: switch number to text if wanted
                answer.answerValue.number
                  ? answer.answerValue.number
                  : answer.answerValue}
              </Answer>
            </InformationItem>
          );
        })}
      </InfoRow>
    );
  });
};

const renderExtraRows = (
  recordsOne,
  recordsTwo,
  colums,
  rowItemAmount,
  rows
) => {
  if (recordsOne.length <= rows && recordsTwo.length <= rows) {
    return;
  }
  let checkIfNeedMoreRows = rows - recordsOne.length;
  const checkIfNeedMoreRowsTwo = rows - recordsTwo.length;
  let amountOfRows = checkIfNeedMoreRowsTwo + checkIfNeedMoreRows;

  if (checkIfNeedMoreRows < 0 && checkIfNeedMoreRowsTwo < 0) {
    amountOfRows = checkIfNeedMoreRows - checkIfNeedMoreRowsTwo;
    checkIfNeedMoreRows = checkIfNeedMoreRows - checkIfNeedMoreRowsTwo;
  }

  const array = [];
  if (checkIfNeedMoreRows > 0) {
    for (let i = amountOfRows; 0 < i; i--) {
      array.push(
        <InfoRow key={'emptyRow' + i} amount={rowItemAmount}>
          {renderEmptyItems(colums)}
        </InfoRow>
      );
    }
  }

  return array;
};

const renderEmptyItems = (amount) => {
  const array = [];
  for (let i = amount; i > 0; i--) {
    array.push(<InformationItem key={'emptyItem' + i}></InformationItem>);
  }
  return array;
};

export const renderInformationRecords = (items) => {
  const background = items.find(
    (test) =>
      test.resultsType === 'informationRecords' &&
      test.title.toLowerCase().includes('personal background')
  );

  let amountOfQuestions = 0;

  return (
    <React.Fragment>
      <InformationContainer newPage>
        {background &&
          background.sections.map((section, index) => {
            let questionsAmount = amountOfQuestions + section.questions.length;
            if (
              section.title &&
              section.title[0] &&
              section.title[0].text === 'Language skills'
            ) {
              amountOfQuestions +=
                (section.questions.length > 4 &&
                  Math.round(section.questions.length / 4)) ||
                1;

              return (
                <React.Fragment key={index}>
                  {questionsAmount &&
                    questionsAmount % 5 === 0 &&
                    newPage(amountOfQuestions)}

                  <Table>
                    <InfoRow noBorder amount='1fr'>
                      <InformationHeader>
                        {section.title[0].text}
                      </InformationHeader>
                      <Table style={{ marginRight: '1rem' }}>
                        <InfoRow noBorder amount='1fr 0.5fr 0.5fr'>
                          <HeaderItem personal>Language</HeaderItem>
                          <HeaderItem personal>Written</HeaderItem>
                          <HeaderItem personal>Spoken</HeaderItem>
                        </InfoRow>
                        <InfoRow amount='1fr 0.5fr 0.5fr' top>
                          {renderAddQuestions(
                            section.questions[0]
                              ? section.questions[0].records[0]
                              : '',
                            3
                          )}
                        </InfoRow>
                        <InfoRow amount='1fr 0.5fr 0.5fr'>
                          {renderAddQuestions(
                            section.questions[0]
                              ? section.questions[0].records[1]
                              : '',
                            3
                          )}
                        </InfoRow>
                        <InfoRow amount='1fr 0.5fr 0.5fr'>
                          {renderAddQuestions(
                            section.questions[0]
                              ? section.questions[0].records[2]
                              : '',
                            3
                          )}
                        </InfoRow>
                        <InfoRow amount='1fr 0.5fr 0.5fr'>
                          {renderAddQuestions(
                            section.questions[0]
                              ? section.questions[0].records[3]
                              : '',
                            3
                          )}
                        </InfoRow>

                        {renderMoreAddQuestions(
                          section.questions[0]
                            ? section.questions[0].records
                            : '',
                          '1fr 0.5fr 0.5fr',
                          4
                        )}
                        {renderExtraRows(
                          section.questions[0] && section.questions[0].records
                            ? section.questions[0].records
                            : '',
                          '',
                          3,
                          '1fr 0.5fr 0.5fr',
                          4
                        )}
                      </Table>
                    </InfoRow>
                  </Table>
                </React.Fragment>
              );
            }

            if (
              section.title &&
              section.title[0] &&
              section.title[0].text === 'References'
            ) {
              return (
                <React.Fragment key='Exp'>
                  <InformationContainer newPage />
                  <InformationHeader>References</InformationHeader>
                  <Table>
                    <InfoRow noBorder amount='1fr 1fr 2fr'>
                      <HeaderItem personal>Name</HeaderItem>
                      <HeaderItem personal>Title</HeaderItem>
                      <HeaderItem personal>Phone/Email</HeaderItem>
                    </InfoRow>

                    <InfoRow amount='1fr 1fr 2fr' top>
                      {renderAddQuestions(
                        section.questions[0].records[0] || '',
                        3
                      )}
                    </InfoRow>
                    <InfoRow amount='1fr 1fr 2fr'>
                      {renderAddQuestions(
                        section.questions[0].records[1] || '',
                        3
                      )}
                    </InfoRow>
                    <InfoRow amount='1fr 1fr 2fr'>
                      {renderAddQuestions(
                        section.questions[0].records[2] || '',
                        3
                      )}
                    </InfoRow>
                    {renderMoreAddQuestions(
                      section.questions[0] && section.questions[0].records
                        ? section.questions[0].records
                        : '',
                      '1fr 1fr 2fr',
                      3
                    )}
                  </Table>
                </React.Fragment>
              );
            }

            section.questions.forEach((data) => {
              if (data.records.length > 1)
                questionsAmount += data.records.length - 1;
            });

            const rowsOfQuestions = 9;

            return (
              (section.questions && section.questions.length && (
                <React.Fragment key={index}>
                  {questionsAmount &&
                    (questionsAmount % rowsOfQuestions === 0 ||
                      (questionsAmount % rowsOfQuestions < 5 &&
                        amountOfQuestions % rowsOfQuestions > 5)) &&
                    newPage(amountOfQuestions)}
                  <InformationHeader>{section.title[0].text}</InformationHeader>
                  {section.questions.map((data, key) => {
                    if (data && Array.isArray(data.question)) {
                      return data.records.map((answers) =>
                        answers.map((answer, index) => {
                          amountOfQuestions++;

                          return (
                            <React.Fragment key={index}>
                              {((questionsAmount &&
                                questionsAmount % rowsOfQuestions === 0) ||
                                amountOfQuestions % rowsOfQuestions === 0) &&
                                newPage(amountOfQuestions)}
                              <InfoRow top marginTop key={answer + index}>
                                <InformationItem minHeight={'75px'}>
                                  <Question>{data.question[index]}</Question>
                                  <Answer>{answer.answerValue || ''}</Answer>
                                </InformationItem>
                              </InfoRow>
                            </React.Fragment>
                          );
                        })
                      );
                    }

                    amountOfQuestions++;
                    return (
                      <React.Fragment key={key}>
                        <InfoRow top marginTop>
                          {renderQuestions(data || '', data.question, '75px')}
                        </InfoRow>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              )) ||
              null
            );
          })}
      </InformationContainer>
    </React.Fragment>
  );
};

const newPage = (amountOfQuestions) => {
  amountOfQuestions = 0;
  return <InformationContainer newPage />;
};

//Need to be a class component for ReactPrint to work
export class TableToPrint extends Component {
  render() {
    const { items, user } = this.props;
    const tests = [...items];
    tests.sort(
      (a, b) => new Date(b.testCompletionDate) - new Date(a.testCompletionDate)
    );

    return (
      <Container>
        <React.Fragment>
          <CandidateInformationToPrint user={user} />
          {renderPersonalFactorsPage(tests)}
        </React.Fragment>
        <React.Fragment>{renderInformationRecords(tests)}</React.Fragment>
      </Container>
    );
  }
}
const Container = styled.div`
  margin: ${(props) => props.theme.spacing.large};

  padding-left: ${(props) => props.theme.spacing.small};
  padding-right: ${(props) => props.theme.spacing.small};
  padding-top: ${(props) => props.theme.spacing.small};
  padding-bottom: ${(props) => props.theme.spacing.small};
`;

const Table = styled.div`
  display: grid;
  page-break-inside: avoid;

  ${(props) =>
    props.new &&
    css`
      margin-top: 10px;
    `};
`;

const Header = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 7rem;
  background-color: white;

  font-size: 1.4rem;
  font-weight: 600;
  font-style: italic;
`;
const HeaderItem = styled.div`
  align-items: center;
  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `};
  ${(props) =>
    props.first &&
    css`
      margin-left: 10px;
    `};
  ${(props) =>
    props.personal &&
    css`
      font-size: 1.2rem;
      color: grey;
    `};
  ${(props) =>
    props.right &&
    css`
      margin-right: 0;
      margin-left: auto;
      margin-top: 0.5rem;
    `};
`;

const InformationHeader = styled.header`
  color: #4591c4;
  display: grid;
  flex-direction: row;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: ${(props) => (props.small ? '0.5rem' : '2rem')};
  margin-top: ${(props) => (props.top ? '0rem' : '2rem')};
  ${(props) =>
    props.main &&
    css`
      font-size: 3rem;
    `};
  ${(props) =>
    props.small &&
    css`
      font-size: 1.4rem;
    `};
`;

const TextArea = styled.div`
  padding-top: 4px;
  padding-left: 4px;
  border: 1px solid grey;
  height: 120px;
  margin-top: 20px;
`;

const OmegaTableHead = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.noOmega ? ' 1.31fr 45px 45px 45px 5fr' : '1.2fr 45px 45px 5fr'};
  height: 17px;
  background-color: white;

  font-size: 1rem;
  font-weight: 600;
`;

const OmegaItem = styled.div`
  border-right: 1px solid grey;

  height: 20px;
  line-height: 20px
    ${(props) =>
      props.center &&
      css`
        text-align: center;
      `};
  ${(props) =>
    props.first &&
    css`
      margin-left: 3px;
    `};
`;

const OmegaRow = styled.div`
  border-left: 1px solid grey;
  border-bottom: 1px solid grey;
  ${(props) =>
    props.top &&
    css`
      border-top: 1px solid grey;
    `};
    ${(props) =>
      props.middle &&
      css`
        border-right: 1px solid grey;
      `};
  height: 20px
      
  display: grid;
  align-items: center;
  grid-template-columns: ${(props) =>
    props.noOmega ? ' 1.31fr 45px 45px 45px 5fr' : '1.2fr 45px 45px 5fr'};
  font-size: 1rem;
`;

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.amount};
  border-left: ${(props) => (props.noBorder ? '0px' : '1px')} solid grey;
  border-bottom: ${(props) =>
      props.noBorderBottom || props.noBorder ? '0px' : '1px'}
    solid grey;
  ${(props) =>
    props.top &&
    css`
      border-top: 1px solid grey;
    `};

  ${(props) =>
    props.maxHeight &&
    css`
      max-height: 20px;
    `};
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: 1.5rem;
    `};
`;

const InformationItem = styled.div`
  min-height: ${(props) => (props.minHeight ? props.minHeight : '20px')};
  font-size: 1.2rem;
  border-right: 1px solid grey;

  ${(props) =>
    props.border &&
    css`
      border-left: 1px solid grey;
      border-bottom: 1px solid grey;
    `};
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: 1.5rem;
    `};
`;

const Question = styled.div`
  color: grey;
  margin-left: 3px;
  margin-top: 3px;
  font-size: 1.2rem;
`;

const Answer = styled.div`
  margin-left: 5px;
  margin-top: 3px;
  color: black;
  font-size: 1.2rem;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const InformationContainer = styled.div`
  display: grid;
  font-size: 1.6rem;
  page-break-before: always;
  ${(props) =>
    props.newPage &&
    css`
      padding-top: 3rem;
    `}
`;

const SmallTitle = styled.span`
  margin-right: 6rem;
  font-weight: bold;
  font-size: 1.2rem;
`;
const SmallTitleInfo = styled.span`
  font-weight: normal;
`;

const OmegaTitle = styled.div`
  display: grid;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 4px;
  height: 20px;
`;

const DateText = styled.div`
  text-align: right;
  margin-right: 3px;
  font-size: 1.2rem;
  font-weight: normal;
`;
