import React from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';

export const FormLabel = ({ label, id, error, message, iconAfterLabel }) => {
  return (
    <>
      <LabelContainer>
        {label && (
          <Label error={error} htmlFor={id}>
            {label}
          </Label>
        )}
        {iconAfterLabel && <IconAfterLabel src={iconAfterLabel} />}
        {message && <ErrorMsg> * {message}</ErrorMsg>}
      </LabelContainer>
    </>
  );
};

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing.small};
`;
const Label = styled.label`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  display: inline;
`;

const IconAfterLabel = styled.img`
  margin-left: ${(props) => props.theme.spacing.small};
`;

const ErrorMsg = styled.span`
  color: ${(props) => props.theme.color.error};
  font-size: 1.3rem;
  margin-left: 1rem;
  @media ${device.mobileM} {
    font-size: 1.2rem;
  }
  @media ${device.mobileS} {
    font-size: 1.1rem;
  }
`;
