import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { CreationProgress } from '../../../shared/progress-bar/CreationProgress';
import { InputWrapper } from '../../../shared/input/InputWrapper';
import { Languages } from '../../questions/edit-question/Languages';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import { Button } from '../../../shared/button/Button';
import { device } from '../../../../styles/device';
import { mic } from '../../../shared/assets/icons';
import { AddInstructions, AddExcersises, InstructionSound } from './';
import { useFetch } from '../../../../helpers/hooks/fetchData';

export const InstructionTemplate = ({ template, setPage, setTemplate }) => {
  const [newSounds, setNewSounds] = useState([]);
  const [payload, setPayload] = useState({});
  const { data } = useFetch(payload);
  useEffect(() => {
    if (data) {
      setTemplate(template);

      setPage(3);
    }
  }, [data, setPage, setTemplate, template]);

  const validationSchema = yup.object().shape({
    title: yup.string().required('This is a required field.'),
    instruction: yup.lazy((obj) =>
      yup.object(
        mapValues(obj, () =>
          yup.object().shape({
            text: yup.string().required('This is a required field.'),
          })
        )
      )
    ),
  });

  const questionSoundSelected = (event, id) => {
    if (event.target && event.target.files && event.target.files[0]) {
      const newSound = {};
      newSound[id] =
        event.target && event.target.files && event.target.files[0];

      setNewSounds({ ...newSounds, ...newSound });
    }
  };

  return (
    <Container>
      <CreationProgress
        step='second'
        firstStepText='General'
        secondStepText='Instruction'
        thirdStepText='Questions'
      />
      <StandardSpacing />
      <Formik
        initialValues={template}
        values={template}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          template.instruction = values.instruction;

          const formData = new FormData();
          formData.append('template', JSON.stringify(template));
          for (const prop in newSounds) {
            formData.append(prop, newSounds[prop]);
          }
          formData.append('isStringified', true);

          setPayload({
            method: 'put',
            endpoint: `/api/omega/templates/${template._id}/`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          // isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <InnerContainer>
              <InputWrapper
                title='Instruction text'
                errorMsg={
                  errors.instruction &&
                  touched.instruction &&
                  'One or several fields are missing input value.'
                }
              >
                <Languages
                  availableLanguages={template.availableLanguages}
                  errors={errors}
                  touched={touched}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  instruction
                />
              </InputWrapper>

              <InputWrapper
                marginTop
                title='Instruction end text'
                errorMsg={
                  errors.instruction &&
                  touched.instruction &&
                  'One or several fields are missing input value.'
                }
              >
                <Languages
                  availableLanguages={template.availableLanguages}
                  errors={errors}
                  touched={touched}
                  values={values}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  instruction
                  endText
                />
              </InputWrapper>
              <InstructionSound
                availableLanguages={template.availableLanguages}
                errors={errors}
                touched={touched}
                values={values}
                onChange={questionSoundSelected}
                onBlur={handleBlur}
                type='instruction'
                title='Add instruction sound file'
                icon={mic}
                acceptedFormats='.mp3, .m4a, .wav'
                sound
              />
              <AddInstructions template={template} />
              <AddExcersises template={template} />
            </InnerContainer>
            <FullColumn>
              <ButtonContainer>
                <Button secondary text='Back' onClick={() => setPage(1)} />
                <MarginButton />
                <Button
                  isDisabled={isEmpty(errors)}
                  primary
                  type='submit'
                  text='Continue to questions'
                />
              </ButtonContainer>
            </FullColumn>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
const InnerContainer = styled.div`
  max-width: 804px;
`;
const StandardSpacing = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const FullColumn = styled.div`
  width: 100%;
  margin-top: 8rem;
`;
const ButtonContainer = styled.div`
  background: ${(props) => props.theme.color.white};
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  padding: ${(props) => props.theme.spacing.standard};
  margin-bottom: ${(props) => props.theme.spacing.standard};
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
const MarginButton = styled.div`
  width: 6rem;
  @media ${device.mobileL} {
    height: ${(props) => props.theme.spacing.small};
  }
`;
