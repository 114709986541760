import React, { useContext } from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';
import { UnansweredSectionListItem } from '../../../components/candidate/test/UnansweredSectionListItem';
import { FinishTest } from '../../../components/candidate/test/FinishTest';
import { ContentContext } from '../../../helpers/contentContext';
import { CandidatePageButton } from '../../../components/shared/button/CandidatePageButton';

export const InfoPage = ({
  onClick,
  language,
  index,
  isCompleted,
  questionsRemaning,
  timedTest,
}) => {
  const content = useContext(ContentContext) || {};

  return (
    <Container>
      {/* PROGRESS BAR HÄR */}
      <TextContainer>
        <Title>
          {isCompleted
            ? content.allAnsweredHeadline
            : content.unansweredHeadline}
        </Title>
        <Paragraph>
          {isCompleted ? content.allAnsweredText : content.unansweredText}
        </Paragraph>
        {!timedTest && <Paragraph>{content.continueLaterText}</Paragraph>}
      </TextContainer>
      {!isCompleted && (
        <PageInfoContainer>
          <SectionHeader>{content.section}</SectionHeader>
          {questionsRemaning.map((section, key) => {
            return (
              <UnansweredSectionListItem
                language={language}
                section={section}
                key={key}
                onClick={onClick}
              />
            );
          })}
        </PageInfoContainer>
      )}
      <ButtonContainer>
        <ButtonWidth>
          <CandidatePageButton
            text={content.goToStart}
            onClick={() => {
              onClick({ navigate: 1 });
            }}
            secondary
          />
        </ButtonWidth>

        <FinishTest index={index}>{content.finish}</FinishTest>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  height: 100%;
  min-height: calc(100vh - 28.5rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${(props) => props.theme.color.white};
`;

const TextContainer = styled.div`
  width: 67.2rem;
  height: fit-content;
  margin-top: 5rem;
  margin-bottom: 8rem;
  @media ${device.tablet} {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 160%;
  letter-spacing: 0.01em;
`;

const Paragraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;
  letter-spacing: 0.01em;
  margin-top: 2.5rem;
  white-space: pre-line;
`;

const PageInfoContainer = styled.div`
  width: 73.2rem;
  border-radius: 0.4rem;
  padding: 2rem;
  background: ${(props) => props.theme.color.bgGrey};
  margin-bottom: 11rem;
  @media ${device.tablet} {
    width: 100%;
    border-radius: 0;
  }
`;

const SectionHeader = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 160%;
  letter-spacing: 0.01em;
  margin: 1rem 1rem 3.5rem 1rem;
  border-bottom: 1px solid #000000;
  @media ${device.tablet} {
    margin: 0 0 3.5rem 0;
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 8rem;
  width: 100%;
  gap: 4rem;
  display: flex;
  justify-content: center;
  @media ${device.mobileL} {
    flex-direction: column;
    width: 100%;
    padding: 0 2rem;
    gap: 2rem;
  }
`;

const ButtonWidth = styled.div`
  width: 20.4rem;
  @media ${device.mobileL} {
    width: 100%;
  }
`;
