import React, { useState, useEffect, useContext } from 'react';
import { useFetch } from '../../helpers/hooks/fetchData';
import styled from 'styled-components';
import { CandidatePageModal } from '../shared/modal/CandidatePageModal';
import { CandidatePageButton } from './button/CandidatePageButton';
import { ContentContext } from '../../helpers/contentContext';

export const Logout = ({ showModal, setShowModal }) => {
  const content = useContext(ContentContext) || {};
  const [payload, setPayload] = useState({});
  const { hasError, data } = useFetch(payload);

  useEffect(() => {
    if (data) {
      return window.location.assign('/omega/login');
    }
  }, [data]);

  const logout = () => {
    setPayload({
      endpoint: '/logout',
      method: 'post',
    });
  };

  return (
    <>
      {showModal && (
        <CandidatePageModal
          show={showModal}
          handleExit={setShowModal}
          width='40rem'
          paragraph={
            content.confirmLogout || 'Are you sure you want to log out'
          }
        >
          {!hasError && (
            <>
              <CandidatePageButton
                type='submit'
                onClick={() => logout()}
                primary
                text={content.logout || 'Log out'}
              />
              <PaddingBetweenButton />
              <CandidatePageButton
                onClick={() => setShowModal(false)}
                secondary
                text={content.cancel || 'Cancel'}
                type='reset'
              />
            </>
          )}
        </CandidatePageModal>
      )}
    </>
  );
};

const PaddingBetweenButton = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;
