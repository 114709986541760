import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../styles/device';
import {
  capitalizeFirstLetter,
  getLanguageText,
} from '../../../../helpers/common';

export const PersonalityScores = ({ cluster, data, language }) => {
  const userCluster =
    data &&
    data.clusters &&
    data.clusters.find((clust) => clust.title === cluster.title);

  const renderColumns = (score, borderBottom, borderSides) => {
    const columns = [];
    for (let i = 1; i <= 9; i++) {
      //TODO: change name isSame
      const isSame = i === Math.round(score);
      columns.push(
        <Column
          key={i}
          fillColumn={isSame}
          borderBottom={borderBottom}
          borderLeft={borderSides && i === 1}
          borderRight={borderSides && i === 9}
          noBorderRight={!borderSides && i === 9}
        >
          {i}
        </Column>
      );
    }

    return columns;
  };

  const setUpFacet = (facet) => {
    const userFacet = userCluster.facets.find(
      (userFacet) =>
        facet.facet.title === (userFacet.title && userFacet.title.toLowerCase())
    );

    if (userFacet) {
      const description =
        (facet && getLanguageText(facet.facet.descriptions, language)) || '';

      return (
        <FacetContainer key={facet._id}>
          <InnerTitle>{userFacet.title}</InnerTitle>
          <InnerText left>
            {description ? description.lowValue.shortDescription : ''}
          </InnerText>
          <Grid>{renderColumns(userFacet.stan, true, true)}</Grid>
          <InnerText right>
            {description ? description.highValue.shortDescription : ''}
          </InnerText>
        </FacetContainer>
      );
    }

    return null;
  };

  return (
    <Container key={cluster.title}>
      <Title>
        <InnerTitle bold>{capitalizeFirstLetter(cluster.title)}</InnerTitle>
        <Text left>Low scores</Text>
        <Grid>{renderColumns()}</Grid>
        <Text right>High scores</Text>
      </Title>
      {cluster.facets.map((facet) => userCluster && setUpFacet(facet))}
    </Container>
  );
};

const Container = styled.div`
  @media ${device.laptop} {
    width: 100%;
  }
  width: 97%;
  padding-bottom: 2rem;
  color: #00284a;
`;

const FacetContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

const Text = styled.div`
  width: 100%;
  font-size: 3rem;
  ${(props) => props.left && 'text-align: end;'}
  ${(props) => props.right && 'text-align: start;'};
`;

const InnerText = styled.div`
  width: 100%;
  font-size: 1.6rem;
  ${(props) => props.left && 'text-align: end;'}
  ${(props) => props.right && 'text-align: start;'}
`;

const InnerTitle = styled.div`
  position: absolute;
  left: 0;
  font-size: 1.6rem;
  text-align: start;
  ${(props) => props.bold && 'font-weight: 700; font-size: 2.4rem; top: 3px;'}
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #00284a;
  position: relative;
`;

const Grid = styled.div`
  display: flex;
  min-height: 4rem;
  height: 30%;
  flex-direction: row;
  margin: 0 1rem;
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  border-right: dotted 3px #00284a;
  padding: 0 1rem;
  font-size: 3rem;
  ${(props) => props.fillColumn && 'background:  #00284a;'}
  ${(props) => props.fillColumn && 'color: white;'};
  ${(props) => props.borderBottom && 'border-bottom: solid 1px #00284a;'}
  ${(props) => props.borderRight && 'border-right: solid 1px #00284a;'}
  ${(props) => props.borderLeft && 'border-left: solid 1px #00284a;'}
  ${(props) => props.noBorderRight && 'border-right:0;'}`;
