import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  InitNewTemplate,
  DeleteTemplate,
  CloneTemplate,
} from '../../../components/admin/templates/list-templates';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { StandardTable } from '../../../components/shared/standard-table/StandardTable';
import { Button } from '../../../components/shared/button/Button';
import { SearchBar } from '../../../components/shared/search-bar/SearchBar';
import history from '../../../history';
import { userHasAccess } from '../../../helpers/permissions';

//set as environment variable later
const itemsPerPage = 25;

export const ViewAllTemplates = (props) => {
  const [payload, setPayload] = useState({});
  const endpoint = '/api/omega/templates';
  const method = 'get';
  const [templates, setTemplates] = useState([]);
  const [currentTemplate, setCurrentTemplate] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);

  const [moreTemplatesAvailable, setMoreTemplatesAvailable] = useState(false);
  const [params, setParams] = useState({
    searchString: '',
    sortPropName: '',
    sortHeading: null,
    skip: 0,
    limit: itemsPerPage,
    isSurvey:
      userHasAccess('TEXT') && !userHasAccess('TEMPLATES') ? true : false,
  });

  const { data } = useFetch(payload);

  useEffect(() => {
    setPayload({ endpoint, method, params });
  }, [params, endpoint, method]);

  useEffect(() => {
    setUpdateInProgress(false);
    const newTemplates = (data && data.data) || [];

    if (userHasAccess('TEMPLATES')) {
      setTemplates((prevTemplates) => [...prevTemplates, ...newTemplates]);
    } else if (userHasAccess('TEXT')) {
      setTemplates([...newTemplates]);
    }
  }, [data]);

  useEffect(() => {
    const count = data && data.count;
    const moreTemplatesAvailable = count && params.limit + params.skip < count;
    setMoreTemplatesAvailable(moreTemplatesAvailable);
  }, [data, params.limit, params.skip]);

  /**
   * Set sort name and heading of sort attribute.
   * @param {String} propName
   */
  const sortByProp = (propName) => {
    setUpdateInProgress(true);
    if (!propName) return false;
    //clear templates array
    setTemplates([]);
    const skip = params.sortPropName !== propName ? 0 : params.skip;

    //if third press on same heading, clear the sorting
    if (params.sortHeading === -1 && propName === params.sortPropName) {
      return setParams({ ...params, sortHeading: '', sortPropName: '', skip });
    }

    //if not cleared, heading will be set to 1 on first click and -1 on second click
    const sortHeading =
      propName === params.sortPropName && params.sortHeading === 1 ? -1 : 1;
    const sortPropName = propName;
    return setParams({ ...params, sortHeading, sortPropName, skip });
  };

  const searchEntered = (s) => {
    setUpdateInProgress(true);
    //clear templates array
    setTemplates([]);
    const skip = 0;
    return setParams({ ...params, searchString: s, skip });
  };

  const showMore = () => {
    const skip = params.skip + itemsPerPage;
    return setParams({ ...params, skip });
  };

  const navigateToTemplatePage = (slug) => {
    return props.history.push('/omega/admin/templates/edit-template/' + slug);
  };

  const editTemplate = (id) => {
    let clickedTemplate = templates.find((template) => template._id === id);

    history.push(`/admin/templates/edit-template/${clickedTemplate.slug}`, {
      slug: clickedTemplate.slug,
    });
  };
  const cloneTemplate = (id) => {
    setCurrentTemplate(templates[templates.findIndex((tmp) => tmp._id === id)]);
    setShowCloneModal(true);
  };

  const deleteTemplate = (id) => {
    setCurrentTemplate(templates[templates.findIndex((tmp) => tmp._id === id)]);
    setShowDeleteModal(true);
  };

  const dropDownOptions = [
    {
      title: 'Edit',
      onClick: editTemplate,
    },
    {
      title: 'Clone',
      onClick: cloneTemplate,
    },
    {
      title: 'Delete',
      onClick: deleteTemplate,
      delete: true,
    },
  ];

  return (
    <>
      <SearchBar
        searchString={params.searchString}
        onChange={searchEntered}
        placeholder={'Search'}
      />
      <Container>
        {!updateInProgress && templates && (
          <StandardTable
            type='templates'
            items={templates}
            onHeaderClick={sortByProp}
            onItemClick={navigateToTemplatePage}
            dropDownOptions={dropDownOptions}
          />
        )}

        {!!moreTemplatesAvailable && (
          <Button onClick={showMore} text='Show more' primary />
        )}

        <DeleteTemplate
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          template={currentTemplate}
        />
        <CloneTemplate
          showModal={showCloneModal}
          setShowModal={setShowCloneModal}
          template={currentTemplate}
        />
      </Container>
      {(userHasAccess('TEMPLATES') === 'crud' ||
        userHasAccess('TEXT') === 'crud') && <InitNewTemplate />}
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 7rem;
`;
