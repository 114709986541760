import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';
import { Formik } from 'formik';

export const TextOption = ({
  option,
  onChange,
  textAnswer,
  id,
  index,
  answerIx,
}) => {
  const optionText = option.text;
  const validationSchema = Yup.object().shape({
    answer: Yup.string(),
  });

  //TODO: Fix so no need for doubleclick
  const [answer, setAnswer] = useState(textAnswer);

  return (
    <div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ answer: textAnswer }}
      >
        {({ values, handleBlur, handleSubmit, errors, touched }) => (
          <form onSubmit={handleSubmit}>
            {optionText && <Label>{optionText}</Label>}
            <Input
              autoFous={false}
              isError={errors.answer && touched.answer}
              onChange={(e) => {
                setAnswer(e.target.value);
              }}
              onBlur={() => {
                onChange({
                  type: 'text',
                  value: answer,
                  index,
                  answerIx,
                });
              }}
              type='text'
              value={answer}
              id={id}
              hasLabel={optionText}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

const Label = styled.label`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 160%;
  letter-spacing: 0.01em;
  width: 100%;
  color: ${(props) => props.theme.color.black};
`;

const Input = styled.input`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  border: 0rem;
  border-bottom: 0.1rem solid ${(props) => props.theme.color.mediumGrey};
  margin-bottom: 0.5rem;
  display: block;
  width: 100%;
  ${(props) =>
    props.hasLabel &&
    css`
      margin-top: 1rem;
    `}
`;
