import React from 'react';
import styled, { css } from 'styled-components';
import { FormLabel } from './FormLabel';
import { Flag } from './FlagWrapper';
import { IconInInput } from './InputField';
import { device } from '../../../styles/device';

export const TextareaField = ({
  iconAfterLabel,
  iconInInput,
  flag,
  label,
  id,
  error,
  message,
  ...textareaProps
}) => (
  <>
    {label && (
      <FormLabel
        label={label}
        id={id}
        error={error}
        message={message}
        iconAfterLabel={iconAfterLabel}
      />
    )}
    <InputContainer flag>
      {flag && <Flag langImg={flag} />}
      <Textarea
        flag
        message={message}
        id={id}
        error={error}
        {...textareaProps}
      />
      {iconInInput && <IconInInput src={iconInInput} />}
    </InputContainer>
  </>
);

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  border-radius: 1rem;
  position: relative;
  ${(props) =>
    props.flag &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.standard};
      &:last-child {
        margin-bottom: 0px;
      }
    `}
`;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 5rem;
  resize: none;
  font-size: 1.6rem;
  line-height: 150%;
  border-radius: 0.4rem;
  font-family: 'Roboto';
  background-color: ${(props) => props.theme.color.bgGrey};
  border: ${(props) =>
    props.error ? `1px solid ${props.theme.color.error}` : 'none'};
  padding: 1.5rem;
  ${(props) =>
    props.message &&
    css`
      border: 1px solid ${(props) => props.theme.color.error};
    `}
  &:focus {
    border: 1px solid ${(props) => props.theme.color.shadow};
    box-shadow: 0 0 3px ${(props) => props.theme.color.shadow};
    outline: none;
  }
  ::placeholder {
    color: #959595;
    opacity: 1;
    font-style: italic;
  }
  @media ${device.mobileL} {
    font-size: 1.4rem;
  }
`;
