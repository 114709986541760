import { useState, useEffect } from 'react';
import axios from 'axios';
import history from '../../history';

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const useFetch = (payload, resetHasError) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [hasError, setHasError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const resetErrorText = () => setErrorText('');

  useEffect(() => {
    const fetchUrl = async (payload) => {
      // Reset hasError to false if needed
      if (!payload.endpoint && resetHasError) {
        return setHasError(false);
      }

      if (resetHasError && resetHasError) {
        setHasError(false);
      }

      if (payload.endpoint) {
        setIsLoading(true);
        try {
          let data = payload.data || null;
          if (payload.endpoint.includes('?')) {
            payload.endpoint += '&_csrf=' + localStorage.getItem('csrf');
          } else {
            payload.endpoint += '?_csrf=' + localStorage.getItem('csrf');
          }
          const result = await axios({
            url: `${baseUrl}${payload.endpoint}`,
            method: payload.method || 'get',
            params: payload.params || null,
            data: data,
            headers: payload.headers || null,
            withCredentials: true,
          });
          if (result && result.headers && result.headers.token) {
            localStorage.setItem('csrf', result.headers.token);
          }
          setData(result.data);
        } catch (error) {
          if (
            error.response &&
            error.response.headers &&
            error.response.headers.token
          ) {
            localStorage.setItem('csrf', error.response.headers.token);
          }
          if (error && error.response && error.response.status === 304) {
            return setHasError(false);
          }
          if (error && error.response && error.response.status === 403) {
            localStorage.setItem('isOmegaAdmin', false);
            localStorage.setItem('isCandidate', false);
            const route =
              history && history.location && history.location.pathname;
            if (
              route === '/choose-password/' ||
              route === '/change-password/'
            ) {
              return false;
            }
            history.push('/');
          }
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            setErrorText(error.response.data.error);
          }
          setHasError(true);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchUrl(payload);
  }, [payload, resetHasError]);
  return { data, isLoading, hasError, errorText, resetErrorText };
};

export { useFetch };
