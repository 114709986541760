import React from 'react';
import styled, { css } from 'styled-components';

export const CandidatePageButton = ({
  text,
  onClick,
  primary,
  type,
  disabled,
  secondary,
}) => {
  return (
    <Container
      onClick={onClick}
      primary={primary}
      secondary={secondary}
      type={type}
      disabled={disabled}
    >
      {text}
    </Container>
  );
};

const Container = styled.button`
  width: 100%;
  min-height: 6.2rem;
  box-sizing: border-box;
  display: block;
  outline: none;
  border: 2px solid transparent;
  border-radius: 0.4rem;
  font-style: normal;
  font-family: 'futura-pt', sans-serif;
  font-size: 2.2rem;
  line-height: 3.5rem;
  cursor: pointer;
  ${(props) =>
    props.primary &&
    css`
      color: ${(props) => props.theme.color.white};
      background-color: ${(props) => props.theme.color.darkBlue};
    `}
  ${(props) =>
    props.secondary &&
    css`
      color: ${(props) => props.theme.color.darkBlue};
      background-color: ${(props) => props.theme.color.white};
      border-color: ${(props) => props.theme.color.darkBlue};
    `}
    ${(props) =>
      props.disabled &&
      css`
        color: ${(props) => props.theme.color.bgGrey};
        background-color: ${(props) => props.theme.color.shadow};
        cursor: default;
      `}
`;
