import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  LanguageItems,
  LanguageItemsWrapper,
} from '../../../components/admin/reports/show-report/ChangeLanguage';
import { SelectData } from '../../../components/admin/reports/show-report/SelectData';
import { RenderSection } from '../../../components/admin/reports/show-report/TemplateSection';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { AccessDenied } from '../../../components/shared/AccessDenied';

export const Report = (props) => {
  const [payload, setPayload] = useState({});
  const [templatePayload, setTemplatePayload] = useState({});
  const [templateIndex, setTemplateIndex] = useState(0);
  const [userSlug, setUserSlug] = useState('');
  const [users, setUsers] = useState([]);
  const [templateSections, setTemplateSections] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [consultant, setConsultant] = useState({});
  const [access, setAccess] = useState(true);
  const paramsData = useParams();
  const slug =
    (paramsData && paramsData.slug) ||
    (props.location && props.location.state && props.location.state.slug) ||
    props.history.location.pathname.substring(
      props.history.location.pathname.lastIndexOf('/') + 1
    );
  const endpoint = `/api/omega/projects/report/${slug}`;
  const templateEndpoint = `/api/omega/projects/report-template/${slug}`;
  const method = 'get';

  const { data, isLoading, hasError } = useFetch(payload);
  const {
    data: templateData,
    isLoading: templateIsLoading,
    hasError: templateHasError,
  } = useFetch(templatePayload);

  const setTemplatesData = useCallback(() => {
    if (templates.length) {
      setAvailableLanguages(templates[templateIndex].availableLanguages);
    }
  }, [templates, templateIndex]);

  const setData = useCallback(() => {
    setTemplates(data.data.templates);
    setUsers(data.data.users);
    setConsultant(data.data.consultant && data.data.consultant[0]);

    if (data.data.templates.length) {
      const availableLanguages =
        data.data.templates[templateIndex].availableLanguages;

      const english = availableLanguages.find((lang) => lang.code === 'EN');

      setAvailableLanguages(availableLanguages);

      if (!selectedLanguage) {
        setSelectedLanguage(
          (english && english.code) ||
            (availableLanguages[0] && availableLanguages[0].code) ||
            ''
        );
      }
    }
  }, [data, selectedLanguage, templateIndex]);

  useEffect(() => {
    setPayload({ endpoint, method });
  }, [endpoint]);

  useEffect(() => {
    if (users && users.length) {
      setTemplatePayload({
        endpoint: templateEndpoint,
        method,
        params: { templateIndex, userSlug },
      });
      setTemplatesData();
    }
  }, [templateIndex, users, templateEndpoint, userSlug, setTemplatesData]);

  useEffect(() => {
    if (data && data.data) {
      setData();
    }
  }, [data, setData]);

  useEffect(() => {
    if (templateData && templateData.data) {
      setTemplateSections(templateData.data);
    }

    if (templateData && templateData.error) {
      setAccess(false);
    }
  }, [templateData]);

  return (
    <Container>
      {isLoading || templateIsLoading ? (
        <InnerContainer>Loading...</InnerContainer>
      ) : hasError || templateHasError ? (
        <InnerContainer>Something went wrong</InnerContainer>
      ) : !access ? (
        <AccessDenied contact={consultant && consultant.name} />
      ) : (
        <Fragment>
          <Header>
            {(templates.length && templates.length > 1 && (
              <SelectData
                data={templates}
                selected={templateIndex}
                setSelected={setTemplateIndex}
                white
              />
            )) ||
              null}
            {(availableLanguages.length && availableLanguages.length > 1 && (
              <LanguageItemsWrapper>
                <LanguageItems
                  currentLanguage={selectedLanguage}
                  setCurrentLanguage={setSelectedLanguage}
                  availableLanguages={availableLanguages}
                />
              </LanguageItemsWrapper>
            )) ||
              null}
          </Header>
          {data && data.data && (
            <InnerContainer>
              <div>{data.data.title}</div>
              {(templateSections &&
                templateSections.sections &&
                templateSections.sections.length &&
                templateSections.sections.map((section, index) => (
                  <RenderSection
                    key={index}
                    section={section}
                    data={templateSections.sectionsData[index]}
                    language={selectedLanguage}
                    users={users}
                    setUserSlug={setUserSlug}
                    userSlug={userSlug}
                    index={index}
                  />
                ))) || <>Section not completed</>}
            </InnerContainer>
          )}
        </Fragment>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: ${(props) => props.theme.color.white};
  padding-top: 5rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%
  height: 100%;
  padding-left: 1rem;
`;

const InnerContainer = styled.div`
  text-align: center;
  font-size: 3rem;
`;
