import React from 'react';
import styled from 'styled-components';
import { SubMenu } from '../../../components/admin/navigation/SubMenu';
import { Route, useRouteMatch, Redirect, Switch } from 'react-router-dom';
import { Main } from '../../../components/main/Main';
import history from '../../../history';

export const AdminReports = ({ route }) => {
  const { path } = useRouteMatch();

  return (
    <Container>
      {route &&
        route.hasSubMenu &&
        !history.location.pathname.includes('/show-report/') && (
          <SubMenu route={route} />
        )}
      <Switch>
        <Route path={`${path}/:child`}>
          <Main route={route} />
        </Route>
        <Redirect from='/admin/reports' to='/admin/reports/view-all' />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.bgGrey};
`;
