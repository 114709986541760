import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Scatterplot } from '../../diagrams/Scatterplot';
import { getLanguageText } from '../../../../../helpers/common';

export const RenderScatterplotRow = ({ section, sectionsData, language }) => {
  const scatterplotWidth = 700;
  const [showFacets, setShowFacets] = useState({});

  const setAllFalse = useCallback(() => {
    const falses = [];
    falses.length = sectionsData.length;
    falses.fill(false);
    return falses;
  }, [sectionsData]);

  useEffect(() => {
    if (sectionsData && sectionsData.length) {
      const showFacets = {
        median: setAllFalse(),
        all: setAllFalse(),
        singleData: [],
      };

      for (let i = sectionsData[0].cluster.length; i > 0; i--) {
        showFacets.singleData.push(setAllFalse());
      }
      setShowFacets(showFacets);
    }
  }, [sectionsData, setAllFalse]);

  const handleShowFacets = (object, index, selected) => {
    if (selected !== undefined)
      showFacets[object][selected][index] = !showFacets[object][selected][
        index
      ];

    if (selected === undefined)
      showFacets[object][index] = !showFacets[object][index];

    setShowFacets({ ...showFacets });
  };

  const getMedianData = (index, data) => {
    let sortedData = [];

    if (typeof index === 'number' && sectionsData && sectionsData[index]) {
      sortedData = sectionsData[index].cluster.sort((a, b) => a.x - b.x);
    }

    if (data && data.length) {
      sortedData = data.sort((a, b) => a.x - b.x);
    }

    let median = { x: 1 };

    if (sortedData.length % 2) {
      const objectIx = sortedData.length / 2 - 0.5;
      median.x = sortedData[objectIx].x;
    } else {
      const firstObjectIx = sortedData.length / 2 - 1;
      const secoundObjectIx = firstObjectIx + 1;
      median.x =
        (sortedData[firstObjectIx].x + sortedData[secoundObjectIx].x) / 2;
    }
    return [median];
  };

  const renderFacetRow = (data, row, medianData, allData, selected) => {
    const facetData = row.cluster.facets.find(
      (facetData) => facetData.facet._id === data._id
    );

    const linePos = scatterplotWidth + 40;
    const descriptions =
      facetData && facetData.facet
        ? getLanguageText(facetData.facet.descriptions, language)
        : '';

    return (
      (facetData && (
        <InnerContainer key={facetData._id}>
          <Text leftText>
            {descriptions && descriptions.lowValue.longDescription}
          </Text>
          <RowContainer isFacet>
            <Line pos={linePos / 2} />
            <Line pos={linePos / 4} />
            <Line pos={(linePos / 4) * 3} />
            {medianData && (
              <Scatterplot
                data={medianData}
                width={scatterplotWidth}
                radius={10}
                noText
                isRow
              />
            )}
            {allData && (
              <Scatterplot data={data.data} width={scatterplotWidth} isRow />
            )}
            {selected !== undefined && (
              <Scatterplot
                data={data.data}
                width={scatterplotWidth}
                selected={selected}
                isRow
              />
            )}
          </RowContainer>
          <Text>{descriptions && descriptions.highValue.longDescription}</Text>
        </InnerContainer>
      )) ||
      null
    );
  };

  const renderRow = (row, index, type, medianData, selected) => {
    const linePos = scatterplotWidth + 40;
    return (
      (sectionsData[index] && (
        <InnerContainer
          key={index}
          isOpen={
            selected !== undefined
              ? showFacets[type] &&
                showFacets[type][selected] &&
                showFacets[type][selected][index]
              : showFacets[type] && showFacets[type][index]
          }
        >
          <Text leftText>{getLanguageText(row.lowValue, language)}</Text>
          <RowContainer isTop={!index}>
            <Line pos={linePos / 2} />
            <Line pos={linePos / 4} />
            <Line pos={(linePos / 4) * 3} />
            {type === 'median' && medianData && medianData.length && (
              <ScatterplotContainer
                onClick={() => handleShowFacets(type, index)}
              >
                <Scatterplot
                  data={medianData}
                  width={scatterplotWidth}
                  radius={10}
                  noText
                  isRow
                />
              </ScatterplotContainer>
            )}
            {type === 'all' && (
              <ScatterplotContainer
                onClick={() => handleShowFacets(type, index)}
              >
                <Scatterplot
                  data={sectionsData[index].cluster}
                  width={scatterplotWidth}
                  isRow
                />
              </ScatterplotContainer>
            )}
            {type === 'singleData' && selected !== undefined && (
              <ScatterplotContainer
                onClick={() => handleShowFacets(type, index, selected)}
              >
                <Scatterplot
                  data={sectionsData[index].cluster}
                  width={scatterplotWidth}
                  selected={selected}
                  isRow
                />
              </ScatterplotContainer>
            )}
          </RowContainer>
          <Text>{getLanguageText(row.highValue, language)}</Text>
        </InnerContainer>
      )) ||
      null
    );
  };

  const renderRows = () => {
    for (let i = sectionsData.length - 1; i >= 0; i--) {
      if (
        (sectionsData[i].cluster && !sectionsData[i].cluster.length) ||
        (sectionsData[i].facets && !sectionsData[i].facets.length)
      ) {
        sectionsData.splice(i, 1);
        section.data.scatterplotRows.splice(i, 1);
      }
    }
    if (!sectionsData.length) {
      //TODO: fix better response
      return <>Missing some data</>;
    }

    const charts = [];
    const title = getLanguageText(section.title, language);

    if (section.median) {
      const medians = [];

      section.data.scatterplotRows.forEach((row, index) => {
        const data = getMedianData(index);

        medians.push(
          <Fragment key={index}>
            {renderRow(row, index, 'median', data)}
            {showFacets['median'] &&
              showFacets['median'][index] &&
              sectionsData[index].facets.map((facet) => {
                const data = getMedianData('', facet.data);
                return renderFacetRow(facet, row, data);
              })}
          </Fragment>
        );
      });

      charts.push(
        <ClusterContainer key={title}>
          <ClusterTitle>{title}</ClusterTitle>
          {medians}
          <MedianText>Group median values</MedianText>
        </ClusterContainer>
      );
    }

    charts.push(
      <ClusterContainer key={title + 'text'}>
        <ClusterTitle>{title}</ClusterTitle>
        {section.data.scatterplotRows.map((row, index) => (
          <Fragment key={index}>
            {renderRow(row, index, 'all')}
            {showFacets['all'] &&
              showFacets['all'][index] &&
              sectionsData[index].facets.map((facet) =>
                renderFacetRow(facet, row, false, true)
              )}
          </Fragment>
        ))}
      </ClusterContainer>
    );

    if (section.singleData) {
      // Sort by name alphabetic order.
      sectionsData.forEach((data) => {
        data.cluster.sort((a, b) => a.name.localeCompare(b.name));

        data.facets.forEach((facet) =>
          facet.data.sort((a, b) => a.name.localeCompare(b.name))
        );
      });

      sectionsData[0].cluster.forEach((_, index) => {
        charts.push(
          <ClusterContainer key={title + index}>
            <ClusterTitle>{title}</ClusterTitle>
            {section.data.scatterplotRows.map((row, ix) => {
              return (
                <Fragment key={ix}>
                  {renderRow(row, ix, 'singleData', false, index)}
                  {showFacets['singleData'] &&
                    showFacets['singleData'][index][ix] &&
                    sectionsData[ix].facets.map((facet) =>
                      renderFacetRow(facet, row, false, false, index)
                    )}
                </Fragment>
              );
            })}
          </ClusterContainer>
        );
      });
    }

    return charts;
  };

  return <Container>{<>{renderRows()}</> || <div>NO DATA</div>}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  padding-bottom: 4rem;
`;

const ClusterTitle = styled.div`
  font-size: 3rem;
  font-weight: 700;
  margin: 6rem 0 3rem;
  color: #00284a;
`;

const ClusterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${(props) => props.isOpen && 'opacity: 0.6'}
`;

const Text = styled.p`
  width: 100%;
  font-size: 2rem;
  font-weight: 600;
  text-align: ${(props) => (props.leftText && 'right') || 'left'};
  ${(props) => (props.leftText && 'margin-right:') || 'margin-left:'} 1.5rem;
  color: #00284a;
`;

const RowContainer = styled.div`
  border: 1px solid #00284a;
  border-top: ${(props) => (props.isTop && '1px solid #00284a') || '0'};
  padding: 1rem 0;
  display: flex;
  position: relative;
  // ${(props) => (props.isFacet && '0.5') || '0.8'};
`;

const ScatterplotContainer = styled.div`
  cursor: pointer;
`;

const Line = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => props.pos}px;
  width: 1px;
  height: 71px;
  border-right: 1px solid #00284a;
  opacity: 0.8;
`;

const MedianText = styled.div`
  font-size: 1.5rem;
  margin-top: 1rem;
  color: #00284a;
  opacity: 0.7;
`;
