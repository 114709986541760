import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const ImageSlider = ({ images }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      {/* added style tagg because the arrows was white and didnt find css file */}
      <style>
        {`.slick-prev:before, .slick-next:before{
          color:#000000;
          font-size: 60px;
          }
          
          .slick-prev, .slick-next{
            height:60px;
          }

          .slick-prev{
            left: -65px;
          }`}
      </style>
      <Slider {...settings}>
        {images.map((image, index) => (
          <img key={image.medium} src={image.medium} alt={`Fim ${index}`}></img>
        ))}
      </Slider>
    </>
  );
};
