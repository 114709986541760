import React from 'react';
import styled from 'styled-components';
import { Route, useRouteMatch, Redirect, Switch } from 'react-router-dom';
import { Main } from '../../../components/main/Main';

export const Test = ({ route, deepRoute }) => {
  const { path } = useRouteMatch();
  return (
    <Container>
      <Switch>
        {deepRoute ? (
          <Route path={`${path}/:child/:slug`}>
            <Main route={route} />
          </Route>
        ) : (
          <Route path={`${path}/:child`}>
            <Main route={route} />
          </Route>
        )}
        <Redirect from='/candidate/test' to='/candidate/test/active-test' />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.bgGrey};
`;
