import React from 'react';
import styled from 'styled-components';
import { chevron } from '../../../shared/assets/icons';

export const Chevron = ({ isRotated }) => {
  return <Image isRotated={isRotated} src={chevron} />;
};

const Image = styled.img`
  transition: transform 0.2s ease-in-out;
  transform: ${(props) => (props.isRotated ? 'rotate(90deg)' : '')};
`;
