import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../styles/device';

export const Button = ({ text, onClick, type, disabled }) => {
  return (
    <Container disabled={disabled} onClick={onClick && onClick} type={type}>
      {text}
    </Container>
  );
};

const Container = styled.button`
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.theme.color.darkBlue};
  cursor: pointer;
  color: #fff;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  letter-spacing: 0.07em;
  padding: 1.4rem 5.6rem;

  @media ${device.mobileL} {
    width: 100%;
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: none;
      background: ${(props) => props.theme.color.mediumGrey};
    `}
`;
