import React from 'react';
import { Field } from 'formik';
import { StandardSpacing } from './SetQuestion';
import { InputWrapper } from '../../../shared/input/InputWrapper';
import { FileInput } from '../../../shared/input/FileInput';

export const QuestionVideo = ({
  availableLanguages,
  onChange,
  onBlur,
  values,
  errors,
  touched,
  type,
  title,
  acceptedFormats,
  icon,
}) => {
  return (
    <>
      {availableLanguages && availableLanguages.length > 0 && (
        <>
          <StandardSpacing />
          <InputWrapper title={title} icon={icon}>
            {availableLanguages.map((lang, index) => {
              return (
                <React.Fragment key={index}>
                  <Field
                    as={FileInput}
                    id={`${type}[${lang.code}]`}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={values.fileInput || ''}
                    error={errors.fileInput && touched.fileInput}
                    name='fileInput'
                    acceptedFormats={acceptedFormats}
                    flag={lang.flag}
                    currentFileName={
                      (values &&
                        values[type] &&
                        values[type][lang.code] &&
                        values[type][lang.code].fileName) ||
                      null
                    }
                    //TODO: fixa så de blir video
                    sound
                  />
                </React.Fragment>
              );
            })}
          </InputWrapper>
        </>
      )}
    </>
  );
};
