import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FormLabel } from './FormLabel';
import { device } from '../../../styles/device';
import { Flag } from './FlagWrapper';

export const InputUploadFile = ({ text }) => {
  const [fileValue, setFileValue] = useState('');

  const setLabel = (event) => {
    if (event.target.files[0].name !== '') {
      setFileValue(event.target.files[0].name);
    } else {
      return;
    }
  };

  return (
    <Wrapper>
      <FileUpload>
        <File
          type='file'
          id='file-input'
          onChange={(event) => {
            setLabel(event);
          }}
        />
        {fileValue ? <Text>{fileValue}</Text> : <Text>{text}</Text>}
      </FileUpload>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4rem;
  background-color: ${(props) => props.theme.color.primary};
`;

const FileUpload = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.6rem;
  font-weight: 600;

  @media ${device.mobileM} {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 600;
  }
`;

const File = styled.input`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
`;

const Text = styled.span``;

export const InputField = ({
  label,
  id,
  error,
  message,
  className,
  iconAfterLabel,
  iconInInput,
  isContactFormProfile,
  disabled,
  flag,
  ...inputProps
}) => (
  <>
    {label && (
      <FormLabel
        label={label}
        id={id}
        error={error}
        message={message}
        iconAfterLabel={iconAfterLabel}
      />
    )}
    <InputContainer isContactFormProfile={isContactFormProfile} flag>
      {flag && <Flag langImg={flag} />}
      <Input
        {...inputProps}
        id={id}
        error={error}
        iconInInput={iconInInput}
        className={className}
        message={message}
        disabled={disabled}
      />
      {iconInInput && <IconInInput src={iconInInput} />}
    </InputContainer>
  </>
);

const InputContainer = styled.div`
  height: 6rem;
  width: 100%;
  border-radius: 1rem;
  position: relative;
  ${(props) =>
    props.flag &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.standard};
      &:last-child {
        margin-bottom: 0px;
      }
    `}
  ${(props) =>
    props.isContactFormProfile &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.small};
    `}
`;

const Input = styled.input`
  height: 100%;
  width: 100%;
  border-radius: 0.4rem;
  background-color: ${(props) => props.theme.color.bgGrey};
  font-size: 1.6rem;
  line-height: 1.8rem;
  border: none;
  padding-left: ${(props) =>
    props.iconInInput
      ? `${props.theme.spacing.extraLarge}`
      : `${props.theme.spacing.small}`};

  &:focus {
    border: 1px solid ${(props) => props.theme.color.shadow};
    box-shadow: 0 0 3px ${(props) => props.theme.color.shadow};
    outline: none;
  }
  ${(props) =>
    props.message &&
    css`
      border: 1px solid ${(props) => props.theme.color.error};
    `}
  @media ${device.mobileL} {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  ::placeholder {
    color: #959595;
    opacity: 1;
    font-style: italic;
  }
`;

export const IconInInput = styled.img`
  height: 1.8rem;
  width: 1.8rem;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.theme.spacing.standard};
`;
