import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';
import { StandardTable } from '../../shared/standard-table/StandardTable';
import { OmegaQuestionTable } from '../../shared/standard-table/OmegaQuestionTable';
import { TableToPrint } from '../../shared/standard-table/TableToPrint';
import { Button } from '../../shared/button/Button';
import ReactToPrint from 'react-to-print';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { chevron, sortArrowDown } from '../../shared/assets/icons';

export const Results = ({ user }) => {
  const [results, setResults] = useState(null);
  const [payload, setPayload] = useState({
    params: { _id: user._id },
  });
  const endpoint = '/api/omega/results/get-results';
  const method = 'get';
  const { data } = useFetch(payload);

  useEffect(() => {
    setPayload({ endpoint, method, params: { _id: user._id } });
  }, [endpoint, method, user._id]);

  useEffect(() => {
    if (data && data.results) {
      setResults(data.results.results);
    }
  }, [data]);
  const sortByProp = () => {
    return 'sortByProps';
  };
  const componentRef = useRef();

  //TODO add real data
  // const mock = [
  //   {
  //     name: 'Antologo',
  //     completeDate: '2020-10-23',
  //     timeLeft: '13:37',
  //     result: '6/13',
  //     points: '100',
  //     pointsSecond: '200',
  //     pdf: true,
  //     previewAnswer: true,
  //   },
  //   {
  //     name: 'Number',
  //     completeDate: '2020-10-13',
  //     timeLeft: '13:38',
  //     result: '2/20',
  //     points: '10',
  //     pointsSecond: '20',
  //     pdf: false,
  //     previewAnswer: true,
  //   },
  //   {
  //     name: 'Text',
  //     completeDate: '2020-05-13',
  //     timeLeft: '14:38',
  //     result: '1/20',
  //     points: '15',
  //     pointsSecond: '23',
  //     pdf: false,
  //     previewAnswer: true,
  //   },
  // ];

  return (
    <Container>
      {data && results && (
        <>
          <HiddenContainer>
            <TableToPrint user={user} ref={componentRef} items={results} />
          </HiddenContainer>

          <StandardTable
            type='results'
            items={results}
            onHeaderClick={sortByProp}
            icon={chevron}
            iconActive={sortArrowDown}
          />
          <OmegaQuestionTable
            type='results'
            items={results}
            icon={chevron}
            iconActive={sortArrowDown}
          />
          <ButtonContainer>
            <ButtonInner>
              <ReactToPrint
                trigger={() => <Button text='View and print results' primary />}
                content={() => componentRef.current}
              />
            </ButtonInner>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 8rem;
  margin-bottom: ${(props) => props.theme.spacing.medium};
  @media ${device.mobileL} {
    margin-top: 5rem;
    margin-bottom: 0rem;
  }
`;

const HiddenContainer = styled.div`
  display: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  padding-top: ${(props) => props.theme.spacing.extraLarge};
  padding-bottom: ${(props) => props.theme.spacing.extraLarge};
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};

  @media ${device.mobileL} {
    padding-bottom: ${(props) => props.theme.spacing.medium};
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;

const ButtonInner = styled.div`
  display: flex;
  width: 19.5rem;

  @media ${device.mobileL} {
    width: 100%;
  }
`;
