import React, { useState } from 'react';
import styled from 'styled-components';
import { device } from '../../styles/device';
import { InputPassword } from '../../components/candidate/landing/InputPassword';

export const AdminChangePassword = () => {
  const [response, setResponse] = useState(false);

  return (
    <>
      <Container>
        <ContentContainer>
          <TextContainer>
            <Title>Change your password</Title>
          </TextContainer>
          {response && (
            <TextContainer small>
              <Text error={response === 'error'}>
                {response === 'error' && 'Failed to update password'}
                {response === 'updated' && 'Password updated'}
              </Text>
            </TextContainer>
          )}
          <InputPassword changePassword isAdmin setResponse={setResponse} />
        </ContentContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 0 auto;
`;

const ContentContainer = styled.div`
  width: 65rem;
  padding: 8rem 0rem;
  @media ${device.tablet} {
    padding: 8rem 2rem;
    width: 100%;
  }
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.1rem;
  padding-bottom: 2.5rem;
`;
const Text = styled.div`
  font-size: 1.6rem;
  //   line-height: 4.1rem;
  color: ${(props) => (props.error && 'red') || 'green'};
`;

const TextContainer = styled.div`
  padding-bottom: ${(props) => (props.small ? '0rem' : '5rem')};
  word-wrap: none;
  height: fit-content;
`;
