import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../styles/device';

/*propsen för dom olika borderfärgerna som ska togglas beroende på vilken tid det är:
  expired = röd
  timeRunningOut = orange
  active = grön 
*/

export const CountDownActiveTest = ({
  stylingForTabletAndDown,
  active,
  timeRunningOut,
  expired,
  timeRemaining,
}) => {
  return (
    <Container
      stylingForTabletAndDown={stylingForTabletAndDown}
      active={active}
      timeRunningOut={timeRunningOut}
      expired={expired}
    >
      <Text>Time remaining:</Text>
      {timeRemaining && <Time>{timeRemaining}</Time>}
    </Container>
  );
};

const Container = styled.div`
  width: 14.7rem;
  height: 8.8rem;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5rem;
  ${(props) =>
    props.active &&
    css`
      border: 2px solid #33c764;
    `}
    ${(props) =>
      props.timeRunningOut &&
      css`
        border: 2px solid #f5c722;
      `}
    ${(props) =>
      props.expired &&
      css`
        border: 2px solid #db2121;
      `}
  ${(props) =>
    props.stylingForTabletAndDown &&
    css`
      @media ${device.tablet} {
        right: 5rem;
        width: 8.7rem;
        height: 2.9rem;
      }
      @media ${device.mobileL} {
        right: 1.5rem;
        top: 1.5rem;
        width: 8.7rem;
        height: 2.9rem;
      }
    `}
`;

const Text = styled.p`
  font-weight: normal;
  padding-bottom: 1.3rem;
  font-size: 1.6rem;
  line-height: 1.9rem;
  @media ${device.tablet} {
    display: none;
  }
`;

const Time = styled.p`
  font-weight: bold;
  font-size: 2.6rem;
  line-height: 3rem;
  @media ${device.tablet} {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
`;
