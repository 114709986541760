import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../../styles/device';

export const SelectData = ({ data, setSelected, selected, useSlug, white }) => (
  <Fragment key='selectData'>
    <InputContainer>
      {data && data.length && (
        <Select
          defaultValue={selected}
          onChange={({ target }) => setSelected(target.value)}
          white={white}
        >
          {data.map((data, index) => (
            <option key={data._id} value={(useSlug && data.slug) || index}>
              {data.title || data.name}
            </option>
          ))}
        </Select>
      )}
    </InputContainer>
  </Fragment>
);

const Select = styled.select`
  height: 100%;
  width: 100%;
  border-radius: 0.4rem;
  background-color: ${(props) => props.theme.color.bgGrey};
  font-size: 1.6rem;
  line-height: 1.8rem;
  border: none;
  cursor: pointer;
  padding-left: ${(props) =>
    props.iconInInput
      ? `${props.theme.spacing.extraLarge}`
      : `${props.theme.spacing.small}`};

  &:focus {
    border: 1px solid ${(props) => props.theme.color.shadow};
    box-shadow: 0 0 3px ${(props) => props.theme.color.shadow};
    outline: none;
  }

  ${(props) =>
    props.message &&
    css`
      border: 1px solid ${(props) => props.theme.color.error};
    `}

  ${(props) =>
    props.white &&
    css`
      background-color: ${(props) => props.theme.color.white};
    `}
    
  @media ${device.mobileL} {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
`;

const InputContainer = styled.div`
  height: 4rem;
  max-width: 25rem;
  border-radius: 1rem;
  position: relative;

  ${(props) =>
    props.flag &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.standard};
      &:last-child {
        margin-bottom: 0px;
      }
    `}
  ${(props) =>
    props.isContactFormProfile &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.small};
    `}
`;
