import React from 'react';
import styled from 'styled-components';
import { Thumbnail } from '../../shared/thumbnail/Thumbnail';
import { device } from '../../../styles/device';
import { SmallLabel } from '../../shared/labels/SmallLabel';
import { calculateProgress } from '../../../helpers/common';
import { displayDateAndTime } from '../../../helpers/common';
import { ProfileCardTestDate } from './ProfileCardTestDate';

export const ProfileCard = ({
  users,
  setUsers,
  onItemClick,
  inverted,
  projectView = false,
}) => {
  const truncateString = (string) => {
    if (string && string.length > 25) {
      return string.substring(0, 25) + '...';
    } else {
      return string;
    }
  };

  const coordinatorName = (user) => {
    try {
      if (!user.activeProjects || !user.activeProjects[0]) return '';
      const coordinatorIx = user.activeProjects[0].managers.findIndex(
        (item) => item.roleName === 'Project coordinator'
      );
      const displayIndex = coordinatorIx !== -1 ? coordinatorIx : 0;
      return user.activeProjects[0].managers[displayIndex].user.name;
    } catch (error) {
      return '';
    }
  };

  const renderUsers = () => {
    return users.map((user) => {
      return (
        <Container
          key={user._id}
          onClick={() => onItemClick(user.slug)}
          inverted={inverted}
          projectView={projectView}
        >
          <InfoBox projectView={projectView}>
            <ThumbnailContainer>
              <Thumbnail
                image={user.profileImage && user.profileImage.thumbnail}
                name={user.name}
                size='8'
                progress={calculateProgress(user)}
                signed={user.isSigned}
              />
            </ThumbnailContainer>
            <LastActiveText inverted={inverted}>
              {user.lastLoggedIn && displayDateAndTime(user.lastLoggedIn)}
            </LastActiveText>
          </InfoBox>
          <TopContainer projectView={projectView}>
            <CardHeading>{truncateString(user.name)}</CardHeading>
            <Center>
              <InfoText>
                {user.email ? truncateString(user.email) : 'No email'}
              </InfoText>
              <InfoText>
                {user.info && user.info.position
                  ? truncateString(user.info.position)
                  : 'No role assigned'}
              </InfoText>
              <InfoText>
                {user.info && user.info.omegaConsultant
                  ? truncateString(user.info.omegaConsultant.name)
                  : 'No consultant assigned'}
              </InfoText>
            </Center>
          </TopContainer>
          <BottomContainer projectView={projectView}>
            {(projectView && (
              <OmegaTests onClick={(event) => event.stopPropagation()}>
                {user.omegaTests &&
                  user.omegaTests.map((test, index) => (
                    <ProfileCardTestDate
                      key={index}
                      currentTest={test}
                      currentUser={user}
                      setUsers={setUsers}
                    />
                  ))}
              </OmegaTests>
            )) || (
              <>
                <BottomCenter>
                  <InfoText>
                    {user.activeProjects.length > 0
                      ? truncateString(user.activeProjects[0].name)
                      : 'No project confirmed'}
                  </InfoText>
                  <InfoText>{coordinatorName(user)}</InfoText>
                </BottomCenter>
                <SignedBox>
                  <Signed>
                    {user.isSigned && <SmallLabel signed text='Signed' />}
                  </Signed>
                </SignedBox>
              </>
            )}
          </BottomContainer>
        </Container>
      );
    });
  };
  return renderUsers();
};

const Container = styled.div`
  position: relative;
  width: ${(props) => (props.projectView ? '36' : '32')}rem;
  height: ${(props) => (props.projectView ? '23' : '17')}rem;
  border-radius: 0.4rem;
  background: ${(props) =>
    props.inverted ? props.theme.color.bgGrey : props.theme.color.white};
  cursor: pointer;
  padding: ${(props) => props.theme.spacing.standard};

  @media (max-width: 1104px) {
    width: 31rem;
  }
  @media ${device.laptop} {
    width: 30rem;
  }
  @media (max-width: 979px) {
    width: 35rem;
  }
  @media (max-width: 868px) {
    width: 33rem;
  }
  @media ${device.tablet} {
    width: 31rem;
  }
  @media (max-width: 725px) {
    width: 30rem;
  }
  @media (max-width: 669px) {
    width: 36rem;
  }
  @media ${device.mobileL} {
    width: 38rem;
  }
  @media ${device.mobileM} {
    width: 34rem;
  }
  @media ${device.mobileS} {
    width: 29rem;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  height: ${(props) => (props.projectView ? '41' : '55')}%;
  border-bottom: ${(props) => `1px solid ${props.theme.color.lightGrey}`};
  padding-bottom: ${(props) => props.theme.spacing.small};
`;

const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  height: ${(props) => (props.projectView ? '59' : '45')}%;
  padding-top: ${(props) => props.theme.spacing.small};
`;
const InfoBox = styled.div`
  position: absolute;
  height: auto;
  width: 10rem;
  right: 7%;
  bottom: ${(props) => (props.projectView ? '62' : '53')}%;
`;
const CardHeading = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  max-width: 19rem;
  white-space: nowrap;
`;
const InfoText = styled.p`
  font-size: 1.2rem;
  max-width: 19rem;
  white-space: nowrap;
`;
const LastActiveText = styled.p`
  margin-top: 1.2rem;
  font-size: 1.2rem;
  font-style: italic;
  text-align: right;
  color: ${(props) =>
    props.inverted ? props.theme.color.offGrey : props.theme.color.lightGrey};
`;
const ThumbnailContainer = styled.div`
  margin-left: ${(props) => props.theme.spacing.standard};
`;
const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: ${(props) => props.theme.spacing.small};
  padding-top: ${(props) => props.theme.spacing.extraSmall};
`;
const BottomCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: space-around;
  flex: 2;
  height: 100%;
`;
const SignedBox = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
`;
const Signed = styled.div`
  width: 8.3rem;
  height: 3rem;
`;

const OmegaTests = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  row-gap: 0.5rem;
  font-size: 1.2rem;
  cursor: default;
`;
