import React from 'react';
import styled, { css } from 'styled-components';
import { SearchBar } from '../search-bar/SearchBar';
import { TableHeader } from './TableHeader';
import { TableData } from './TableData';
import { device } from '../../../styles/device';

export const SortingTable = ({
  title,
  icon,
  searchBar,
  setSearchString,
  searchString,
  searchBarPlaceholder,
  data,
  setData,
  selectedData,
  setSelectedData,
  showHeaders,
  columnWidths,
  customHeaders,
  templateQuestions,
  noMargin = false,
  scrollOverflow = false,
  disabled = false,
  padding,
}) => {
  const addItems = (index) => {
    const newData = [...data];
    const selectedItem = newData.splice(index, 1)[0];

    const newSelectedItems = [...selectedData];
    newSelectedItems.push(selectedItem);
    setSelectedData(newSelectedItems);
    setData(newData);
  };

  const removeItem = (index) => {
    const newSelectedItems = [...selectedData];
    newSelectedItems.splice(index, 1);
    setSelectedData(newSelectedItems);
  };

  const columnWidthWithoutId = ['0%', ...columnWidths];

  return (
    <Container noMargin={noMargin} templateQuestions={templateQuestions}>
      {title && (
        <Header>
          {icon && <Icon src={icon} />}
          <Title>{title}</Title>
        </Header>
      )}
      <InnerContainer>
        {searchBar && (
          <SearchBar
            noMargin
            placeholder={searchBarPlaceholder}
            onChange={setSearchString}
            searchString={searchString}
          />
        )}
        {showHeaders && (
          <RowHeader>
            <TableHeader
              data={data}
              columnWidths={columnWidths}
              customHeaders={customHeaders}
            />
            <HeadItem sortingColumn={'20rem'}></HeadItem>
          </RowHeader>
        )}
        <SelectedDataContainer scrollOverflow={scrollOverflow}>
          {selectedData && (
            <TableData
              padding={padding}
              isSelected
              moveRow={removeItem}
              data={selectedData}
              setSelectedData={setSelectedData}
              columnWidths={columnWidthWithoutId}
              disabled={disabled}
            />
          )}
        </SelectedDataContainer>
        {!disabled && (
          <DataContainer>
            {data && (
              <TableData
                padding={padding}
                moveRow={addItems}
                data={data}
                columnWidths={columnWidthWithoutId}
              />
            )}
          </DataContainer>
        )}
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;
  border-radius: 0.4rem;

  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;

  ${(props) =>
    !props.noMargin &&
    css`
      padding: 2rem;
      margin: 5rem;
    `}


  ${(props) =>
    props.templateQuestions &&
    css`
      margin: 0;
      margin-top: 2rem;
    `}

  @media ${device.tablet} {
    margin: 0rem;
    ${(props) =>
      props.templateQuestions &&
      css`
        margin: 0;
        margin-top: 2rem;
      `}
  }
`;

const InnerContainer = styled.div`
  min-height: 30rem;
  padding: 2rem;
  width: 100%;
  background-color: #eeeeee;
  border-radius: 0.4rem;
`;

const SelectedDataContainer = styled.div`
  ${(props) =>
    props.scrollOverflow && ' overflow-y: scroll; max-height: 30rem;'}
  padding-bottom: 1rem;
  border-bottom: 0.2rem solid #d6d6d6;
  width: 100%;
`;

const DataContainer = styled.div`
  overflow: scroll;
  max-height: 30rem;
  padding-bottom: 1rem;
  width: 100%;
`;

const HeadItem = styled.div`
  padding: 1rem;
  display: flex;
  font-weight: bold;
  font-style: italic;
  ${(props) =>
    props.columnWidth &&
    css`
        width ${(props) => props.columnWidth};
      `}

  ${(props) =>
    props.sortingColumn &&
    css`
        width ${(props) => props.sortingColumn};
        justify-content: flex-end;
      `}
`;
const RowHeader = styled.div`
  /* tabort sen */
  @media ${device.tablet} {
    display: none;
  }
  padding: 2.5rem 1rem;
  display: flex;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.standard};
  align-items: center;
`;

const Title = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${(props) =>
    props.error
      ? `1px solid ${props.theme.color.error}`
      : `1px solid ${props.theme.color.darkBlue}`};
`;

const Icon = styled.img`
  margin-right: ${(props) => props.theme.spacing.extraSmall};
`;
