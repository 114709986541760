import React from 'react';
import styled from 'styled-components';
import {
  optionsOnlyWhite,
  optionsOnlyGrey,
  addCameraWhite,
  addCameraGrey,
  penWithBorderWhite,
  penWithBorderGrey,
  numberTypeWhite,
  numberTypeGrey,
  radioButtonWhite,
  radioButtonGrey,
  dropDownWhite,
  dropDownGrey,
  checkedItemCircleWhite,
  checkedItemCircleGrey,
  omegaGrey,
  omegaWhite,
  plus,
  plusBlack,
  eyeGrey,
  eyeDark,
} from '../../../shared/assets/icons';
import { TypeButton } from './TypeButton';

const questionTypes = [
  {
    icon: penWithBorderGrey,
    activeIcon: penWithBorderWhite,
    text: 'Text',
    id: 'text',
  },
  {
    icon: addCameraGrey,
    activeIcon: addCameraWhite,
    text: 'Image',
    id: 'image',
  },
  {
    icon: numberTypeGrey,
    activeIcon: numberTypeWhite,
    text: 'Numbers',
    id: 'numbers',
  },
  {
    icon: optionsOnlyGrey,
    activeIcon: optionsOnlyWhite,
    text: 'Options only',
    id: 'optionsOnly',
  },
  {
    icon: omegaGrey,
    activeIcon: omegaWhite,
    text: 'Omega',
    id: 'omega',
  },
  {
    //TODO: change icons
    icon: eyeGrey,
    activeIcon: eyeDark,
    text: 'Likert scale',
    id: 'likert',
  },
  {
    icon: dropDownGrey,
    activeIcon: dropDownWhite,
    text: 'FIM',
    id: 'fim',
  },
  {
    //TODO: Swithc name and id??
    icon: plusBlack,
    activeIcon: plus,
    text: 'Add',
    id: 'add',
  },
];

export const optionTypes = [
  {
    icon: penWithBorderGrey,
    activeIcon: penWithBorderWhite,
    text: 'Text',
    id: 'text',
  },
  {
    icon: addCameraGrey,
    activeIcon: addCameraWhite,
    text: 'Image',
    id: 'image',
  },
  {
    icon: checkedItemCircleGrey,
    activeIcon: checkedItemCircleWhite,
    text: 'Checkbox',
    id: 'checkbox',
  },
  {
    icon: radioButtonGrey,
    activeIcon: radioButtonWhite,
    text: 'Radio button',
    id: 'radio',
  },
  {
    icon: dropDownGrey,
    activeIcon: dropDownWhite,
    text: 'Dropdown',
    id: 'dropdown',
  },
  {
    icon: dropDownGrey,
    activeIcon: dropDownWhite,
    text: 'FIM',
    id: 'fim',
  },
];

const yesOrNoOptions = [
  {
    icon: checkedItemCircleGrey,
    activeIcon: checkedItemCircleWhite,
    text: 'Yes',
    id: 'yes',
    value: true,
  },
  {
    icon: optionsOnlyGrey,
    activeIcon: optionsOnlyWhite,
    text: 'No',
    id: 'no',
    value: false,
  },
];

export const Type = ({
  questionType,
  optionType,
  chosenType,
  setChosenType,
  question,
  yesOrNoOption,
}) => {
  const handleOnClick = (type) => {
    setChosenType(type);
  };

  if (
    question &&
    question.type &&
    question.type.fim &&
    optionType &&
    !chosenType
  ) {
    const type = optionTypes.find((op) => op.id === 'fim');
    setChosenType(type);
  }

  return (
    <Container>
      {questionType &&
        questionTypes.map((type, index) => (
          <TypeButton
            chosenType={chosenType}
            setChosenType={setChosenType}
            key={index}
            type={type}
            active={chosenType && chosenType === type.id}
            onClick={() => handleOnClick(type.id)}
          />
        ))}
      {optionType &&
        optionTypes.map((type, index) => {
          if (
            question.type.image &&
            type.id !== 'image' &&
            type.id !== 'radio' &&
            type.id !== 'checkbox'
          )
            return <React.Fragment key={index} />;
          if (question.type.numbers && type.id !== 'radio')
            return <React.Fragment key={index} />;
          if (question.type.fim && type.id !== 'fim')
            return <React.Fragment key={index} />;
          if (
            question.type.optionsOnly &&
            type.id !== 'radio' &&
            type.id !== 'checkbox'
          )
            return <React.Fragment key={index} />;
          return (
            <TypeButton
              chosenType={chosenType}
              setChosenType={setChosenType}
              key={index}
              type={type}
              active={chosenType && chosenType.id === type.id}
              onClick={() => handleOnClick(type)}
            />
          );
        })}
      {yesOrNoOption &&
        yesOrNoOptions.map((type, index) => (
          <TypeButton
            chosenType={chosenType}
            setChosenType={setChosenType}
            key={index}
            type={type}
            active={chosenType === type.value}
            onClick={() => {
              handleOnClick(type.value);
            }}
          />
        ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom -1.5rem;
 
`;
