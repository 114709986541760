import React, { useState } from 'react';
import styled from 'styled-components';
import { device } from '../../styles/device';
import { Header } from '../../components/candidate/navigation/Header';
import { Footer } from '../../components/candidate/navigation/Footer';
import {
  SignInForm,
  ForgetPasswordForm,
} from '../../components/candidate/signin/form';

export const CandidateLogin = () => {
  const [showForgetPasswordForm, toggleForm] = useState(false);
  return (
    <EntireWrapper>
      <Header removeLogOutIcon />
      <Container>
        <ContentContainer>
          <TextContainer>
            {showForgetPasswordForm ? (
              <>
                <Title>Did you forget your password?</Title>
                <IntroText>
                  Fill in your e-mail below to choose a new one.
                </IntroText>
                <Padding />
                <ForgetPasswordForm
                  toggleForm={() => toggleForm(!showForgetPasswordForm)}
                />
              </>
            ) : (
              <>
                <Title>Welcome to Navigio</Title>
                <IntroText>Please sign in to continue.</IntroText>
                <Padding />
                <SignInForm
                  toggleForm={() => toggleForm(!showForgetPasswordForm)}
                />
              </>
            )}
          </TextContainer>
        </ContentContainer>
      </Container>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </EntireWrapper>
  );
};

const EntireWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  position: relative;
  min-height: 100vh;
  padding-bottom: 11rem;
  @media ${device.tablet} {
    padding-bottom: 6rem;
  }
`;

const Container = styled.div`
  font-family: 'futura-pt', sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 11rem;
`;

const ContentContainer = styled.div`
  width: 65rem;
  padding: 8rem 0rem;
  @media ${device.tablet} {
    width: 100%;
    padding: 8rem 2rem;
  }
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.1rem;
  padding: 8rem 0 2.5rem 0;
  @media ${device.mobileL} {
    font-size: 2.9rem;
    line-height: 3.8rem;
  }
`;

const IntroText = styled.h2`
  font-size: 2.2rem;
  line-height: 2.8rem;

  @media ${device.tablet} {
    width: 40rem;
  }
  @media ${device.mobileL} {
    width: 100%;
    font-size: 1.9rem;
    line-height: 2.5rem;
  }
`;

const Padding = styled.div`
  height: 3rem;
`;

const TextContainer = styled.div`
  padding-bottom: 8rem;
  word-wrap: none;
  height: fit-content;
  @media ${device.tablet} {
    padding: 0 2rem 10rem 2rem;
  }
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
