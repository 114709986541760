import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Card } from '../../../components/admin/languages/Card';
import { device } from '../../../styles/device';
import { useFetch } from '../../../helpers/hooks/fetchData';

export const ViewAllLanguages = () => {
  const [payload, setPayload] = useState({});
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const endpoint = '/api/omega/languages';
  const method = 'get';

  const { data } = useFetch(payload);

  useEffect(() => {
    if (data && data.data) {
      setSelectedLanguages(data.data);
    }
  }, [data, selectedLanguages]);

  useEffect(() => {
    setPayload({ endpoint, method });
  }, [endpoint, method]);

  return (
    <Container>
      <Padding />
      <InnerContainer>
        <CardsContainer>
          <Card addNew alreadyExistingLanguages={selectedLanguages} />
          {data &&
            data.data.map((language) => (
              <Card
                id={language._id}
                key={language._id}
                code={language.code}
                language={language.language}
                flag={language.flag}
                flagCard
                slug={language.slug}
              />
            ))}
          {data && data.data.length === 0 && (
            <EmptyMessage>No languages added yet.</EmptyMessage>
          )}
        </CardsContainer>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.bgGrey};
`;

const InnerContainer = styled.div`
  padding: 1rem;
  margin-top: 1rem;
  padding: 0 ${(props) => props.theme.spacing.extraLarge};
  @media ${device.mobileL} {
    padding: 0 ${(props) => props.theme.spacing.small};
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Padding = styled.div`
  padding-top: 3rem;
`;

const EmptyMessage = styled.h2`
  font-size: 2rem;
  color: ${(props) => props.theme.color.offBlack};
  font-style: italic;
  padding-left: ${(props) => props.theme.spacing.standard};
`;
