import React from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';

export const Flag = ({ langImg }) => (
  <FlagWrapper>
    <FlagImage src={langImg} />
  </FlagWrapper>
);

const FlagWrapper = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -1.6rem;
  right: ${(props) => props.theme.spacing.standard};
  box-shadow: 0px 0.05rem 1rem rgba(0, 0, 0, 0.12);
`;

const FlagImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  @media ${device.mobileM} {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
`;
