import { SE, DK, NO, FI, EN, FR, DE, ES } from '../assets/icons';

export const countries = [
  {
    id: 1,
    code: 'SE',
    language: 'Swedish',
    flag: SE,
  },
  {
    id: 2,
    code: 'DK',
    language: 'Danish',
    flag: DK,
  },
  {
    id: 3,
    code: 'NO',
    language: 'Norwegian',
    flag: NO,
  },
  {
    id: 4,
    code: 'FI',
    language: 'Finnish',
    flag: FI,
  },
  {
    id: 5,
    code: 'EN',
    language: 'English',
    flag: EN,
  },
  {
    id: 6,
    code: 'FR',
    language: 'French',
    flag: FR,
  },
  {
    id: 7,
    code: 'DE',
    language: 'German',
    flag: DE,
  },
  {
    id: 8,
    code: 'ES',
    language: 'Spanish',
    flag: ES,
  },
];
