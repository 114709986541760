import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FormLabel } from './FormLabel';
import { InputField } from './InputField';

export const Checkboxes = ({
  label,
  id,
  error,
  message,
  iconAfterLabel,
  isContactFormProfile,
  data,
  onChange,
  values,
  name,
  factoryLoading,
  handleFactoryBlur,
  handleFactoryChange,
  ...inputProps
}) => {
  const [reload, setReload] = useState({});
  return (
    <>
      {label && (
        <FormLabel
          label={label}
          id={id}
          error={error}
          message={message}
          iconAfterLabel={iconAfterLabel}
        />
      )}
      <InputContainer isContactFormProfile={isContactFormProfile} flag>
        {data &&
          data.map((obj, index) => {
            const id = `${name}.${index}`;

            let valueIndex = values.findIndex(
              (object) => object === obj._id || object[name] === obj._id
            );

            const isChecked = (valueIndex >= 0 && true) || false;

            return (
              <div key={obj._id}>
                <Checkbox
                  {...inputProps}
                  id={id}
                  type='checkbox'
                  value={obj._id}
                  checked={isChecked}
                  onChange={(e) => {
                    onChange(e);
                    setReload({ ...reload });
                  }}
                  className='checkbox'
                />
                <ChecboxLabel htmlFor={id}>{obj.title}</ChecboxLabel>
                {factoryLoading && isChecked && (
                  <InputField
                    id={`facets[${valueIndex}].factoryLoading`}
                    onChange={handleFactoryChange}
                    onBlur={handleFactoryBlur}
                    value={(values && values[valueIndex].factoryLoading) || 0}
                    type='number'
                    step='any'
                  />
                )}
              </div>
            );
          })}
      </InputContainer>
    </>
  );
};

const Checkbox = styled.input`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1rem;
  position: relative;
  ${(props) =>
    props.flag &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.standard};
      &:last-child {
        margin-bottom: 0px;
      }
    `}
  ${(props) =>
    props.isContactFormProfile &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.small};
    `}
`;

const ChecboxLabel = styled.label`
  font-size: 1.7rem;
  cursor: pointer;
  display: inline-block;
  color: ${(props) =>
    props.error
      ? `1px solid ${props.theme.color.error}`
      : `1px solid ${props.theme.color.darkBlue}`};
`;
