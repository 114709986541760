import React from 'react';
import styled from 'styled-components';
import { OmegaItem } from './OmegaItem';
import { NoResultsFound } from '../error-page-components/NoResultsFound';

export const OmegaQuestionTable = ({
  type,
  items,
  dropDownOptions,
  icon,
  iconActive,
}) => {
  let hasOmegaQuestions = true;

  return (
    <Table>
      {items && hasOmegaQuestions ? (
        items.map((item, index) => {
          if (item.omegaQuestions) {
            return (
              <OmegaItem
                type={type}
                key={index}
                tabIndex={index}
                item={item}
                dropDownOptions={dropDownOptions}
                icon={icon}
                iconActive={iconActive}
              />
            );
          } else return null;
        })
      ) : (
        <NoResultsFound />
      )}
    </Table>
  );
};

const Table = styled.div`
  display: grid;
  margin-top: ${(props) => props.theme.spacing.large};
`;
