import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '../../../shared/button/Button';
import { Modal } from '../../../shared/modal/Modal';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { TableInput } from './TableInput';

export const SendInvitationModal = ({ users, showModal, setShowModal }) => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [content, setContent] = useState([]);
  const [payload, setPayload] = useState({});
  const [amountOfEmailSent, setAmountOfEmailSent] = useState(0);
  const [emailError, setEmailError] = useState([]);
  const [formError, setFormError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { data, isLoading, hasError } = useFetch(payload);

  const sortUsers = useCallback(
    (data) => {
      return data
        .map((candidate) => {
          if (
            selectedUsers.findIndex(
              (selected) => selected._id === candidate._id
            ) !== -1
          ) {
            return false;
          }

          return {
            _id: candidate._id,
            name: candidate.name,
          };
        })
        .filter(Boolean);
    },
    [selectedUsers]
  );

  useEffect(() => {
    setContent(sortUsers(users));
  }, [users, sortUsers]);

  useEffect(() => {}, []);

  useEffect(() => {
    if (data && data.data && data.data.length) {
      setAmountOfEmailSent(data.data.length);
    }

    if (data && data.error && data.error.length) {
      setEmailError(data.error);
    }
  }, [data, setShowModal]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedUsers.length) {
      setPayload({
        endpoint: '/api/omega/projects/send-invitations',
        method: 'post',
        data: selectedUsers,
      });
      setIsSubmitted(true);
    } else setFormError('Select candidates');
  };

  return (
    <>
      <Modal
        show={showModal}
        handleExit={setShowModal}
        width={'40rem'}
        title={'Send invitations'}
      >
        {!isSubmitted && (
          <Form onSubmit={handleSubmit}>
            <TableInput
              label={'Candidates'}
              content={content}
              setContent={setContent}
              selectedContent={selectedUsers}
              setSelectedContent={setSelectedUsers}
              sortSelected={sortUsers}
              noMargin
              scrollOverflow
            />
            <ErrorText>{formError}</ErrorText>
            <Margin />
            <Button
              primary
              type='submit'
              text='Send invitations'
              //TODO:   Disable on islaoding
              isDisabled={isLoading}
            />
            <Margin small />
            <Button
              secondary
              type='button'
              text='Cancel'
              onClick={() => setShowModal(false)}
            />
          </Form>
        )}

        {isLoading && <Text>Sending invitations...</Text>}

        {isSubmitted && !isLoading && (
          <>
            {(hasError && (
              <>
                <Text>Error sending invitations</Text>
                <Button text='OK' onClick={() => setShowModal(false)} />
              </>
            )) || (
              <>
                {(amountOfEmailSent && (
                  <Text>{amountOfEmailSent} invitations sent. </Text>
                )) ||
                  null}

                {emailError && emailError.length > 0 && (
                  <>
                    <Text>Error sending invitations to:</Text>
                    {emailError.map((name, index) => (
                      <Text key={index}>{name}</Text>
                    ))}
                  </>
                )}
                <Button text='OK' onClick={() => setShowModal(false)} />
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
`;
const ErrorText = styled.div`
  font-size: 1.6rem;
  color: red;
  margin-top: 0.5rem;
`;

const Margin = styled.div`
  height: ${(props) =>
    props.small ? props.theme.spacing.small : props.theme.spacing.extraLarge};
`;
