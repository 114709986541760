import React, { useEffect, useState, Fragment } from 'react';
import { Formik, Field } from 'formik';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { InputField } from '../../../shared/input/InputField';
import styled from 'styled-components';
import * as yup from 'yup';
import { Button } from '../../../shared/button/Button';
import { device } from '../../../../styles/device';
import { InputWrapper } from '../../../shared/input/InputWrapper';
import { CreationProgress } from '../../../shared/progress-bar/CreationProgress';
import {
  LanguageItem,
  LanguageItemWrapper,
} from '../../questions/edit-question/LanguageItem';
import { CategoryList } from '../../../shared/drop-downs/CategoryList';
import { Type } from '../../questions/edit-question/Type';
import { getNicelyFormattedTimeFromMS } from '../../../../helpers/common';
import { Modal } from '../../../shared/modal/Modal';
import { QuestionsPerPage, TimeLimit } from './';

export const GeneralTemplate = ({ template, setPage, setTemplate }) => {
  const [languages, setLanguages] = useState(null);
  const [categories, setCategories] = useState(null);
  const [languagePayload] = useState({ endpoint: '/api/omega/languages/' });
  const [categoriesPayload] = useState({ endpoint: '/api/omega/categories/' });
  const [chosenCategory, setChosenCategory] = useState(template.category);
  const [chosenLanguage, setChosenLanguage] = useState(
    template.availableLanguages
  );
  const [update, setUpdate] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const [hasTimeLimit, setHasTimeLimit] = useState(template.hasTimeLimit);
  const [timeLimit, setTimeLimit] = useState();
  const [questionsPerPage, setQuestionsPerPage] = useState(
    template.questionsPerPage || 3
  );

  const validationSchema = yup.object().shape({
    title: yup.string().required('This is a required field.'),
  });

  const categoriesData = useFetch(categoriesPayload);
  const languagesData = useFetch(languagePayload);
  const abortController = new AbortController();
  const signal = abortController.signal;
  const [payload, setPayload] = useState({});
  const { data, hasError } = useFetch(payload);

  useEffect(() => {
    if (data) {
      const availableLanguages = data.availableLanguages;
      template.availableLanguages = availableLanguages;
      setTemplate(template);

      if (data.hasInstruction) {
        setPage(2);
      } else {
        setPage(3);
      }

      return function cancel() {
        abortController.abort();
      };
    }
  }, [data, abortController, setPage, setTemplate, template]);

  useEffect(() => {
    if (hasError) {
      setModalTitle('Error saving template');
      setShowModal(true);
    }
  }, [hasError]);

  useEffect(() => {
    if (categoriesData && categoriesData.data && categoriesData.data.data) {
      setCategories(categoriesData.data.data);
    }
  }, [categoriesData]);

  useEffect(() => {
    if (languagesData && languagesData.data && languagesData.data.data) {
      setLanguages(languagesData.data.data);
    }
  }, [languagesData]);

  const addLanguage = (language, key) => {
    const langIx = template.availableLanguages.findIndex(
      (availableLanguage) => availableLanguage.code === language.code
    );
    if (langIx === -1) {
      template.questions = template.questions || {};
      if (!template.questions[language.code]) {
        template.questions[language.code] = { text: '' };
      }
      template.availableLanguages.push(language);
    } else {
      template.availableLanguages.splice(langIx, 1);
      delete template.questions[language.code];
    }
    setUpdate(!update);
    setChosenLanguage([...chosenLanguage, key]);
  };

  useEffect(() => {
    if (hasTimeLimit) {
      template.hasTimeLimit = hasTimeLimit;
    }
    if (!hasTimeLimit) {
      template.hasTimeLimit = hasTimeLimit;
    }
  }, [template.hasTimeLimit, hasTimeLimit]);

  useEffect(() => {
    if (chosenCategory) template.category = chosenCategory;
  }, [chosenCategory, template.category]);

  const setHasInstruction = (hasInstruction) => {
    template.hasInstruction = hasInstruction;
    setUpdate(!update);
  };

  const setExercisePage = (exercisePage) => {
    template.exercisePage = exercisePage;
    setUpdate(!update);
  };

  const setIsRequired = (isRequired) => {
    template.isRequired = isRequired;
    setUpdate(!update);
  };

  const toMilliseconds = (time) => {
    let indexes = time.split(':');
    let milliseconds =
      parseInt(indexes[0], 10) * (60000 * 60) +
      parseInt(indexes[1], 10) * 60000 +
      parseInt(indexes[2], 10) * 1000;
    return milliseconds;
  };

  const changeTime = (e) => {
    setTimeLimit(e.target.value);
  };
  useEffect(() => {
    const timeObj = getNicelyFormattedTimeFromMS(template.timeLimit, true);
    const newTimeLimit =
      (timeObj.hours < 10 ? '0' + timeObj.hours : timeObj.hours) +
      ':' +
      (timeObj.minutes < 10 ? '0' + timeObj.minutes : timeObj.minutes) +
      ':' +
      (timeObj.seconds < 10 ? '0' + timeObj.seconds : timeObj.seconds);

    setTimeLimit(newTimeLimit);
  }, [template.timeLimit, setTimeLimit]);

  if (!template.titles) {
    template.titles = {};
  }

  return (
    <Container>
      <CreationProgress
        step='first'
        firstStepText='General'
        secondStepText='Instruction'
        thirdStepText='Questions'
      />
      <StandardSpacing />
      <Formik
        initialValues={{ ...template, timeLimit }}
        values={{ ...template, timeLimit }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          template.title = values.title;
          template.titles = values.titles;
          template.timeLimit = toMilliseconds(timeLimit || 0);
          template.questionsPerPage = questionsPerPage;

          setPayload({
            method: 'put',
            endpoint: `/api/omega/templates/${template._id}/`,
            data: template,
            headers: {
              'Content-Type': 'application/json',
            },
            signal: signal,
          });
        }}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <InnerContainer>
              <LeftColumn>
                <InputWrapper
                  errorMsg={errors.title && touched.title && `${errors.title}`}
                  title='Internal title'
                >
                  <Field
                    as={InputField}
                    id='internal title'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title || ''}
                    error={errors.title && touched.title}
                    type='text'
                    name='title'
                    placeholder='Internal title'
                  />

                  {template.availableLanguages.map((language) => (
                    <Fragment key={language.code}>
                      <Title>{language.language} title</Title>
                      <Field
                        as={InputField}
                        id={`title.${language.language}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.titles[language.code] || ''}
                        error={errors.titles && touched.titles}
                        type='text'
                        name={`titles.${language.code}`}
                        placeholder={`${language.language} title`}
                      />
                    </Fragment>
                  ))}
                </InputWrapper>
                <InputWrapper marginTop height='29' title='Category'>
                  <CategoryList
                    categories={categories}
                    setChosenCategory={setChosenCategory}
                    chosenCategory={chosenCategory}
                  />
                </InputWrapper>
                {chosenCategory && (
                  <>
                    <InputWrapper marginTop title='Include instruction page'>
                      <Type
                        chosenType={template.hasInstruction}
                        setChosenType={setHasInstruction}
                        yesOrNoOption
                      />
                    </InputWrapper>
                    {template.hasInstruction && (
                      <InputWrapper
                        marginTop
                        title='Secound page for exercises'
                      >
                        <Type
                          chosenType={template.exercisePage}
                          setChosenType={setExercisePage}
                          yesOrNoOption
                        />
                      </InputWrapper>
                    )}
                  </>
                )}
              </LeftColumn>
              <Space />

              <RightColumn>
                {typeof template.hasInstruction !== 'undefined' && (
                  <InputWrapper title='Languages'>
                    <LanguageItemWrapper>
                      {languages &&
                        languages.length > 0 &&
                        languages.map((language, key) => (
                          <LanguageItem
                            key={key}
                            onClick={() => addLanguage(language, key)}
                            code={language.code}
                            flag={language.flag}
                            active={template.availableLanguages.some(
                              (pickedLanguage) =>
                                pickedLanguage._id === language._id
                            )}
                          />
                        ))}
                    </LanguageItemWrapper>
                  </InputWrapper>
                )}
                {chosenLanguage && chosenLanguage.length > 0 && (
                  <React.Fragment>
                    <InputWrapper
                      marginTop
                      title='Time limit'
                      errorMsg={
                        errors.timeLimit &&
                        touched.timeLimit &&
                        `${errors.timeLimit}`
                      }
                    >
                      <TimeLimit
                        value={timeLimit}
                        hasTimeLimit={hasTimeLimit}
                        setHasTimeLimit={setHasTimeLimit}
                        setTimeLimit={setTimeLimit}
                        onChange={changeTime}
                        onBlur={handleBlur}
                        error={errors.timeLimit && touched.timeLimit}
                      />
                    </InputWrapper>
                    <InputWrapper
                      marginTop
                      title='Require answer on all questions'
                    >
                      <Type
                        chosenType={template.isRequired}
                        setChosenType={setIsRequired}
                        yesOrNoOption
                      />
                    </InputWrapper>

                    <QuestionsPerPage
                      questionsPerPage={questionsPerPage}
                      setQuestionsPerPage={setQuestionsPerPage}
                    />
                  </React.Fragment>
                )}
              </RightColumn>
            </InnerContainer>
            <FullColumn>
              <ButtonContainer>
                <Button secondary text='Save draft' />
                <MarginButton />
                <Button
                  isDisabled={isSubmitting}
                  type='submit'
                  primary
                  text='Continue'
                />
              </ButtonContainer>
            </FullColumn>
          </Form>
        )}
      </Formik>
      <Modal
        handleExit={() => setShowModal(false)}
        title={modalTitle}
        show={showModal}
        width='40rem'
      >
        <ModalContent>
          <Button onClick={() => setShowModal(false)} text='OK' primary />
        </ModalContent>
      </Modal>
    </Container>
  );
};
const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing.large};
`;
const FullColumn = styled.div`
  width: 100%;
  margin-top: 8rem;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  background: ${(props) => props.theme.color.white};
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  padding: ${(props) => props.theme.spacing.standard};
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
const MarginButton = styled.div`
  width: 6rem;
  @media ${device.mobileL} {
    height: ${(props) => props.theme.spacing.small};
  }
`;
const LeftColumn = styled.div`
  flex: 1;
  height: 100%;
`;
const RightColumn = styled.div`
  flex: 1;
  height: 100%;
`;
export const StandardSpacing = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;
const Space = styled.div`
  width: ${(props) => props.theme.spacing.standard};
  @media ${device.tablet} {
    height: ${(props) => props.theme.spacing.standard};
  }
`;
const ModalContent = styled.div`
  font-size: 4rem;
  flex-direction: column;
`;

const Title = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${(props) =>
    props.error
      ? `1px solid ${props.theme.color.error}`
      : `1px solid ${props.theme.color.darkBlue}`};
`;
