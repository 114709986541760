import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { displayDateAndTime } from '../../../helpers/common';
import { FaPlusCircle } from 'react-icons/fa';
import { useFetch } from '../../../helpers/hooks/fetchData';

export const ProfileCardTestDate = ({ currentUser, setUsers, currentTest }) => {
  const [payload, setPayload] = useState({});
  const { data, isLoading, hasError } = useFetch(payload, true);
  const isInactive = currentTest.isDeactivatedByAdmin;

  useEffect(() => {
    if (data && data.data && data.data.testUpdated) {
      setUsers((users) => {
        users.forEach((user) => {
          if (user._id === currentUser._id) {
            user.omegaTests.forEach(
              (test) =>
                test._id === currentTest._id &&
                (test.isDeactivatedByAdmin = false)
            );
          }
        });

        return [...users];
      });
    }
  }, [data, currentTest, currentUser, setUsers]);

  return (
    <Container>
      <TestTitle>{currentTest.template.title}</TestTitle>
      <TestStatus>
        {(currentTest.testCompletionDate &&
          displayDateAndTime(currentTest.testCompletionDate, true)) ||
          (isInactive && (
            <InactiveRow>
              <Fragment>
                {(isLoading && 'Loading...') ||
                  (hasError && 'Error: Try again') ||
                  'Inactive'}
              </Fragment>
              <Icon
                onClick={(event) => {
                  event.stopPropagation();
                  setPayload({
                    endpoint: '/api/omega/users/candidate/activate-test',
                    method: 'PUT',
                    data: { id: currentTest._id, isActive: false },
                  });
                }}
              >
                <FaPlusCircle />
              </Icon>
            </InactiveRow>
          )) ||
          'Not completed'}
      </TestStatus>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.45fr;
  min-height: 1.5rem;
`;

const TestTitle = styled.div`
  font-weight: 700;
`;

const TestStatus = styled.div`
  color: ${(props) => props.theme.color.offGrey};
  font-style: italic;
  display: flex;
`;

const InactiveRow = styled.div`
  font-style: italic;
  display: flex;
  width: 100%;
`;

const Icon = styled.div`
  margin: 1px 0.5rem 0 auto;
  height: fit-content;
  padding: 0;
  border: 0;
  cursor: pointer;
  color: ${(props) => props.theme.color.mediumBlue};

  &:hover {
    color: #25a7ff;
    transform: scale(1.05);
  }
`;
