import React from 'react';
import styled from 'styled-components';
import { Scatterplot } from '../../diagrams/Scatterplot';
import { getLanguageText } from '../../../../../helpers/common';

export const RenderFourFielder = ({ section, sectionsData, language }) => {
  const scatterplotWidth = 500;
  const renderLines = () => {
    const width = scatterplotWidth + 40;
    const lines = [];

    const pos = width / 3;

    lines.push(
      <VerticalLine key='v90' pos={pos} height={width} opacity='.6' />
    );
    lines.push(
      <VerticalLine key='v91' pos={width - pos} height={width} opacity='.6' />
    );
    lines.push(
      <HorizontalLine key='h90' pos={pos} height={width} opacity='.6' />
    );
    lines.push(
      <HorizontalLine key='h91' pos={width - pos} height={width} opacity='.6' />
    );

    let position = width;
    let size = width / 9;

    for (let i = 8; i > 0; i--) {
      position -= size;
      if (i !== 3 || i !== 6) {
        lines.push(
          <VerticalLine
            key={`v${i}`}
            pos={position}
            height={width}
            opacity='.5'
          />
        );
        lines.push(
          <HorizontalLine
            key={`h${i}`}
            pos={position}
            height={width}
            opacity='.5'
          />
        );
      }
    }

    return lines;
  };

  return (
    <Container>
      {(sectionsData && sectionsData.length && (
        <InnerContainer width={scatterplotWidth + 75}>
          <Grid>
            <TextContainer columns='1fr'>
              <Text rotated>
                {section.data.fourFielder.y.lowValue[language]}
              </Text>
              <Text rotated>
                {getLanguageText(
                  section.data.fourFielder.y.cluster.titles,
                  language
                ) || section.data.fourFielder.y.cluster.title}
              </Text>
              <Text rotated>
                {section.data.fourFielder.y.highValue[language]}
              </Text>
            </TextContainer>
            <ScatterplotContainer>
              {renderLines()}
              <Scatterplot data={sectionsData} width={scatterplotWidth} />
            </ScatterplotContainer>
          </Grid>
          <TextContainer columns='1fr 1fr 1fr' bottom>
            <Text>{section.data.fourFielder.x.lowValue[language]}</Text>
            <Text>
              {getLanguageText(
                section.data.fourFielder.x.cluster.titles,
                language
              ) || section.data.fourFielder.x.cluster.title}
            </Text>
            <Text>{section.data.fourFielder.x.highValue[language]}</Text>
          </TextContainer>
        </InnerContainer>
      )) || <div>Missing some data</div>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  margin-right: 2rem
  padding-top: 4rem;
  padding-bottom: 4rem;
  color:#00284a;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
  height: 100%;
  position: relative;
  align-items: center;
`;

const ScatterplotContainer = styled.div`
  position: relative;
  border: 1px solid #00284aa6;
  padding: 2rem 0;
  display: flex;
  position: relative;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
`;

const VerticalLine = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => props.pos}px;
  width: 1px;
  height: ${(props) => props.height}px;
  border-right: 1px solid #00284a;
  opacity: ${(props) => props.opacity};
`;

const HorizontalLine = styled.div`
  position: absolute;
  top: ${(props) => props.pos}px;
  left: 0 ${(props) => props.pos}px;
  width: ${(props) => props.height}px;
  height: 1px;
  border-bottom: 1px solid #00284a;
  opacity: ${(props) => props.opacity};
`;

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.columns};
  ${(props) => props.bottom && 'margin-left: 4rem; margin-top: 1rem;'}
  ${(props) => !props.bottom && 'justify-content: center;margin-right: 1rem;'}
  width: ${(props) => (props.bottom && '100%') || '2rem'};
  height: 100%;
  position: relative;
  align-items: center;
`;

const Text = styled.div`
  ${(props) => props.rotated && 'transform: rotate(-90deg);'}
  font-size: 1.6rem;
  text-transform: uppercase;
  text-align: center;
`;
