import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  radioButtonChecked,
  radioButtonUnchecked,
} from '../../../shared/assets/icons';
import { device } from '../../../../styles/device';

export const RadioButtonOption = ({
  options,
  setAnswer,
  disabled,
  type,
  omegaNumber,
  selected,
  hasImageQuestion,
  isExercise,
  isInstruction,
  correctExercises,
  addQuestion,
  index,
  answerIx,
  fimQuestion,
  keyIndex,
}) => {
  const numberOfOptions = 4;
  //TODO: fix animation with state below
  const [doAnimation, setDoAnimation] = useState(false);

  useEffect(() => {
    setDoAnimation(true);
  }, [doAnimation]);

  return (
    <Wrapper
      hasImageQuestion={hasImageQuestion}
      manyOptions={options.length > numberOfOptions}
    >
      {options.map((option, key) => {
        const correctExercise =
          type === 'fim'
            ? correctExercises &&
              correctExercises[index] &&
              correctExercises[index].find((o) => {
                if (o && o.fimRowOptionId === option.fimRowOptionId)
                  return true;
                return false;
              })
            : correctExercises && correctExercises.indexOf(option._id) !== -1;

        const disable = disabled ? disabled.indexOf(option.text) !== -1 : false;
        if (option.type !== type && type !== 'fim') return false;
        const showCheckedFim =
          fimQuestion &&
          selected &&
          selected.fimRowOptionId === option.fimRowOptionId;

        return (
          <Container
            manyOptions={options.length > numberOfOptions}
            key={key}
            exercise={isExercise}
            onClick={
              !disable && !isInstruction
                ? () => {
                    const value = addQuestion
                      ? {
                          _id: option._id,
                          type,
                          text: option.text,
                          number: key + 1,
                        }
                      : fimQuestion
                      ? {
                          fimRowOptionId: option.fimRowOptionId,
                          text: option.EN,
                        }
                      : option._id;
                    setAnswer({
                      type: type || 'radio',
                      value,
                      omegaNumber,
                      isCorrect: option.isCorrect,
                      index,
                      answerIx,
                      keyIndex,
                    });
                  }
                : null
            }
          >
            {correctExercise ? (
              <AnimationContainer doAnimation={doAnimation}>
                <IconRadio
                  src={
                    selected === option._id || showCheckedFim
                      ? radioButtonChecked
                      : radioButtonUnchecked
                  }
                />
                <Label disable={disable}>{option.text}</Label>
              </AnimationContainer>
            ) : (
              <Center>
                <IconRadio
                  src={
                    (selected && selected === option._id) ||
                    (isInstruction && option.isCorrect) ||
                    showCheckedFim
                      ? radioButtonChecked
                      : radioButtonUnchecked
                  }
                />
                <Label disable={disable}>{option.text}</Label>
              </Center>
            )}
          </Container>
        );
      })}
      <PreLoadImage src={radioButtonChecked} />
    </Wrapper>
  );
};

const AnimationContainer = styled.div`
  width: fit-content;
  background: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem;
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: ${(props) => props.theme.color.exercise};
    transition: width 2.3s;
  }
  ${(props) =>
    props.doAnimation &&
    css`
      &:after {
        width: 100%;
      }
    `}

  justify-content: ${(props) =>
    props.manyOptions ? 'space-between' : 'flex-start'};
  justify-content: ${(props) =>
    props.hasImageQuestion && !props.manyOptions && 'center'};
  @media ${device.mobileL} {
    justify-content: flex-start;
  }
`;

const Center = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) =>
    props.manyOptions ? 'space-between' : 'flex-start'};
  justify-content: ${(props) =>
    props.hasImageQuestion && !props.manyOptions && 'center'};
  @media ${device.mobileL} {
    justify-content: flex-start;
  }
`;

const Container = styled.div`
  margin: 1rem 0rem 1rem 0rem;
  display: flex;
  width: fit-content;
  height: ${(props) => props.exercise && '6.5rem'};
  margin: ${(props) => !props.manyOptions && '1rem 5rem 1rem 0rem'};
  @media ${device.mobileL} {
    margin: 1rem 5rem 1rem 0rem;
  }
`;

const Label = styled.h3`
  margin-left: 1rem;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;
  color: ${(props) =>
    props.disable ? props.theme.color.mediumGrey : props.theme.color.black};
`;

const IconRadio = styled.img`
  cursor: pointer;
`;

const PreLoadImage = styled.img`
  display: none;
`;
