import React, { useState, useEffect } from 'react';
import { SortingTable } from '../../../shared/sorting-table/SortingTable';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { Field } from 'formik';
import { InputField } from '../../../shared/input/InputField';
import { InputWrapper } from '../../../shared/input/InputWrapper';

export const AddQuestions = ({
  template,
  sectionIndex,
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
}) => {
  const [selectedData, setSelectedData] = useState(
    template.questionSections &&
      template.questionSections[sectionIndex] &&
      template.questionSections[sectionIndex].questions &&
      template.questionSections[sectionIndex].questions.map((question) => {
        return {
          _id: question._id,
          title: question.title,
          languages:
            question.availableLanguages &&
            question.availableLanguages.map((lang, index) => {
              return index !== question.availableLanguages.length - 1
                ? `${lang.code}, `
                : lang.code;
            }),
          category: question.category && question.category.title,
        };
      })
  );
  const [payload, setPayload] = useState({
    params: { onlyQuestions: true },
  });
  const [searchString, setSearchString] = useState('');
  const [questions, setQuestions] = useState(null);
  const endpoint = '/api/omega/questions';
  const method = 'get';

  useEffect(() => {
    setPayload({
      endpoint,
      method,
      params: {
        onlyQuestions: true,
        searchString: searchString,
      },
    });
  }, [searchString]);
  const { data, isLoading } = useFetch(payload);

  const searchEntered = (s) => {
    setSearchString(s);
  };

  useEffect(() => {
    if (!values.questionSections[sectionIndex]) {
      values.questionSections[sectionIndex] =
        template.questionSections[sectionIndex];
    }
  }, [sectionIndex, values, template]);

  useEffect(() => {
    values.selectedQuestions = values.selectedQuestions || [];
    values.selectedQuestions[sectionIndex] = selectedData.map((question) => {
      return question._id;
    });
  }, [selectedData, values, sectionIndex]);

  useEffect(() => {
    if (data && data.data) {
      const newQuestions = data.data
        .map((item) => {
          if (
            selectedData.findIndex((selected) => selected._id === item._id) !==
            -1
          ) {
            return false;
          }
          return {
            _id: item._id,
            title: item.title,
            languages:
              item.availableLanguages &&
              item.availableLanguages.map((lang, index) => {
                return index !== item.availableLanguages.length - 1
                  ? `${lang.code}, `
                  : lang.code;
              }),
            category: item.category && item.category.title,
          };
        })
        .filter(Boolean);
      setQuestions(newQuestions);
    }
  }, [data, selectedData]);

  const columnWidths = ['60%', '20%', '20%'];
  const customHeaders = ['Title', 'Languages', 'Category'];
  const preIdString = `questionSections[${sectionIndex}]`;
  return (
    <>
      {isLoading !== 'undefined' && values.questionSections[sectionIndex] && (
        <>
          <InputWrapper
            errorMsg={
              //TODO: fix error message for the new changes
              errors &&
              errors.questionSections &&
              errors.questionSections[sectionIndex] &&
              errors.questionSections[sectionIndex].title &&
              touched &&
              touched.questionSections &&
              touched.questionSections[sectionIndex] &&
              touched.questionSections[sectionIndex].title &&
              `${errors.questionSections[sectionIndex].title}`
            }
            title='Title of group'
          >
            {template.availableLanguages.map((language, key) => {
              if (!values.questionSections[sectionIndex].title[key]) {
                values.questionSections[sectionIndex].title.push({
                  language: language.code,
                  text: '',
                });
              }
              return (
                <Field
                  key={language.code}
                  as={InputField}
                  id={`${preIdString}.description`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={
                    (values.questionSections[sectionIndex] &&
                      values.questionSections[sectionIndex].title[key].text) ||
                    ''
                  }
                  error={
                    errors &&
                    errors.questionSections &&
                    errors.questionSections[sectionIndex] &&
                    errors.questionSections[sectionIndex].title[language.code]
                      .text &&
                    touched &&
                    touched.questionSections &&
                    touched.questionSections[sectionIndex] &&
                    touched.questionSections[sectionIndex].title[language.code]
                      .text
                  }
                  type='text'
                  name={`${preIdString}.title[${key}].text`}
                  placeholder={`Title of group (${language.code})`}
                />
              );
            })}
          </InputWrapper>
          <InputWrapper
            errorMsg={
              errors &&
              errors.questionSections &&
              errors.questionSections[sectionIndex] &&
              errors.questionSections[sectionIndex].description &&
              touched &&
              touched.questionSections &&
              touched.questionSections[sectionIndex] &&
              touched.questionSections[sectionIndex].description &&
              `${errors.questionSections[sectionIndex].description}`
            }
            title='Instructions'
          >
            <Field
              as={InputField}
              id={`${preIdString}.description`}
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                (values.questionSections[sectionIndex] &&
                  values.questionSections[sectionIndex].description) ||
                ''
              }
              error={
                errors &&
                errors.questionSections &&
                errors.questionSections[sectionIndex] &&
                errors.questionSections[sectionIndex].description &&
                touched &&
                touched.questionSections &&
                touched.questionSections[sectionIndex] &&
                touched.questionSections[sectionIndex].description
              }
              type='text'
              name={`${preIdString}.description`}
              placeholder='Instruction'
            />
          </InputWrapper>
          <SortingTable
            title='Add questions to group'
            showHeaders
            searchBar
            searchString={searchString}
            setSearchString={searchEntered}
            searchBarPlaceholder='Search questions'
            data={questions}
            setData={setQuestions}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            columnWidths={columnWidths}
            customHeaders={customHeaders}
            templateQuestions
          />
        </>
      )}
      {isLoading !== 'undefined' && !values.questionSections[sectionIndex] && (
        <InputWrapper />
      )}
    </>
  );
};
