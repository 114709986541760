import * as React from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';

export const PortalMenu = () => {
  return (
    <Container>
      <Button>
        <Line />
        <Padding />
        <Line />
        <Padding />
        <Line />
      </Button>
      Omega tests
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-bottom: ${(props) => props.theme.spacing.standardL};
  font-size: ${(props) => props.theme.size.breadcrumb};
  color: ${(props) => props.theme.color.white};
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
  }
`;

const Button = styled.div`
  margin-right: ${(props) => props.theme.spacing.medium};
  display: inline-block;
  border: none;
  cursor: pointer;
`;

const Line = styled.div`
  border: 0.1rem solid ${(props) => props.theme.color.white};
  width: 2rem;
`;

const Padding = styled.div`
  margin-bottom: 0.5rem;
`;
