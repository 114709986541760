import React, { useState } from 'react';
import { useFetch } from '../../../helpers/hooks/fetchData';
import styled, { css } from 'styled-components';
import { PersonalDetails } from './PersonalDetails';
import { PreviewEmail } from './PreviewEmail';
import { Results } from './Results';
import { Tests } from './Tests';
import { device } from '../../../styles/device';
import { Button } from '../../shared/button/Button';
import { Modal } from '../../shared/modal/Modal';
import history from '../../../history';
import { EditPreviewEmail } from './EditPreviewEmail';
import { OpenAi } from './OpenAi';
import { CopyTokenLinkModal } from '../reports/projects/CopyTokenLinkModal';

export const Tabs = ({ user, setUser }) => {
  const [currentId, setCurrentId] = useState(0);
  const [active, setActive] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showMailModal, setShowMailModal] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [candidateLogin, setCandidateLogin] = useState(false);

  const [emailSubject, setEmailSubject] = useState(
    user.emailContent && user.emailContent.subject
      ? user.emailContent.subject
      : user.defaultLanguage &&
        user.defaultLanguage.emailContent &&
        user.defaultLanguage.emailContent.subject
      ? user.defaultLanguage.emailContent.subject
      : 'This is an invitation email for tests'
  );
  const [emailTitle, setEmailTitle] = useState(
    user.emailContent && user.emailContent.title
      ? user.emailContent.title
      : user.defaultLanguage &&
        user.defaultLanguage.emailContent &&
        user.defaultLanguage.emailContent.title
      ? user.defaultLanguage.emailContent.title.replace(/{{NAME}}/g, user.name)
      : `Hello ${user.name}, we are considering you for a new position`
  );
  const [emailContent, setEmailContent] = useState(
    user.emailContent && user.emailContent.content
      ? user.emailContent.content
      : user.defaultLanguage &&
        user.defaultLanguage.emailContent &&
        user.defaultLanguage.emailContent.content &&
        user.defaultLanguage.emailContent.content.firstBlock !== ''
      ? user.defaultLanguage.emailContent.content
      : 'Globally promote fully tested collaboration and idea-sharing for inexpensive initiatives. Dynamically reinvent customized portals before resource maximizing action items. Progressively redefine process-centric experiences with 24/365 alignments. Professionally build out-of-the-box alignments through high-quality sources. Proactively implement diverse total linkage vis-a-vis enterprise-wide communities.'
  );
  const [linkDescription, setLinkDescription] = useState(
    user.emailContent && user.emailContent.activationLink
      ? user.emailContent.activationLink
      : user.defaultLanguage &&
        user.defaultLanguage.emailContent &&
        user.defaultLanguage.emailContent.activationLink
      ? user.defaultLanguage.emailContent.activationLink
      : 'Here is your activation link. Click it to access the test page.'
  );
  const [finalGreeting, setFinalGreeting] = useState(
    user.emailContent && user.emailContent.finalGreeting
      ? user.emailContent.finalGreeting
      : user.defaultLanguage &&
        user.defaultLanguage.emailContent &&
        user.defaultLanguage.emailContent.finalGreeting
      ? user.defaultLanguage.emailContent.finalGreeting
      : 'We are looking forward to hearing from you soon!'
  );
  const [payload, setPayload] = useState({});

  const { data } = useFetch(payload);

  if (data && candidateLogin) {
    history.push('/auth');
  }

  const loginAsCandidate = () => {
    setCandidateLogin(true);

    if (user) {
      return setPayload({
        endpoint: '/api/omega/users/login-as-candidate/' + user._id,
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
    }
    setShowModal(false);
  };

  // Checking if users email.content are "empty"
  const checkIfEmpty =
    user.emailContent &&
    user.emailContent.subject &&
    user.emailContent.title &&
    user.emailContent.content &&
    user.emailContent.activationLink &&
    user.emailContent.finalGreeting
      ? false
      : true;

  // Check if the email text is the same as default
  const checkIfEditedDefault =
    user.defaultLanguage.emailContent.subject === emailSubject &&
    user.defaultLanguage.emailContent.title.replace(/{{NAME}}/g, user.name) ===
      emailTitle &&
    user.defaultLanguage.emailContent.content === emailContent &&
    user.defaultLanguage.emailContent.activationLink === linkDescription &&
    user.defaultLanguage.emailContent.finalGreeting === finalGreeting;

  //Check if email text is the same as personal text
  const checkIfEditedPersonal =
    user.emailContent &&
    user.emailContent.subject === emailSubject &&
    user.emailContent.title === emailTitle &&
    user.emailContent.content === emailContent &&
    user.emailContent.activationLink === linkDescription &&
    user.emailContent.finalGreeting === finalGreeting;

  const sendTestMail = () => {
    let edited = false;
    // Checking if any string is eddited
    if (
      (!checkIfEditedDefault && checkIfEmpty) ||
      (!checkIfEditedPersonal && !checkIfEmpty)
    ) {
      edited = true;
    }

    if (user) {
      setPayload({
        endpoint: '/api/omega/users/send-invitation-email',
        method: 'post',
        data: JSON.stringify({
          email: user.email,
          omegaCandidateAccessToken: user.omegaCandidateAccessToken,
          emailSubject,
          emailTitle,
          emailContent,
          linkDescription,
          finalGreeting,
          endingLine1:
            user.defaultLanguage &&
            user.defaultLanguage.emailContent &&
            user.defaultLanguage.emailContent.optionalInfo1
              ? user.defaultLanguage.emailContent.optionalInfo1
              : 'Kind regards',

          endingLine2:
            user.defaultLanguage &&
            user.defaultLanguage.emailContent &&
            user.defaultLanguage.emailContent.optionalInfo2
              ? user.defaultLanguage.emailContent.optionalInfo2
              : 'Astrid Karlsson',

          endingLine3:
            user.defaultLanguage &&
            user.defaultLanguage.emailContent &&
            user.defaultLanguage.emailContent.optionalInfo3
              ? user.defaultLanguage.emailContent.optionalInfo3
              : 'Navigio',

          endingLine4:
            user.defaultLanguage &&
            user.defaultLanguage.emailContent &&
            user.defaultLanguage.emailContent.optionalInfo4
              ? user.defaultLanguage.emailContent.optionalInfo4
              : '0706789849',

          sender:
            user.defaultLanguage &&
            user.defaultLanguage.emailContent &&
            user.defaultLanguage.emailContent.sender
              ? user.defaultLanguage.emailContent.sender
              : 'omega@navigio.se',
          edited,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    setShowMailModal(false);
  };

  const setStates = (index) => {
    setCurrentId(index);
    setActive(index ? index : 0);
  };

  const tabsArray = ['Tests', 'Contact', 'Results', 'OpenAi', 'Personal'];

  const renderTabs = () => {
    return tabsArray.map((tab, index) => (
      <Tab
        active={active === index}
        key={index}
        onClick={() => setStates(index)}
      >
        <TabText>{tab}</TabText>
      </Tab>
    ));
  };

  const renderContent = () => {
    switch (currentId) {
      case 0:
        return <Tests user={user} setUser={setUser}></Tests>;
      case 1:
        return <PreviewEmail user={user} />;
      case 2:
        return <Results user={user} />;
      case 3:
        return <OpenAi user={user} setUser={setUser} />;
      case 4:
        return <PersonalDetails user={user} setUser={setUser} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <ButtonContainer hideInMobile hide={currentId !== 0}>
        {user.cv && (
          <AtagInner
            href={`${process.env.REACT_APP_API_BASE_URL}/files/${user.cv.fileId}`}
            target='_blank'
          >
            <Button text='Open CV' primary />
          </AtagInner>
        )}
        {user && user.omegaCandidateAccessToken && (
          <ButtonInner>
            <Button
              onClick={() => {
                setShowTokenModal(true);
              }}
              text='User access link'
              primary
            />
          </ButtonInner>
        )}
        <ButtonInner>
          <Button
            onClick={() => setShowMailModal(true)}
            text='Send invitation'
            primary
          />
        </ButtonInner>
        <ButtonInner>
          <Button
            onClick={() => setShowModal(true)}
            text='Log in as candidate'
            primary
          />
        </ButtonInner>
      </ButtonContainer>
      <InnerContainer>
        <TabsContainer>{renderTabs()}</TabsContainer>
      </InnerContainer>
      <ContentContainer>
        {currentId === 0 && (
          <ButtonContainer showInMobile>
            <Button onClick={sendTestMail} text='Send invitation' primary />
            <Button
              onClick={loginAsCandidate}
              text='Log in as candidate'
              primary
            />
          </ButtonContainer>
        )}
        {renderContent()}
      </ContentContainer>

      <CopyTokenLinkModal
        users={[user]}
        showModal={showTokenModal}
        handleExit={() => setShowTokenModal(false)}
        singleUser
      />

      <Modal
        handleExit={() => setShowMailModal(false)}
        title='Confirm send invitation'
        show={showMailModal}
        width='60rem'
      >
        <EditPreviewEmail
          language={user.defaultLanguage && user.defaultLanguage.language}
          emailTitle={emailTitle}
          setEmailTitle={setEmailTitle}
          emailContent={emailContent}
          setEmailContent={setEmailContent}
          linkDescription={linkDescription}
          setLinkDescription={setLinkDescription}
          finalGreeting={finalGreeting}
          setFinalGreeting={setFinalGreeting}
          emailSubject={emailSubject}
          setEmailSubject={setEmailSubject}
        />
        <PaddingBetweenButton />
        <Button
          onClick={() => sendTestMail()}
          text='Yes, send invitation'
          primary
        />
        <PaddingBetweenButton />
        <Button
          onClick={() => setShowMailModal(false)}
          secondary
          text='Cancel'
        />
      </Modal>
      <Modal
        handleExit={() => setShowModal(false)}
        title='Confirm log in'
        show={showModal}
        width='40rem'
      >
        <Button
          onClick={() => loginAsCandidate()}
          text='Yes, log in as candidate'
          primary
        />
        <PaddingBetweenButton />
        <Button onClick={() => setShowModal(false)} secondary text='Cancel' />
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InnerContainer = styled.div`
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};

  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;

const TabsContainer = styled.ul`
  display: flex;
  flex-direction: row;
  font-size: 4rem;
  overflow: auto;

  @media ${device.mobileL} {
    justify-content: space-between;
  }
`;

const TabText = styled.p`
  text-align: center;
`;

const Tab = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
  height: 4.5rem;
  min-width: 15rem;
  border-radius: 0.9rem 0.9rem 0rem 0rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-size: 1.6rem;
  color: ${(props) => props.theme.color.textGrey};
  padding: ${(props) => props.theme.spacing.standard};
  padding-right: ${(props) => props.theme.spacing.large};

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.color.white};
      color: ${(props) => props.theme.color.black};
      font-weight: bold;
    `}

  @media ${device.tablet} {
    min-width: 12rem;
  }

  @media ${device.mobileL} {
    clip-path: none;
    height: 4rem;
    min-width: 8rem;
    font-size: 1.4rem;
    padding-top: ${(props) => props.theme.spacing.extraSmall};
    padding-bottom: ${(props) => props.theme.spacing.extraSmall};
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  background: ${(props) => props.theme.color.white};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 0.5rem;
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  padding-bottom: ${(props) => props.theme.spacing.small};
  padding-top: ${(props) => props.theme.spacing.small};

  ${(props) =>
    props.showInMobile &&
    css`
      display: none;
    `}

  ${(props) =>
    props.hide &&
    css`
      visibility: hidden;
    `}
    
    @media ${device.mobileL} {
        padding-left: ${(props) => props.theme.spacing.small};
        padding-right: ${(props) => props.theme.spacing.small};
            
    ${(props) =>
      props.hideInMobile &&
      css`
        display: none;
      `}
        ${(props) =>
          props.showInMobile &&
          css`
            display: flex;
          `}
    }
`;

const ButtonInner = styled.div`
  display: flex;
  width: 19.5rem;

  @media ${device.mobileL} {
    width: 100%;
  }
`;

const AtagInner = styled.a`
  display: flex;
  width: 19.5rem;
  text-decoration: none;

  @media ${device.mobileL} {
    width: 100%;
  }
`;

const PaddingBetweenButton = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;
