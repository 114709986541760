import React from 'react';
import styled from 'styled-components';
import {
  capitalizeFirstLetter,
  getLanguageText,
} from '../../../../helpers/common';

export const PersonalityScoresText = ({ data, language }) => {
  const title =
    (data.title && capitalizeFirstLetter(data.title)) ||
    'Most prominent character traits';
  return (
    <Container key={title}>
      <Title>{title}</Title>
      {data.data.map((row, index) => {
        const description = getLanguageText(row.descriptions, language);
        return (
          <Row key={index}>
            {`${index + 1}  `}
            {description &&
              (row.value === 'high'
                ? capitalizeFirstLetter(description.highValue.longDescription)
                : capitalizeFirstLetter(description.lowValue.longDescription))}
          </Row>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 95%;
  padding-bottom: 2rem;
  color: #00284a;
  margin: 1rem;
`;

const Row = styled.div`
  width: 100%;
  font-size: 2rem;
  text-align: start;
  padding-top: 1rem;
  padding-left: 8rem;
`;

const Title = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 3rem;
  border-bottom: solid 3px #00284a;
  border-top: solid 1px #00284a;
  padding: 0.5rem 1rem;
`;
