import React, { useState, useEffect } from 'react';
import {
  RadioButtonOption,
  TextOption,
  ImageOption,
  CheckboxOptions,
  DropdownOptions,
} from './options';
import { QuestionTitle, ImageQuestion } from './questions';
import styled, { css } from 'styled-components';
import { VideoInstruction } from './questions/VideoInstruction';

export const Question = ({
  question,
  category,
  language,
  submitAnswer,
  currentAnswer,
  correctExercises,
  exerciseClick,
  allowedAnswers,
  exersiceIndex,
  setHasVideo,
}) => {
  const [addQuestions, setAddQuestions] = useState([]);
  const [addAnswers, setAddAnswers] = useState(
    (currentAnswer &&
      currentAnswer.addAnswers &&
      currentAnswer.addAnswers.map((answer) => {
        return answer.map((element) => {
          if (typeof element.answerValue === 'string') {
            return element.answerValue;
          } else if (element.answerValue.type === 'radio') {
            return {
              number: element.answerValue.number,
              text: element.answerValue.text,
              _id: element.answerValue._id,
              type: element.answerValue.type,
            };
          } else if (element.answerValue.type === 'dropdown') {
            return {
              text: element.answerValue.text,
              _id: element.answerValue._id,
              type: element.answerValue.type,
            };
          }
          return '';
        });
      })) || [[]]
  );

  const langCode = !question.type.add
    ? null
    : question.type.add && question.addQuestion[language.code]
    ? language.code
    : question.addQuestion['EN']
    ? 'EN'
    : Object.keys(question.addQuestion)[0];

  const [answerCheckboxes, setAnswerCheckboxes] = useState(
    (currentAnswer &&
      currentAnswer.optionType === 'checkbox' &&
      currentAnswer.answers &&
      currentAnswer.answers.length &&
      currentAnswer.answers.map((answer) => {
        return {
          _id: answer.optionId,
          text: answer.answerValue,
        };
      })) ||
      []
  );
  const [radioAnswer, setRadioAnswer] = useState(
    (currentAnswer &&
      currentAnswer.optionType === 'radio' &&
      currentAnswer.answers &&
      currentAnswer.answers[0] && {
        _id: currentAnswer.answers[0].optionId,
        text: currentAnswer.answers[0].answerValue,
      }) ||
      (currentAnswer &&
        currentAnswer.optionType === 'likert' &&
        currentAnswer.answers &&
        currentAnswer.answers[0].answerValue) ||
      null
  );
  const [fimAnswer, setFimAnswer] = useState(
    (currentAnswer &&
      currentAnswer.optionType === 'fim' &&
      currentAnswer.answers &&
      currentAnswer.fimAnswers[0] &&
      currentAnswer.fimAnswers[0].answerValue &&
      currentAnswer.fimAnswers[0].answerValue) ||
      null
  );
  const [dropdownAnswer, setDropdownAnswer] = useState(
    (currentAnswer &&
      currentAnswer.optionType === 'dropdown' &&
      currentAnswer.answers &&
      currentAnswer.answers[0] && {
        _id: currentAnswer.answers[0].optionId,
        text: currentAnswer.answers[0].answerValue,
      }) ||
      null
  );

  const [imageAnswer, setImageAnswer] = useState(
    (currentAnswer &&
      currentAnswer.optionType === 'image' &&
      currentAnswer.answers &&
      currentAnswer.answers[0] && {
        _id: currentAnswer.answers[0].optionId,
        text: currentAnswer.answers[0].answerValue,
      }) ||
      null
  );
  const [omegaAnswers, setOmegaAnswers] = useState(
    (currentAnswer &&
      currentAnswer.optionType === 'omega' &&
      currentAnswer.omegaAnswer) || {
      1: null,
      2: null,
      3: null,
    }
  );
  const [textAnswer, setTextAnswer] = useState(
    (currentAnswer &&
      currentAnswer.optionType === 'text' &&
      currentAnswer.answers[0].answerValue) ||
      ''
  );
  const [optionType, setOptionType] = useState(
    question.type.add ? 'addQuestion' : null
  );

  const getVideo = () => {
    if (question.isInstruction && question.instructionVideos) {
      return (
        question.instructionVideos[language.code] ||
        question.instructionVideos['EN'] ||
        null
      );
    }
    return null;
  };

  const RenderQuestion = () => {
    const video = getVideo();

    if (video) {
      setHasVideo(true);
      return <VideoInstruction video={video} />;
    } else if (question.type.image) {
      return (
        <ImageQuestion
          image={question.questionImage && question.questionImage.large}
        />
      );
    } else if (question.type.optionsOnly) {
      return null;
    } else if (question.type.numbers) {
      return <QuestionTitle number={true} title={question.numbersQuestion} />;
    } else if (question.type.fim) {
      //TODO: Title for fim question
      return <QuestionTitle title='FIM QUESTIONs' fim={question.fimQuestion} />;
    } else if (question.question[language.code]) {
      return <QuestionTitle title={question.question[language.code].text} />;
    } else {
      return (
        <QuestionTitle
          title={
            (question.question['EN'] && question.question['EN'].text) ||
            question.question[Object.keys(question.question)[0]].text
          }
        />
      );
    }
  };

  const checkCheckboxAnwser = (answers, value) => {
    const ix = answers.findIndex((item) => item._id === value._id);
    if (ix === -1) {
      return [...answers, value];
    } else {
      const newAnswerCheckboxes = [...answers];
      newAnswerCheckboxes.splice(ix, 1);
      return newAnswerCheckboxes;
    }
  };

  const setAnswer = ({
    type,
    value,
    isCorrect,
    omegaNumber,
    text,
    index,
    answerIx,
    checked,
    keyIndex,
  }) => {
    if (question.type.add) {
      if (type === 'checkbox') {
        addAnswers[answerIx][index] = checkCheckboxAnwser(
          addAnswers[answerIx][index],
          value
        );
        setAddAnswers([...addAnswers]);
      } else {
        addAnswers[answerIx][index] = value;

        if (!addAnswers[answerIx].includes('') || type !== 'text') {
          setAddAnswers([...addAnswers, []]);
        }
      }

      return;
    }

    setOptionType(type);
    if (type === 'checkbox') {
      setAnswerCheckboxes(checkCheckboxAnwser(answerCheckboxes, value));
    } else if (type === 'omega') {
      const newOmegaAnswers = {};
      newOmegaAnswers[omegaNumber] = value;
      setOmegaAnswers({ ...omegaAnswers, ...newOmegaAnswers });
    } else if (type === 'radio') {
      setRadioAnswer({ _id: value, text });
    } else if (type === 'likert') {
      setRadioAnswer(value);
    } else if (type === 'fim') {
      setFimAnswer({ ...fimAnswer, [answerIx]: value });
    } else if (type === 'image') {
      setImageAnswer(value);
    } else if (type === 'text') {
      setTextAnswer(value);
    } else if (type === 'dropdown') {
      setDropdownAnswer(value);
    }
    if (exerciseClick) {
      exerciseClick(
        { type, value, isCorrect, question },
        checked,
        type,
        index,
        keyIndex
      );
    }
  };

  useEffect(() => {
    if (addAnswers && Array.isArray(addAnswers[0]) && addAnswers[0].length) {
      submitAnswer({
        questionId: question._id,
        optionType,
        answer: addAnswers,
      });
    }
  }, [addAnswers, question._id, submitAnswer, optionType]);

  useEffect(() => {
    if (
      answerCheckboxes &&
      Array.isArray(answerCheckboxes) &&
      answerCheckboxes.length
    ) {
      submitAnswer({
        questionId: question._id,
        optionType,
        answer: answerCheckboxes,
      });
    }
  }, [answerCheckboxes, question._id, submitAnswer, optionType]);

  useEffect(() => {
    if (
      omegaAnswers &&
      (omegaAnswers[1] || omegaAnswers[2] || omegaAnswers[3])
    ) {
      submitAnswer({
        questionId: question._id,
        optionType,
        answer: omegaAnswers,
      });
    }
  }, [omegaAnswers, question._id, submitAnswer, optionType]);

  useEffect(() => {
    if (radioAnswer) {
      submitAnswer({
        questionId: question._id,
        optionType,
        answer: radioAnswer,
      });
    }
  }, [radioAnswer, question._id, submitAnswer, optionType]);

  useEffect(() => {
    if (fimAnswer) {
      submitAnswer({
        questionId: question._id,
        optionType,
        answer: fimAnswer,
      });
    }
  }, [fimAnswer, question._id, submitAnswer, optionType]);

  useEffect(() => {
    if (imageAnswer) {
      submitAnswer({
        questionId: question._id,
        optionType,
        answer: imageAnswer,
      });
    }
  }, [imageAnswer, question._id, submitAnswer, optionType]);

  useEffect(() => {
    if (textAnswer) {
      submitAnswer({
        questionId: question._id,
        optionType,
        answer: textAnswer,
      });
    }
  }, [textAnswer, question._id, submitAnswer, optionType]);

  useEffect(() => {
    if (dropdownAnswer) {
      submitAnswer({
        questionId: question._id,
        optionType,
        answer: dropdownAnswer,
      });
    }
  }, [dropdownAnswer, question._id, submitAnswer, optionType]);

  const translatedFimOptions = (lang, options) => {
    let translate = (left, none, right) => {
      options[0].text = left;
      options[1].text = none;
      options[2].text = right;
      options[0].EN = 'left';
      options[1].EN = 'none';
      options[2].EN = 'right';
    };

    switch (lang.code) {
      case 'SE':
        translate('Vänster', 'Ingen', 'Höger');
        break;

      case 'EN':
        translate('Left', 'None', 'Right');
        break;

      case 'NO':
        translate('Venstre', 'Ingen', 'Høyre');
        break;

      case 'DE':
        translate('Links', 'Keiner', 'Rechts');
        break;

      case 'ES':
        translate('Izquierda', 'Ninguno', 'Derecha');
        break;
      default:
        translate('Vänster', 'Ingen', 'Höger');
    }
  };

  const RenderOptions = ({
    hasImageQuestion,
    addQuestion,
    index,
    answerIx,
  }) => {
    const optionsArray = addQuestion
      ? question.options[langCode][index].addOptions
      : question.options[language.code] ||
        question.options.Universal ||
        question.options['EN'] ||
        question.options[Object.keys(question.options)[0]];

    return optionsArray.map((option, key) => {
      if (addQuestion && !addAnswers[answerIx]) {
        addAnswers.push([]);
        addAnswers[answerIx].push('');
      } else if (addQuestion && addAnswers[answerIx].length < index + 1) {
        addAnswers[answerIx].push('');
      }

      const fimQuestions = [];

      switch (option.type) {
        case 'text':
          return (
            <TextOption
              key={key}
              id={'input-' + index}
              option={option}
              onChange={setAnswer}
              textAnswer={
                addQuestion ? addAnswers[answerIx][index] : textAnswer
              }
              index={index}
              answerIx={answerIx}
            />
          );
        case 'image':
          if (optionsArray.findIndex((item) => item.type === 'image') !== key) {
            return false;
          }
          return (
            <ImageOption
              key={key}
              options={optionsArray}
              onClick={setAnswer}
              selected={imageAnswer}
              type='image'
              isExercise={question.isExercise}
              isInstruction={question.isInstruction}
              correctExercises={correctExercises}
            />
          );
        case 'radio':
          if (optionsArray.findIndex((item) => item.type === 'radio') !== key) {
            return false;
          }

          return (
            <RadioButtonOption
              hasImageQuestion={hasImageQuestion}
              key={key}
              options={optionsArray}
              setAnswer={setAnswer}
              selected={
                addQuestion
                  ? addAnswers[answerIx][index] &&
                    addAnswers[answerIx][index]._id
                  : radioAnswer && radioAnswer._id
              }
              type={option.type}
              radioAnswer={
                addQuestion ? addAnswers[answerIx][index] : radioAnswer
              }
              isExercise={question.isExercise}
              isInstruction={question.isInstruction}
              correctExercises={correctExercises}
              addQuestion={addQuestion ? true : false}
              index={
                exersiceIndex === 0 || exersiceIndex ? exersiceIndex : index
              }
              answerIx={answerIx}
            />
          );
        case 'fim':
          if (getVideo()) return null;

          for (const key in option.fimRowOptions) {
            translatedFimOptions(language, option.fimRowOptions[key]);
            const keyIndex = key === 'A' ? 0 : key === 'B' ? 1 : 2;
            fimQuestions.push(
              <React.Fragment key={key}>
                <p>{key}</p>
                <RadioButtonOption
                  hasImageQuestion={hasImageQuestion}
                  key={key}
                  options={option.fimRowOptions[key]}
                  setAnswer={setAnswer}
                  selected={fimAnswer && fimAnswer[key]}
                  type={'fim'}
                  radioAnswer={
                    addQuestion ? addAnswers[answerIx][index] : radioAnswer
                  }
                  isExercise={question.isExercise}
                  isInstruction={question.isInstruction}
                  correctExercises={correctExercises}
                  addQuestion={addQuestion ? true : false}
                  index={
                    exersiceIndex === 0 || exersiceIndex ? exersiceIndex : index
                  }
                  keyIndex={keyIndex}
                  answerIx={key}
                  fimQuestion
                />
              </React.Fragment>
            );
          }
          return fimQuestions;

        case 'checkbox':
          if (
            optionsArray.findIndex((item) => item.type === 'checkbox') !== key
          ) {
            return false;
          }

          if (addQuestion && !Array.isArray(addAnswers[answerIx][index])) {
            addAnswers[answerIx][index] = [];
          }

          return (
            <CheckboxOptions
              key={key}
              category={category}
              allowedAnswers={allowedAnswers}
              options={optionsArray}
              setAnswer={setAnswer}
              answerCheckboxes={
                addQuestion ? addAnswers[answerIx][index] : answerCheckboxes
              }
              isCorrect
              isExercise={question.isExercise}
              isInstruction={question.isInstruction}
              correctExercises={correctExercises}
              index={
                exersiceIndex === 0 || exersiceIndex ? exersiceIndex : index
              }
              answerIx={answerIx}
            />
          );
        case 'dropdown': {
          if (
            optionsArray.findIndex((item) => item.type === 'dropdown') !== key
          ) {
            return false;
          }
          return (
            <DropdownOptions
              key={key}
              options={optionsArray}
              placeholder={
                addQuestion
                  ? question.addQuestion[langCode][index]
                  : question.question[language.code].text
              }
              setAnswer={setAnswer}
              dropdownAnswer={
                addQuestion ? addAnswers[answerIx][index] : dropdownAnswer
              }
              index={index}
              answerIx={answerIx}
              selected={addQuestion ? addAnswers[answerIx][index] : null}
            />
          );
        }

        default:
          return <React.Fragment key={key}></React.Fragment>;
      }
    });
  };

  const RenderOmega = () => {
    const options = [
      { text: 1, _id: 1, type: 'omega' },
      { text: 2, _id: 2, type: 'omega' },
      { text: 3, _id: 3, type: 'omega' },
      { text: 4, _id: 4, type: 'omega' },
      { text: 5, _id: 5, type: 'omega' },
      { text: 6, _id: 6, type: 'omega' },
    ];
    return ['text1', 'text2', 'text3'].map((prop, key) => {
      const disabled = Object.keys(omegaAnswers)
        .map((ix) => {
          if (parseInt(ix) === key + 1) return null;
          return omegaAnswers[ix] || null;
        })
        .filter(Boolean);
      return (
        <React.Fragment key={key}>
          <QuestionTitle
            title={
              question.omegaQuestions && question.omegaQuestions[language.code]
                ? question.omegaQuestions[language.code][prop]
                : question.omegaQuestions['EN']
                ? question.omegaQuestions['EN'][prop]
                : question.omegaQuestions[
                    Object.keys(question.omegaQuestions)[0]
                  ][prop]
            }
          />
          <RadioButtonOption
            options={options}
            disabled={disabled}
            type='omega'
            omegaNumber={key + 1}
            setAnswer={setAnswer}
            selected={omegaAnswers[key + 1]}
          />
          <OmegaSpacing />
        </React.Fragment>
      );
    });
  };

  const RenderSimilarityScale = () => {
    //If the amount of buttons change just change length
    const options = Array.from({ length: 7 }, (_, index) => ({
      _id: index + 1,
      text: index + 1,
      type: 'likert',
    }));

    return (
      <React.Fragment>
        <QuestionTitle
          title={
            question.question && question.question[language.code]
              ? question.question[language.code].text
              : question.question['EN']
              ? question.question['EN'].text
              : question.question[Object.keys(question.question)[0]].text
          }
        />
        <RadioButtonOption
          options={options}
          type='likert'
          setAnswer={setAnswer}
          selected={radioAnswer}
        />
        <OmegaSpacing />
      </React.Fragment>
    );
  };

  const handleAddmoreQuestions = () => {
    setAddAnswers([...addAnswers, []]);
    const quest = (
      <React.Fragment key={addQuestions.length}>
        {question.addQuestion[langCode].questions.map((quest, index) => {
          return (
            <React.Fragment key={index}>
              {quest === question.addQuestion[langCode].questions &&
              addAnswers.length !== 1 ? (
                <OmegaSpacing />
              ) : (
                <></>
              )}
              <QuestionTitle title={quest} />
              <RenderOptions
                addQuestion={quest}
                index={index}
                answerIx={addQuestions.length}
              />
            </React.Fragment>
          );
        })}
        <br />
      </React.Fragment>
    );
    setAddQuestions([...addQuestions, quest]);
  };

  const handleRemoveQuestion = () => {
    const questions = addQuestions.filter(
      (_, i) => i !== addQuestions.length - 1
    );
    const answers = addAnswers.filter((_, i) => i !== addQuestions.length - 1);

    setAddQuestions(questions);
    setAddAnswers(answers);
  };

  const RenderAddQuestion = () => {
    if (addQuestions.length === 0) {
      handleAddmoreQuestions();
    }

    return (
      <React.Fragment>
        <QuestionTitle title={question.addQuestion[langCode].title} />

        {addQuestions}
        <button onClick={handleAddmoreQuestions}>+</button>
        <button onClick={handleRemoveQuestion}>-</button>
      </React.Fragment>
    );
  };

  if (question.type.add && addQuestions.length === 0 && addAnswers.length > 1) {
    const array = [];
    addAnswers.forEach(() => {
      array.push(
        <React.Fragment key={array.length}>
          {question.addQuestion[langCode].questions.map((quest, index) => {
            return (
              <React.Fragment key={index}>
                {quest === question.addQuestion[langCode][0] &&
                addAnswers.length !== 1 ? (
                  <OmegaSpacing />
                ) : (
                  <></>
                )}
                <QuestionTitle title={quest} />
                <RenderOptions
                  addQuestion={quest}
                  index={index}
                  answerIx={array.length}
                />
              </React.Fragment>
            );
          })}
          <br />
        </React.Fragment>
      );
    });
    setAddQuestions(array);
  }

  return (
    <Container isImageQuestion={question.type.image}>
      {question.type.omega ? (
        <RenderOmega />
      ) : question.type.add ? (
        <RenderAddQuestion />
      ) : question.type.likert ? (
        <RenderSimilarityScale />
      ) : (
        <>
          <RenderQuestion />
          <RenderOptions hasImageQuestion={question.type.image} />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 6rem;
  margin-bottom: 6rem;
  font-size: 2rem;
  ${(props) =>
    props.isImageQuestion &&
    css`
      margin-top: 9rem;
      margin-bottom: 9rem;
    `}
`;

const OmegaSpacing = styled.div`
  padding-top: 7rem;
`;
