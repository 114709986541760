import React, { useEffect, useState, useContext } from 'react';
import { CandidatePageButton } from '../../../components/shared/button/CandidatePageButton';
import { CandidateTestProgressBar } from '../../../components/shared/progress-bar/CandidateTestProgressBar';
import { AudioFile } from '../../../components/candidate/question/sound/SoundFile.js';
import { Question } from '../../../components/candidate/question/Question';
import styled, { css } from 'styled-components';
import { device } from '../../../styles/device';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { ContentContext } from '../../../helpers/contentContext';
import { getIndexFromPath } from '../../../helpers/common';

export const Instructions = (location) => {
  const index = getIndexFromPath(location.location.pathname);
  const content = useContext(ContentContext) || {};
  const endpoint = '/api/omega/candidate-test/get-instructions';
  const method = 'get';
  const [payload, setPayload] = useState({});
  const [correctExercises, setCorrectExercises] = useState([]);
  const [allCorrect, setAllCorrect] = useState(null);
  const [allowedAnswers, setAllowedAnswers] = useState(null);
  const [showError, setShowError] = useState(false);
  const [allExercisesAnswered, setAllExercisesAnswered] = useState(false);
  const [amountClicked, setAmountClicked] = useState(0);
  const [playSound, setPlaySound] = useState([false, false]);
  const [exercisePage, setExercisePage] = useState(0);
  const [hasVideo, setHasVideo] = useState(false);

  const { data } = useFetch(payload);
  const page = 0;
  const submitAndNavigate = () => {
    window.location.assign(`/omega/candidate/test/active-test/${index}`);
  };

  useEffect(() => {
    if (data && data.exerciseQuestions) {
      setAllowedAnswers(data.allowedAnswers);
      const isRadio = typeof correctExercises[0] === 'string';

      const fimQuestion =
        data.exerciseQuestions[0] &&
        data.exerciseQuestions[0].options[
          Object.keys(data.exerciseQuestions[0].options)[0]
        ][0].type === 'fim';

      let newCorrectExercises = [];
      let fimLength = 0;

      if (fimQuestion) {
        correctExercises.forEach((question) => {
          if (question)
            newCorrectExercises = [...newCorrectExercises, ...question];
        });

        newCorrectExercises.forEach((answer) => {
          if (typeof answer !== 'undefined') {
            fimLength++;
          }
        });
      }

      let correct = true;
      for (const anwser of fimQuestion
        ? newCorrectExercises
        : correctExercises) {
        if (!anwser) {
          correct = false;
          break;
        }
        correct = true;
      }

      const correctAnswers = fimQuestion
        ? data.exerciseQuestions.length * 3 === fimLength
        : data.exerciseQuestions
            .map((question) => {
              return question.options[Object.keys(question.options)[0]]
                .map((option) => {
                  return option.isCorrect;
                })
                .filter(Boolean).length;
            })
            .reduce((a, b) => a + b, 0) === correctExercises.length;

      setAllCorrect(correctAnswers && correct);

      if (!allExercisesAnswered) {
        const newAllowedAnswers = allowedAnswers ? allowedAnswers : 1;

        const answersLength = data.exerciseQuestions.length * newAllowedAnswers;

        setAllExercisesAnswered(
          isRadio
            ? correctExercises.length === answersLength
            : fimQuestion
            ? data.exerciseQuestions.length * 3 === fimLength
            : amountClicked === answersLength
        );
      }

      setShowError(!(correctAnswers && correct) && allExercisesAnswered);
    }
  }, [
    correctExercises,
    data,
    allExercisesAnswered,
    amountClicked,
    allowedAnswers,
  ]);

  const exerciseClick = (
    { value, isCorrect },
    checked,
    type,
    index,
    answerIndex
  ) => {
    setAmountClicked(
      type === 'radio' || type === 'fim'
        ? amountClicked
        : checked
        ? amountClicked - 1
        : amountClicked + 1
    );

    setPlaySound([true, isCorrect]);

    if (isCorrect) {
      if (checked) {
        const newCorrectExercises = correctExercises.filter((v) => v !== value);

        setCorrectExercises(newCorrectExercises);
      } else if (type === 'radio') {
        correctExercises[index] = value;
        setCorrectExercises([...correctExercises]);
      } else if (type === 'fim') {
        if (correctExercises[index] && correctExercises[index].length) {
          correctExercises[index][answerIndex] = value;
        } else {
          correctExercises[index] = [];
          correctExercises[index][answerIndex] = value;
        }

        setCorrectExercises([...correctExercises]);
      } else {
        setCorrectExercises([...correctExercises, value]);
      }
    } else if (type === 'radio') {
      correctExercises[index] = '';
      setCorrectExercises([...correctExercises]);
    } else if (type === 'fim') {
      if (correctExercises[index] && correctExercises[index].length) {
        correctExercises[index][answerIndex] = '';
      } else {
        correctExercises[index] = [];
        correctExercises[index][answerIndex] = '';
      }
      setCorrectExercises([...correctExercises]);
    } else {
      setCorrectExercises([...correctExercises]);
    }
  };

  const instructionSound = () => {
    const src =
      data.instruction &&
      data.instruction[data.language.code] &&
      data.instruction[data.language.code].sound.src
        ? data.instruction[data.language.code].sound.src
        : data.instruction &&
          data.instruction['EN'] &&
          data.instruction['EN'].sound.src
        ? data.instruction['EN'].sound.src
        : '';

    return src && !hasVideo ? (
      <AudioButtonContainer>
        <AudioFile
          audioUrl={src}
          playText={content.playInstructionSound}
          pauseText={content.pauseInstructionSound}
        />
      </AudioButtonContainer>
    ) : (
      ''
    );
  };

  const exerciseSound = (isCorrect, pause) => {
    const correctOrIncorrect = isCorrect
      ? 'exerciseSoundCorrect'
      : 'exerciseSoundIncorrect';

    const src =
      data.exerciseQuestions &&
      data.exerciseQuestions[0] &&
      data.exerciseQuestions[0][correctOrIncorrect] &&
      data.exerciseQuestions[0][correctOrIncorrect][data.language.code] &&
      data.exerciseQuestions[0][correctOrIncorrect][data.language.code].src
        ? data.exerciseQuestions[0][correctOrIncorrect][data.language.code].src
        : data.exerciseQuestions &&
          data.exerciseQuestions[0] &&
          data.exerciseQuestions[0][correctOrIncorrect] &&
          data.exerciseQuestions[0][correctOrIncorrect]['EN'] &&
          data.exerciseQuestions[0][correctOrIncorrect]['EN'].src
        ? data.exerciseQuestions[0][correctOrIncorrect]['EN'].src
        : '';

    return src ? <AudioFile audioUrl={src} play={true} pause={pause} /> : null;
  };

  const showData = (isExerice) => {
    return (
      (data.exercisePage && isExerice ? exercisePage : !exercisePage) ||
      !data.exercisePage
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [exercisePage]);

  useEffect(() => {
    setPayload({ endpoint, method, params: { index } });
  }, [endpoint, method, index]);

  return (
    <>
      {data && (
        <>
          <CandidateTestProgressBar
            progressPage={0}
            totalNumberOfPages={0}
            pages={[]}
            timeLimit={data.hasTimeLimit && data.timeLimit}
            timeString={'00:40'}
            notStarted
            page={0}
            testName={data.titles[content.langCode] || data.title}
          />

          <TestContainer>
            {instructionSound()}

            <ContentContainer>
              {showData() && (
                <>
                  <Header>{content.instructionHeader}</Header>
                  <Text>
                    {data.instruction && data.instruction[data.language.code]
                      ? data.instruction[data.language.code].text
                      : data.instruction && data.instruction['EN']
                      ? data.instruction['EN'].text
                      : data.instruction &&
                        data.instruction[Object.keys(data.instruction)[0]].text}
                  </Text>

                  {data.instructionQuestions &&
                    data.instructionQuestions.length > 0 && (
                      <Header>{content.instructionQuestions}</Header>
                    )}
                </>
              )}

              {showData() &&
                data.instructionQuestions &&
                data.instructionQuestions.map((question, key) => {
                  return (
                    <Question
                      key={key}
                      category={data.category}
                      language={data.language}
                      question={question}
                      setHasVideo={setHasVideo}
                      submitAnswer={() => {}}
                    />
                  );
                })}
              {showData(true) &&
                data.exerciseQuestions &&
                data.exerciseQuestions.length > 0 && (
                  <ExerciseQuestionContainer
                    newPage={data.exercisePage && exercisePage}
                  >
                    <Header>{content.exerciseQuestions}</Header>
                    {data.exerciseQuestions.map((question, key) => {
                      return (
                        <React.Fragment key={key}>
                          {question.exerciseText && (
                            <Text background>
                              {question.exerciseText &&
                              //comparing to users prioritized language, default language: EN
                              question.exerciseText[data.language.code]
                                ? question.exerciseText[data.language.code].text
                                : question.exerciseText['EN']
                                ? question.exerciseText['EN'].text
                                : question.exerciseText[
                                    Object.keys(question.exerciseText)[0]
                                  ].text}
                            </Text>
                          )}
                          <Question
                            key={key}
                            category={data.category}
                            language={data.language}
                            question={question}
                            exerciseClick={exerciseClick}
                            submitAnswer={() => {}}
                            correctExercises={correctExercises}
                            allowedAnswers={allowedAnswers}
                            exersiceIndex={key}
                          />
                        </React.Fragment>
                      );
                    })}
                  </ExerciseQuestionContainer>
                )}
              {playSound[0] &&
                exerciseSound(true, playSound[2] || !playSound[1])}
              {playSound[0] &&
                exerciseSound(false, playSound[2] || playSound[1])}

              {showData() && (
                <Text background>
                  {data.instruction && data.instruction[data.language.code]
                    ? data.instruction[data.language.code].endText
                    : data.instruction && data.instruction['EN']
                    ? data.instruction['EN'].endText
                    : data.instruction &&
                      data.instruction[Object.keys(data.instruction)[0]]
                        .endText}
                </Text>
              )}
              {showError && (
                <Text background>
                  {content.exerciseError ||
                    'Please complete the example questions to continue the test.'}
                </Text>
              )}

              <ButtonContainer>
                {exercisePage ? (
                  <ButtonWidth left>
                    <CandidatePageButton
                      text={content.instructionHeader || 'Instructions'}
                      onClick={() => {
                        setAllExercisesAnswered(false);
                        setCorrectExercises([]);
                        setAmountClicked(0);
                        setPlaySound([true, false, true]);
                        setExercisePage(0);
                      }}
                      secendary
                    />
                  </ButtonWidth>
                ) : null}
                <ButtonWidth>
                  <CandidatePageButton
                    text={
                      (data.exercisePage &&
                        !exercisePage &&
                        (content.exerciseQuestions || 'Exercise Questions')) ||
                      content.start ||
                      'Start'
                    }
                    onClick={() => {
                      if (data.exercisePage && !exercisePage) {
                        setExercisePage(1);
                      } else {
                        submitAndNavigate({
                          navigate: page ? page + 1 : 'next',
                        });
                      }
                    }}
                    primary
                    disabled={
                      ((data.exercisePage && exercisePage) ||
                        !data.exercisePage) &&
                      !allCorrect
                    }
                  />
                </ButtonWidth>
              </ButtonContainer>
            </ContentContainer>
          </TestContainer>
        </>
      )}
    </>
  );
};

const TestContainer = styled.div`
  background: ${(props) => props.theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem;
  @media ${device.mobileL} {
    padding-top: 2rem;
  }
`;

const AudioButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0rem 15rem -3.2rem 0rem;
  @media ${device.mobileL} {
    margin: 3.3rem;
    justify-content: center;
  }
`;

const ContentContainer = styled.div`
  width: 67.2rem;
  padding: 0rem 2rem;
  @media ${device.tablet} {
    width: 100%;
  }
`;

const Header = styled.h3`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 4.1rem;
`;

const Text = styled.p`
  white-space: pre-line;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;
  margin: 4.8rem 0rem;

  ${(props) =>
    props.background &&
    css`
      background-color: #fffc9c;
      padding-left: 1rem;
    `}
`;

const ExerciseQuestionContainer = styled.div`
  ${(props) =>
    !props.newPage &&
    css`
      border-top: 0.1rem solid ${(props) => props.theme.color.mediumGrey};
      padding-top: 6rem;
    `}
`;

const ButtonWidth = styled.div`
  width: 20.4rem;
  ${(props) =>
    props.left &&
    css`
      margin-right: 2rem;
    `}
  @media ${device.mobileL} {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 11rem;
  margin-top: 4rem;
  @media ${device.mobileL} {
    flex-direction: column;
    width: 100%;
    padding: 0 2rem;
  }
`;
