import React from 'react';
import styled from 'styled-components';
import { PersonalityScores } from '../../diagrams/PersonalityScores';
import { SelectData } from '../SelectData';
import { PersonalityScoresText } from '../../diagrams/PersonalityScoresText';
import { PersonalityScoresScatterplot } from './PersonalityScoresScatterplot';

export const RenderPersonalityScores = ({
  section,
  sectionsData,
  language,
  users,
  setUserSlug,
  userSlug,
}) => {
  if (!userSlug && users && users.length) {
    setUserSlug(users[0].slug);
  }

  const renderDiagramOrText = () => {
    let personalityScoresContent = [];
    if (section.type === 3)
      personalityScoresContent = section.data.personalityScore
        .map((cluster, index) => {
          return (
            sectionsData &&
            sectionsData.clusters[index].facets.length && (
              <PersonalityScoresScatterplot
                key={index}
                cluster={cluster}
                data={sectionsData}
                language={language}
              />
            )
          );
        })
        .filter(Boolean);

    if (section.type === 4)
      personalityScoresContent = section.data.personalityScore
        .map((cluster, index) => {
          return (
            sectionsData &&
            sectionsData.clusters[index].facets.length && (
              <PersonalityScores
                key={index}
                cluster={cluster}
                data={sectionsData}
                language={language}
              />
            )
          );
        })
        .filter(Boolean);

    if (section.type === 5)
      personalityScoresContent =
        sectionsData &&
        sectionsData
          .map((data, index) => {
            return (
              data.data &&
              data.data.length && (
                <PersonalityScoresText
                  key={index}
                  data={data}
                  language={language}
                />
              )
            );
          })
          .filter(Boolean);

    return (
      (personalityScoresContent &&
        personalityScoresContent.length &&
        personalityScoresContent) || <div>Missing some data</div>
    );
  };

  return (
    <Container>
      <SelectContainer>
        <Text>Name: </Text>
        <SelectData
          useSlug
          data={users}
          selected={userSlug}
          setSelected={setUserSlug}
          white
        />
      </SelectContainer>
      {/* TODO: select norm: stanine 1/2, db? or more? */}
      <InnerContainer>{renderDiagramOrText()}</InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 2rem;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  font-size: 2rem;
  padding-left: 2.5rem;
  padding-right: 1rem;
`;
