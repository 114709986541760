import React, { useState, useEffect, Fragment } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Button } from '../../shared/button/Button';
import { InputField } from '../../shared/input/InputField';
import { Modal } from '../../shared/modal/Modal';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { SelectInput } from '../../shared/input/SelectInput';
import { rolesToPick } from '../../../helpers/permissions';

export const CreateNew = ({
  title,
  endpoint,
  showModal,
  setShowModal,
  moveAftherCreate,
  type,
  setData,
}) => {
  const [payload, setPayload] = useState({});
  const [needReload, setNeedReload] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [titleToShow, setTitleToShow] = useState(
    title === 'permissions' ? 'user' : title
  );
  const [roles] = useState(rolesToPick());

  let validationSchema = Yup.object().shape({
    title: Yup.string().required('This is a required field.'),
  });

  let initialValues = {
    title: '',
  };

  if (type === 'custom-user-permissions') {
    initialValues = {
      name: '',
      email: '',
      role: '',
      password: '',
    };

    validationSchema = Yup.object().shape({
      name: Yup.string().required('This is a required field.'),
      email: Yup.string()
        .email()
        .required('This is a required field.'),
      role: Yup.string().required('This is a required field.'),
      password: Yup.string().min(
        8,
        'Password is too short - should be 8 chars minimum.'
      ),
      // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    });
  }

  const { data, hasError } = useFetch(payload, true);

  useEffect(() => {
    if (data && data.data) {
      setData((objects) => [...objects, data.data]);
      setIsCreated(true);
    }

    if (data && data.error && data.error.toLowerCase().includes('email')) {
      setEmailError(data.error);
    }
  }, [data, setData]);

  useEffect(() => {
    setTitleToShow(title === 'permissions' ? 'user' : title);
  }, [title]);

  useEffect(() => {
    if (needReload) {
      setPayload({});
      setNeedReload(false);
    }
  }, [needReload]);

  return (
    <>
      <Button
        add
        onClick={() => {
          setShowModal(true);
        }}
      />

      <Modal
        show={showModal}
        handleExit={() => {
          setShowModal(false);
          setEmailError(false);
        }}
        width={'40rem'}
        title={`Create new ${titleToShow}`}
      >
        {showModal && !hasError && !isCreated && (
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async (values) => {
              let newObject = {
                title: values.title,
              };

              if (type === 'custom-user-permissions') {
                setEmailError(false);

                newObject = {
                  name: values.name,
                  email: values.email,
                  role: values.role,
                  password: values.password,
                };
              }

              setPayload({
                method: 'post',
                data: newObject,
                endpoint: endpoint,
                headers: {
                  'Content-Type': 'application/json',
                },
              });
            }}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                {(type === 'custom-user-permissions' && (
                  <Fragment>
                    <InputField
                      label='Full name'
                      id='name'
                      type='text'
                      name='name'
                      placeholder=''
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      error={errors.name && touched.name}
                      message={errors.name && touched.name && `${errors.name}`}
                    />

                    <InputField
                      label='Email'
                      id='email'
                      type='text'
                      name='email'
                      placeholder=''
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email && touched.email}
                      message={
                        (errors.email && touched.email && `${errors.email}`) ||
                        emailError
                      }
                    />

                    <InputField
                      label='Password'
                      id='password'
                      type='password'
                      name='password'
                      // A fix to not autowrite email and password from saved in chrome
                      readOnly
                      onFocus={({ target }) =>
                        target.removeAttribute('readOnly')
                      }
                      autocomplete='off'
                      placeholder=''
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={errors.password && touched.password}
                      message={
                        errors.password &&
                        touched.password &&
                        `${errors.password}`
                      }
                    />

                    <SelectInput
                      id='role'
                      label='Role'
                      placeholder='Select role'
                      data={roles}
                      onChange={({ target }) => (values.role = target.value)}
                      message={errors.role && touched.role && `${errors.role}`}
                    />
                  </Fragment>
                )) || (
                  <InputField
                    label='Title'
                    id='title'
                    type='text'
                    name='title'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    error={errors.title && touched.title}
                    message={errors.title && touched.title && `${errors.title}`}
                  />
                )}
                <Margin />
                <Button
                  primary
                  type='submit'
                  disabled={isSubmitting}
                  text={`Create ${titleToShow}`}
                />
              </Form>
            )}
          </Formik>
        )}

        {!hasError && isCreated && (
          <>
            <h2>
              <Capitalise>{titleToShow}: </Capitalise>
              <Bold> {data && data.data.title} </Bold>
              was created.
              {type === 'custom-user-permissions' && (
                <div>Invitation email was sent</div>
              )}
            </h2>
            <Button
              text='OK'
              onClick={() => {
                setIsCreated(false);
                setShowModal(false);
                moveAftherCreate(data.data.slug);
              }}
            />
          </>
        )}
        {hasError && (
          <>
            <h2>Error creating {titleToShow}</h2>
            <Button
              text='OK'
              onClick={() => {
                setShowModal(false);
                setEmailError(false);
                setNeedReload(true);
              }}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Margin = styled.div`
  height: ${(props) => props.theme.spacing.extraLarge};
`;

const Capitalise = styled.span`
  text-transform: capitalize;
`;

const Bold = styled.span`
  font-weight: 700;
`;
