import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../helpers/hooks/fetchData';
import styled from 'styled-components';
import { Modal } from '../../shared/modal/Modal';
import { Button } from '../../shared/button/Button';

export const DeleteLanguage = ({ _id, showModal, setShowModal }) => {
  const [payload, setPayload] = useState({});
  const { data, hasError } = useFetch(payload);
  const errorText = 'An error occured when deleting a language.';

  useEffect(() => {
    if (data) {
      setShowModal(false);
      return window.location.reload();
    }
  }, [data, setShowModal]);

  const deletelanguage = () => {
    if (!_id) {
      return false;
    }

    setPayload({
      endpoint: `/api/omega/languages/${_id}`,
      method: 'delete',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return window.location.assign('/omega/admin/languages/view-all');
  };

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          handleExit={setShowModal}
          title='Delete language'
          width='40rem'
        >
          {!hasError && (
            <>
              <Label>Are you sure you want to delete this language?</Label>
              <Margin />
              <Button
                type='submit'
                onClick={() => deletelanguage()}
                primary
                text='Delete'
              />
              <PaddingBetweenButton />
              <Button
                onClick={() => setShowModal(false)}
                secondary
                text='Cancel'
                type='reset'
              />
            </>
          )}
          {hasError && (
            <>
              <ErrorMessage>{errorText}</ErrorMessage>
              <Button secondary onClick={() => setShowModal(false)} text='OK' />
            </>
          )}
        </Modal>
      )}
    </>
  );
};

const Label = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
`;
const ErrorMessage = styled.div`
  color: ${(props) => props.theme.color.error};
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 3rem;
`;

const Margin = styled.div`
  height: ${(props) => props.theme.spacing.small};
`;
const PaddingBetweenButton = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;
