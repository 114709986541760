import React from 'react';
import styled, { css } from 'styled-components';
//import { checkedImg, uncheckedImg } from '../../../shared/assets/icons';

export const AlternativeOption = ({ checked, optionText, optionValue }) => {
  const permissionLevel =
    optionValue === 'read' ? 'Read Only' : 'crud' ? 'Full access' : 'none';

  return (
    optionValue &&
    optionValue !== 'none' && (
      <Container>
        <Text>{optionText}</Text>
        {checked && <Icon>{permissionLevel}</Icon>}
      </Container>
    )
  );
};

const Container = styled.div`
  margin-bottom: 0px;
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: 3fr 2fr 1fr 5fr;
`;

const Icon = styled.div`
  padding-left: ${(props) => props.theme.spacing.extraSmall};
`;

const Text = styled.p`
  font-size: ${(props) => props.theme.size.medium};
  line-height: 1.6rem;
  ${(props) =>
    props.alt &&
    css`
      font-style: italic;
      padding-right: ${(props) => props.theme.spacing.extraSmall};
      font-weight: bold;
    `}
`;
