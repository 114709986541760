import React, { useState } from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';
import { search } from '../assets/icons';
import { ViewSwitch } from '../../../components/shared/view-switch/viewSwitch';

export const SearchBar = ({
  noMargin,
  searchString,
  onChange,
  placeholder,
  viewSwitch,
  setActiveButton,
  whiteBackground,
}) => {
  const [input, setInput] = useState(searchString);
  const [lastSearch, setLastSearch] = useState(null);

  return (
    <>
      {noMargin ? (
        <>
          <Background>
            <InnerContainer>
              <Input
                value={input}
                onInput={(e) => setInput(e.target.value)}
                onChange={(e) => onChange(e.target.value)}
                placeholder={placeholder}
              />
              <Icon src={search} />
            </InnerContainer>
          </Background>
          {viewSwitch && <ViewSwitch setActiveButton={setActiveButton} />}{' '}
        </>
      ) : (
        <SearchContainer white={whiteBackground}>
          <Background>
            <InnerContainer>
              <Input
                value={input}
                onInput={(e) => setInput(e.target.value)}
                onChange={(e) => {
                  if (!lastSearch || new Date() - lastSearch > 100) {
                    setLastSearch(new Date());
                    onChange(e.target.value);
                  }
                }}
                placeholder={placeholder}
              />
              <Icon src={search} />
            </InnerContainer>
          </Background>
          {viewSwitch && <ViewSwitch setActiveButton={setActiveButton} />}
        </SearchContainer>
      )}
    </>
  );
};
const SearchContainer = styled.div`
  display: flex;
  background: ${(props) =>
    props.white ? props.theme.color.white : props.theme.color.bgGrey};
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;

const Background = styled.div`
  height: 5rem;
  width: 100%;
`;

const InnerContainer = styled.div`
  height: 5rem;
  width: 100%;
  position: relative;
  border-radius: 1rem;
`;

const Input = styled.input`
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  background-color: ${(props) => props.theme.color.darkGrey};
  color: ${(props) => props.theme.color.offGrey};
  font-style: italic;
  font-size: 1.6rem;
  border: none;
  padding-left: 6rem;
  &:focus {
    border: 1px solid ${(props) => props.theme.color.shadow};
    box-shadow: 0 0 3px ${(props) => props.theme.color.shadow};
    outline: none;
  }
`;

const Icon = styled.img`
  height: 2rem;
  width: 2rem;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.theme.spacing.standard};
`;
