import React from 'react';
import styled, { css } from 'styled-components';
import { checkedImg, uncheckedImg } from '../../../shared/assets/icons';
export const SingleOption = ({ correctAnswer, altCount, optionText }) => {
  return (
    <Container>
      <Text alt='true'>Alt. {altCount}.</Text>
      <Text>{optionText}</Text>
      {correctAnswer ? <Icon src={checkedImg} /> : <Icon src={uncheckedImg} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.8rem;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Icon = styled.img`
  padding-left: ${(props) => props.theme.spacing.extraSmall};
`;

const Text = styled.p`
  font-size: ${(props) => props.theme.size.medium};
  line-height: 1.6rem;
  ${(props) =>
    props.alt &&
    css`
      font-style: italic;
      padding-right: ${(props) => props.theme.spacing.extraSmall};
      font-weight: bold;
    `}
`;
