import React, { useState } from 'react';
import styled from 'styled-components';
import { paperclipBlack, switchGrey } from '../../shared/assets/icons';
import { UploadCV } from './UploadCV';
import { device } from '../../../styles/device';

export const UploadedCV = ({ cv }) => {
  const [updateCV, setUpdateCV] = useState(false);

  return (
    <>
      {!updateCV ? (
        <Container>
          <InputStyle>
            <Paperclip src={paperclipBlack} />
            <FileNameTextContainer>
              <InputFileName>{cv}</InputFileName>
            </FileNameTextContainer>
            <ChangeCVOnClick onClick={() => setUpdateCV(true)}>
              <ChangeCVIcon src={switchGrey} />
            </ChangeCVOnClick>
          </InputStyle>
        </Container>
      ) : (
        <UploadCV setUpdateCV={setUpdateCV} />
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.bgGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9rem;
`;

const InputStyle = styled.div`
  width: 33.2rem;
  height: 5.1rem;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background: ${(props) => props.theme.color.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const Paperclip = styled.img`
  margin-left: 1.5rem;
  margin-right: 2rem;
`;

const FileNameTextContainer = styled.div`
  height: 100%;
  width: 70%;
`;

const InputFileName = styled.p`
  font-size: 2.2rem;
  line-height: 5rem;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${device.mobileL} {
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

const ChangeCVOnClick = styled.div`
  cursor: pointer;
  position: absolute;
  right: 1.5rem;
`;

const ChangeCVIcon = styled.img``;
