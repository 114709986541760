import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../styles/device';
import { close } from '../assets/icons';

export const Modal = ({
  handleExit,
  children,
  show,
  width,
  title,
  removeOverFlowScroll,
  add,
  skipTitleMargin,
}) => {
  const toggleClassName = show ? 'modal show' : 'modal';
  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'auto';
  }, [show]);

  const node = useRef();
  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClick);
    } else {
      document.removeEventListener('mousedown', handleClick);
    }
  });

  const handleClick = (event) => {
    if (node.current === event.target || event.target.type === 'reset') {
      document.removeEventListener('mousedown', handleClick);
      document.body.style.overflow = 'auto';
      handleExit(false);
    }
    if (event.target.type === 'submit') {
      document.body.style.overflow = 'auto';
    }
    if (!node.current) document.removeEventListener('mousedown', handleClick);
  };

  return (
    <Overlay className={toggleClassName} ref={node}>
      <ModalContainer width={width} removeOverFlowScroll={removeOverFlowScroll}>
        <HeaderBox className='header-box' skipTitleMargin={skipTitleMargin}>
          {title && <Title>{title}</Title>}
          <ModalButton
            role='button'
            onClick={() => {
              document.body.style.overflow = 'auto';
              handleExit(false);
            }}
          >
            <CloseIcon src={close} />
          </ModalButton>
        </HeaderBox>
        <ModalContent add={add}>{children}</ModalContent>
      </ModalContainer>
    </Overlay>
  );
};

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  width: ${(props) => (props.width ? ` ${props.width}` : ' 75% ')};
  max-height: 85%;
  border-radius: 0.5rem;
  overflow: ${(props) => (props.removeOverFlowScroll ? 'visible' : 'scroll')};
  background: #fff;
  box-shadow: 1rem 1rem 1.7rem -0.9rem rgba(0, 0, 0, 0.7);
  position: relative;
  @media ${device.mobileL} {
    width: 90%;
  }
`;
const ModalButton = styled.button`
  cursor: pointer;
  border-top-right-radius: 0.5rem;
  border: none;
  background-color: ${(props) => props.theme.color.white};
  position: absolute;
  top: 0;
  right: 0;
  padding-top: ${(props) => props.theme.spacing.standard};
  padding-right: ${(props) => props.theme.spacing.standard};
`;

const ModalContent = styled.div`
  padding: ${(props) => props.theme.spacing.standard};

  @media ${device.mobileM} {
    padding: ${(props) => props.theme.spacing.standardL};
  }
  ${(props) =>
    props.add &&
    css`
      height: 50rem;
      overflow-y: scroll;
    `}
`;

const CloseIcon = styled.img``;

const Title = styled.div`
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.1rem;
`;

const HeaderBox = styled.div`
  padding-top: ${(props) => props.theme.spacing.standard};
  margin-bottom: ${(props) =>
    !props.skipTitleMargin && props.theme.spacing.extraLarge};
  display: flex;
  justify-content: center;
  align-items: center;
`;
