import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { device } from '../../../../styles/device';
import { SearchBar } from '../../../shared/search-bar/SearchBar';
import { ProfileCard } from '../../candidates/ProfileCard';
import { NoResultsFound } from '../../../shared/error-page-components/NoResultsFound';
import history from '../../../../history';

export const ProjectOverview = ({ users, setUsers }) => {
  const [searchString, setSearchString] = useState('');
  const [usersToView, setUsersToView] = useState([]);

  useEffect(() => {
    if (users.length) setUsersToView(users);
  }, [users]);

  const searchEntered = (string) => {
    const pattern = new RegExp(string, 'i');
    setSearchString(string);
    setUsersToView(users.filter((user) => pattern.test(user.name)));
  };

  return (
    <Container>
      <SearchBar
        searchString={searchString}
        onChange={searchEntered}
        placeholder={'Search'}
        whiteBackground
      />

      <CardsContent>
        {usersToView.length === 0 && <NoResultsFound />}
        <CardsContainer>
          <ProfileCard
            users={usersToView}
            setUsers={setUsers}
            onItemClick={(slug) =>
              history.push(`/admin/candidates/${slug}`, { slug })
            }
            inverted
            projectView
          />
        </CardsContainer>
      </CardsContent>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 4rem;
  margin-bottom: ${(props) => props.theme.spacing.medium};
  @media ${device.mobileL} {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
`;

const CardsContent = styled.main`
  width: 100%;
  margin-top: ${(props) => props.theme.spacing.extraSmall};
`;

const CardsContainer = styled.div`
  width: 100%;
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  margin-top: ${(props) => props.theme.spacing.extraLarge};
  margin-bottom: ${(props) => props.theme.spacing.extraLarge};
  display: grid;
  justify-items: start;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  grid-column-gap: ${(props) => props.theme.spacing.standard};
  grid-row-gap: 6.5rem;

  @media (max-width: 1104px) {
    grid-column-gap: ${(props) => props.theme.spacing.extraSmall};
    padding-right: ${(props) => props.theme.spacing.extraSmall};
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }
  @media (max-width: 979px) {
    grid-column-gap: ${(props) => props.theme.spacing.small};
  }
  @media (max-width: 868px) {
    padding-right: ${(props) => props.theme.spacing.standard};
  }
  @media ${device.tablet} {
    padding-right: ${(props) => props.theme.spacing.extraSmall};
  }
  @media (max-width: 725px) {
    padding-right: 0.5rem;
  }
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;
