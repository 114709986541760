import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  LanguageItems,
  LanguageItemsWrapper,
} from '../show-report/ChangeLanguage';
import { PreviewSection } from './PreviewSection';

export const PreviewTemplate = ({ template }) => {
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  useEffect(() => {
    if (template.title) {
      setAvailableLanguages(template.availableLanguages);
      setSelectedLanguage(template.availableLanguages[0].code);
    }
  }, [template]);

  return (
    <Container>
      {(availableLanguages.length && availableLanguages.length > 1 && (
        <LanguageItemsWrapper>
          <LanguageItems
            currentLanguage={selectedLanguage}
            setCurrentLanguage={setSelectedLanguage}
            availableLanguages={availableLanguages}
          />
        </LanguageItemsWrapper>
      )) ||
        null}
      {(template && availableLanguages.length && (
        <Fragment>
          <Title>{template.title}</Title>
          {template &&
            template.sections &&
            template.sections.length &&
            template.sections.map((section, index) => (
              <PreviewSection
                key={index}
                language={selectedLanguage}
                section={section}
              />
            ))}
        </Fragment>
      )) || <div>NO DATA</div>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  padding-bottom: 4rem;
`;

const Title = styled.h1`
  font-size: 3rem;
  font-weight: 700;
  align-text: center;
`;
