import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { dots } from '../assets/icons';

export const DropDownMenu = ({ dropDownOptions, id, dontRender }) => {
  const node = useRef();
  const [open, setOpen] = useState(false);
  const handleClick = (e) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return dontRender ? (
    <></>
  ) : (
    <>
      <DropDown
        ref={node}
        id={'noToggle'}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <img id={'noToggle'} src={dots} alt='Options' />
        <MenuWrapper open={open}>
          {dropDownOptions.map((item, key) => {
            return (
              <Border key={key}>
                <Item
                  onClick={() => {
                    setOpen(false);
                    item.onClick(item.id || id);
                  }}
                  delete={item.delete}
                >
                  {item.image && (
                    <FlagImage src={item.image} alt={item.title} />
                  )}
                  {item.title}
                </Item>
              </Border>
            );
          })}
        </MenuWrapper>
      </DropDown>
    </>
  );
};

const DropDown = styled.div`
  border-radius: 0.4rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 2rem 0.7rem;
  margin: 0.2rem 0 0.2rem 0.2rem;
  cursor: pointer;
`;

const MenuWrapper = styled.div`
  display: none;
  border-radius: 0.4rem;

  ${(props) =>
    props.open &&
    css`
      max-height: 25rem;
      overflow: auto;
      z-index: 1;
      display: block;
      background: ${(props) => props.theme.color.white};
      position: absolute;
      right: 3rem;
      top: 1.5rem;
      border: 0.1rem solid #00000019
      box-shadow: 0.1rem 0.1rem 0.5rem 0px #00000019;
    `}
`;

const Border = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.color.darkGrey};
  width: 100%;
  max-width: 20rem;
  padding: 1rem;
  &:last-child {
    border-bottom: none;
  }
`;

const Item = styled.button`
  display: block;
  white-space: nowrap;
  color: ${(props) => props.theme.color.offBlack};
  font-size: 1.6rem;
  line-height: 1.9rem;
  border: none;
  text-align: left;
  border-radius: 0.4rem;
  padding: 1rem;
  width: fit-content%;
  background: white;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.color.bgGrey};
  }

  ${(props) =>
    props.delete &&
    css`
      color: ${(props) => props.theme.color.error};
    `}
`;

const FlagImage = styled.img`
  height: 1.3rem;
  margin-right: 1rem;
`;
