/* ANIMATIONS */
export const correctAnswer =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/animations/answer-correct.svg';

/* END ANIMATIONS*/

/* LOGO */
export const navigioLogo =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/navigio-logo.svg';
/* END LOGO */

/* ICONS */
export const plusBox =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/plus-box.svg';

export const minusBox =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/minus-box.svg';

export const arrowBoxUp =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/arrow-box-up.svg';

export const arrowBoxDown =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/arrow-box-down.svg';

export const search =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/search-icon.svg';

export const user =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/user-icon.svg';

export const camera =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/camera.svg';

export const chevron =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/chevron.svg';

export const chevronGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/chevron-grey.svg';

export const mic =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/mic.svg';

export const clock =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/clock.svg';

export const sortArrowDown =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/sort-arrow-down.svg';

export const sortArrowUp =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/sort-arrow-up.svg';

export const sortArrows =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/sort-arrows.svg';

export const showCards =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/show-cards.svg';

export const showCardsDark =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/show-cards-dark.svg';

export const showList =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/show-list.svg';

export const showListDark =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/show-list-dark.svg';

export const exercise =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/exercise.svg';

export const exerciseWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/exercise-white.svg';

export const exerciseDark =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/exersise-dark.svg';

export const close =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/close.svg';

export const info =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/info.svg';

export const regular =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/regular.svg';

export const regularWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/regular-white.svg';

export const instruction =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/instruction.svg';

export const instructionWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/instruction-white.svg';

export const instructionDark =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/instruction-dark.svg';

export const checkedImg =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/checked-option.svg';

export const uncheckedImg =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/unchecked-option.svg';

export const dots =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/option-dots.svg';

export const plus =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/plus.svg';

export const plusBlack =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/plus-black.svg';

export const checkboxGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/checkbox-grey.svg';

export const checkboxUncheckedGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/checkbox-unchecked-grey.svg';

export const optionsOnlyWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/options-only-white.svg';

export const optionsOnlyGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/options-only-grey.svg';

export const addCameraWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/add-camera-white.svg';

export const addCameraGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/add-camera-grey.svg';

export const penWithBorderWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/pen-with-borders-white.svg';

export const penWithBorderGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/pen-with-borders-grey.svg';

export const numberTypeWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/numbers-type-white.svg';

export const numberTypeGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/numbers-type-grey.svg';

export const radioButtonWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/radio-button-white.svg';

export const radioButtonGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/radio-button-grey.svg';

export const dropDownWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/dropdown-white.svg';

export const dropDownGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/dropdown-grey.svg';

export const checkedItemCircleWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/checked-item-circle-white.svg';

export const checkedItemCircleGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/checked-item-circle-grey.svg';

export const eyeDark =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/eye.svg';

export const correctCheckWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/correct-check-white.svg';

export const correctCheckGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/correct-check-grey.svg';

export const editPenGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/edit-pen-grey.svg';

export const editPenWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/edit-pen-white.svg';

export const fileUploadGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/file-up-grey.svg';

export const fileUploadWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/file-up-white.svg';

export const downChevronDarkGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/down-chevron-darkgrey.svg';

export const eyeGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/eye-grey.svg';
export const checkedItemCircleBlack =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/checked-item-circle-black.svg';

export const dropDownBlack =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/dropdown-black.svg';

export const radioButtonBlack =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/radio-button-black.svg';

export const radioButtonUnchecked =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/radio-button-unchecked.svg';

export const radioButtonChecked =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/radio-button-checked.svg';

export const penWithBorderBlack =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/pen-with-borders-black.svg';

export const addCameraBlack =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/add-camera-black.svg';

export const omegaGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/omega-grey.svg';

export const omegaWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/omega-white.svg';

export const logOutWhite =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/log-out-white.svg';
export const amountPlus =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/amount-plus.svg';

export const amountMinus =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/amount-minus.svg';

export const switchGrey =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/switch-grey.svg';

export const paperclipBlack =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/paperclip-black.svg';
export const closedSection =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/closed-section.svg';

export const testCheckbox =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/test-checkbox.svg';

export const testCheckboxChecked =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/test-checkbox-checked.svg';

/* END ICONS */

/* ICONS CANDIDATE*/
export const checkboxBlackChecked =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/checkbox-black-checked.svg';

export const checkboxBlack =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/checkbox-black.svg';

export const playSound =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/play-sound.svg';

export const pauseSound =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/icons/pause-sound.svg';

/* END ICONS CANDIDATE*/

/* FLAGS */
export const SE =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/flags/SE.svg';

export const DK =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/flags/DK.svg';

export const NO =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/flags/NO.svg';

export const FI =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/flags/FI.svg';

export const EN =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/flags/GB.svg';

export const FR =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/flags/FR.svg';

export const DE =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/flags/DE.svg';

export const ES =
  'https://navigio-digital-tool-public-production.s3-eu-west-1.amazonaws.com/omega-assets/flags/ES.svg';
/* END FLAGS */
