import React, { useEffect, useState } from 'react';
import { Formik, Field } from 'formik';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { InputField } from '../../../shared/input/InputField';
import { FileInput } from '../../../shared/input/FileInput';
import styled from 'styled-components';
import * as yup from 'yup';
import { Button } from '../../../shared/button/Button';
import { device } from '../../../../styles/device';
import { InputWrapper } from '../../../shared/input/InputWrapper';
import { CreationProgress } from '../../../shared/progress-bar/CreationProgress';
import { LanguageItem, LanguageItemWrapper } from './LanguageItem';
import { Languages } from './Languages';
import { CategoryList } from '../../../shared/drop-downs/CategoryList';
import { Type } from './Type';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import { PreviewNumberQuestion } from './PreviewNumberQuestion';
import { QuestionSounds } from './QuestionSounds';
import { MultipleQuestions } from './MultipleQuestions';
import { QuestionVideo } from './QuestionVideo';
import { SelectInput } from '../../../shared/input/SelectInput';
import { FormLabel } from '../../../shared/input/FormLabel';
import { userHasAccess } from '../../../../../src/helpers/permissions.js';
export const SetQuestion = ({ question, setPage, setQuestion }) => {
  const [languages, setLanguages] = useState(null);
  const [categories, setCategories] = useState(null);
  const [update, setUpdate] = useState(true);
  const [languagePayload] = useState({ endpoint: '/api/omega/languages/' });
  const languagesData = useFetch(languagePayload);
  const [facetsPayload] = useState({ endpoint: '/api/omega/facets/' });
  const [selectedFacet, setSelectedFacet] = useState(question.facet || '');
  const [isFacetInverted, setIsFacetInverted] = useState(
    question.inverted || false
  );
  const [facets, setFacets] = useState([]);
  const [selectedFacets, setSelectedFacets] = useState(
    (question.omegaSettings && {
      1: question.omegaSettings[1].facet || '',
      2: question.omegaSettings[2].facet || '',
      3: question.omegaSettings[3].facet || '',
    }) || { 1: '', 2: '', 3: '' }
  );
  const [isInverted, setIsInverted] = useState(
    (question.omegaSettings && {
      1: question.omegaSettings[1].inverted || false,
      2: question.omegaSettings[2].inverted || false,
      3: question.omegaSettings[3].inverted || false,
    }) || { 1: false, 2: false, 3: false }
  );
  const [facetsError, setFacetsError] = useState([false, false, false]);
  const [facetError, setFacetError] = useState(false);
  const [categoriesPayload] = useState({
    endpoint: '/api/omega/categories/',
    params: {
      isSurvey:
        userHasAccess('TEXT') && !userHasAccess('QUESTIONS') ? true : false,
    },
  });
  const [chosenLanguage, setChosenLanguage] = useState([]);
  const [languageError, setLanguageError] = useState(null);
  const [chosenCategory, setChosenCategory] = useState(question.category);
  const [newImage, setNewImage] = useState(null);
  const [newVideos, setNewVideos] = useState(null);
  const [newImages, setNewImages] = useState([]);
  const [newSounds, setNewSounds] = useState([]);
  const [numbers, setNumbers] = useState(question.numbersQuestion);
  const [chosenType, setChosenType] = useState(
    Object.keys(question.type)
      .map((item) => {
        return question.type[item] ? item : '';
      })
      .filter(Boolean)
      .toString()
  );

  const [fimImages, setFimImages] = useState(() => {
    const fimImages = [];
    question.fimImages = [];

    if (
      question.fimQuestion &&
      question.fimQuestion.instructionImages &&
      question.fimQuestion.instructionImages.length
    ) {
      question.fimQuestion.instructionImages.forEach((_, index) => {
        fimImages.push({ index });
      });
    }
    return fimImages;
  });

  const [payload, setPayload] = useState({});
  const { data } = useFetch(payload);

  function generateNumberArray(length) {
    // Use Array.from() to create an array of numbers from 0 to length - 1
    return Array.from({ length }, (_, index) => index);
  }

  const renderAddQuestion = () => {
    let array = [0];

    if (question.addQuestion) {
      const langCode = Object.keys(question.addQuestion)[0];
      const questions = question.addQuestion[langCode].questions;
      const addQuestionLength = questions.length;

      array = generateNumberArray(addQuestionLength);
    }

    return array;
  };
  const [addQuestions, setAddQuestions] = useState(renderAddQuestion());

  if (data) {
    const availableLanguages = data.availableLanguages;
    question.availableLanguages = availableLanguages;

    if (data.questionImage) {
      question.questionImage = data.questionImage;
    }
    if (data.fimQuestion) {
      question.fimQuestion = data.fimQuestion;
    }
    if (question.type.omega || question.type.likert) {
      //Temporary redirect to view all questions. Omega/likertScale questions might skip page 2.
      window.location.assign('/omega/admin/questions/view-all');
    } else {
      setPage(2);
    }
  }

  const validationSchema = yup.object().shape({
    title: yup.string().required('This is a required field.'),
    question: yup.lazy((obj) =>
      yup.object(
        mapValues(obj, () =>
          yup.object().shape({
            text: yup.string().required('This is a required field.'),
          })
        )
      )
    ),
    fileInput: yup.string(),
  });
  const { data: facetsData } = useFetch(facetsPayload);

  useEffect(() => {
    if (facetsData && facetsData.data) {
      setFacets(facetsData.data);
    }
  }, [facetsData]);

  const categoriesData = useFetch(categoriesPayload);
  useEffect(() => {
    if (categoriesData && categoriesData.data && categoriesData.data.data) {
      setCategories(categoriesData.data.data);
    }
  }, [categoriesData]);

  useEffect(() => {
    if (languagesData && languagesData.data && languagesData.data.data) {
      setLanguages(languagesData.data.data);

      // Set availble lang to eng for fim questions, fim should only have 1 lang.
      // Fim exercise can have more than 1 lang for the description
      if (chosenType === 'fim' && !question.availableLanguages.length) {
        const lang = languagesData.data.data.find((lang) => lang.code === 'EN');
        question.availableLanguages = [lang];
        setChosenLanguage('EN');
      }
    }
  }, [languagesData, question.availableLanguages, chosenType]);

  useEffect(() => {
    question.numbersQuestion = numbers;
  }, [numbers, question.numbersQuestion]);

  const addLanguage = (language, key) => {
    //remove uni language
    const uniIx = question.availableLanguages.findIndex(
      (lang) => lang.code === 'Universal'
    );
    if (uniIx !== -1) {
      question.availableLanguages.splice(uniIx, 1);
    }

    const langIx = question.availableLanguages.findIndex(
      (availableLanguage) => availableLanguage.code === language.code
    );
    if (langIx === -1) {
      question.options = question.options || {};

      question.availableLanguages.push(language);
    } else {
      question.availableLanguages.splice(langIx, 1);
      delete question.options[language.code];
    }

    setLanguageError(null);
    setUpdate(!update);
    setChosenLanguage([...chosenLanguage, key]);
  };

  useEffect(() => {
    //TODO: Do we need todo something here?!
    // just want to update when user add another question
  }, [addQuestions, fimImages]);

  useEffect(() => {
    question.type = {
      //TODO: Swithc name and id on add??
      add: chosenType === 'add',
      text: chosenType === 'text',
      image: chosenType === 'image',
      numbers: chosenType === 'numbers',
      optionsOnly: chosenType === 'optionsOnly',
      fim: chosenType === 'fim',
      omega: chosenType === 'omega',
      likert: chosenType === 'likert',
    };
  }, [chosenType, question.type]);

  useEffect(() => {
    if (chosenCategory) question.category = chosenCategory;
  }, [chosenCategory, question.category]);

  const fileSelected = (event) => {
    const image =
      (event.target && event.target.files && event.target.files[0]) || null;
    setNewImage(image);
  };

  const fimFileSelected = (event, values, index) => {
    const image =
      (event.target && event.target.files && event.target.files[0]) || null;
    if (image) {
      const oldFile = values.fimQuestion.instructionImages.find(
        (e) => values.fimQuestion.instructionImages[index] === e
      );

      if (oldFile) {
        values.fimQuestion.instructionImages[index] = { index };
      }

      setNewImages([...newImages, image]);
    }
  };

  const questionVideoSelected = (event, id) => {
    if (event.target && event.target.files && event.target.files[0]) {
      const newVideo = {};
      newVideo[id] =
        event.target && event.target.files && event.target.files[0];
      setNewVideos({ ...newVideos, ...newVideo });
    }
  };

  const questionSoundSelected = (event, id) => {
    if (event.target && event.target.files && event.target.files[0]) {
      const newSound = {};
      newSound[id] =
        event.target && event.target.files && event.target.files[0];

      setNewSounds({ ...newSounds, ...newSound });
    }
  };

  const renderLanguageComponent = () => {
    if (chosenType && chosenType !== 'numbers' && chosenType !== 'fim') {
      return true;
    }

    if (chosenType && (question.isInstruction || question.isExercise)) {
      return true;
    }

    return false;
  };

  const renderSoundsComponent = () => {
    if (question.isExercise || question.isInstruction) {
      return true;
    }
    return false;
  };

  const renderExerciseSoundsComponent = () => {
    if (question.isExercise) {
      return true;
    }

    if (chosenType === 'fim' && question.isInstruction) {
      return true;
    }

    return false;
  };

  const renderOmegaFacets = () => {
    const returnObjects = [];

    for (let i = 1; i <= 3; i++) {
      returnObjects.push(
        <React.Fragment key={i}>
          <FormLabel label={`Text: ${i}`} />
          <SelectInput
            label='Facet'
            key={i}
            data={facets}
            placeholder={'Select facet'}
            value={selectedFacets[i]}
            onChange={({ target }) => {
              selectedFacets[i] = target.value;
              setSelectedFacets({ ...selectedFacets });
            }}
          />
          {facetsError[i - 1] && (
            <SmallErrorMessage marginBottom>
              {facetsError[i - 1]}
            </SmallErrorMessage>
          )}
          <TypeContainer>
            <FormLabel label='Inverted question' />
            <Type
              chosenType={isInverted[i]}
              setChosenType={(e) => {
                isInverted[i] = e;
                setIsInverted({ ...isInverted });
              }}
              yesOrNoOption
            />
          </TypeContainer>
        </React.Fragment>
      );
    }
    return returnObjects;
  };

  const setNoOfQuestions = (value) => {
    const noOfQuestions = (question.noOfQuestions || 1) + value;
    const newNoOfQuestion =
      noOfQuestions > 20 ? 20 : noOfQuestions < 1 ? 1 : noOfQuestions;
    setQuestion({ ...question, noOfQuestions: newNoOfQuestion });
  };

  const addMoreImages = (values, touched) => {
    if (!touched.fimImages) {
      touched.fimImages = [];
    }

    if (!values.fimQuestion) {
      values.fimQuestion = { instructionImages: [] };
    }

    if (!values.fimQuestion.instructionImages) {
      values.fimQuestion.instructionImages = [];
    }

    values.fimQuestion.instructionImages.push({ index: fimImages.length });

    setFimImages([...fimImages, { index: fimImages.length }]);
  };

  const removeImage = (index, touched) => {
    if (!touched.fimImages) {
      touched.fimImages = [];
    }

    fimImages.splice(index, 1);
    question.fimQuestion.instructionImages.splice(index, 1);
    setFimImages([...fimImages]);
  };

  return (
    <Container>
      <CreationProgress
        step='first'
        firstStepText='Question'
        secondStepText='Options'
        thirdStepText='Preview'
      />
      <StandardSpacing />
      <Formik
        initialValues={question}
        values={question}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          question = { ...question, ...values };
          if (newImage) question.newImage = newImage;
          if (newImages.length) question.newImages = newImages;

          if (!(question.isInstruction || question.isExercise)) {
            const facetErrorText = 'Need to select a facet';
            if (question.type.likert) {
              if (!selectedFacet) return setFacetError(facetErrorText);
              else {
                question.facet = selectedFacet;
                question.inverted = isFacetInverted;
              }
            }

            if (values.type.omega) {
              for (const key in selectedFacets) {
                if (!selectedFacets[key]) facetsError[key - 1] = facetErrorText;
                else facetsError[key - 1] = false;
              }
              if (facetsError[0] || facetsError[1] || facetsError[2])
                return setFacetsError([...facetsError]);

              question.omegaSettings = {
                1: { facet: selectedFacets[1], inverted: isInverted[1] },
                2: { facet: selectedFacets[2], inverted: isInverted[2] },
                3: { facet: selectedFacets[3], inverted: isInverted[3] },
              };
            }
          }

          if (
            renderLanguageComponent() &&
            question.availableLanguages.length === 0
          ) {
            return setLanguageError('Please choose at least one language');
          }

          const formData = new FormData();
          formData.append('question', JSON.stringify(question));
          formData.append('newImage', newImage);
          for (const prop in newVideos) {
            formData.append(prop, newVideos[prop]);
          }

          for (const prop in newSounds) {
            formData.append(prop, newSounds[prop]);
          }

          for (const prop in newImages) {
            formData.append('newImages' + prop, newImages[prop]);
          }

          formData.append('isStringified', true);
          setQuestion(question);
          setPayload({
            method: 'put',
            endpoint: `/api/omega/questions/${question._id}/`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }}
      >
        {({
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          //isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <InnerContainer>
              <LeftColumn>
                <InputWrapper
                  errorMsg={errors.title && touched.title && `${errors.title}`}
                  title='Internal title'
                >
                  <Field
                    as={InputField}
                    id='title'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title || ''}
                    error={errors.title && touched.title}
                    type='text'
                    name='title'
                    placeholder='Internal title'
                  />
                </InputWrapper>
                <InputWrapper marginTop height='29' title='Category'>
                  <CategoryList
                    categories={categories}
                    setChosenCategory={setChosenCategory}
                    chosenCategory={chosenCategory}
                  />
                </InputWrapper>
                {chosenCategory && (
                  <InputWrapper marginTop title='Question type'>
                    <Type
                      chosenType={chosenType}
                      setChosenType={setChosenType}
                      questionType
                    />
                  </InputWrapper>
                )}
                {chosenType === 'omega' &&
                  !(question.isInstruction || question.isExercise) && (
                    <InputWrapper marginTop title='Omega settings'>
                      {renderOmegaFacets()}
                    </InputWrapper>
                  )}
                {chosenType === 'likert' &&
                  !(question.isInstruction || question.isExercise) && (
                    <InputWrapper marginTop title='Facet'>
                      <SelectInput
                        data={facets}
                        placeholder={'Select facet'}
                        value={selectedFacet}
                        onChange={({ target }) =>
                          setSelectedFacet(target.value)
                        }
                      />
                      {facetError && (
                        <SmallErrorMessage marginBottom>
                          {facetError}
                        </SmallErrorMessage>
                      )}
                      <TypeContainer>
                        <FormLabel label='Inverted question' />
                        <Type
                          chosenType={isFacetInverted}
                          setChosenType={(target) => setIsFacetInverted(target)}
                          yesOrNoOption
                        />
                      </TypeContainer>
                    </InputWrapper>
                  )}
              </LeftColumn>
              <Space />
              <RightColumn>
                {renderLanguageComponent() && (
                  <InputWrapper title='Languages'>
                    <LanguageItemWrapper>
                      {languages &&
                        languages.length > 0 &&
                        languages.map((language, key) => (
                          <LanguageItem
                            key={key}
                            onClick={() =>
                              addLanguage(language, key, chosenType)
                            }
                            code={language.code}
                            flag={language.flag}
                            active={question.availableLanguages.some(
                              (pickedLanguage) =>
                                pickedLanguage._id === language._id
                            )}
                          />
                        ))}
                    </LanguageItemWrapper>
                    {languageError && (
                      <SmallErrorMessage>{languageError}</SmallErrorMessage>
                    )}
                  </InputWrapper>
                )}

                {(chosenType === 'text' || chosenType === 'likert') &&
                  question.availableLanguages.length > 0 && (
                    <InputWrapper title='Question' marginTop>
                      <Languages
                        availableLanguages={question.availableLanguages}
                        errors={errors}
                        touched={touched}
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        question
                      />
                    </InputWrapper>
                  )}
                {chosenType === 'omega' &&
                  question.availableLanguages.length > 0 && (
                    <InputWrapper title='Questions' marginTop>
                      <Languages
                        availableLanguages={question.availableLanguages}
                        errors={errors}
                        touched={touched}
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        omegaQuestion
                      />
                    </InputWrapper>
                  )}
                {chosenType === 'numbers' && (
                  <PreviewNumberQuestion
                    values={numbers}
                    setNumbers={setNumbers}
                  />
                )}
                {chosenType === 'add' &&
                  question.availableLanguages.length > 0 && (
                    <InputWrapper
                      title={`Questions: ${addQuestions.length}`}
                      marginTop
                    >
                      <Languages
                        availableLanguages={question.availableLanguages}
                        errors={errors}
                        touched={touched}
                        values={values}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        addQuestion
                        addQuestions={addQuestions}
                        setAddQuestions={setAddQuestions}
                      />
                    </InputWrapper>
                  )}

                {renderSoundsComponent() && (
                  <>
                    <QuestionSounds
                      availableLanguages={question.availableLanguages}
                      errors={errors}
                      touched={touched}
                      values={values}
                      onChange={questionSoundSelected}
                      onBlur={handleBlur}
                      type='soundFileExercise'
                      title='Add question sounds'
                      acceptedFormats='.mp3, .m4a, .wav'
                      sound
                    />
                    <QuestionVideo
                      availableLanguages={question.availableLanguages}
                      errors={errors}
                      touched={touched}
                      values={values}
                      onChange={questionVideoSelected}
                      onBlur={handleBlur}
                      type='instructionVideos'
                      title='Add question video'
                      acceptedFormats='.m4a, .wav, .mp4'
                    />
                  </>
                )}
                {renderExerciseSoundsComponent() && (
                  <>
                    <QuestionSounds
                      availableLanguages={question.availableLanguages}
                      errors={errors}
                      touched={touched}
                      values={values}
                      onChange={questionSoundSelected}
                      onBlur={handleBlur}
                      type='exerciseSoundCorrect'
                      title='Add correct answered sound'
                      acceptedFormats='.mp3, .m4a, .wav'
                      sound
                    />
                    <QuestionSounds
                      availableLanguages={question.availableLanguages}
                      errors={errors}
                      touched={touched}
                      values={values}
                      onChange={questionSoundSelected}
                      onBlur={handleBlur}
                      type='exerciseSoundIncorrect'
                      title='Add incorrect answered sound'
                      acceptedFormats='.mp3, .m4a, .wav'
                      sound
                    />
                    {question.availableLanguages.length > 0 && (
                      <InputWrapper title='Question text explanation' marginTop>
                        <Languages
                          availableLanguages={question.availableLanguages}
                          errors={errors}
                          touched={touched}
                          values={values}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          exerciseQuestion
                        />
                      </InputWrapper>
                    )}
                  </>
                )}
                {chosenType === 'image' && (
                  <>
                    <InputWrapper
                      title={
                        values.questionImage && values.questionImage.thumbnail
                          ? 'Change question image file'
                          : 'Add question image file'
                      }
                      marginTop
                      errorMsg={
                        errors.fileInput &&
                        touched.fileInput &&
                        `${errors.fileInput}`
                      }
                    >
                      {values.questionImage &&
                        values.questionImage.thumbnail && (
                          <img
                            src={values.questionImage.thumbnail}
                            alt='question'
                          />
                        )}
                      <Field
                        as={FileInput}
                        id='fileInput'
                        onChange={fileSelected}
                        onBlur={handleBlur}
                        value={values.fileInput || ''}
                        error={errors.fileInput && touched.fileInput}
                        name='fileInput'
                        image
                      />
                    </InputWrapper>

                    <MultipleQuestions
                      hasMultipleQuestions={question.multipleQuestions}
                      setHasMultipleQuestions={(value) =>
                        setQuestion({ ...question, multipleQuestions: value })
                      }
                      noOfQuestions={question.noOfQuestions}
                      setNoOfQuestions={setNoOfQuestions}
                    />
                  </>
                )}
                {chosenType === 'fim' && (
                  <>
                    <InputWrapper
                      title={
                        values.fimQuestion &&
                        values.fimQuestion.image &&
                        values.fimQuestion.image.thumbnail
                          ? 'Change question image file'
                          : 'Add question image file'
                      }
                      marginTop
                      errorMsg={
                        errors.fileInput &&
                        touched.fileInput &&
                        `${errors.fileInput}`
                      }
                    >
                      {values.fimQuestion &&
                        values.fimQuestion.image &&
                        values.fimQuestion.image.thumbnail && (
                          <img
                            src={values.fimQuestion.image.thumbnail}
                            alt='question'
                          />
                        )}
                      <Field
                        as={FileInput}
                        id='fileInput'
                        onChange={fileSelected}
                        onBlur={handleBlur}
                        value={values.fileInput || ''}
                        error={errors.fileInput && touched.fileInput}
                        name='fileInput'
                        image
                      />
                    </InputWrapper>
                    {(question.isInstruction || question.isExercise) && (
                      <InputWrapper
                        title={'Add more question image file'}
                        marginTop
                        addMore={() => addMoreImages(values, touched)}
                      >
                        {fimImages.map((image, index) => (
                          <React.Fragment key={index}>
                            {values.fimQuestion &&
                              values.fimQuestion.instructionImages &&
                              values.fimQuestion.instructionImages[index]
                                .thumbnail && (
                                <img
                                  src={
                                    values.fimQuestion.instructionImages[index]
                                      .thumbnail
                                  }
                                  alt='question'
                                />
                              )}
                            <Field
                              as={FileInput}
                              id='fileInput'
                              onChange={(e) =>
                                fimFileSelected(e, values, index)
                              }
                              onBlur={handleBlur}
                              value={values.fimImages[index] || ''}
                              name={'fileInput' + index}
                              image
                            />
                            <RemoveButton
                              type='button'
                              onClick={() => removeImage(index, touched)}
                            >
                              -
                            </RemoveButton>
                          </React.Fragment>
                        ))}
                      </InputWrapper>
                    )}
                  </>
                )}
              </RightColumn>
            </InnerContainer>
            <FullColumn>
              <ButtonContainer>
                <Button secondary text='Save draft' type='submit' />
                <MarginButton />
                <Button
                  isDisabled={isEmpty(errors)}
                  primary
                  type='submit'
                  text='Continue'
                />
              </ButtonContainer>
            </FullColumn>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const FullColumn = styled.div`
  width: 100%;
  margin-top: 8rem;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  background: ${(props) => props.theme.color.white};
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  padding: ${(props) => props.theme.spacing.standard};
  margin-bottom: ${(props) => props.theme.spacing.standard};
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
const MarginButton = styled.div`
  width: 6rem;
  @media ${device.mobileL} {
    height: ${(props) => props.theme.spacing.small};
  }
`;
const LeftColumn = styled.div`
  flex: 1;
  height: 100%;
`;
const RightColumn = styled.div`
  flex: 1;
  height: 100%;
`;
export const StandardSpacing = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;
const Space = styled.div`
  width: ${(props) => props.theme.spacing.standard};
  @media ${device.tablet} {
    height: ${(props) => props.theme.spacing.standard};
  }
`;
const SmallErrorMessage = styled.div`
  margin-top: 1rem;
  ${(props) => props.marginBottom && 'margin-bottom: 2rem'};
  color: ${(props) => props.theme.color.error};
`;

const RemoveButton = styled.button`
  margin-bottom: 1rem;
`;

const TypeContainer = styled.div`
  margin-bottom: 2rem;
`;
