import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../styles/device';
import { displayDateAndTime } from '../../../../helpers/common';

export const ProjectProfileCard = ({ projects, onItemClick }) => {
  const truncateString = (string) => {
    if (string && string.length > 25) {
      return string.substring(0, 25) + '...';
    } else {
      return string;
    }
  };

  const renderUsers = () => {
    return projects.map((project) => {
      return (
        <Container key={project._id} onClick={() => onItemClick(project._id)}>
          <InfoBox>
            <CreatedAtDate>
              {project.isProject ? 'Project' : 'Group'}
            </CreatedAtDate>
          </InfoBox>
          <TopContainer>
            <CardHeading>{truncateString(project.title)}</CardHeading>
            <Center>
              <InfoText>
                Leader: {project.consultant[0] && project.consultant[0].name}
              </InfoText>
              <InfoText>
                Access: {(project.access && project.access.length) || 0}
              </InfoText>
            </Center>
          </TopContainer>
          <BottomContainer>
            <BottomCenter>
              <InfoText>
                Candidates: {(project.users && project.users.length) || 0}
              </InfoText>
              <InfoText>
                Templates:{' '}
                {(project.templates && project.templates.length) || 0}
              </InfoText>
            </BottomCenter>

            <DateContainer>
              <CreatedAtDate>
                {project.addedAt && displayDateAndTime(project.addedAt)}
              </CreatedAtDate>
            </DateContainer>
          </BottomContainer>
        </Container>
      );
    });
  };
  return renderUsers();
};

const Container = styled.div`
  position: relative;
  width: 32rem;
  height: 17rem;
  border-radius: 0.4rem;
  background: ${(props) => props.theme.color.white};
  cursor: pointer;
  padding: ${(props) => props.theme.spacing.standard};
  color: ${(props) => props.theme.color.offBlack};
  @media (max-width: 1104px) {
    width: 31rem;
  }
  @media ${device.laptop} {
    width: 30rem;
  }
  @media (max-width: 979px) {
    width: 35rem;
  }
  @media (max-width: 868px) {
    width: 33rem;
  }
  @media ${device.tablet} {
    width: 31rem;
  }
  @media (max-width: 725px) {
    width: 30rem;
  }
  @media (max-width: 669px) {
    width: 36rem;
  }
  @media ${device.mobileL} {
    width: 38rem;
  }
  @media ${device.mobileM} {
    width: 34rem;
  }
  @media ${device.mobileS} {
    width: 29rem;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  height: 55%;
  border-bottom: ${(props) => `1px solid ${props.theme.color.lightGrey}`};
  padding-bottom: ${(props) => props.theme.spacing.small};
`;

const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  height: 45%;
  padding-top: ${(props) => props.theme.spacing.small};
`;

const InfoBox = styled.div`
  position: absolute;
  height: auto;
  width: 10rem;
  right: 7%;
  bottom: 56%;
`;

const CardHeading = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  max-width: 19rem;
  white-space: nowrap;
`;

const InfoText = styled.p`
  font-size: 1.2rem;
  max-width: 19rem;
  white-space: nowrap;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: ${(props) => props.theme.spacing.small};
  padding-top: ${(props) => props.theme.spacing.extraSmall};
`;

const BottomCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: space-around;
  flex: 2;
  height: 100%;
`;

const DateContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
`;

const CreatedAtDate = styled.p`
  margin-top: 0rem;
  font-size: 1.2rem;
  font-style: italic;
  text-align: right;
  color: ${(props) => props.theme.color.lightGrey};
`;
