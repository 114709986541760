import React, { useState } from 'react';
import styled from 'styled-components';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { InputField } from '../../shared/input/InputField';
import { TextareaField } from '../../shared/input/Textarea';
import { Modal } from '../../shared/modal/Modal';
import { Button } from '../../shared/button/Button';
import { device } from '../../../styles/device';
import { EditPreviewEmail } from './EditPreviewEmail';

export const PreviewEmail = ({ user }) => {
  const [payload, setPayload] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [emailData, setEmailData] = useState({});

  const validationSchema = Yup.object().shape({
    emailSubject: Yup.string().required('This is a required field.'),
    emailTitle: Yup.string().required('This is a required field.'),
    emailContent: Yup.string().required('This is a required field.'),
    linkDescription: Yup.string().required('This is a required field.'),
    finalGreeting: Yup.string(),
    endingLine1: Yup.string(),
    endingLine2: Yup.string(),
    endingLine3: Yup.string(),
    endingLine4: Yup.string(),
    sender: Yup.string()
      .required('This is a required field.')
      .email('This is not a valid email format.'),
  });

  const initialValues = {
    emailSubject:
      user.emailContent && user.emailContent.subject
        ? user.emailContent.subject
        : user.defaultLanguage &&
          user.defaultLanguage.emailContent &&
          user.defaultLanguage.emailContent.subject
        ? user.defaultLanguage.emailContent.subject
        : 'This is an invitation email for tests',

    emailTitle:
      user.emailContent && user.emailContent.title
        ? user.emailContent.title
        : user.defaultLanguage &&
          user.defaultLanguage.emailContent &&
          user.defaultLanguage.emailContent.title
        ? user.defaultLanguage.emailContent.title.replace(
            /{{NAME}}/g,
            user.name
          )
        : `Hello ${user.name}, we are considering you for a new position`,

    emailContent:
      user.emailContent && user.emailContent.content
        ? user.emailContent.content
        : user.defaultLanguage &&
          user.defaultLanguage.emailContent &&
          user.defaultLanguage.emailContent.content &&
          user.defaultLanguage.emailContent.content.firstBlock !== ''
        ? user.defaultLanguage.emailContent.content
        : 'Globally promote fully tested collaboration and idea-sharing for inexpensive initiatives. Dynamically reinvent customized portals before resource maximizing action items. Progressively redefine process-centric experiences with 24/365 alignments. Professionally build out-of-the-box alignments through high-quality sources. Proactively implement diverse total linkage vis-a-vis enterprise-wide communities.',
    linkDescription:
      user.emailContent && user.emailContent.activationLink
        ? user.emailContent.activationLink
        : user.defaultLanguage &&
          user.defaultLanguage.emailContent &&
          user.defaultLanguage.emailContent.activationLink
        ? user.defaultLanguage.emailContent.activationLink
        : 'Here is your activation link. Click it to access the test page.',

    finalGreeting:
      user.emailContent && user.emailContent.finalGreeting
        ? user.emailContent.finalGreeting
        : user.defaultLanguage &&
          user.defaultLanguage.emailContent &&
          user.defaultLanguage.emailContent.finalGreeting
        ? user.defaultLanguage.emailContent.finalGreeting
        : 'We are looking forward to hearing from you soon!',

    endingLine1:
      user.emailContent && user.emailContent.optionalInfo1
        ? user.emailContent.optionalInfo1
        : user.defaultLanguage &&
          user.defaultLanguage.emailContent &&
          user.defaultLanguage.emailContent.optionalInfo1
        ? user.defaultLanguage.emailContent.optionalInfo1
        : 'Kind regards',

    endingLine2:
      user.emailContent && user.emailContent.optionalInfo2
        ? user.emailContent.optionalInfo2
        : user.defaultLanguage &&
          user.defaultLanguage.emailContent &&
          user.defaultLanguage.emailContent.optionalInfo2
        ? user.defaultLanguage.emailContent.optionalInfo2
        : 'Astrid Karlsson',

    endingLine3:
      user.emailContent && user.emailContent.optionalInfo3
        ? user.emailContent.optionalInfo3
        : user.defaultLanguage &&
          user.defaultLanguage.emailContent &&
          user.defaultLanguage.emailContent.optionalInfo3
        ? user.defaultLanguage.emailContent.optionalInfo3
        : 'Navigio',

    endingLine4:
      user.emailContent && user.emailContent.optionalInfo4
        ? user.emailContent.optionalInfo4
        : user.defaultLanguage &&
          user.defaultLanguage.emailContent &&
          user.defaultLanguage.emailContent.optionalInfo4
        ? user.defaultLanguage.emailContent.optionalInfo4
        : '0706789849',

    sender:
      user.emailContent && user.emailContent.sender
        ? user.emailContent.sender
        : user.defaultLanguage &&
          user.defaultLanguage.emailContent &&
          user.defaultLanguage.emailContent.sender
        ? user.defaultLanguage.emailContent.sender
        : 'omega@navigio.se',
    edited: true,
  };

  const result = useFetch(payload);

  return (
    <>
      <Container>
        <ContentContainer>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async (values, { resetForm }) => {
              values = JSON.parse(
                JSON.stringify(values).replace(/{{NAME}}/g, user.name)
              );
              setEmailData(values);
              resetForm();
              setShowModal(true);
            }}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <InputContainer>
                  <InputField
                    label='Subject'
                    id='emailSubject'
                    type='text'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailSubject}
                    error={errors.emailSubject && touched.emailSubject}
                    message={
                      errors.emailSubject &&
                      touched.emailSubject &&
                      `${errors.emailSubject}`
                    }
                    isContactFormProfile
                  />
                  <MarginDiv />
                  <InputField
                    label='Title'
                    id='emailTitle'
                    type='text'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailTitle}
                    error={errors.emailTitle && touched.emailTitle}
                    message={
                      errors.emailTitle &&
                      touched.emailTitle &&
                      `${errors.emailTitle}`
                    }
                    isContactFormProfile
                  />
                  <MarginDiv />
                  <TextareaField
                    label='Content'
                    id='emailContent'
                    rows='8'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.emailContent}
                    error={errors.emailContent && touched.emailContent}
                    message={
                      errors.emailContent &&
                      touched.emailContent &&
                      `${errors.emailContent}`
                    }
                  />
                  <InputField
                    id='finalGreeting'
                    type='text'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.finalGreeting}
                    error={errors.finalGreeting && touched.finalGreeting}
                    message={
                      errors.finalGreeting &&
                      touched.finalGreeting &&
                      `${errors.finalGreeting}`
                    }
                    isContactFormProfile
                  />
                  <MarginDiv />
                  <LinkContainer>
                    <InputField
                      id='linkDescription'
                      type='text'
                      label='Activation link'
                      placeholder=''
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.linkDescription}
                      error={errors.linkDescription && touched.linkDescription}
                      message={
                        errors.linkDescription &&
                        touched.linkDescription &&
                        `${errors.linkDescription}`
                      }
                      isContactFormProfile
                    />
                    <ActivationLink>
                      {user && user.omegaCandidateAccessToken
                        ? 'https://omega.navigio.se/omega/choose-password/?token=' +
                          user.omegaCandidateAccessToken
                        : 'No token available'}
                    </ActivationLink>
                  </LinkContainer>
                  <MarginDiv />
                  <InputField
                    id='endingLine1'
                    type='text'
                    label='Optional information'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.endingLine1}
                    error={errors.endingLine1 && touched.endingLine1}
                    message={
                      errors.endingLine1 &&
                      touched.endingLine1 &&
                      `${errors.endingLine1}`
                    }
                    isContactFormProfile
                  />
                  <InputField
                    id='endingLine2'
                    type='text'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.endingLine2}
                    error={errors.endingLine2 && touched.endingLine2}
                    message={
                      errors.endingLine2 &&
                      touched.endingLine2 &&
                      `${errors.endingLine2}`
                    }
                    isContactFormProfile
                  />
                  <InputField
                    id='endingLine3'
                    type='text'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.endingLine3}
                    error={errors.endingLine3 && touched.endingLine3}
                    message={
                      errors.endingLine3 &&
                      touched.endingLine3 &&
                      `${errors.endingLine3}`
                    }
                    isContactFormProfile
                  />
                  <InputField
                    id='endingLine4'
                    type='text'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.endingLine4}
                    error={errors.endingLine4 && touched.endingLine4}
                    message={
                      errors.endingLine4 &&
                      touched.endingLine4 &&
                      `${errors.endingLine4}`
                    }
                    isContactFormProfile
                  />
                  <InputField
                    id='sender'
                    type='email'
                    placeholder=''
                    label='Sender'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.sender}
                    error={errors.sender && touched.sender}
                    message={
                      errors.sender && touched.sender && `${errors.sender}`
                    }
                    isContactFormProfile
                  />
                  <ButtonContainer>
                    <ButtonInnerContainer>
                      <Button
                        type='submit'
                        disabled={isSubmitting}
                        text='Send'
                        primary
                      />
                    </ButtonInnerContainer>
                  </ButtonContainer>
                </InputContainer>
              </Form>
            )}
          </Formik>
        </ContentContainer>
      </Container>
      <Modal
        handleExit={() => setShowModal(false)}
        title='Confirm send invitation'
        show={showModal}
        width='60rem'
      >
        <EditPreviewEmail
          language={user.defaultLanguage && user.defaultLanguage.language}
          emailTitle={emailData.emailTitle}
          emailContent={emailData.emailContent}
          linkDescription={emailData.linkDescription}
          finalGreeting={emailData.finalGreeting}
          emailSubject={emailData.emailSubject}
          disableEdit
        />
        <PaddingBetweenButton />
        <Button
          onClick={() => {
            setPayload({
              endpoint: '/api/omega/users/send-invitation-email',
              method: 'post',
              data: JSON.stringify({
                email: user.email,
                omegaCandidateAccessToken: user.omegaCandidateAccessToken,
                ...emailData,
              }),
              headers: {
                'Content-Type': 'application/json',
              },
            });
            setShowModal(false);
            setShowResponseModal(true);
          }}
          text='Yes, send invitation'
          primary
        />
        <PaddingBetweenButton />
        <Button onClick={() => setShowModal(false)} secondary text='Cancel' />
      </Modal>
      <Modal
        show={showResponseModal}
        handleExit={setShowResponseModal}
        title='Send e-mail to candidate'
        width='40rem'
      >
        <ModalContent>
          {result && result.hasError ? (
            <Description>Something went wrong, try again.</Description>
          ) : (
            <Description>
              You have successfully sent an invitation email to this candidate!
            </Description>
          )}
        </ModalContent>
        <Button
          onClick={() => setShowResponseModal(false)}
          secondary
          text='OK'
        />
      </Modal>
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => props.theme.spacing.extraLarge};
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};

  @media ${device.mobileL} {
    margin-top: 0rem;
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;

const ContentContainer = styled.div`
  min-width: 78rem;
  margin: 6rem 0 3rem 0;
  @media ${device.laptop} {
    min-width: 100%;
  }
`;

const InputContainer = styled.div`
  width: 100%;
`;

const LinkContainer = styled.div``;

const Description = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const ActivationLink = styled.h3`
  text-decoration: underline;
  font-size: 1.5rem;
  color: ${(props) => props.theme.color.mediumBlue};
  margin-bottom: ${(props) => props.theme.spacing.standard};
  padding-left: ${(props) => props.theme.spacing.small};
`;

const MarginDiv = styled.div`
  height: 3rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const ButtonInnerContainer = styled.div`
  display: flex;
  width: 27.1rem;
  padding-top: ${(props) => props.theme.spacing.extraLarge};
  padding-bottom: ${(props) => props.theme.spacing.extraLarge};
  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding-bottom: 0rem;
  }
`;

const ModalContent = styled.div`
  font-size: 2rem;
  padding-bottom: ${(props) => props.theme.spacing.standard};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const PaddingBetweenButton = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;
