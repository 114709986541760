import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from '../../../../styles/device';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '../../../shared/button/Button';
import { InputField } from '../../../shared/input/InputField';
import { SelectInput } from '../../../shared/input/SelectInput';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { Modal } from '../../../shared/modal/Modal';
import { TableInput } from './TableInput';

export const Settings = ({ project, setProject, hasReadOnly }) => {
  const [showModal, setShowModal] = useState(false);
  const [consultants, setConsultans] = useState([]);
  const [consultantsForAccess, setConsultansForAccess] = useState([]);
  const [consultant, setConsultant] = useState('');
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectedAccess, setSelectedAccess] = useState([]);
  const [testTemplates, setTestTemplates] = useState([]);
  const [selectedTestTemplates, setSelectedTestTemplates] = useState([]);
  const [positions, setPositions] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [payload, setPayload] = useState({});

  const { data } = useFetch(payload);

  useEffect(() => {
    if (project && project.access) {
      setSelectedAccess(
        project.access.map((consultant) => ({
          _id: consultant._id,
          name: consultant.name,
          email: consultant.email,
        }))
      );
    }

    if (project && project.templates) {
      setSelectedTemplates(
        project.templates.map((template) => ({
          _id: template._id,
          title: template.title,
        }))
      );
    }

    if (project && project.users) {
      setSelectedCandidates(
        project.users.map((user) => ({
          _id: user._id,
          title: user.name,
          email: user.email,
        }))
      );
    }

    if (project && project.consultant) {
      setConsultant((project.consultant[0] && project.consultant[0]._id) || '');
    }

    if (project && project.testTemplates) {
      setSelectedTestTemplates(
        project.testTemplates.map((template) => ({
          _id: template._id,
          title: template.title,
        }))
      );
    }

    if (project && project.positions) {
      setSelectedPositions(
        project.positions.map((position) => ({
          _id: position._id,
          title: position.title,
        }))
      );
    }
  }, [project]);

  const sortTemplates = (data, selectedData) => {
    return data
      .map((template) => {
        if (
          selectedData.findIndex(
            (selected) => selected._id === template._id
          ) !== -1
        ) {
          return false;
        }

        return { _id: template._id, title: template.title };
      })
      .filter(Boolean);
  };

  const sortCandidates = (data) => {
    return data
      .map((candidate) => {
        if (
          selectedCandidates.findIndex(
            (selected) => selected._id === candidate._id
          ) !== -1
        ) {
          return false;
        }

        return {
          _id: candidate._id,
          title: candidate.name,
          email: candidate.email,
        };
      })
      .filter(Boolean);
  };

  const sortAccess = (data, _, value) => {
    const newConsultants = data
      .map((data) => {
        if (
          value === data._id ||
          (!value && data._id === consultant) ||
          selectedAccess.findIndex((selected) => selected._id === data._id) !==
            -1
        ) {
          return false;
        }

        return { _id: data._id, name: data.name, email: data.email };
      })
      .filter(Boolean);

    setConsultans((consultants) => {
      if (consultants.length) return consultants;
      return data;
    });

    return newConsultants;
  };

  const initialValues = project && {
    title: project.title,
    access: project.access,
    consultant: (project.consultant[0] && project.consultant[0]._id) || '',
    template: project.templates,
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This is a required field.'),
    consultant: Yup.string().required('This is a required field.'),
  });

  useEffect(() => {
    if (data && data.projectSaved) {
      setShowModal(true);
      setProject(data.data);
    }
  }, [data, setProject]);

  const columnWidths = ['100%'];
  const customHeaders = ['Name'];

  return (
    <>
      <Container>
        {initialValues && (
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async (values) => {
              const newProject = {
                _id: project._id,
                title: values.title,
                consultant: [consultant],
                templates: selectedTemplates,
                users: selectedCandidates,
                access: selectedAccess,
                testTemplates: selectedTestTemplates,
                positions: selectedPositions,
              };
              setPayload({
                method: 'patch',
                data: newProject,
                endpoint: '/api/omega/projects',
                headers: {
                  'Content-Type': 'application/json',
                },
              });
            }}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <InnerContainer>
                  <InputField
                    label='Title'
                    id='title'
                    type='text'
                    name='title'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    error={errors.title && touched.title}
                    message={errors.title && touched.title && `${errors.title}`}
                    disabled={hasReadOnly}
                  />
                  <SelectInput
                    label='Consultant'
                    id='consultant'
                    placeholder='Select consultant:'
                    name='consultant'
                    onChange={({ target }) => {
                      setConsultant(target.value);
                      setConsultansForAccess(
                        sortAccess(consultants, false, target.value)
                      );
                    }}
                    onBlur={handleBlur}
                    value={consultant}
                    error={errors.consultant && touched.consultant}
                    message={
                      errors.consultant &&
                      touched.consultant &&
                      `${errors.consultant}`
                    }
                    data={consultants.length && consultants}
                    disabled={hasReadOnly}
                  />

                  <TableInput
                    showHeaders
                    searchBar
                    label='Candidates'
                    content={!hasReadOnly && candidates}
                    setContent={setCandidates}
                    selectedContent={selectedCandidates}
                    setSelectedContent={setSelectedCandidates}
                    sortSelected={sortCandidates}
                    columnWidths={['40%', '60%']}
                    customHeaders={['Name', 'Email']}
                    noMargin
                    scrollOverflow
                    endpoint='/api/omega/users'
                    disabled={hasReadOnly}
                    padding
                  />
                  <Margin />

                  <TableInput
                    searchBar
                    label='Candidates positions'
                    content={!hasReadOnly && positions}
                    setContent={setPositions}
                    selectedContent={selectedPositions}
                    setSelectedContent={setSelectedPositions}
                    sortSelected={sortTemplates}
                    columnWidths={columnWidths}
                    noMargin
                    scrollOverflow
                    endpoint='/api/omega/positions'
                    disabled={hasReadOnly}
                  />
                </InnerContainer>

                <InnerContainer>
                  <TableInput
                    searchBar
                    label='Access'
                    content={!hasReadOnly && consultantsForAccess}
                    setContent={setConsultansForAccess}
                    selectedContent={selectedAccess}
                    setSelectedContent={setSelectedAccess}
                    columnWidths={['50%', '50%']}
                    customHeaders={customHeaders}
                    sortSelected={sortAccess}
                    noMargin
                    scrollOverflow
                    endpoint='/api/omega/users/permissions'
                    disabled={hasReadOnly}
                    padding
                  />

                  <Margin />

                  <TableInput
                    searchBar
                    label='Report templates'
                    content={!hasReadOnly && templates}
                    setContent={setTemplates}
                    selectedContent={selectedTemplates}
                    setSelectedContent={setSelectedTemplates}
                    columnWidths={columnWidths}
                    customHeaders={customHeaders}
                    sortSelected={sortTemplates}
                    noMargin
                    scrollOverflow
                    endpoint='/api/omega/report-template'
                    disabled={hasReadOnly}
                  />

                  <Margin />
                  {project && project.isProject && (
                    <TableInput
                      searchBar
                      label='Test templates'
                      content={!hasReadOnly && testTemplates}
                      setContent={setTestTemplates}
                      selectedContent={selectedTestTemplates}
                      setSelectedContent={setSelectedTestTemplates}
                      sortSelected={sortTemplates}
                      columnWidths={columnWidths}
                      customHeaders={customHeaders}
                      noMargin
                      scrollOverflow
                      endpoint='/api/omega/templates'
                      disabled={hasReadOnly}
                    />
                  )}

                  {!hasReadOnly && (
                    <ButtonContainer>
                      <Button
                        primary
                        type='button'
                        disabled={isSubmitting}
                        onClick={handleSubmit}
                        text={`Save ${
                          project && project.isProject ? 'project' : 'group'
                        }`}
                      />
                    </ButtonContainer>
                  )}
                </InnerContainer>
              </Form>
            )}
          </Formik>
        )}
      </Container>
      <Modal
        handleExit={() => setShowModal(false)}
        title='Changes have been saved'
        show={showModal}
        width='40rem'
      >
        <Button onClick={() => setShowModal(false)} text='OK' primary />
      </Modal>
    </>
  );
};

const Container = styled.div`
  margin-top: 4rem;
  margin-bottom: ${(props) => props.theme.spacing.large};
  padding-bottom: 5rem;
  @media ${device.mobileL} {
    margin-top: 3rem;
    margin-bottom: 0rem;
  }
`;

const InnerContainer = styled.div`
  width: 50%; // ändra pga device
  @media ${device.tablet} {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 100%;
  padding: 0rem 5%;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  @media ${device.tablet} {
    gap: 1.5rem;
    flex-direction: column;
  }
`;

const Margin = styled.div`
  height: ${(props) => props.theme.spacing.extraLarge};
`;

const ButtonContainer = styled.div`
  position: fixed;
  width: 15rem;
  right: 2rem;
  bottom: 2rem;
`;
