import React from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';
import { Question } from '../question/Question';

export const Test = ({ questionsData, language, submitAnswer }) => {
  const { questions, currentAnswers } = questionsData;

  return (
    <Container>
      {questions &&
        questions.length &&
        questions.map((question, key) => {
          const currentAnswer = currentAnswers[question._id];
          let sectionTitle = '';
          if (questionsData.sections && !questions[0].type.omega) {
            sectionTitle = questionsData.sections.map((section) => {
              if (section.sectionIndex === key) {
                let text = '';
                let engText = '';
                section.title.forEach((title) => {
                  if (title.language === language.code) {
                    text = title.text;
                  } else if (title.language === 'EN') {
                    engText = title.text;
                  }
                });
                return (
                  <SectionTitle key={text}>
                    {text ? text : engText ? engText : section.title[0].text}
                  </SectionTitle>
                );
              }
              return '';
            });
          }
          return (
            <React.Fragment key={key}>
              {sectionTitle}
              <Question
                category={questionsData.category}
                language={language}
                question={question}
                currentAnswer={currentAnswer}
                key={key}
                questionKeyOnPage={key}
                submitAnswer={submitAnswer}
                allowedAnswers={questionsData.allowedAnswers}
              />
            </React.Fragment>
          );
        })}
    </Container>
  );
};

const Container = styled.div`
  width: 80rem;
  @media ${device.tablet} {
    width: 100%;
    padding: 0 1.5rem;
  }
`;

const SectionTitle = styled.div`
  font-style: bold;
  font-weight: 500;
  font-size: 3.5rem;
`;
