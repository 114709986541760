import React, { useEffect, createContext, useReducer, useContext } from 'react';
import styled from 'styled-components';
import { device } from '../../styles/device';
import { TestTable } from '../../components/candidate/profile/TestTable';
import { UploadCV } from '../../components/candidate/profile/UploadCV';
import { UploadedCV } from '../../components/candidate/profile/UploadedCV';
import { ContentContext } from '../../helpers/contentContext';

export const UserContext = createContext(null);

export const CandidateProfile = ({ data }) => {
  const content = useContext(ContentContext) || {};
  const reducer = (user, action) => {
    switch (action.type) {
      case 'update':
        return { ...user, ...action.payload };
      default:
        return user;
    }
  };

  const [user, dispatch] = useReducer(reducer);
  useEffect(() => {
    if (data) {
      dispatch({ type: 'update', payload: data });
    }
  }, [data, dispatch]);

  return (
    <>
      {user && (
        <UserContext.Provider value={dispatch}>
          <Container>
            {user.cv ? <UploadedCV cv={user.cv} /> : <UploadCV />}
            <TextContainer>
              <Title>{content.welcomeHeadline}</Title>
              <IntroText>{content.welcomeText}</IntroText>
              <Padding />
              <IntroText> {content.welcomeGoodluck}</IntroText>
            </TextContainer>
            <TestTable
              user={user}
              tests={(user.omegaTests && user.omegaTests) || null}
            />
          </Container>
        </UserContext.Provider>
      )}
    </>
  );
};

const Container = styled.div`
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.01em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 11rem;
`;

const Title = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.1rem;
  padding: 8rem 0 2.5rem 0;
  @media ${device.mobileL} {
    font-size: 2.9rem;
    line-height: 3.8rem;
  }
`;

const IntroText = styled.h2`
  font-size: 2.2rem;
  line-height: 160%;
  white-space: pre-line;
  @media ${device.mobileL} {
    font-size: 1.9rem;
    line-height: 2.5rem;
  }
`;

const Padding = styled.div`
  height: 3rem;
`;

const TextContainer = styled.div`
  max-width: 65rem;
  padding-bottom: 8rem;
  word-wrap: none;
  height: fit-content;
  @media ${device.tablet} {
    padding: 0 2rem 10rem 2rem;
  }
`;
