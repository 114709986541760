import React from 'react';
import styled from 'styled-components';
import { TableItem } from './TableItem';
import { TableHeader } from './TableHeader';
import { NoResultsFound } from '../error-page-components/NoResultsFound';

export const StandardTable = ({
  type,
  items,
  onHeaderClick,
  onItemClick,
  dropDownOptions,
  icon,
  iconActive,
}) => {
  return (
    <Table>
      <TableHeader type={type} onClick={onHeaderClick} />
      {items && items.length === 0 ? (
        <NoResultsFound />
      ) : (
        items.map((item, index) => {
          return (
            <TableItem
              type={type}
              key={index}
              tabIndex={index}
              item={item}
              onItemClick={onItemClick}
              dropDownOptions={dropDownOptions}
              icon={icon}
              iconActive={iconActive}
            />
          );
        })
      )}
    </Table>
  );
};

const Table = styled.div`
  display: grid;
  margin-top: ${(props) => props.theme.spacing.large};
`;
