import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from '../../../../styles/device';
import { Formik } from 'formik';
import { Button } from '../../../shared/button/Button';
import { InputField } from '../../../shared/input/InputField';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { Modal } from '../../../shared/modal/Modal';
import { minusBox, plusBlack } from '../../../shared/assets/icons';

export const CriticalSuccessFactors = ({
  project,
  setProject,
  hasReadOnly, //TODO: fix this
}) => {
  const [showModal, setShowModal] = useState(false);
  const [criticalSuccessFactors, setCriticalSuccessFactors] = useState(['']);
  const [errorText, setErrorText] = useState('');
  const [payload, setPayload] = useState({});

  useEffect(() => {
    if (
      project &&
      project.criticalSuccessFactors &&
      project.criticalSuccessFactors.length
    ) {
      setCriticalSuccessFactors(project.criticalSuccessFactors);
    }
  }, [project]);

  const { data } = useFetch(payload);

  const addFactor = () => {
    setCriticalSuccessFactors([...criticalSuccessFactors, '']);
  };

  const removeFactor = (index) => {
    criticalSuccessFactors.splice(index, 1);
    if (!criticalSuccessFactors.length) return setCriticalSuccessFactors(['']);

    setCriticalSuccessFactors([...criticalSuccessFactors]);
  };

  const factorChange = (target, index) => {
    criticalSuccessFactors[index] = target.value;
    setCriticalSuccessFactors([...criticalSuccessFactors]);
  };

  useEffect(() => {
    if (data && data.projectSaved) {
      setShowModal(true);

      setProject((project) => {
        project.criticalSuccessFactors = data.data;
        return { ...project };
      });
    }
  }, [data, setProject]);

  return (
    <>
      <Container>
        <Formik
          initialValues={{}}
          onSubmit={async () => {
            const newProject = {
              _id: project._id,
              criticalSuccessFactors,
            };
            let error = false;
            let errorText = 'Missing text on row: ';

            criticalSuccessFactors.forEach((factor, i) => {
              if (!factor) {
                error = true;
                errorText += `${i + 1}, `;
              }
            });

            if (error && criticalSuccessFactors.length > 1) {
              setErrorText(errorText);
              setShowModal(true);
              return;
            }

            setErrorText('');
            setPayload({
              method: 'patch',
              data: newProject,
              endpoint: '/api/omega/projects/critical-success-factors',
              headers: {
                'Content-Type': 'application/json',
              },
            });
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <InnerContainer>
                {criticalSuccessFactors.map((factor, index) => (
                  <InputContainer key={index} readOnly={hasReadOnly}>
                    <Number>{index + 1}</Number>
                    <div>
                      <InputField
                        value={factor}
                        onChange={({ target }) => factorChange(target, index)}
                        disabled={hasReadOnly}
                      />
                    </div>
                    {!hasReadOnly && (
                      <Icon
                        alignEnd
                        big
                        onClick={() => removeFactor(index)}
                        src={minusBox}
                      />
                    )}
                  </InputContainer>
                ))}
                {!hasReadOnly && (
                  <>
                    <Margin />
                    <ButtonContainer>
                      <Icon src={plusBlack} onClick={addFactor} />
                      <Button
                        primary
                        type='submit'
                        disabled={isSubmitting}
                        text={`Save ${
                          project && project.isProject ? 'project' : 'group'
                        }`}
                      />
                    </ButtonContainer>
                  </>
                )}
              </InnerContainer>
            </Form>
          )}
        </Formik>
      </Container>
      <Modal
        handleExit={() => setShowModal(false)}
        title={errorText ? 'Error' : 'Changes have been saved'}
        show={showModal}
        width='40rem'
      >
        <ModalText>{errorText}</ModalText>
        <Button onClick={() => setShowModal(false)} text='OK' primary />
      </Modal>
    </>
  );
};

const Container = styled.div`
  margin-top: 4rem;
  margin-bottom: ${(props) => props.theme.spacing.large};
  @media ${device.mobileL} {
    margin-top: 3rem;
    margin-bottom: 0rem;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 100%;
  padding: 0rem 5%;
  display: flex;
  flex-direction: row;
  gap: 3rem;
  @media ${device.tablet} {
    gap: 1.5rem;
    flex-direction: column;
  }
`;

const Margin = styled.div`
  height: ${(props) => props.theme.spacing.extraLarge};
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 2fr ${(props) => !props.readOnly && ' 0.1fr'};
  width: 100%;
  gap: 3rem;
  margin-bottom: 2rem;
  align-items: center;
`;

const Icon = styled.img`
  height: 3rem;
  width: 3rem;
  margin-left: ${(props) => props.theme.spacing.standard};
  ${(props) => props.alignEnd && 'margin-left: auto;'}
  ${(props) => props.onClick && 'cursor: pointer;'}
  ${(props) => props.big && 'height: 6rem; width: 6rem; '}
`;

const Number = styled.div`
  font-size: 3rem;
`;

const ModalText = styled.div`
  font-size: 1.6rem;
  margin-bottom: 1rem;
`;
