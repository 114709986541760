import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../styles/device';

export const QuestionList = ({
  questions,
  setChosenQuestion,
  chosenQuestion,
}) => {
  const handleOnClick = (question) => {
    setChosenQuestion(question);
  };
  if (questions && questions.length > 0) {
    return (
      <Container>
        <QuestionUl questions={questions}>
          {questions.map((question, index) => {
            const choosenQuestionId =
              (chosenQuestion && chosenQuestion) || chosenQuestion || '';

            return (
              <QuestionLi
                active={choosenQuestionId === question}
                key={index}
                onClick={() => handleOnClick(question)}
              >
                {question}
              </QuestionLi>
            );
          })}
        </QuestionUl>
      </Container>
    );
  } else {
    return (
      <Container>
        <Text>No questions to choose from.</Text>
      </Container>
    );
  }
};

const Container = styled.div``;

const QuestionUl = styled.ul`
  width: 100%;
  background: ${(props) => props.theme.color.bgGrey};
  font-size: 1.6rem;
  line-height: 4rem;
  border-radius: 0.4rem;
  overflow-y: scroll;
  height: 21.5rem;
  padding: ${(props) => props.theme.spacing.extraSmall};
  @media ${device.mobileM} {
    font-size: 1.4rem;
  }
`;
const QuestionLi = styled.li`
  width: 100%;
  height: 4rem;
  padding-left: ${(props) => props.theme.spacing.small};
  border-radius: 0.4rem;

  &:hover {
    background: ${(props) => props.theme.color.selectedGrey};
    color: ${(props) => props.theme.color.white};
    cursor: pointer;
    border-radius: 0.4rem;
  }

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.color.selectedGrey};
      color: ${(props) => props.theme.color.white};
      border-radius: 0.4rem;
    `}
`;
const Text = styled.h3`
  font-size: 1.6rem;
`;
