import React from 'react';
import styled from 'styled-components';

export const ImportCandidate = () => {
  return <Container>Import candidate </Container>;
};

const Container = styled.div`
  width: 100%;
`;
