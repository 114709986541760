import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NoResultsFound } from '../../../components/shared/error-page-components/NoResultsFound';
import { SearchBar } from '../../../components/shared/search-bar/SearchBar';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { StandardTable } from '../../../components/shared/standard-table/StandardTable';
import { Tabs } from '../../../components/admin/reports/projects/Tabs';
import { CreateNew } from '../../../components/admin/settings/CreateNew';
import { SettingsModal } from '../../../components/admin/settings/SettingsModal';
import { ArchiveModal } from '../../../components/admin/settings/ArchiveModal';
import { Button } from '../../../components/shared/button/Button';
import { userHasAccess } from '../../../../src/helpers/permissions';

const itemsPerPage = 25;

export const ViewAllSettings = ({ history }) => {
  const [payload, setPayload] = useState({});
  const [endpoint, setEndpoint] = useState('');
  const [objects, setObjects] = useState([]);
  const [objectToUpdate, setObjectToUpdate] = useState({});
  const [moreObjectsAvailable, setMoreObjectsAvailable] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showCreateNewModal, setShowCreateNewModal] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [currentObjectId, setCurrentObjectId] = useState('');
  const [archiveEndpoint, setArchiveEndpoint] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [params, setParams] = useState({
    searchString: '',
    sortPropName: '',
    sortHeading: null,
    skip: 0,
    limit: itemsPerPage,
  });
  const method = 'get';
  const [tabs, setTabs] = useState({});

  const [titleList, setTitleList] = useState({});

  const [endpointsList, setEndpointsList] = useState({});

  useEffect(() => {
    let id = '';
    const tempTabs = {};
    const titles = {};
    const endpoints = {};

    if (userHasAccess('CLUSTERS') === 'crud') {
      id = 'Clusters';
      tempTabs.Clusters = 'Clusters';
      titles.Clusters = 'cluster';
      endpoints.Clusters = 'clusters';
    }
    if (userHasAccess('FACETS') === 'crud') {
      if (!id) id = 'Facets';
      tempTabs.Facets = 'Facets';
      titles.Facets = 'facets';
      endpoints.Facets = 'facets';
    }

    if (userHasAccess('USERS') === 'crud') {
      if (!id) id = 'Users';
      tempTabs.Users = 'Users';
      titles.Users = 'users';
      endpoints.Users = 'users/permissions';
    }

    setCurrentId(id);
    setTabs(tempTabs);
    setTitleList(titles);
    setEndpointsList(endpoints);
  }, []);

  const { data, isLoading } = useFetch(payload);

  useEffect(() => {
    setPayload({ endpoint, method, params });
  }, [params, endpoint, method]);

  useEffect(() => {
    setUpdateInProgress(false);
    if (data && data.data) {
      setObjects((objects) => {
        return data.data.length
          ? [...objects, ...data.data]
          : objects.length
          ? objects
          : [];
      });
    }
  }, [data]);

  useEffect(() => {
    const count = data && data.count;
    const moreQuestionsAvailable = count && params.limit + params.skip < count;
    setMoreObjectsAvailable(moreQuestionsAvailable);
  }, [data, params.skip, params.limit]);

  useEffect(() => {
    if (currentId) {
      setTitle(titleList[currentId]);
      setEndpoint(`/api/omega/${endpointsList[currentId]}`);
      setObjects([]);
      setParams((params) => {
        if (params.skip)
          return {
            ...params,
            skip: 0,
            limit: itemsPerPage,
          };

        return params;
      });
    }

    switch (currentId) {
      case 'Users':
        setType('custom-user-permissions');
        break;
      default:
        setType('title-only');
        break;
    }
  }, [currentId, titleList, endpointsList, type]);

  const sortByProp = (propName) => {
    setUpdateInProgress(true);
    if (!propName) return false;
    //clear users array
    setObjects([]);
    const skip = params.sortPropName !== propName ? 0 : params.skip;

    //if third press on same heading, clear the sorting
    if (params.sortHeading === -1 && propName === params.sortPropName) {
      return setParams({ ...params, sortHeading: '', sortPropName: '', skip });
    }

    //if not cleared, heading will be set to 1 on first click and -1 on second click
    const sortHeading =
      propName === params.sortPropName && params.sortHeading === 1 ? -1 : 1;
    const sortPropName = propName;
    return setParams({ ...params, sortHeading, sortPropName, skip });
  };

  const searchEntered = (s) => {
    setUpdateInProgress(true);
    //clear users array
    setObjects([]);
    const skip = 0;
    return setParams({ ...params, searchString: s, skip });
  };

  const openSettings = (id) => {
    const object = objects.find((obj) => obj._id === id);
    switch (currentId) {
      case 'Clusters':
      case 'Facets':
        return history.push(`/admin/settings/edit-${title}/${object.slug}`, {
          slug: object.slug,
        });
      case 'Users':
        setObjectToUpdate(object);
        setShowSettingsModal(true);
        break;
      default:
        return null;
    }
  };

  const moveAftherCreate = (slug) => {
    switch (currentId) {
      case 'Clusters':
      case 'Facets':
        return history.push(`/admin/settings/edit-${title}/${slug}`, {
          slug,
        });
      default:
        return null;
    }
  };

  const openDeleteModal = (id) => {
    switch (currentId) {
      case 'Clusters':
      case 'Facets':
        setArchiveEndpoint(`${endpoint}/archive/${id}`);
        break;
      case 'Users':
        setArchiveEndpoint(`/api/omega/users/delete/${id}`);
        break;
      default:
        return null;
    }

    setShowDeleteModal(true);
    setCurrentObjectId(id);
  };
  const dropDownOptions = [
    {
      title: 'Edit',
      onClick: openSettings,
    },
    {
      title: 'Delete',
      onClick: openDeleteModal,
      delete: true,
    },
  ];

  const showMore = () => {
    const skip = params.skip + itemsPerPage;
    return setParams({ ...params, skip });
  };

  const renderContent = () => {
    return (
      <>
        <ListContainer>
          {objects.length > 0 ? (
            <StandardTable
              type={type}
              items={objects}
              onHeaderClick={sortByProp}
              dropDownOptions={dropDownOptions}
            />
          ) : (
            objects.length > 0 &&
            type === 'custom-user-permissions' && (
              <StandardTable
                type={type}
                items={objects}
                onHeaderClick={sortByProp}
                dropDownOptions={dropDownOptions}
              />
            )
          )}
          {!updateInProgress && objects.length === 0 && !isLoading && (
            <NoResultsFound />
          )}
        </ListContainer>
        {moreObjectsAvailable && (
          <Button onClick={showMore} text='Show more' primary />
        )}
        <CreateNew
          type={type}
          title={title}
          endpoint={endpoint}
          showModal={showCreateNewModal}
          setShowModal={setShowCreateNewModal}
          moveAftherCreate={moveAftherCreate}
          setData={setObjects}
        />

        {showSettingsModal && (
          <SettingsModal
            type={type}
            title={title}
            object={objectToUpdate}
            setObjects={setObjects}
            showModal={showSettingsModal}
            setShowModal={setShowSettingsModal}
            endpoint={endpoint}
          />
        )}
        {showDeleteModal && (
          <ArchiveModal
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            title={title}
            endpoint={archiveEndpoint}
            id={currentObjectId}
            setObjects={setObjects}
          />
        )}
      </>
    );
  };

  return (
    <Container>
      <SearchBar
        searchString={params.searchString}
        onChange={searchEntered}
        placeholder={'Search'}
      />
      {(currentId && (
        <Tabs
          tabsObject={tabs}
          currentId={currentId}
          setCurrentId={setCurrentId}
          renderContent={renderContent}
        />
      )) || <>Something went wrong</>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  flex-direction: column;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 7rem;
`;

const ListContainer = styled.div`
  width: 100%;
  margin-bottom: 7rem;
`;
