import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../../styles/device';

export const TypeButton = ({ chosenType, type, active, onClick }) => {
  return (
    <Container
      chosenType={chosenType}
      type={type}
      active={active}
      onClick={onClick}
    >
      {active ? <Icon src={type.activeIcon} /> : <Icon src={type.icon} />}
      <Text active={active}>{type.text}</Text>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.color.bgGrey};
  width: 47%;
  padding: ${(props) => props.theme.spacing.extraSmall};
  border-radius: 0.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  &:nth-child(2n + 1) {
    margin-right: 1rem;
  }
  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.color.selectedGrey};
    `}
  display: flex;
  height: 5rem;
  align-items: center;
  border-radius: 0.4rem;
  margin-bottom: ${(props) => props.theme.spacing.small};
`;

const Icon = styled.img`
  padding-left: 0.5rem;
  @media (max-width: 800px) {
    padding-left: 0;
  }
`;

const Text = styled.span`
  padding-left: ${(props) => props.theme.spacing.small};
  font-size: 1.6rem;
  color: ${(props) => props.theme.color.textGrey};
  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.color.white};
    `}
  
  @media ${device.mobileL} {
    font-size: 1.4rem;
    padding-left: ${(props) => props.theme.spacing.extraSmall};
  }
`;
