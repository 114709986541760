import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';
import { CandidatePageButton } from '../../../components/shared/button/CandidatePageButton';
import { useFetch } from '../../../helpers/hooks/fetchData';
import history from '../../../history';
import { ContentContext } from '../../../helpers/contentContext';
import { getIndexFromPath } from '../../../helpers/common';

export const ThankYouPage = (location) => {
  const index = getIndexFromPath(location.location.pathname);
  const content = useContext(ContentContext) || {};
  const endpoint = '/api/omega/candidate-test/finish-test';
  const method = 'get';
  const [timeIsUp, setTimeIsUp] = useState(false);
  const [payload, setPayload] = useState({});
  useEffect(() => {
    setPayload({ endpoint, method, params: { index } });
  }, [method, index]);

  const { data, isLoading } = useFetch(payload);
  useEffect(() => {
    if (data && data.questions) {
      return history.push(`/omega/candidate/active-test/${index}`);
    }
    if (data && data.timeIsUp) {
      setTimeIsUp(true);
    }

    if (!isLoading && isLoading !== undefined && !data) {
      return history.push('/omega/candidate/home');
    }
  }, [data, isLoading, index]);

  const testTitle =
    data && ((data.titles && data.titles[content.langCode]) || data.title);

  return (
    <>
      <Wrapper>
        {!isLoading && isLoading !== undefined && data && (
          <ContentContainer>
            {timeIsUp ? (
              <Header>{content.timesUpThankYou}</Header>
            ) : (
              <Header>{content.thankYou}</Header>
            )}
            <Text>
              {content.thankYouText1 + ' '}
              {testTitle || content.test}
              {' ' + content.thankYouText2}
            </Text>
            <Space />
            <ButtonWrapper>
              <ButtonContainer>
                <CandidatePageButton
                  text={content.navigateToStartPage}
                  onClick={() => history.push('/omega/candidate/home')}
                  primary
                />
              </ButtonContainer>
            </ButtonWrapper>
          </ContentContainer>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background: ${(props) => props.theme.color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5.5rem;
  flex-direction: column;
  font-family: 'futura-pt', sans-serif;
  letter-spacing: 0.01em;
`;

const ContentContainer = styled.div`
  margin: 0rem 2rem;
`;

const Header = styled.h3`
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.1rem;
  margin-bottom: 2rem;
`;

const Text = styled.p`
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;
  white-space: pre-line;
`;

const Space = styled.div`
  height: 12rem;
  @media ${device.mobileL} {
    height: 9rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  width: 20.4rem;
  @media ${device.mobileL} {
    width: 100%;
  }
`;
