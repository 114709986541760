import React from 'react';
import styled, { css } from 'styled-components';

export const SmallLabel = ({ text, signed, internal, marginTop }) => {
  return (
    <Text signed={signed} internal={internal} marginTop={marginTop}>
      {text}
    </Text>
  );
};

const Text = styled.div`
  font-style: normal;
  font-weight: bold;
  color: white;
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: 1.4rem;
    `}

  ${(props) =>
    props.signed &&
    css`
      background-color: ${(props) => props.theme.color.mediumBlue};
      padding: 0.7rem 1.8rem;
      width: 8.3rem;
      border-radius: 0.3rem;
      font-size: 1.4rem;
      line-height: 1.6rem;
    `}
  ${(props) =>
    props.internal &&
    css`
      background-color: ${(props) => props.theme.color.orange};
      width: 7.7rem;
      border-radius: 1.7rem;
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-align: center;
      padding: 0.2rem 0;
    `}
`;
