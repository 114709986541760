import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../styles/device';

export const OmegaItem = ({ item, type, icon, iconActive }) => {
  const [active, setActive] = useState(false);

  switch (type) {
    case 'results':
      return (
        <ContentContainer>
          <>
            {item && (
              <ItemRow results='true' key={item.title} aria-pressed='false'>
                <MediumColumn bold>
                  {'Omega Questions'}
                  <MediumColumn hideInDesktop>{item.completeDate}</MediumColumn>
                </MediumColumn>
                {item.resultsType === 'correctOrIncorrect' && (
                  <>
                    <MediumColumn>
                      {item.totalQuestions && (
                        <>
                          {item.totalCorrectAnswers} / {item.totalAnswered}
                        </>
                      )}
                    </MediumColumn>
                    <MediumColumn>{item.timeLeft}</MediumColumn>
                    <MediumColumn hide>{item.completeDate}</MediumColumn>
                    <MediumColumn hide>{item.points}</MediumColumn>
                    <MediumColumn hide>{item.pointsSecond}</MediumColumn>
                  </>
                )}
              </ItemRow>
            )}
            <MediumColumn textAlignRight>
              <Icon
                onClick={() => setActive(!active)}
                src={active ? iconActive : icon}
                alt='Icon'
              />
            </MediumColumn>
          </>
          {active &&
            item.omegaQuestions &&
            item.resultsType === 'omega' &&
            item.omegaQuestions.map((question, key) => {
              return (
                <ItemRow results='true' key={key} aria-pressed='false'>
                  <ColumnContent bold>{question.question}</ColumnContent>
                  <ColumnContent>{question.answer}</ColumnContent>
                </ItemRow>
              );
            })}
        </ContentContainer>
      );

    default:
      return <></>;
  }
};

const ItemRow = styled.div`
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: 1fr 3fr 2fr 2fr 2fr 1fr;

  ${(props) =>
    props.categories &&
    css`
      grid-template-columns: 4fr 5fr 1fr;
    `}
  ${(props) =>
    props.templates &&
    css`
      grid-template-columns: 5fr 4fr 3fr 3fr 2fr 1fr;
    `}
  ${(props) =>
    props.templateMobile &&
    css`
      display: none;
    `}

    ${(props) =>
      props.results &&
      css`
        grid-template-columns: 2fr 2fr 2fr 2fr 1fr 1fr;
      `}

      ${(props) =>
        props.test &&
        css`
          grid-template-columns: 5fr 1fr 1fr;
        `}

  @media ${device.tablet} {
    grid-template-columns: 1fr 2fr 1fr;
    ${(props) =>
      props.categories &&
      css`
        grid-template-columns: 1fr 1fr;
      `}
      ${(props) =>
        props.results &&
        css`
          grid-template-columns: 2fr 1fr 1fr;
          justify-items: flex-start;
        `}
      ${(props) =>
        props.test &&
        css`
          grid-template-columns: 1fr 1fr;
        `}
        ${(props) =>
          props.templates &&
          css`
            display: none;
          `}
    ${(props) =>
      props.templateMobile &&
      css`
        display: grid;
        grid-template-columns: 1fr 1fr;
      `}
  }


  font-size: 1.6rem;
  ${(props) =>
    props.candidate &&
    css`
      cursor: pointer;
    `}
`;

const ColumnContent = styled.div`
  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `}
  margin: 0.5rem 0rem;

  @media ${device.mobileL} {
    ${(props) =>
      props.hide &&
      css`
        display: none;
      `}
  }
`;

const MediumColumn = styled.div`

  ${(props) =>
    props.dropDown &&
    css`
      display: flex;
      justify-content: flex-end;
      margin-right: 4rem;
    `}

    ${(props) =>
      props.hideInDesktop &&
      css`
        font-weight: normal;
        display: none;
      `}

      ${(props) =>
        props.textAlignRight &&
        css`
          display: flex;
          justify-content: flex-end;
        `}

  @media ${device.tablet} {
    ${(props) =>
      props.bold &&
      css`
        font-weight: 700;
      `}
    ${(props) =>
      props.hide &&
      css`
        display: none;
      `}

      ${(props) =>
        props.hideInDesktop &&
        css`
          display: flex;
          padding-top: ${(props) => props.theme.spacing.extraSmall};
        `}

  }
`;

const ContentContainer = styled.div`
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  padding-top: ${(props) => props.theme.spacing.small};
  padding-bottom: ${(props) => props.theme.spacing.small};
  display: grid;
  min-height: 7rem;
  background-color: white;
  box-shadow: 0px -1px 0px #d6d6d6, 0px 1px 0px #d6d6d6;
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
  @media ${device.tablet} {
    &:first-of-type {
      box-shadow: 0px 0px 0px, 0px 1px 0px #d6d6d6;
    }
  }
`;

const Icon = styled.img`
  cursor: pointer;
  padding: 0.8rem;
`;
