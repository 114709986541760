import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../../styles/device';

export const ImageOption = ({ options, onClick, selected, type }) => {
  return (
    <Container>
      {options.map((option, key) => {
        return (
          <Image
            key={key}
            onClick={() =>
              onClick({
                type: type || 'image',
                value: option,
              })
            }
            src={option.image.small}
            alt='option'
            selected={selected && selected._id === option._id}
          />
        );
      })}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Image = styled.img`
  width: 23%;
  height: 12.5rem;
  margin: 1rem 0rem;
  border-radius: 0.4rem;
  object-fit: contain;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      border: 5px solid ${(props) => props.theme.color.selectedGrey};
      box-sizing: border-box;
    `}

    @media ${device.mobileL} {
        width: 48%;
       
  }
`;
