import React from 'react';
import styled from 'styled-components';
import { amountMinus, amountPlus } from '../../../shared/assets/icons';
import { Type } from './Type';
import { InputWrapper } from '../../../shared/input/InputWrapper';

export const MultipleQuestions = ({
  hasMultipleQuestions,
  setHasMultipleQuestions,
  noOfQuestions,
  setNoOfQuestions,
}) => {
  return (
    <>
      <InputWrapper marginTop title='This image contains multiple questions'>
        <Type
          chosenType={hasMultipleQuestions}
          setChosenType={setHasMultipleQuestions}
          yesOrNoOption
        />
        {hasMultipleQuestions && (
          <>
            <AmountContainer>
              <AmountButton role='button' onClick={() => setNoOfQuestions(-1)}>
                <AmountIcon src={amountMinus} alt='minus' />
              </AmountButton>
              <Amount>{noOfQuestions || 1}</Amount>
              <AmountButton role='button' onClick={() => setNoOfQuestions(1)}>
                <AmountIcon src={amountPlus} alt='plus' />
              </AmountButton>
            </AmountContainer>
          </>
        )}
      </InputWrapper>
    </>
  );
};
const AmountContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const AmountButton = styled.div`
  cursor: pointer;
`;
const AmountIcon = styled.img``;
const Amount = styled.div`
  margin-top: 0.8rem;
  margin-right: ${(props) => props.theme.spacing.standard};
  margin-left: ${(props) => props.theme.spacing.standard};
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  height: 1rem;
  width: 2rem;
  text-align: center;
`;
