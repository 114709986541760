import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Header } from '../../components/candidate/navigation/Header';
import { Footer } from '../../components/candidate/navigation/Footer';
import { device } from '../../styles/device';
import { useFetch } from '../../helpers/hooks/fetchData';
import { checkboxBlackChecked } from '../../components/shared/assets/icons';
import { checkboxBlack } from '../../components/shared/assets/icons';
import { InputPassword } from '../../components/candidate/landing/InputPassword';
import { useLocation, Redirect } from 'react-router-dom';

export const ChoosePassword = () => {
  const { search } = useLocation();
  const [payload, setPayload] = useState({});
  const [user, setUser] = useState({});
  const { data, hasError, isLoading } = useFetch(payload);
  const [agree, setAgree] = useState(false);

  useEffect(() => {
    if (search) {
      const searchArray = search.split('?token=');
      const token = searchArray[searchArray.length - 1];
      setPayload({
        method: 'get',
        endpoint: `/api/omega/candidate-public/validate-token/${token}`,
      });
    }
  }, [search]);

  useEffect(() => {
    if (data) {
      setUser(data.user && data.user);
    }
  }, [data]);

  return (
    <>
      {isLoading !== undefined && !isLoading && !hasError && (
        <>
          <Header user={user} />
          <Container>
            <ContentContainer>
              <TextContainer>
                <Title>Hi {user.name}</Title>
                By signing this document you give us your consent to store
                Personal Data about you. The data will be stored until you
                request otherwise. You can at any time request to have the data
                removed from our database. If you wish to learn more about your
                rights according to the Norwegian, Swedish or Danish Personal
                Data Act (Poolyl/PuUPersondataloven), please use the following
                link for:
                <Ul>
                  <li>
                    <Lable>Norway:</Lable>
                    <Link href='www.lovdata.no'>www.lovdata.no</Link>
                  </li>
                  <li>
                    <Lable>Sweden:</Lable>
                    <Link href='www.datainspektionen.se'>
                      www.datainspektionen.se
                    </Link>
                  </li>
                  <li>
                    <Lable>Denmark:</Lable>
                    <Link href='www.datatilsynet.dk'>www.datatilsynet.dk</Link>
                  </li>
                </Ul>
                Navigio appreciates the confidence you have given us in
                registering your Personal Data. We will handle all Personal Data
                as strictly confidential information.
              </TextContainer>
              <AgreementContainer>
                <Checkbox
                  onClick={() => setAgree(!agree)}
                  src={agree ? checkboxBlackChecked : checkboxBlack}
                />{' '}
                I agree the above
              </AgreementContainer>
              <InputPassword agree={agree} />
            </ContentContainer>
          </Container>
          <Footer />
        </>
      )}

      {isLoading !== undefined && !isLoading && hasError && (
        <>
          <Redirect to='/omega' />
        </>
      )}
    </>
  );
};

const Container = styled.div`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 0 auto;
`;

const ContentContainer = styled.div`
  max-width: 65rem;
  padding: 8rem 0rem;
  @media ${device.tablet} {
    padding: 8rem 2rem;
  }
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.1rem;
  padding-bottom: 2.5rem;
`;

const Link = styled.a`
  color: ${(props) => props.theme.color.black};
  text-decoration: underline;
  font-style: italic;
`;

const Lable = styled.div`
  display: inline-block;
  width: 10rem;
`;

const Ul = styled.ul`
  margin: 3rem 0rem;
`;

const TextContainer = styled.div`
  padding-bottom: 8rem;
  word-wrap: none;
  height: fit-content;
`;

const AgreementContainer = styled.div`
  display: flex;
  margin-bottom: 4rem;
`;
const Checkbox = styled.img`
  margin-right: 3rem;
  cursor: pointer;
`;
