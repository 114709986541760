import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { DropDownSelect } from '../languages/DropDownSelect';
import { device } from '../../../styles/device';

export const DefaultLanguage = ({
  defaultLanguage,
  setDefaultLanguage,
  absolute,
  newUser,
}) => {
  const [openLanguageChoiceDropDown, setOpenLanguageChoiceDropDown] = useState(
    false
  );
  const [selectedItemText, setSelectedItemText] = useState(
    (defaultLanguage && defaultLanguage.language) || 'Select language'
  );
  const [activeText, setActiveText] = useState(false);

  const [payload, setPayload] = useState({});
  const [languages, setLanguages] = useState([]);

  const endpoint = '/api/omega/languages';
  const method = 'get';

  const { data } = useFetch(payload);

  useEffect(() => {
    if (data && data.data) {
      setLanguages(data.data);

      if (newUser && data.data.length) {
        const swedishIx = data.data.findIndex((lang) => lang.code === 'SE');
        const lang = swedishIx !== -1 ? data.data[swedishIx] : data.data[0];
        setSelectedItemText(lang.language);
        setDefaultLanguage(lang);
      }
    }
  }, [data, newUser, setDefaultLanguage]);

  useEffect(() => {
    setPayload({ endpoint, method });
  }, [endpoint, method]);

  const selectLanguage = (id, language) => {
    setDefaultLanguage(
      languages[languages.findIndex((item) => item._id === id)]
    );
    setOpenLanguageChoiceDropDown(false);
    setSelectedItemText(language);
    setActiveText(true);
  };

  return (
    <Container absolute={absolute}>
      <DropDownSelect
        onClick={() =>
          setOpenLanguageChoiceDropDown(!openLanguageChoiceDropDown)
        }
        isRotated={openLanguageChoiceDropDown}
        selectedItemText={selectedItemText}
        activeText={activeText}
      />

      {openLanguageChoiceDropDown ? (
        <LanguageVisibleContainer absolute={absolute}>
          <Ul>
            {languages.map((language) => {
              return (
                <Li
                  key={language._id}
                  onClick={() =>
                    selectLanguage(language._id, language.language)
                  }
                >
                  {language.language}
                </Li>
              );
            })}
          </Ul>
        </LanguageVisibleContainer>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.standard};
  position: relative;
  @media ${device.tablet} {
    ${(props) =>
      props.absolute &&
      css`
        margin-bottom: ${(props) => props.theme.spacing.medium};
      `}
  }
`;

const LanguageVisibleContainer = styled.div`
  max-height: 23.3rem;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing.extraSmall};
  overflow: scroll;
  border-radius: 0.4rem;
  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      top: 7rem;
      left: 0;
      z-index: 2147483647;
      margin-top: 0;
      margin-bottom: 4rem;
    `}

    @media ${device.tablet} {
      ${(props) =>
        props.absolute &&
        css`
          position: relative;
          margin-top: ${(props) => props.theme.spacing.extraSmall};
          margin-bottom: 4rem;
          top: 0;
        `}
    }
`;

const Ul = styled.ul`
  width: 100%;
  background: ${(props) => props.theme.color.bgGrey};
  font-size: 1.6rem;
  line-height: 4rem;
  border-radius: 0.4rem;
  padding: ${(props) => props.theme.spacing.extraSmall};
  @media ${device.mobileM} {
    font-size: 1.4rem;
  }
`;
const Li = styled.li`
  width: 100%;
  height: 4rem;
  padding-left: ${(props) => props.theme.spacing.small};
  border-radius: 0.4rem;

  &:hover {
    background: ${(props) => props.theme.color.selectedGrey};
    color: ${(props) => props.theme.color.white};
    cursor: pointer;
    border-radius: 0.4rem;
  }
`;
