import React, { useState } from "react";
import styled, { css } from "styled-components";
import { device } from "../../../styles/device";
import { Chevron } from "../../../components/admin/questions/list-questions";
import { DropDownMenu } from "../../../components/shared/drop-downs";
import { AlternativeOption } from "../../../components/admin/questions/list-questions/AlternativeOption";
//import { userHasAccess } from "../../../../src/helpers/common";

export const EditPermissions = ({
  id,
  name,
  email,
  role,
  usersCustomPermissions,
  dropDownOptions
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [setActive, setActiveState] = useState(0);

  function toggleAccordion(e) {
    if (e.target.id) {
      return;
    } else {
      setIsOpen(!isOpen);
      return setActiveState(!setActive);
    }
  }

  let hasPermissions = false;
  let hasPermissionValue = false;

  return (
    <Container id={id} setActive={setActive}>
      <Content onClick={(e) => toggleAccordion(e)} setActive={setActive} >
        <Section hideInMobile>{name}</Section>
        <Section hideInMobile>{email}</Section>
        <Section hideInMobile>{role}</Section>

        {/* FlexContener is ONLY visible in mobile */}
        <FlexContainer>
          <Section showInMobile internalTitle >{name}</Section>
          <Section showInMobile categories img>{email}</Section>
          <Section>{role}</Section>
        </FlexContainer>
        {/* FlexContener is ONLY visible in mobile */}

        <ChevContainer>
          <DropDownBox>
            <DropDownMenu
              dontRender={false}
              dropDownOptions={dropDownOptions}
              id={id}
            />
          </DropDownBox>
          <DropDownBoxDesktop setActive={true}>
            <DropDownMenu dropDownOptions={dropDownOptions} dontRender={false} id={id} />
          </DropDownBoxDesktop>
          <Chevron isRotated={setActive} />
        </ChevContainer>
      </Content>

      {isOpen && usersCustomPermissions && Object.keys(usersCustomPermissions).length > 0 ? (

        <HiddenContent setActive={setActive}>
          <HiddenInner>
            <Row>
              <OptionsBox>
                <Header>
                  <Text headline>Custom Permissions</Text>
                  <Text headline>Permission Level</Text>
                  <Text headline></Text>
                </Header>
                {Object.keys(usersCustomPermissions).map((permission, key) => {
                  const permissionIsGranted = Object.keys(usersCustomPermissions).includes(permission);
                  const permissionValue = usersCustomPermissions[permission];
                  if (permissionIsGranted && permissionValue !== "none") {
                    hasPermissions = true;
                    hasPermissionValue = true;
                  }
                  return (hasPermissions && (
                    <AlternativeOption
                      key={key}
                      optionText={permission}
                      optionValue={permissionValue}
                      checked={permissionIsGranted}
                    />
                  )
                  )
                }

                )}
                {!hasPermissionValue && !hasPermissions && (
                  <Text perMissing>{"[User has no custom permissions assigned]"}</Text>
                )}
              </OptionsBox>
            </Row>
          </HiddenInner>
        </HiddenContent>
      ) : (
        <HiddenContent setActive={setActive}>
          <HiddenInner>
            <Row>
              <OptionsBox>
                <Header>
                  <Text headline>Custom Permissions</Text>
                  <Text headline>Permission Level</Text>
                  <Text headline></Text>
                </Header>
                <Text perMissing>{"[User has no custom permissions assigned]"}</Text>
              </OptionsBox>
            </Row>
          </HiddenInner>
        </HiddenContent>
      )}
    </Container>
  );
}

const Container = styled.div`
  box-shadow: 0px -1px 0px #d6d6d6, 0px 1px 0px #d6d6d6;
  background-color: ${(props) => (props.setActive ? '#f7f7f7' : 'white')};
`;
const Content = styled.div`
  display: grid;
  grid-template-columns: 35% 32% 22%;
  min-height: 7rem;
  align-items: center;
  cursor: pointer;
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  @media ${device.tablet} {
    grid-template-columns: 100%;
    display: flex;
    min-height: 8rem;
  }
  @media ${device.mobileL} {
    padding: ${(props) => props.theme.spacing.small};
  }
  position: relative;
`;
const Section = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-right: ${(props) => props.theme.spacing.standard};
  &:last-child {
    margin-right: 0;
  }

  ${(props) =>
    props.languages &&
    css`
      @media ${device.laptop} {
        display: none;
      }
    `}
  ${(props) =>
    props.category &&
    css`
      @media ${device.tablet} {
        padding-top: 0.5rem;
      }
    `}
    ${(props) =>
    props.internalTitle &&
    css`
        @media ${device.tablet} {
          font-weight: 500;
          padding-bottom: -${(props) => props.theme.spacing.extraSmall};
        }
      `}
    ${(props) =>
    props.img &&
    css`
        min-width: 5.5rem;
      `}
      ${(props) =>
    props.showInMobile &&
    css`
          display: none;

          @media ${device.tablet} {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        `}
      ${(props) =>
    props.hideInMobile &&
    css`
          @media ${device.tablet} {
            display: none;
          }
        `}

`;

const FlexContainer = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;
  padding-right: ${(props) => props.theme.spacing.medium};
  @media ${device.tablet} {
    display: flex;
  }
`;

const ChevContainer = styled.div`
  position: absolute;
  right: ${(props) => props.theme.spacing.extraLarge};
  display: flex;
  flex-direction: row;
  @media ${device.mobileL} {
    right: ${(props) => props.theme.spacing.standard};
  }
`;

const HiddenContent = styled.div`
  opacity: ${(props) => (props.setActive ? '100%' : '0%')};
  display: ${(props) => (props.setActive ? 'flex' : 'none')};
  padding-bottom: ${(props) => props.theme.spacing.large};
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  width: 100%;
  position: relative;
  @media ${device.tablet} {
    padding-bottom: ${(props) => props.theme.spacing.standard};
  }
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;

const HiddenInner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.spacing.large};
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;
const OptionsBox = styled.div`
  width: 100%;
  @media ${device.tablet} {
    width: 100%;
  }
`;
const Text = styled.p`
  font-size: ${(props) => props.theme.size.medium};
  line-height: 1.6rem;
  ${(props) =>
    props.headline &&
    css`
      padding-bottom: ${(props) => props.theme.spacing.extraSmall};
      font-weight: 600;
      font-style: italic;
    `}
  ${(props) =>
    props.perMissing &&
    css`
        padding-bottom: ${(props) => props.theme.spacing.extraSmall};
        font-weight: 500;
        font-style: normal;
      `}
`;
const DropDownBox = styled.div`
display: none;
margin-right: ${(props) => props.theme.spacing.medium};
@media only screen and (max-width: 1000px) {
  display: block;
  justify-content: flex-end;
  margin-left: auto;
}
`;
const DropDownBoxDesktop = styled.div`
  display: none;
  ${(props) =>
    props.setActive &&
    css`
      display: block;
      padding-right: ${(props) => props.theme.spacing.standard};
      @media only screen and (max-width: 1000px) {
        display: none;
      }
      @media ${device.tablet} {
        display: none;
      }
    `}
`;

const Header = styled.header`
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: 3fr 2fr 1fr 5fr;
  ${(props) =>
    props.categories &&
    css`
      grid-template-columns: 4fr 5fr 1fr;
    `}
  ${(props) =>
    props.templates &&
    css`
      grid-template-columns: 5fr 4fr 3fr 3fr 2fr 1fr;
    `} 

    ${(props) =>
    props.project &&
    css`
        grid-template-columns: 3fr 2fr 2fr 3fr 1fr;
      `}

    ${(props) =>
    props.titleOnly &&
    css`
        grid-template-columns: 9fr 1fr;
      `}

      ${(props) =>
    props.editPermissions &&
    css`
        grid-template-columns: 35% 32% 22%;
      `}
    


  height: 7rem;
  ${(props) =>
    props.categories &&
    css`
      grid-template-columns: 4fr 5fr 1fr;
    `}
  ${(props) =>
    props.results &&
    css`
      grid-template-columns: 2fr 2fr 2fr 2fr 1fr 1fr;
      box-shadow: none;
    `}

  ${(props) =>
    props.test &&
    css`
      grid-template-columns: 5fr 1fr 1fr;
      box-shadow: none;
    `}

 

  @media ${device.tablet} {
    ${(props) =>
    !props.print &&
    css`
        display: none;
      `}
  }
`;
