import React, { useState, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Thumbnail } from '../thumbnail/Thumbnail';
import { DropDownMenu } from '../drop-downs';
import { SmallLabel } from '../labels/SmallLabel';
import { AmountCircle } from '../labels/AmountCircle';
import { device } from '../../../styles/device';
import {
  getNicelyFormattedTimeFromMS,
  projectAccess,
} from '../../../helpers/common';
import { calculateProgress } from '../../../helpers/common';
import { EditPermissions } from '../../../pages/admin/settings/edit-permissions';
import {
  getNavigioRoleTitle,
  userHasAccess,
} from '../../../helpers/permissions';
import { Button } from '../button/Button';
import history from '../../../history';

const omegaRowData = (categories, title) => {
  if (title) {
    const category = categories.find(
      (_category) => _category.title.toLowerCase() === title.toLowerCase()
    );

    return (
      <ItemRow results='true' key={title} aria-pressed='false'>
        <ColumnContent bold firstLetterUppercase>
          {title}
        </ColumnContent>
        <ColumnContent>{category && category.rp}</ColumnContent>
        <ColumnContent>{Math.round(category && category.stan)}</ColumnContent>
      </ItemRow>
    );
  }
};

//TODO: kinda same as TableToPrint
const renderOmegaRows = (categories, type) => {
  if (categories && categories.length) {
    if (type === 1) {
      return (
        <Fragment>
          {omegaRowData(categories, 'social need')}
          {omegaRowData(categories, 'self confidence')}
          {omegaRowData(categories, 'balance')}
          {omegaRowData(categories, 'routine')}
          {omegaRowData(categories, 'doubt')}
          {omegaRowData(categories, 'security')}
          {omegaRowData(categories, 'thinking')}
          {omegaRowData(categories, 'capacity')}
          {omegaRowData(categories, 'persistency')}
          {omegaRowData(categories, 'contact')}
          {omegaRowData(categories, 'trust')}
          {omegaRowData(categories, 'impulsiveness')}
        </Fragment>
      );
    }

    if (type === 2) {
      return (
        <Fragment>
          {omegaRowData(categories, 'backing')}
          {omegaRowData(categories, 'empathy')}
          {omegaRowData(categories, 'charity')}
          {omegaRowData(categories, 'influence')}
          {omegaRowData(categories, 'power')}
          {omegaRowData(categories, 'self dependence')}
          {omegaRowData(categories, 'prestige')}
          {omegaRowData(categories, 'competition')}
          {omegaRowData(categories, 'failure avoidance')}
          {omegaRowData(categories, 'fighting spirit')}
          {omegaRowData(categories, 'change')}
          {omegaRowData(categories, 'cost benefit')}
          {omegaRowData(categories, 'challenge')}
          {omegaRowData(categories, 'incisiveness')}
          {omegaRowData(categories, 'structure')}
          {omegaRowData(categories, 'goal dependence')}
        </Fragment>
      );
    }
  }
};

export const TableItem = ({
  item,
  type,
  tabIndex,
  onItemClick,
  dropDownOptions,
  icon,
  iconActive,
}) => {
  let progress;
  const [active, setActive] = useState(false);
  let access;

  const arrowButton = (
    <Fragment>
      <MediumColumn />
      <MediumColumn textAlignRight>
        <Icon
          onClick={() => setActive(!active)}
          src={icon}
          alt='Icon'
          active={active}
        />
      </MediumColumn>
    </Fragment>
  );

  const renderQuestionArray = (answers, question, quesitonIndex) => {
    let tempArray = [];

    answers.forEach((answer, index) => {
      if (Array.isArray(answer.answerValue)) {
        tempArray = [
          ...tempArray,
          ...renderQuestionArray(answer.answerValue, question, index),
        ];
      } else {
        tempArray.push(
          <ItemRow
            test='true'
            key={answer.text ? answer.text : answer + index}
            aria-pressed='false'
          >
            <ColumnContent bold>
              {question.question[quesitonIndex >= 0 ? quesitonIndex : index]}
            </ColumnContent>
            <ColumnContent>
              {answer.text
                ? answer.text
                : answer.answerValue.text
                ? answer.answerValue.text
                : answer.answerValue.number
                ? answer.answerValue.number
                : answer.answerValue}
            </ColumnContent>
          </ItemRow>
        );
      }
    });

    return tempArray;
  };

  switch (type) {
    case 'users':
      progress = calculateProgress(item);
      return (
        <ContentContainer>
          {item && (
            <ItemRow
              candidate
              key={item._id}
              aria-pressed='false'
              tabIndex={tabIndex}
              role='button'
              onClick={() => onItemClick(item.slug)}
            >
              <ThumbnailColumn>
                <Thumbnail
                  image={item.profileImage && item.profileImage.thumbnail}
                  name={item.name}
                  size='5.5'
                  progress={progress}
                  signed={item.isSigned}
                />
              </ThumbnailColumn>

              <MobileColumn>
                <ColumnContent bold>{item.name}</ColumnContent>
                {item.activeProjects &&
                  item.activeProjects.map((projects) => {
                    return (
                      <ColumnContent key={projects.name}>
                        {projects.name} - {projects.companyName}
                      </ColumnContent>
                    );
                  })}
              </MobileColumn>
              <MediumColumn hide>{item.name}</MediumColumn>
              <MediumColumn hide>
                {item.activeProjects &&
                  item.activeProjects.map((projects) => {
                    return (
                      <ColumnContent key={projects.name}>
                        {projects.name} - {projects.companyName}
                      </ColumnContent>
                    );
                  })}
              </MediumColumn>
              <MediumColumn hide>{progress && progress + '%'}</MediumColumn>
              <MediumColumn hide>
                {item.info && item.info.position}
              </MediumColumn>
              <MediumColumn>
                {item.isSigned && <SmallLabel signed text={'Signed'} />}
              </MediumColumn>
            </ItemRow>
          )}
        </ContentContainer>
      );
    case 'categories':
      return (
        <ContentContainer>
          {item && (
            <ItemRow
              categories
              key={item._id}
              aria-pressed='false'
              tabIndex={tabIndex}
              role='button'
            >
              <MediumColumn>
                {item.title}
                <MobileColumn>
                  {!!item.noOfQuestions && (
                    <AmountCircle amount={item.noOfQuestions} />
                  )}
                </MobileColumn>
              </MediumColumn>
              <MediumColumn hide>
                {!!item.noOfQuestions && (
                  <AmountCircle amount={item.noOfQuestions} />
                )}
              </MediumColumn>
              {userHasAccess('CATEGORIES') === 'crud' && (
                <MediumColumn dropDown>
                  <DropDownMenu
                    id={item._id}
                    dropDownOptions={dropDownOptions}
                  />
                </MediumColumn>
              )}
            </ItemRow>
          )}
        </ContentContainer>
      );
    case 'results':
      return (
        <ContentContainer>
          <>
            {item && (
              <ItemRow results='true' key={item.title} aria-pressed='false'>
                <MediumColumn bold>
                  {item.title}
                  <MediumColumn hideInDesktop>
                    {item.testCompletionDate &&
                      new Date(item.testCompletionDate).toDateString()}
                  </MediumColumn>
                </MediumColumn>
                {(item.resultsType === 'correctOrIncorrect' && (
                  <>
                    <MediumColumn>
                      {item.totalQuestions && (
                        <>
                          {item.totalCorrectAnswers} / {item.totalAnswered}
                        </>
                      )}
                    </MediumColumn>
                    <MediumColumn>
                      {(item.stanine1 && Math.round(item.stanine1)) || 0}/
                      {(item.stanine2 && Math.round(item.stanine2)) || 0}
                    </MediumColumn>
                    <MediumColumn>
                      {item.testCompletionDate &&
                        new Date(item.testCompletionDate).toDateString()}
                    </MediumColumn>
                  </>
                )) || (
                  <>
                    <MediumColumn />
                    <MediumColumn />
                    <MediumColumn>
                      {item.testCompletionDate &&
                        new Date(item.testCompletionDate).toDateString()}
                    </MediumColumn>
                    {arrowButton}
                  </>
                )}
              </ItemRow>
            )}
          </>
          {active &&
            item.questions &&
            item.resultsType === 'informationRecords' &&
            item.questions.map((question) => {
              if (
                question.records.length > 1 ||
                (question.records[0] && Array.isArray(question.records[0]))
              ) {
                return question.records.map((answers) => {
                  let tempArray = [];
                  if (Array.isArray(answers)) {
                    tempArray = [...renderQuestionArray(answers, question)];
                  } else {
                    tempArray.push(
                      <ItemRow
                        test='true'
                        key={answers.answerValue}
                        aria-pressed='false'
                      >
                        <ColumnContent bold>{question.question}</ColumnContent>
                        <ColumnContent>{answers.answerValue}</ColumnContent>
                      </ItemRow>
                    );
                  }

                  return tempArray;
                });
              }

              return (
                <ItemRow
                  test='true'
                  key={question.question}
                  aria-pressed='false'
                >
                  <ColumnContent bold>{question.question}</ColumnContent>
                  <ColumnContent>
                    {question.records[0] && question.records[0].answerValue}
                  </ColumnContent>
                </ItemRow>
              );
            })}
          {active &&
            item.categories &&
            item.resultsType === 'omega' &&
            ((item.title.includes('1') &&
              renderOmegaRows(item.categories, 1)) ||
              (item.title.includes('2') &&
                renderOmegaRows(item.categories, 2)))}
        </ContentContainer>
      );
    case 'print':
      return (
        <ContentContainer>
          {item && (
            <ItemRow results='true' key={item.name} aria-pressed='false'>
              <MediumColumn bold>
                {item.name}
                <MediumColumn hideInDesktop>{item.completeDate}</MediumColumn>
              </MediumColumn>
              <MediumColumn>{item.result}</MediumColumn>
              <MediumColumn>{item.timeLeft}</MediumColumn>
              <MediumColumn hide>{item.completeDate}</MediumColumn>
              <MediumColumn hide>{item.points}</MediumColumn>
              <MediumColumn hide>{item.pointsSecond}</MediumColumn>
            </ItemRow>
          )}
        </ContentContainer>
      );
    case 'test':
      return (
        <ContentContainer>
          {item && (
            <ItemRow test key={item.name} aria-pressed='false'>
              <MediumColumn bold>
                {item.name}
                <MediumColumn hideInDesktop>{item.completeDate}</MediumColumn>
              </MediumColumn>
              <MediumColumn hide>{item.completeDate}</MediumColumn>
              <MediumColumn textAlignRight>
                <Icon
                  onClick={() => setActive(!active)}
                  src={active ? iconActive : icon}
                  alt='Icon'
                />
              </MediumColumn>
            </ItemRow>
          )}
        </ContentContainer>
      );

    case 'templates':
      return (
        <ContentContainer>
          {item && (
            <>
              {/* This ItemRow container shows in mobile */}
              <ItemRow templateMobile>
                <MediumColumn>
                  <ColumnContent bold>{item.title}</ColumnContent>
                  <Margin />
                  <ColumnContent>
                    {item.category && item.category.title}
                  </ColumnContent>
                </MediumColumn>
                <ColumnFlexRow>
                  <ColumnContent>
                    {item.questions && item.questions.length > 0 && (
                      <AmountCircle amount={item.questions.length} />
                    )}
                  </ColumnContent>
                  <ColumnContent>
                    {(userHasAccess('TEMPLATES') === 'crud' ||
                      userHasAccess('TEXT') === 'crud') && (
                      <DropDownMenu
                        id={item._id}
                        dropDownOptions={dropDownOptions}
                      />
                    )}
                  </ColumnContent>
                </ColumnFlexRow>
              </ItemRow>
              {/* END This ItemRow container shows in mobile */}
              <ItemRow
                templates
                key={item._id}
                aria-pressed='false'
                tabIndex={tabIndex}
                role='button'
              >
                <MediumColumn>
                  <ColumnContent>{item.title}</ColumnContent>
                </MediumColumn>

                <MediumColumn>
                  {item.availableLanguages &&
                    item.availableLanguages.map((language, key) => {
                      return (
                        <Lang id={language.code} hide key={key}>
                          {language.code}
                        </Lang>
                      );
                    })}
                </MediumColumn>
                <MediumColumn>
                  <ColumnContent>
                    {item.category && item.category.title}
                  </ColumnContent>
                </MediumColumn>
                <ThumbnailColumn>
                  <ColumnContent>
                    {item.questions && item.questions.length > 0 && (
                      <AmountCircle amount={item.questions.length} />
                    )}
                  </ColumnContent>
                </ThumbnailColumn>
                <ThumbnailColumn hide>
                  {item.hasTimeLimit &&
                    !isNaN(item.timeLimit) &&
                    getNicelyFormattedTimeFromMS(item.timeLimit)}
                </ThumbnailColumn>
                {(userHasAccess('TEMPLATES') === 'crud' ||
                  userHasAccess('TEXT') === 'crud') && (
                  <ThumbnailColumn dropDown>
                    <DropDownMenu
                      id={item._id}
                      dropDownOptions={dropDownOptions}
                    />
                  </ThumbnailColumn>
                )}
              </ItemRow>
            </>
          )}
        </ContentContainer>
      );
    case 'projects':
    case 'groups':
      access = userHasAccess('PROJECTS');
      return (
        <ContentContainer>
          {item && (
            <ItemRow
              project
              key={item._id}
              aria-pressed='false'
              tabIndex={tabIndex}
              role='button'
            >
              <MediumColumn>
                <ColumnContent>{item.title}</ColumnContent>
              </MediumColumn>
              <MediumColumn>
                {(item.templates && item.templates.length) || 0}
                <ColumnContent></ColumnContent>
              </MediumColumn>
              <MediumColumn>
                <ColumnContent>{item.users.length}</ColumnContent>
              </MediumColumn>
              <MediumColumn>
                {(item.consultant[0] && item.consultant[0].name) || ''}
              </MediumColumn>
              <MediumColumn dropDown>
                {((access === 'crud' ||
                  (access === 'read' && projectAccess(item))) &&
                  item.templates &&
                  item.templates.length && (
                    <Button
                      primary
                      small
                      text='View report'
                      onClick={() =>
                        history.push(
                          `/admin/reports/show-report/${item.slug}`,
                          { slug: item.slug }
                        )
                      }
                    />
                  )) ||
                  null}

                {(access === 'crud' && (
                  <DropDownMenu
                    id={item._id}
                    dropDownOptions={dropDownOptions}
                  />
                )) ||
                  (projectAccess(item) && (
                    <Button
                      primary
                      small
                      text='View participants'
                      onClick={() =>
                        history.push(`/admin/reports/${type}/${item.slug}`, {
                          slug: item.slug,
                        })
                      }
                    />
                  ))}
              </MediumColumn>
            </ItemRow>
          )}
        </ContentContainer>
      );
    case 'title-only':
      return (
        <ContentContainer>
          {item && (
            <ItemRow
              titleOnly
              key={item._id}
              aria-pressed='false'
              tabIndex={tabIndex}
              role='button'
            >
              <MediumColumn>
                <ColumnContent>{item.title}</ColumnContent>
              </MediumColumn>
              <MediumColumn dropDown>
                <DropDownMenu id={item._id} dropDownOptions={dropDownOptions} />
              </MediumColumn>
            </ItemRow>
          )}
        </ContentContainer>
      );

    case 'custom-user-permissions':
      return (
        item && (
          <EditPermissions
            id={item._id}
            name={item.name}
            email={item.email}
            role={getNavigioRoleTitle(item.role)}
            usersCustomPermissions={item.customPermissions}
            dropDownOptions={dropDownOptions}
          />
        )
      );

    default:
      return <></>;
  }
};

const ItemRow = styled.div`
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: 1fr 3fr 2fr 2fr 2fr 1fr;

  ${(props) =>
    props.categories &&
    css`
      grid-template-columns: 4fr 5fr 1fr;
    `}

  ${(props) =>
    props.project &&
    css`
      grid-template-columns: 3fr 2fr 2fr 3fr 3fr;
    `}

  ${(props) =>
    props.titleOnly &&
    css`
      grid-template-columns: 9fr 1fr;
    `}

  ${(props) =>
    props.templates &&
    css`
      grid-template-columns: 5fr 4fr 3fr 3fr 2fr 1fr;
    `}
  ${(props) =>
    props.templateMobile &&
    css`
      display: none;
    `}

    ${(props) =>
      props.results &&
      css`
        grid-template-columns: 2fr 2fr 2fr 2fr 1fr 1fr;
      `}

      ${(props) =>
        props.test &&
        css`
          grid-template-columns: 5fr 1fr 1fr;
        `}

  @media ${device.tablet} {
    grid-template-columns: 1fr 2fr 1fr;
    ${(props) =>
      props.categories &&
      css`
        grid-template-columns: 1fr 1fr;
      `}
    ${(props) =>
      props.results &&
      css`
        grid-template-columns: 2fr 1fr 1fr;
        justify-items: flex-start;
      `}
      ${(props) =>
        props.test &&
        css`
          grid-template-columns: 1fr 1fr;
        `}
        ${(props) =>
          props.templates &&
          css`
            display: none;
          `}
    ${(props) =>
      props.templateMobile &&
      css`
        display: grid;
        grid-template-columns: 1fr 1fr;
      `}
  }

  font-size: 1.6rem;
  ${(props) =>
    props.candidate &&
    css`
      cursor: pointer;
    `}
`;

const ThumbnailColumn = styled.div`
  @media ${device.tablet} {
    ${(props) =>
      props.hide &&
      css`
        display: none;
      `}
  }
`;

const MobileColumn = styled.div`
  display: none;
  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

const ColumnContent = styled.div`
  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `}

  ${(props) =>
    props.firstLetterUppercase &&
    css`
      :first-letter {
        text-transform: uppercase;
      }
    `}
    
  margin: 0.5rem 0rem;

  @media ${device.mobileL} {
    ${(props) =>
      props.hide &&
      css`
        display: none;
      `}
  }
`;

const MediumColumn = styled.div`
  ${(props) =>
    props.dropDown &&
    css`
      display: flex;
      justify-content: flex-end;
      margin-right: 4rem;
      gap: 2rem;
    `}

  ${(props) =>
    props.hideInDesktop &&
    css`
      font-weight: normal;
      display: none;
    `}

      ${(props) =>
        props.textAlignRight &&
        css`
          display: flex;
          justify-content: flex-end;
        `}

  @media ${device.tablet} {
    ${(props) =>
      props.bold &&
      css`
        font-weight: 700;
      `}
    ${(props) =>
      props.hide &&
      css`
        display: none;
      `}

      ${(props) =>
        props.hideInDesktop &&
        css`
          display: flex;
          padding-top: ${(props) => props.theme.spacing.extraSmall};
        `}
  }
`;

const ColumnFlexRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
const Margin = styled.div`
  height: 0.5rem;
`;

const ContentContainer = styled.div`
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  padding-top: ${(props) => props.theme.spacing.small};
  padding-bottom: ${(props) => props.theme.spacing.small};
  display: grid;
  min-height: 7rem;
  background-color: white;
  box-shadow: 0px -1px 0px #d6d6d6, 0px 1px 0px #d6d6d6;
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
  @media ${device.tablet} {
    &:first-of-type {
      box-shadow: 0px 0px 0px, 0px 1px 0px #d6d6d6;
    }
  }
`;

const Icon = styled.img`
  cursor: pointer;
  padding: 0.8rem;
  ${(prop) =>
    prop.active &&
    css`
      transform: rotate(90deg);
    `}
`;

const Lang = styled.span`
  text-decoration: underline;
  &:after {
    content: ',';
    padding-right: 0.5rem;
  }
  &:last-child:after {
    content: '';
    padding-right: 0;
  }
  @media ${device.tablet} {
    ${(props) =>
      props.hide &&
      css`
        display: none;
      `}
  }
`;
