import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';

import { FaRegPlayCircle, FaRegPauseCircle } from 'react-icons/fa';

const useAudio = ({ audioUrl }) => {
  const [audio] = useState(new Audio(audioUrl));
  const [playing, setPlaying] = useState(false);
  const toggle = () => setPlaying(!playing);
  const pauseAndReset = () => {
    setPlaying(false);
    audio.load();
  };
  const stopSound = () => {
    audio.pause();
  };

  useEffect(() => {
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [audio]);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [audio, playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle, pauseAndReset, stopSound];
};

export const AudioFile = ({ audioUrl, play, pause, playText, pauseText }) => {
  const [playing, toggle, pauseAndReset, stopSound] = useAudio({ audioUrl });

  if (!pause && play && !playing) {
    toggle();
  } else if (pause && playing) {
    pauseAndReset();
  }

  useEffect(() => {
    return () => {
      if (!play && playing) {
        toggle();
        stopSound();
      }
    };
  }, [stopSound, toggle, playing, play]);

  return (
    !play && (
      <Fragment>
        <VocalButton onClick={toggle}>
          {playing ? (
            <Fragment>
              {pauseText}
              <FaRegPauseCircle />
            </Fragment>
          ) : (
            <Fragment>
              {playText}
              <FaRegPlayCircle />
            </Fragment>
          )}
        </VocalButton>
      </Fragment>
    )
  );
};

const VocalButton = styled.button`
  background: ${(props) => props.theme.color.darkBlue};
  color: #ffff;
  border: none;
  border-radius: 0.4rem;
  padding: 0.75rem 1.4rem;
  &:hover {
    cursor: pointer;
  }

  display: flex;
  align-items: center;
  font-size: 1.6rem;
  gap: 1rem;
`;
