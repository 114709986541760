import React, { useEffect, useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../history';
import { Layout } from './layouts/Layout';
import { AdminIndex } from '../pages/admin/index';
import { CandidateIndex } from '../pages/candidate/index';
import { ChoosePassword } from '../pages/candidate/choose-password';
import { ChangePassword } from '../pages/candidate/change-password';
import { CandidateLogin } from '../pages/candidate/login';
import { useFetch } from '../helpers/hooks/fetchData';
import axios from 'axios';
import { ContentProvider } from '../helpers/contentContext';
import { getIndexFromPath } from '../helpers/common';
import { setUserRoleId, userHasAccess } from '../helpers/permissions';

export const App = () => {
  const [payload, setPayload] = useState({});

  useEffect(() => {
    setPayload({
      endpoint: '/check-omega-auth',
      method: 'get',
    });
  }, []);

  const { isLoading, data, hasError } = useFetch(payload);

  // Fix for csrf token invalid on login
  const updateSessionStorage = () => {
    const reload = sessionStorage.getItem('setReload');
    const sessionDate = JSON.parse(sessionStorage.getItem('setReloadDate'));
    const date = new Date();

    const setSession = () =>
      sessionStorage.setItem(
        'setReloadDate',
        JSON.stringify({
          date: date.getDate(),
          hour: date.getHours(),
        })
      );

    if (
      (reload && !sessionDate) ||
      (reload &&
        sessionDate &&
        (date.getDate() !== sessionDate.date ||
          date.getHours() !== sessionDate.hour))
    ) {
      setSession();
      return true;
    }

    if (!reload) {
      sessionStorage.setItem('setReload', true);
      setSession();
      return true;
    }
  };

  useEffect(() => {
    if (hasError && payload.endpoint.includes('/check-omega-auth')) {
      updateSessionStorage() && window.location.reload();
    }
  }, [hasError, payload.endpoint]);

  if (typeof isLoading !== 'undefined' && isLoading !== true) {
    localStorage.setItem('isOmegaAdmin', (data && data.isOmegaAdmin) || false);
    localStorage.setItem('isCandidate', (data && data.isCandidate) || false);
    sessionStorage.setItem('token', (data && data.user) || false);
    setUserRoleId(
      data && data.content && data.content.role,
      data && data.content && data.content.customPermissions
    );
  }

  const isOmegaAdmin = localStorage.getItem('isOmegaAdmin') === 'true';
  const isCandidate = localStorage.getItem('isCandidate') === 'true';
  const NavigateTo = ({ newUrl }) => {
    history.push(newUrl);
    return <> </>;
  };

  const AuthTest = async () => {
    try {
      const auth = await axios({
        url: 'http://localhost:3000/check-omega-auth',
        method: 'get',
        withCredentials: true,
      });
      localStorage.setItem('isOmegaAdmin', auth.data.isOmegaAdmin);
      localStorage.setItem('isCandidate', auth.data.isCandidate);
      history.push('/');
      window.location.reload();
    } catch (e) {
      localStorage.setItem('isOmegaAdmin', false);
      localStorage.setItem('isCandidate', false);
      history.push('/');
      window.location.reload();
    }
    return <></>;
  };

  const index = getIndexFromPath(history.location.pathname);

  if (
    (!index && history.location.pathname.includes('thank-you')) ||
    (!index && history.location.pathname.includes('active-test'))
  ) {
    history.push('/');
  }
  return (
    <>
      <ContentProvider value={(data && data.content) || {}}>
        {typeof isLoading !== 'undefined' && isLoading !== true ? (
          <Layout>
            <Router history={history}>
              <Switch>
                <Route path='/admin'>
                  {isOmegaAdmin ? (
                    <AdminIndex history={history} />
                  ) : (
                    <NavigateTo newUrl='/login' />
                  )}
                </Route>

                <Route path='/candidate'>
                  {isCandidate ? (
                    <CandidateIndex history={history} />
                  ) : (
                    <NavigateTo newUrl='/login' />
                  )}
                </Route>

                <Route path='/auth'>
                  <AuthTest />
                </Route>

                <Route path='/choose-password'>
                  <ChoosePassword />
                </Route>

                <Route path='/change-password'>
                  <ChangePassword />
                </Route>

                <Route path='/login'>
                  {isOmegaAdmin ? (
                    (userHasAccess('CANDIDATES') && (
                      <NavigateTo newUrl='/admin/candidates' />
                    )) || <NavigateTo newUrl='/admin/projects' />
                  ) : isCandidate ? (
                    <NavigateTo newUrl='/candidate/home' />
                  ) : (
                    <CandidateLogin />
                  )}
                </Route>

                <Route path='/'>
                  {isOmegaAdmin ? (
                    (userHasAccess('CANDIDATES') && (
                      <NavigateTo newUrl='/admin/candidates' />
                    )) || <NavigateTo newUrl='/admin/projects' />
                  ) : isCandidate ? (
                    <NavigateTo newUrl='/candidate/home' />
                  ) : (
                    <NavigateTo newUrl='/login' />
                  )}
                </Route>
              </Switch>
            </Router>
          </Layout>
        ) : (
          <div>Loading...</div>
        )}
      </ContentProvider>
    </>
  );
};
