import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { device } from '../../../styles/device';
import { GeneralTemplate } from '../../../components/admin/templates/edit-template/GeneralTemplate';
import { InstructionTemplate } from '../../../components/admin/templates/edit-template/InstructionTemplate';
import { QuestionsTemplate } from '../../../components/admin/templates/edit-template/QuestionsTemplate';
import history from '../../../history';

export const EditTemplate = (props) => {
  const paramsData = useParams();
  const [method] = useState('get');
  const [params] = useState({ useSlug: true });
  const [template, setTemplate] = useState(null);
  const slug =
    (paramsData && paramsData.slug) ||
    (props.location && props.location.state && props.location.state.slug);
  const [endpoint] = useState(`/api/omega/templates/slug/${slug}`);
  const [payload] = useState({ endpoint, method, params });
  const [page, setPage] = useState(1);
  const { data, hasError, isLoading } = useFetch(payload);

  useEffect(() => {
    if (data && data.data && data.data.length) setTemplate(data.data[0]);
  }, [data]);

  useEffect(() => {
    if (hasError) {
      //Error handling
    }
  }, [hasError]);

  // const memoizedSetTemplate = useCallback(() => {
  //   setTemplate(template);
  // }, [template]);

  const renderContent = () => {
    switch (page) {
      case 1:
        return (
          <GeneralTemplate
            template={template}
            setPage={setPage}
            setTemplate={setTemplate}
          />
        );
      case 2:
        return (
          <InstructionTemplate
            template={template}
            setPage={setPage}
            setTemplate={setTemplate}
          />
        );
      case 3:
        return (
          <QuestionsTemplate
            template={template}
            setPage={setPage}
            setTemplate={setTemplate}
          />
        );
      default:
        return <GeneralTemplate template={template} setPage={setPage} />;
    }
  };

  return (
    <Container>
      <InnerContainer>
        {template && renderContent()}
        {!isLoading && isLoading !== undefined && !template && (
          <div>{history.push('/admin/templates/view-all/')}</div>
        )}
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.color.bgGrey};
  min-height: calc(100vh - 224px);
  display: flex;
  justify-content: center;
  @media ${device.laptop} {
    padding: 0 ${(props) => props.theme.spacing.extraLarge};
  }
  @media ${device.mobileL} {
    padding: 0 ${(props) => props.theme.spacing.small};
  }
`;

const InnerContainer = styled.div`
  width: 804px;
  padding-top: ${(props) => props.theme.spacing.standard};
`;
