import React from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';

export const AmountCircle = ({ amount }) => {
  return <Container>{amount}</Container>;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: white;
  background-color: ${(props) => props.theme.color.mediumBlue};
  height: 4rem;
  width: 4rem;
  border-radius: 50%;

  @media ${device.tablet} {
    font-size: 1.2rem;
    line-height: 1.4rem;
    height: 2.8rem;
    width: 2.8rem;
    margin: 0.8rem;
  }
`;
