import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InputField } from '../../shared/input/InputField';
import { TextareaField } from '../../shared/input/Textarea';
import { device } from '../../../styles/device';
import { Button } from '../../shared/button/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { Modal } from '../../shared/modal/Modal';

export const GeneralTabContent = ({ onDeleteLanguageOnClick, language }) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [payload, setPayload] = useState({});
  const { data, hasError } = useFetch(payload);

  useEffect(() => {
    if (data) {
      setModalTitle('Language content saved');
      setShowAddModal(true);
    }
  }, [data]);

  useEffect(() => {
    if (hasError) {
      setModalTitle('Error saving language content');
      setShowAddModal(true);
    }
  }, [hasError]);

  const validationSchema = yup.object().shape({
    copy: yup.lazy((obj) =>
      yup.object(
        mapValues(obj, () =>
          yup.object().shape({
            text: yup.string().required('This is a required field.'),
          })
        )
      )
    ),
  });
  return (
    <Container>
      <Wrapper>
        <Formik
          initialValues={language}
          values={language}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            setPayload({
              endpoint: `/api/omega/languages/${language._id}`,
              method: 'put',
              data: values,
            });
          }}
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <ColumnContainer>
                {Object.keys(values.copy).map((key) => {
                  return (
                    <InputWidth key={values.copy[key].label}>
                      {values.copy[key].rows ? (
                        <TextareaField
                          rows={values.copy[key].rows}
                          placeholder={values.copy[key].label}
                          label={`${values.copy[key].label}:`}
                          value={
                            (values &&
                              values.copy &&
                              values.copy[key] &&
                              values.copy[key].text) ||
                            ''
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id={`copy[${key}].text`}
                          error={
                            errors.copy &&
                            errors.copy[key] &&
                            errors.copy[key].text &&
                            touched &&
                            touched.copy &&
                            touched.copy[key] &&
                            touched.copy[key].text
                          }
                          type='text'
                          name={`copy[${key}].text`}
                          message={
                            touched &&
                            touched.copy &&
                            touched.copy[key] &&
                            errors.copy &&
                            errors.copy[key] &&
                            errors.copy[key].text
                          }
                        />
                      ) : (
                        <InputField
                          placeholder={values.copy[key].label}
                          label={`${values.copy[key].label}:`}
                          value={
                            (values &&
                              values.copy &&
                              values.copy[key] &&
                              values.copy[key].text) ||
                            ''
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id={`copy[${key}].text`}
                          error={
                            errors.copy &&
                            errors.copy[key] &&
                            errors.copy[key].text &&
                            touched &&
                            touched.copy &&
                            touched.copy[key] &&
                            touched.copy[key].text
                          }
                          type='text'
                          name={`copy[${key}].text`}
                          message={
                            touched &&
                            touched.copy &&
                            touched.copy[key] &&
                            errors.copy &&
                            errors.copy[key] &&
                            errors.copy[key].text
                          }
                        />
                      )}
                    </InputWidth>
                  );
                })}
              </ColumnContainer>
              <ButtonContainer>
                <Button
                  remove
                  text='Delete language'
                  onClick={onDeleteLanguageOnClick}
                  type='button'
                />
                <ButtonPaddingInTabletAndDown />
                <Button
                  primary
                  text='Save changes'
                  type='submit'
                  disabled={isSubmitting}
                />
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </Wrapper>
      {showAddModal && (
        <Modal
          handleExit={setShowAddModal}
          title={modalTitle}
          show={showAddModal}
          width={'40rem'}
        >
          <ModalContent>
            <Button
              onClick={() => setShowAddModal(false)}
              text='Done'
              primary
            />
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 9.5rem 0 5.5rem 0;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  height: fit-content;
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

const ColumnContainer = styled.div`
  column-count: 2;
  column-gap: 12rem;
  height: fit-content;
  @media ${device.tablet} {
    column-count: 1;
    column-gap: 0px;
  }
`;

const InputWidth = styled.div`
  width: 31rem;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  margin-bottom: 3rem;
  @media ${device.tablet} {
    max-width: 100%;
  }
`;

const ButtonContainer = styled.div`
  column-count: 2;
  column-gap: 12rem;
  margin-top: 12rem;
  @media ${device.tablet} {
    column-count: 1;
    column-gap: 0px;
    margin-top: 7rem;
    &:first-child {
      margin-bottom: 1.5rem;
    }
  }
`;

const ButtonPaddingInTabletAndDown = styled.div`
  display: none;
  @media ${device.tablet} {
    display: block;
    height: 2rem;
  }
`;

const Form = styled.form``;

const ModalContent = styled.div`
  font-size: 4rem;
  flex-direction: column;
`;
