import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Button } from '../../../shared/button/Button';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { InputField } from '../../../shared/input/InputField';
import { Modal } from '../../../shared/modal/Modal';
import history from '../../../../history';

export const InitNewTemplate = () => {
  const [showModal, setShowModal] = useState(false);
  const [payload, setPayload] = useState({});

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This is a required field.'),
  });

  const initialValues = {
    title: '',
  };

  const { data, hasError } = useFetch(payload);

  useEffect(() => {
    if (data && data.data && !showModal) {
      const newTemplate = data.data;
      history.push(`/admin/templates/edit-template/${newTemplate.slug}`, {
        slug: newTemplate.slug,
      });
    }
  }, [data, showModal]);

  useEffect(() => {
    if (data && data.data) {
      setShowModal(false);
    }
  }, [data]);

  return (
    <>
      <Button add onClick={() => setShowModal(true)} />

      <Modal
        show={showModal}
        handleExit={setShowModal}
        width={'40rem'}
        title='Create new test template'
      >
        {!hasError && (
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async (values, { resetForm }) => {
              const newTemplate = {
                title: values.title,
              };
              setPayload({
                endpoint: '/api/omega/templates',
                method: 'post',
                data: newTemplate,
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              resetForm();
            }}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <InputField
                  label='Internal title'
                  id='title'
                  type='text'
                  name='title'
                  placeholder=''
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  error={errors.title && touched.title}
                  message={errors.title && touched.title && `${errors.title}`}
                />
                <Margin />

                <Button
                  primary
                  type='submit'
                  disabled={isSubmitting}
                  text='Continue'
                />
              </Form>
            )}
          </Formik>
        )}
        {hasError && (
          <>
            <h2>Error creating template</h2>
            <Button text='OK' onClick={() => setShowModal(false)} />
          </>
        )}
      </Modal>
    </>
  );
};

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Margin = styled.div`
  height: ${(props) => props.theme.spacing.extraLarge};
`;
