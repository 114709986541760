import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../styles/device';

export const ListButton = ({
  type,
  onClick,
  text,
  startNew,
  testDisabled,
  isDisabled,
}) => {
  return (
    <Container
      type={type}
      onClick={onClick}
      startNew={startNew}
      testDisabled={testDisabled}
      isDisabled={isDisabled}
    >
      {text}
    </Container>
  );
};

const Container = styled.button`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.05em;
  height: 3.5rem;
  width: 12rem;
  border-radius: 0.4rem;
  font-size: 2rem;
  text-transform: lowercase;
  cursor: pointer;
  @media ${device.mobileL} {
    font-size: 1.8rem;
  }
  color: ${(props) => props.theme.color.darkBlue};
  border: 1px solid ${(props) => props.theme.color.darkBlue};
  background: #fff;

  ${(props) =>
    props.startNew &&
    css`
      color: #fff;
      background: ${(props) => props.theme.color.darkBlue};
      right: 1.5rem;
    `}
  ${(props) =>
    props.testDisabled &&
    css`
      color: ${(props) => props.theme.color.darkGrey};
      background: ${(props) => props.theme.color.white}
      border: 1px solid ${(props) => props.theme.color.darkGrey};
      cursor: default;
    `}
`;
