import React, { useCallback, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import styled from 'styled-components';
import { isEqual } from 'lodash';

export const Scatterplot = ({
  data,
  width,
  selected,
  noText,
  radius = 5,
  isRow = false,
  rowHeight = 50,
  noMargin,
}) => {
  const svgRef = useRef();

  useEffect(() => {
    svgRef.current.innerHTML = '';
  }, [data]);

  const checkSelected = useCallback(
    (returnData, selectedObject, data) => {
      if (selected === undefined) return returnData;
      if (isEqual(selectedObject, data[selected])) return returnData;
      return '';
    },
    [selected]
  );

  const fixScoreDecimals = useCallback((score) => {
    if (score) return score.toFixed(4).replace(/\.?0+$/, '');
    return '';
  }, []);

  const checkValue = (value, obj, yValue) => {
    return yValue
      ? value.x < obj.x + 0.09 &&
          value.y < obj.y + 0.09 &&
          value.x > obj.x - 0.07 &&
          value.y > obj.y - 0.04
      : value.x < obj.x + 0.09 && value.x > obj.x - 0.07;
  };

  const fixOverlapedData = useCallback(
    (data) => {
      const newData = [];
      for (const score of data) {
        const haveY = (score.y && true) || false;

        const dataIx = newData.findIndex((obj) =>
          checkValue(score, obj, haveY)
        );

        const nameAndScore = `Name: ${score.fullName}
        ${(haveY &&
          `<br>X: ${fixScoreDecimals(score.stanine)}
          Y: ${fixScoreDecimals(score.stanineY)}`) ||
          `<br>Score: ${fixScoreDecimals(score.stanine)}`}
        `;
        if (dataIx !== -1) {
          newData[dataIx].names += `<br>${nameAndScore}`;
          newData[dataIx].amount++;
          score.x = newData[dataIx].x;
          if (haveY) score.y = newData[dataIx].y;
        } else {
          newData.push({
            x: score.x,
            names: nameAndScore,
            amount: 1,
            ...(haveY && { y: score.y }),
          });
        }
      }

      return newData;
    },
    [fixScoreDecimals]
  );

  useEffect(() => {
    if (data && data.length) {
      const isSelected = selected !== undefined;
      const scatterData = data.map((obj) => Object.assign({}, obj));
      const overlappedData =
        scatterData && scatterData.length > 1 && fixOverlapedData(scatterData);

      const height = (!isRow && width) || rowHeight;

      const svg = d3
        .select(svgRef.current)
        .attr('width', width)
        .attr('height', height)
        .style('overflow', 'visible');

      const xScale = d3
        .scaleLinear()
        .domain([1, 9])
        .range([0, width]);
      const yScale = d3
        .scaleLinear()
        .domain([1, 9])
        .range([height, 0]);

      // generate dots
      svg
        .selectAll()
        .data(scatterData)
        .enter()
        .append('circle')
        .attr('cx', (d) => xScale(d.x))
        .attr('cy', (d) => yScale((!isRow && d.y) || 5))
        .attr('r', radius)
        .attr('fill', '#00284a')
        .attr('stroke', 'red')
        .style('z-index', '1')
        .attr(
          'stroke-width',
          (obj) =>
            (!noText && isSelected && checkSelected(1, obj, scatterData)) || 0
        )
        .on('mouseover', function(data) {
          const isOverlapping =
            overlappedData &&
            overlappedData.find((obj) => checkValue(data, obj, obj.y && true));

          const text = `
         ${(isOverlapping && isOverlapping.names && `${isOverlapping.names}`) ||
           `Score: ${fixScoreDecimals(data.x)}`}`;

          const topPx = !isRow && (text.split('<br>').length - 1) * 10;

          const [x, y] = d3.mouse(this); // Get current mouse position
          d3.select(this.parentNode.parentNode)
            .append('div')
            .attr('class', 'scatter-tool-tip')
            .style('position', 'absolute')
            .style('left', `${x + 40}px`)
            .style('top', !isRow && `${y - topPx}px`)
            .style('background-color', '#fff')
            .style('border', '1px solid #00284a')
            .style('color', '#00284a')
            .style('padding', '10px')
            .style('min-width', '15rem')
            .style('z-index', '3')
            .style('font-size', '1.6rem')
            .style('text-align', 'start')
            .style('line-height', '2rem')
            .style('border-radius', '1rem')
            .style('white-space', 'nowrap')
            .html(text);

          d3.select(this).attr('fill', 'orange');
        })
        .on('mouseout', function() {
          d3.select(this).attr('fill', '#00284a');
          d3.select(this.parentNode.parentNode)
            .selectAll('.scatter-tool-tip')
            .remove();
        });

      // generate text
      if (!noText) {
        svg
          .append('g')
          .attr('font-family', 'sans-serif')
          .attr('font-size', 9)
          .attr('stroke-linejoin', 'round')
          .attr('stroke-linecap', 'round')
          .selectAll('text')
          .data(scatterData)
          .join('text')
          .attr('dx', (data) => {
            // To center the info box to the mouse
            const nameLengthFactor = isRow ? -3 : -2.5;
            return data.name.length * nameLengthFactor;
          })
          .attr(
            'dy',
            (obj) => (!isSelected && obj.bottomText && '1.6rem') || '-0.9rem'
          )
          .attr('x', (obj) => xScale(obj.x))
          .attr('y', (d) => yScale((!isRow && d.y) || 5))
          .style('pointer-events', 'none')
          .text((obj) => checkSelected(obj.name, obj, scatterData))
          .call((text) => text.clone(true))
          .attr('fill', 'none');
      }
    }
  }, [
    data,
    width,
    selected,
    noText,
    radius,
    isRow,
    rowHeight,
    checkSelected,
    fixScoreDecimals,
    fixOverlapedData,
  ]);

  return (
    <Container>
      {data && data.length && <Svg ref={svgRef} noMargin={noMargin} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Svg = styled.svg`
  ${(props) => !props.noMargin && ' margin-left: 2rem; margin-right: 2rem;'}
  position: relative;
`;
