import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { displayDateAndTime } from '../../../helpers/common';

export const TestStatus = ({ test }) => {
  const [isInactive, setIsActive] = useState(test.isDeactivatedByAdmin);
  const [payload, setPayload] = useState({});

  const { data, isLoading, hasError } = useFetch(payload);

  useEffect(() => {
    if (data && data.data && data.data.testUpdated) {
      setIsActive((isInactive) => !isInactive);
    }
  }, [data]);

  return (
    <Fragment>
      {(isLoading && '...Loading') ||
        (test.isCompleted &&
          ((test.testCompletionDate &&
            `Completed: ${displayDateAndTime(
              test.testCompletionDate,
              true
            )}`) ||
            'Completed')) || (
          <div>
            {(hasError && 'Try again') ||
              (isInactive && 'Inactive') ||
              'Not completed'}
            <Button
              onClick={() =>
                setPayload({
                  method: 'PUT',
                  endpoint: '/api/omega/users/candidate/activate-test',
                  data: { id: test._id, isActive: !isInactive },
                })
              }
            >
              {(isInactive && 'Activate') || 'Deactivate'}
            </Button>
          </div>
        )}
    </Fragment>
  );
};

const Button = styled.button`
  width: fit-content;
  height: fit-content;
  background: #00000000;
  margin-left: 1rem;
  border-radius: 2rem;
  cursor: pointer;
  color: ${(props) => props.theme.color.mediumBlue};
  border: solid 1px ${(props) => props.theme.color.mediumBlue};
  font-size: 1.6rem;

  &:hover {
    background: ${(props) => props.theme.color.mediumBlue};
    color: ${(props) => props.theme.color.white};
  }
`;
