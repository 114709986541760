import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NoResultsFound } from '../../../components/shared/error-page-components/NoResultsFound';
import { Button } from '../../../components/shared/button/Button';
import { SearchBar } from '../../../components/shared/search-bar/SearchBar';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { StandardTable } from '../../../components/shared/standard-table/StandardTable';
import { device } from '../../../styles/device';
import { ProjectProfileCard } from '../../../components/admin/reports/projects/ProjectProfileCard';
import { userHasAccess } from '../../../helpers/permissions';
import { NewProject } from '../../../components/admin/reports/projects/NewProject';

const itemsPerPage = 25;

export const AdminProjects = ({ history }) => {
  const [payload, setPayload] = useState({});
  const [endpoint] = useState('/api/omega/projects');
  const method = 'get';
  const [projects, setProjects] = useState([]);
  const [moreObjectsAvailable, setMoreObjectsAvailable] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [activeButton, setActiveButton] = useState('cards');

  const [params, setParams] = useState({
    searchString: '',
    sortPropName: '',
    sortHeading: null,
    skip: 0,
    limit: itemsPerPage,
    isUserProjects: true,
  });

  const { data, isLoading } = useFetch(payload);

  useEffect(() => {
    setPayload({ endpoint, method, params });
  }, [params, endpoint, method]);

  useEffect(() => {
    setUpdateInProgress(false);
    if (data && data.data) {
      setProjects((projects) =>
        data.data.length
          ? [...projects, ...data.data]
          : projects.length
          ? projects
          : []
      );
    }
  }, [data]);

  useEffect(() => {
    const count = data && data.count;
    const moreQuestionsAvailable = count && params.limit + params.skip < count;
    setMoreObjectsAvailable(moreQuestionsAvailable);
  }, [data, params.skip, params.limit]);

  const sortByProp = (propName) => {
    setUpdateInProgress(true);
    if (!propName) return false;
    //clear project array
    setProjects([]);
    const skip = params.sortPropName !== propName ? 0 : params.skip;

    //if third press on same heading, clear the sorting
    if (params.sortHeading === -1 && propName === params.sortPropName) {
      return setParams({ ...params, sortHeading: '', sortPropName: '', skip });
    }

    //if not cleared, heading will be set to 1 on first click and -1 on second click
    const sortHeading =
      propName === params.sortPropName && params.sortHeading === 1 ? -1 : 1;
    const sortPropName = propName;
    return setParams({ ...params, sortHeading, sortPropName, skip });
  };

  const searchEntered = (s) => {
    setUpdateInProgress(true);
    //clear project array
    setProjects([]);
    const skip = 0;
    return setParams({ ...params, searchString: s, skip });
  };

  const openEdit = (id) => {
    const object = projects.find((obj) => obj._id === id);
    const slug = object.slug;
    const type = object.isProject ? 'projects' : 'groups';

    return history.push(`/admin/reports/${type}/${slug}`, { slug });
  };

  const dropDownOptions = [
    {
      title: 'View',
      onClick: openEdit,
    },
  ];

  const showMore = () => {
    const skip = params.skip + itemsPerPage;
    return setParams({ ...params, skip });
  };

  return (
    <Container>
      <SearchBar
        searchString={params.searchString}
        onChange={searchEntered}
        placeholder={'Search'}
        viewSwitch
        setActiveButton={setActiveButton}
      />
      {activeButton === 'list' && (
        <ListContainer>
          {projects.length > 0 && (
            <StandardTable
              type={'projects'}
              items={projects}
              onHeaderClick={sortByProp}
              dropDownOptions={dropDownOptions}
            />
          )}
          {!updateInProgress && projects.length === 0 && !isLoading && (
            <NoResultsFound />
          )}
        </ListContainer>
      )}

      {activeButton === 'cards' && (
        <CardsContent>
          {projects.length === 0 && !isLoading && <NoResultsFound />}
          <CardsContainer>
            <ProjectProfileCard projects={projects} onItemClick={openEdit} />
          </CardsContainer>
        </CardsContent>
      )}

      {(moreObjectsAvailable && (
        <ShowMoreContainer>
          <ButtonWidth>
            <Button onClick={showMore} text='Show more' primary />
          </ButtonWidth>
        </ShowMoreContainer>
      )) ||
        null}

      {userHasAccess('PROJECTS') === 'crud' && (
        <NewProject type='both' title='project/group' />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  padding-top: 6rem;
  padding-bottom: 7rem;
  background: #eeeeee;
`;

const ListContainer = styled.div`
  width: 100%;
  margin-bottom: 7rem;
`;

const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 7rem;
`;

const ButtonWidth = styled.div`
  width: 20rem;
`;

const CardsContent = styled.main`
  width: 100%;
`;

const CardsContainer = styled.div`
  width: 100%;
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  margin-top: ${(props) => props.theme.spacing.extraLarge};
  margin-bottom: ${(props) => props.theme.spacing.extraLarge};
  display: grid;
  justify-items: start;
  grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
  grid-column-gap: ${(props) => props.theme.spacing.standard};
  grid-row-gap: 6.5rem;

  @media (max-width: 1104px) {
    grid-column-gap: ${(props) => props.theme.spacing.extraSmall};
    padding-right: ${(props) => props.theme.spacing.extraSmall};
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  }
  @media (max-width: 979px) {
    grid-column-gap: ${(props) => props.theme.spacing.small};
  }
  @media (max-width: 868px) {
    padding-right: ${(props) => props.theme.spacing.standard};
  }
  @media ${device.tablet} {
    padding-right: ${(props) => props.theme.spacing.extraSmall};
  }
  @media (max-width: 725px) {
    padding-right: 0.5rem;
  }
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;
