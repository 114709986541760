import React from 'react';
import styled, { css } from 'styled-components';
import { ImageSlider } from './ImageSlider';

export const QuestionTitle = ({ title, number, fim = false }) => {
  return (
    (fim && (
      <>
        <SliderContainer>
          <ImageSlider
            images={
              fim.instructionImages
                ? [fim.image, ...fim.instructionImages]
                : [fim.image]
            }
          />
        </SliderContainer>
      </>
    )) || (
      <>
        <Title isNumber={number}>{title}</Title>
      </>
    )
  );
};

const Title = styled.h3`
  margin-bottom: 2rem;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 160%;

  ${(props) =>
    props.isNumber &&
    css`
      word-spacing: 2rem;
    `}
`;

const SliderContainer = styled.div`
  margin-bottom: 3rem;
  width: 100%;
`;
