import React from 'react';
import styled from 'styled-components';
import { device } from '../../../../styles/device';

export const ImageQuestion = ({ image }) => {
  return <Container>{image && <Image src={image || null} />}</Container>;
};

const Container = styled.div`
  padding: 4rem 8.8rem;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  background-color: ${(props) => props.theme.color.bgGrey};
  margin-bottom: 5rem;
  border-radius: 0.4rem;

  @media ${device.tablet} {
    padding: 3.5rem 2rem;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    -webkit-transition: padding 0.2s ease-in-out;
    -o-transition: padding 0.2s ease-in-out;
    transition: padding 0.2s ease-in-out;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;
