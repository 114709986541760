export default {
  /* FONT SIZE */
  size: {
    breadcrumb: '2.4rem',
    menu: '1.8rem',
    buttonText: '1.6rem',
    standard: '1.6rem',
    medium: '1.4rem',
  },
  /* COLORS */
  color: {
    mediumDarkBlue: 'rgba(3, 29, 45, 0.6)',
    lightBlue: 'rgba(3, 29, 45, 0.2)',
    yellowOrange: '#F29D39',
    textGrey: '#959595',
    //MAIN COLORS:
    white: '#fff',
    black: '#000',
    offBlack: '#262626',
    darkBlue: '#031D2D',
    primary: '#2584c4',
    mediumBlue: '#227bb7',
    bgGrey: '#EEEEEE',
    darkGrey: '#E5E5E5',
    offGrey: ' #6B6B6B',
    selectedGrey: '#69757E',
    mediumGrey: '#d6d6d6',
    shadow: '#D8D8D8',
    error: '#db2121',
    success: '#006600',
    lightGrey: '#DADADA',
    noImgGrey: '#C4C4C4',
    add: '#33c764',
    instruction: '#94BEDA',
    exercise: '#33C764',
    orange: '#EEA153',
    noResultsFound: '#808080',
  },
  /* SPACING */
  spacing: {
    extraSmall: '1rem',
    small: '1.5rem',
    standard: '2rem',
    standardL: '2.5rem',
    medium: '3rem',
    large: '4rem',
    extraLarge: '5rem',
  },
};
