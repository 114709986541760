import * as React from 'react';
import styled from 'styled-components';
import { PortalMenu } from '../admin/navigation/PortalMenu';
import { device } from '../../styles/device';
import {
  navigioLogo,
  logOutWhite,
  user,
} from '../../components/shared/assets/icons';
import { Logout } from '../shared/Logout';
import history from '../../history';

export const Header = () => {
  const [showLogout, setShowLogout] = React.useState(false);
  const logOutUser = () => {
    setShowLogout(true);
  };

  const onUserClick = () => {
    return history.push('/admin/change-password');
  };

  return (
    <nav>
      <StyledHeader>
        <a href='/'>
          <Logo src={navigioLogo} />
        </a>

        {/* <LogoutIconContainer ></LogoutIconContainer> */}

        <UserContainer>
          <ClickAble onClick={onUserClick}>
            <Icon src={user} />
          </ClickAble>

          <ClickAble onClick={logOutUser}>
            <LogoutIcon src={logOutWhite} />
          </ClickAble>
        </UserContainer>
      </StyledHeader>
      <PortalMenu />
      <Logout showModal={showLogout} setShowModal={setShowLogout} />
    </nav>
  );
};

const StyledHeader = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  position: relative;
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  position: absolute;
  right: 6.5rem;
  top: 3.3rem;

  @media ${device.tablet} {
    right: 2.5rem;
    top: 2.5rem;
  }
`;

const Logo = styled.img`
  height: 5rem;
  margin-top: ${(props) => props.theme.spacing.small};
  margin-bottom: ${(props) => props.theme.spacing.small};
  margin-left: ${(props) => props.theme.spacing.extraLarge};
  @media ${device.mobileL} {
    margin-left: ${(props) => props.theme.spacing.small};
  }
`;

const Icon = styled.img`
  height: 3.2rem;
  width: 3.2rem;
`;

const ClickAble = styled.div`
  cursor: pointer;
`;

const LogoutIcon = styled.img`
  height: 2.2rem;
  width: 2.2rem;
`;
