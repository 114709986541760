import { AdminTemplates } from '../../../pages/admin/templates/templates';
import { EditTemplate } from '../../../pages/admin/templates/edit-template';
import { ViewAllTemplates } from '../../../pages/admin/templates/view-all';
import { DefaultTemplate } from '../../../pages/admin/templates/default-template';
import { AdminQuestions } from '../../../pages/admin/questions/questions';
import { AdminLanguages } from '../../../pages/admin/languages/languages';
import { AdminCandidates } from '../../../pages/admin/candidates/candidates';
import { ViewAllCandidates } from '../../../pages/admin/candidates/view-all';
import { ImportCandidate } from '../../../pages/admin/candidates/import';
import { Candidate } from '../../../pages/admin/candidates/candidate';
import { ViewAllQuestions } from '../../../pages/admin/questions/view-all';
import { CreateNewQuestion } from '../../../pages/admin/questions/create-new';
import { QuestionInstruction } from '../../../pages/admin/questions/instruction';
import { QuestionExercise } from '../../../pages/admin/questions/exercise';
import { EditQuestion } from '../../../pages/admin/questions/edit-question';
import { AdminCategories } from '../../../pages/admin/categories/categories';
import { SingleLanguage } from '../../../pages/admin/languages/single-language';
import { ViewAllLanguages } from '../../../pages/admin/languages/view-all-languages';
import { AdminReports } from '../../../pages/admin/reports/projects';
import { ViewAllProjects } from '../../../pages/admin/reports/view-all';
import { Project } from '../../../pages/admin/reports/project';
import { AdminSettings } from '../../../pages/admin/settings/settings';
import { ViewAllSettings } from '../../../pages/admin/settings/view-all';
import { EditCluster } from '../../../pages/admin/settings/edit-cluster';
import { EditReportTemplatePage } from '../../../pages/admin/reports/edit-template';
import { Report } from '../../../pages/admin/reports/report';
import { EditFacet } from '../../../pages/admin/settings/edit-facets';
import { AdminProjects } from '../../../pages/admin/projects/your-projects';
import { userHasAccess } from '../../../helpers/permissions';

const templateStructure = () => ({
  name: 'Templates',
  path: '/templates',
  component: AdminTemplates,
  isSubRoute: false,
  hasSubMenu: true,
  routes: [
    {
      name: 'View all',
      path: '/view-all',
      component: ViewAllTemplates,
    },
    {
      name: 'Default template',
      path: '/default-template',
      component: DefaultTemplate,
    },
    {
      name: 'Edit template',
      path: '/edit-template/:slug',
      component: EditTemplate,
      isDynamic: true,
    },
  ],
});
//a copy of the templeStructure
const textTemplateStructure = templateStructure();

const questionStructure = () => ({
  name: 'Questions',
  path: '/questions',
  component: AdminQuestions,
  isSubRoute: false,
  hasSubMenu: true,
  routes: [
    {
      name: 'View all',
      path: '/view-all',
      component: ViewAllQuestions,
    },
    {
      name: 'Create new',
      path: '/create-new-question',
      component: CreateNewQuestion,
    },
    {
      name: 'Instruction',
      path: '/instruction',
      component: QuestionInstruction,
    },
    {
      name: 'Exercise',
      path: '/exercise',
      component: QuestionExercise,
    },
    {
      name: 'Edit question',
      path: '/edit-question/:slug',
      component: EditQuestion,
      isDynamic: true,
    },
  ],
});

//a copy of the templeStructure
const textQuestionStructure = questionStructure();

const routeObject = {
  CANDIDATES: {
    name: 'Candidates',
    path: '/candidates',
    component: AdminCandidates,
    hasSubMenu: true,
    routes: [
      {
        name: 'View all',
        path: '/view-all',
        component: ViewAllCandidates,
        isDynamic: false,
      },
      {
        name: 'Import',
        path: '/import',
        component: ImportCandidate,
        isDynamic: false,
      },
      {
        name: 'Candidate',
        path: '/:slug',
        component: Candidate,
        isDynamic: true,
      },
    ],
  },
  PROJECTS: {
    name: 'Projects',
    path: '/projects',
    component: AdminProjects,
    routes: [],
  },
  REPORTS: {
    name: 'Reports',
    path: '/reports',
    component: AdminReports,
    hasSubMenu: true,
    routes: [
      {
        name: 'View all',
        path: '/view-all',
        component: ViewAllProjects,
      },
      {
        name: 'edit-template',
        path: '/edit-template/:slug',
        component: EditReportTemplatePage,
      },
      {
        name: 'show-report',
        path: '/show-report/:slug',
        component: Report,
      },
      {
        name: 'groups',
        path: '/groups/:slug',
        component: Project,
      },
      {
        name: 'projects',
        path: '/projects/:slug',
        component: Project,
      },
    ],
  },
  TEMPLATES: templateStructure(),
  TEXT: {
    TEXTTEMPLATES: textTemplateStructure,
    TEXTQUESTIONS: textQuestionStructure,
  },
  QUESTIONS: questionStructure(),
  CATEGORIES: {
    name: 'Categories',
    path: '/categories',
    component: AdminCategories,
    hasSubMenu: true,
    routes: [],
  },
  LANGUAGES: {
    name: 'Languages',
    path: '/languages',
    component: AdminLanguages,
    hasSubMenu: true,
    routes: [
      {
        name: 'View all',
        path: '/view-all',
        component: ViewAllLanguages,
      },
      {
        name: 'Edit language copy',
        path: '/edit-language/:slug',
        component: SingleLanguage,
        isDynamic: true,
      },
    ],
  },
  SETTINGS: {
    name: 'Settings',
    path: '/settings',
    component: AdminSettings,
    hasSubMenu: true,
    routes: [
      {
        name: 'View all',
        path: '/view-all',
        component: ViewAllSettings,
      },
      {
        name: 'Edit facets',
        path: 'edit-facets/:slug',
        component: EditFacet,
      },
      {
        name: 'Edit cluster',
        path: 'edit-cluster/:slug',
        component: EditCluster,
      },
    ],
  },
};

let addToRoutes = [];

//export const routes = addToRoutes;

export const updateRoutes = () => {
  let toRoutes = [];

  Object.keys(routeObject).forEach((route) => {
    if (route === 'TEXT' && userHasAccess('TEXT')) {
      Object.keys(routeObject[route]).forEach((subRoute) => {
        switch (subRoute) {
          case 'TEXTTEMPLATES':
            userHasAccess('TEMPLATES') === false &&
              toRoutes.push(routeObject[route][subRoute]);
            break;
          case 'TEXTQUESTIONS':
            userHasAccess('QUESTIONS') === false &&
              toRoutes.push(routeObject[route][subRoute]);
            break;
          default:
            break;
        }
      });
    } else if (route === 'LANGUAGES') {
      userHasAccess(route) === 'crud' && toRoutes.push(routeObject[route]);
    } else if (route === 'PROJECTS') {
      toRoutes.push(routeObject[route]);
      userHasAccess('REPORTS') === false &&
        toRoutes.push(routeObject['REPORTS']);
    } else if (userHasAccess(route)) {
      toRoutes.push(routeObject[route]);
    }
  });

  //Solve this before final commit. We need a default page that every user can access just incase routeObject is empty...
  if (toRoutes.length < 1) {
    toRoutes.push({
      name: 'Projects',
      path: '/projects',
      component: AdminProjects,
      routes: [],
    });
  }

  return toRoutes;
};
addToRoutes = updateRoutes();

export const routes = addToRoutes;
