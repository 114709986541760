import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PreviewTemplate } from '../../../components/admin/reports/templates/PreviewTemplate';
import { EditReportTemplate } from '../../../components/admin/reports/templates/EditReportTemplate';
import { Button } from '../../../components/shared/button/Button';
import { useFetch } from '../../../helpers/hooks/fetchData';
import styled from 'styled-components';
import { InputWrapper } from '../../../components/shared/input/InputWrapper';
import { device } from '../../../styles/device';
import { Modal } from '../../../components/shared/modal/Modal';

export const EditReportTemplatePage = (props) => {
  const paramsData = useParams();
  const [payload, setPayload] = useState({});
  const [clustersPayload] = useState({
    endpoint: '/api/omega/clusters',
  });
  const [isPreview, setIsPreview] = useState(false);
  const [clusters, SetClusters] = useState([]);
  const [sectionOpen, setSectionOpen] = useState(0);
  const [template, setTemplate] = useState({});
  const [title, setTitle] = useState('');
  const [sections, setSections] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  const { data: clustersData } = useFetch(clustersPayload);

  const slug =
    (paramsData && paramsData.slug) ||
    (props.location && props.location.state && props.location.state.slug);
  const endpoint = `/api/omega/report-template/slug/${slug}`;
  const { data, hasError } = useFetch(payload, true);

  useEffect(() => {
    setPayload({ endpoint, method: 'get' });
  }, [endpoint]);

  useEffect(() => {
    if (data && data.data) {
      setTemplate(data.data);
      setTitle(data.data.title);
      setAvailableLanguages(data.data.availableLanguages);
      setSections(data.data.sections);
    }

    if (data && data.data && data.templateSaved) {
      setShowModal(true);
      setModalTitle('Changes have been saved');
      setModalText('');
    }

    if (hasError) {
      setShowModal(true);
      setModalTitle('Something went wrong');
      setModalText('');
    }
  }, [data, hasError]);

  useEffect(() => {
    if (clustersData && clustersData.data) {
      SetClusters(clustersData.data);
    }
  }, [clustersData]);

  const errorModal = ({ text, title, section }) => {
    setShowModal(true);
    setModalTitle(title ? title : 'Need to pick cluster');
    setModalText(text ? text : `Missing cluster in "${section}"`);
  };

  const isClustersIncluded = () => {
    let isNoError = true;

    if (sections && sections.length) {
      sections.forEach((section) => {
        const sectionTitle =
          section.title['EN'] || Object.values(section.title)[0] || ' ';

        if (
          section.data.fourFielder &&
          (!section.data.fourFielder.x.cluster ||
            !section.data.fourFielder.y.cluster)
        ) {
          isNoError = false;
          errorModal({ section: sectionTitle });
        }

        if (
          section.data.personalityScore &&
          section.data.personalityScore.length
        ) {
          section.data.personalityScore.forEach((cluster) => {
            if (!cluster) {
              isNoError = false;
              errorModal({ section: sectionTitle });
            }
          });
        }

        if (
          section.data.scatterplotRows &&
          section.data.scatterplotRows.length
        ) {
          section.data.scatterplotRows.forEach((row) => {
            if (!row.cluster) {
              isNoError = false;
              errorModal({ section: sectionTitle });
            }
          });
        }
      });
    }
    return isNoError;
  };

  const validToPreview = () => {
    const errorText = 'There is nothing to preview';

    if (!template.availableLanguages.length) {
      errorModal({ title: 'Missing a language', text: errorText });
      return false;
    }

    if (!sections.length) {
      errorModal({ title: 'Missing sections', text: errorText });
      return false;
    }

    if (sections.length) {
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];
        if (!section.type) {
          errorModal({
            title: 'Missing section type',
            text: `Need to select type in "${section.title['EN'] ||
              Object.values(section.title)[0] ||
              ' '}"`,
          });
          return false;
        }
      }
    }

    if (!isClustersIncluded()) {
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isNoError = isClustersIncluded();

    if (isNoError) {
      const data = {
        ...template,
        sections,
        title,
        availableLanguages,
      };

      setPayload({
        method: 'patch',
        endpoint: '/api/omega/report-template',
        data,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  };

  return (
    <Container>
      {!isPreview && template && (
        <EditReportTemplate
          template={template}
          title={title}
          setTitle={setTitle}
          availableLanguages={availableLanguages}
          setAvailableLanguages={setAvailableLanguages}
          sections={sections}
          setSections={setSections}
          sectionOpen={sectionOpen}
          setSectionOpen={setSectionOpen}
          clusters={clusters}
          setSubmitPayload={handleSubmit}
        />
      )}
      {isPreview && template && <PreviewTemplate template={template} />}

      <Modal
        handleExit={() => setShowModal(false)}
        title={modalTitle}
        show={showModal}
        width='40rem'
      >
        <ModalText>{modalText}</ModalText>
        <Button
          onClick={() => {
            setShowModal(false);
          }}
          text='OK'
          primary
        />
      </Modal>

      <InputWrapper>
        <ButtonContainer>
          <InnerContainer>
            {/* <Button
              secondary
              type='button'
              text='Back'
              onClick={() => {
                // history.push('/admin/reports/view-all');
              }}
            /> */}
            <MarginButton />
            <Button
              onClick={() => {
                if (validToPreview()) {
                  setTemplate({
                    ...template,
                    sections,
                    title,
                    availableLanguages,
                  });
                  setIsPreview(!isPreview);
                }
              }}
              text={`${(isPreview && 'Edit') || 'Preview'} template`}
              secondary
            />
            <MarginButton />
            <Button onClick={handleSubmit} primary text='Save' type='submit' />
          </InnerContainer>
        </ButtonContainer>
      </InputWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
  margin-bottom: 5rem;
`;

const InnerContainer = styled.div`
  background: ${(props) => props.theme.color.white};
  max-width: 804px;
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  padding: ${(props) => props.theme.spacing.small};
  margin-bottom: ${(props) => props.theme.spacing.standard};
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

const ModalText = styled.div`
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin-bottom: 1rem;
`;

const MarginButton = styled.div`
  width: 6rem;
  @media ${device.mobileL} {
    height: ${(props) => props.theme.spacing.small};
  }
`;
