import React, { Fragment, useEffect, useState } from 'react';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import {
  getFourFielderData,
  getPersonalityScoreDiagramData,
  getPersonalityScoreTextmData,
  getPreviewUsers,
  getScatterplotRowData,
} from './reportHelper';
import { RenderSection } from '../show-report/TemplateSection';

export const PreviewSection = ({ section, language, index }) => {
  const [payload, setPayload] = useState({});
  const [sectionData, setSectionData] = useState(false);
  const [preview, setPreview] = useState(false);
  const [userSlug, setUserSlug] = useState('Börje Jönsson');
  const [users, setUsers] = useState([]);

  const { data } = useFetch(payload);

  //TODO set up data
  useEffect(() => {
    if (section && section.data) {
      switch (section.type) {
        case 1:
          if (
            section.data.scatterplotRows &&
            section.data.scatterplotRows.length
          ) {
            const clusters = [];
            section.data.scatterplotRows.forEach((row) => {
              row.cluster && clusters.push(row.cluster);
            });
            setPayload({
              endpoint: '/api/omega/clusters/ids',
              params: { clusters: clusters },
            });
          }
          break;
        case 2:
          if (
            section.data.fourFielder &&
            section.data.fourFielder.x &&
            section.data.fourFielder.x.cluster &&
            section.data.fourFielder.y &&
            section.data.fourFielder.y.cluster
          ) {
            setPayload({
              endpoint: '/api/omega/clusters/ids',
              params: {
                clusters: [
                  section.data.fourFielder.x.cluster,
                  section.data.fourFielder.y.cluster,
                ],
              },
            });
          }
          break;

        case 3:
        case 4:
        case 5:
          setPayload({
            endpoint: '/api/omega/clusters/ids',
            params: { clusters: section.data.personalityScore },
          });
          break;

        default:
          break;
      }
    }
  }, [section]);

  useEffect(() => {
    if (data && data.data) {
      let rows =
        section.data.scatterplotRows && section.data.scatterplotRows.length
          ? [...section.data.scatterplotRows]
          : section.data.personalityScore &&
            section.data.personalityScore.length
          ? [...section.data.personalityScore]
          : [];
      const preveiw = { ...section, data: {} };
      switch (section.type) {
        case 1:
          preveiw.data.scatterplotRows = rows.map((row) => {
            const newRow = { ...row };
            newRow.cluster = data.data.find(
              (cluster) => cluster._id === row.cluster
            );
            return newRow;
          });

          setSectionData(getScatterplotRowData(preveiw.data.scatterplotRows));
          setPreview(preveiw);
          break;

        case 2:
          preveiw.data.fourFielder = {
            x: {
              cluster: data.data[0],
              lowValue: section.data.fourFielder.x.lowValue,
              highValue: section.data.fourFielder.x.highValue,
            },
            y: {
              cluster: data.data[1],
              lowValue: section.data.fourFielder.y.lowValue,
              highValue: section.data.fourFielder.y.highValue,
            },
          };

          setSectionData(getFourFielderData);
          setPreview(preveiw);
          break;

        case 3:
        case 4:
        case 5:
          preveiw.data.personalityScore = rows
            .map((row) => data.data.find((cluster) => cluster._id === row))
            .filter(Boolean);

          setUsers(getPreviewUsers());
          setPreview(preveiw);
          break;

        default:
          break;
      }
    }
  }, [data, section]);

  useEffect(() => {
    if (preview && preview.data && preview.data.personalityScore) {
      switch (section.type) {
        case 3:
        case 4:
          setSectionData(
            getPersonalityScoreDiagramData(
              userSlug,
              preview.data.personalityScore
            )
          );
          break;

        case 5:
          setSectionData(
            getPersonalityScoreTextmData(preview.data.personalityScore)
          );
          break;

        default:
          break;
      }
    }
  }, [userSlug, preview, section]);

  return (
    <Fragment>
      {(preview && sectionData && (
        <RenderSection
          index={index}
          section={preview}
          data={sectionData}
          language={language}
          users={users}
          setUserSlug={setUserSlug}
          userSlug={userSlug}
        />
      )) ||
        // TODO: better error if data missing
        (preview && !sectionData && <>ERROR!!!!!!!!!!</>) || (
          <>Loading preview...</>
        )}
    </Fragment>
  );
};
