import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

export const CircleProgress = ({ percentage, size, signed }) => {
  const [strokeColour, setStrokeColour] = useState('#F29D39');

  //TODO: strokedasharray and strokedashoffset

  useEffect(() => {
    if (percentage === 100) {
      setStrokeColour('#33C764');
    }
    if (signed) {
      setStrokeColour('#227bb7');
    }
  }, [percentage, signed]);

  let appliedRadius;
  let appliedStroke;
  switch (size) {
    case '5.5':
      appliedRadius = 33;
      appliedStroke = 3;
      break;
    case '8':
      appliedRadius = 48;
      appliedStroke = 4;
      break;
    case '10':
      appliedRadius = 60;
      appliedStroke = 5;
      break;
    default:
      appliedRadius = 33;
      appliedStroke = 3;
  }
  const normalizedRadius = appliedRadius - appliedStroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <StyledProgressCircle signed={signed}>
      <SVG height={appliedRadius * 2} width={appliedRadius * 2}>
        <circle
          className='progress-circle'
          strokeWidth={appliedStroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={appliedRadius}
          cy={appliedRadius}
          strokeLinecap='round'
          stroke={strokeColour}
        />
      </SVG>
    </StyledProgressCircle>
  );
};

const rotate = keyframes`
  from {
    stroke-dasharray: 252 252;
    stroke-dashoffset: -252;
  }
  to {
    stroke-dasharray: 87 87;
    stroke-dashoffset: -252;
  }
`;

const StyledProgressCircle = styled.div`
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: absolute;

  & .progress-circle {
    fill: transparent;
    // animation: ${rotate} 0.5s ease-in 1;
  }
`;
const SVG = styled.svg``;
