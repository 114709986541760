import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Button } from '../../shared/button/Button';
import { InputField } from '../../shared/input/InputField';
import { Modal } from '../../shared/modal/Modal';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { CheckboxGroup } from '../../shared/input/CheckboxGroup';
import {
  listOfUsersRolePermissions,
  listAvailableCustomPermissions,
  rolesToPick,
} from '../../../helpers/permissions';
import { SelectInput } from '../../shared/input/SelectInput';

export const SettingsModal = ({
  object,
  setObjects,
  title,
  endpoint,
  showModal,
  setShowModal,
  type,
}) => {
  const [payload, setPayload] = useState({});
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedRole, setSelectedRole] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const [roles] = useState(rolesToPick());
  let validationSchema;

  if (type === 'custom-user-permissions') {
    validationSchema = Yup.object().shape({
      title: Yup.string(),
    });
  } else {
    validationSchema = Yup.object().shape({
      title: Yup.string().required('This is a required field.'),
    });
  }

  const { data, hasError } = useFetch(payload);
  const [listRolePermissions, setListRolePermissions] = useState();
  const [listCustomPermissions, setListCustomPermissions] = useState();
  const [selectedOptions, setSelectedOptions] = useState(
    object.customPermissions
  );

  const handleOptionChange = (newSelectedOptions) => {
    setSelectedOptions(newSelectedOptions);
  };

  const initialValues = {
    title: object.title,
  };

  useEffect(() => {
    if (data && data.data) {
      setObjects((objects) => {
        const updatedIx = objects.findIndex(
          (object) => object._id === data.data._id
        );
        if (updatedIx !== -1) objects[updatedIx] = data.data;
        return objects;
      });
      setIsUpdated(true);
    }
  }, [data, setShowModal, setObjects]);

  useEffect(() => {
    setSelectedRole(object.role);
  }, [object]);

  useEffect(() => {
    if (selectedRole) {
      const rolePermissions = listOfUsersRolePermissions(selectedRole);
      const availableCustomPermissions =
        listAvailableCustomPermissions(selectedRole);
      setListRolePermissions(rolePermissions);
      setListCustomPermissions(availableCustomPermissions);
    }
  }, [selectedRole]);

  return (
    <>
      <Modal
        show={showModal}
        handleExit={setShowModal}
        width={'40rem'}
        title={'Settings'}
      >
        {!hasError && !isUpdated && (
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async (values) => {
              const customPermissions = { ...selectedOptions };
              if (type === 'custom-user-permissions') {
                for (const key in customPermissions) {
                  if (customPermissions[key] === 'none') {
                    delete customPermissions[key];
                  }
                }

                if (!selectedRole) {
                  return setRoleError('This is a required field.');
                }
              }

              const newObject = {
                _id: object._id,
                title: values.title,
                customPermissions,
                role: selectedRole,
              };

              setPayload({
                method: 'patch',
                data: newObject,
                endpoint: endpoint,
                headers: {
                  'Content-Type': 'application/json',
                },
              });
            }}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                {type === 'custom-user-permissions' && (
                  <>
                    <SelectInput
                      id='role'
                      label='Role'
                      defaultValue={object.role}
                      placeholder='Select role'
                      data={roles}
                      onChange={({ target }) => setSelectedRole(target.value)}
                      message={roleError}
                    />
                    <>
                      <Label>{'Role based permissions'}</Label>
                      {listRolePermissions && (
                        <CheckboxGroup
                          options={listRolePermissions}
                          selectedOptions={listRolePermissions}
                          onChange={handleOptionChange}
                          disabled
                        />
                      )}
                      <Label>{'Custom permissions'}</Label>
                      {listCustomPermissions && (
                        <CheckboxGroup
                          options={listCustomPermissions}
                          selectedOptions={selectedOptions}
                          onChange={handleOptionChange}
                        />
                      )}
                    </>
                  </>
                )}
                {type !== 'custom-user-permissions' && (
                  <InputField
                    label='Title'
                    id='title'
                    type='text'
                    name='title'
                    placeholder=''
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    error={errors.title && touched.title}
                    message={errors.title && touched.title && `${errors.title}`}
                  />
                )}
                <Margin />
                <Button
                  primary
                  type='submit'
                  disabled={isSubmitting}
                  text={`Save ${title}`}
                />
              </Form>
            )}
          </Formik>
        )}
        {isUpdated && type !== 'custom-user-permissions' && (
          <>
            <h2>
              <Capitalise>{title}: </Capitalise>
              <Bold>{object.title}</Bold>
              <Bold> {data.data.title}</Bold>
            </h2>
            <Button text='OK' onClick={() => setShowModal(false)} />
          </>
        )}
        {isUpdated && type === 'custom-user-permissions' && (
          <>
            <h2>
              <Capitalise>
                <Bold>{'Successfuly updated permissions.'}</Bold>
              </Capitalise>
            </h2>
            <Button text='OK' onClick={() => setShowModal(false)} />
          </>
        )}
        {hasError && (
          <>
            <h2>Error updating {title}</h2>
            <Button text='OK' onClick={() => setShowModal(false)} />
          </>
        )}
      </Modal>
    </>
  );
};

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Margin = styled.div`
  height: ${(props) => props.theme.spacing.extraLarge};
`;

const Capitalise = styled.span`
  text-transform: capitalize;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const Label = styled.label`
  font-size: 16px;
  margin: 35px 0px 17px;
  font-weight: bold;
  color: #000;
  font-style: italic;
  color: ${(props) => props.theme.color.black};
`;
