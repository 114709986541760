import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../helpers/hooks/fetchData';
import styled from 'styled-components';
import { StandardTable } from '../../../components/shared/standard-table/StandardTable';
import { Button } from '../../../components/shared/button/Button';
import {
  AddCategory,
  EditCategory,
  DeleteCategory,
} from '../../../components/admin/categories';
import { SearchBar } from '../../../components/shared/search-bar/SearchBar';
import { userHasAccess } from '../../../helpers/permissions.js';
const itemsPerPage = 25;

export const AdminCategories = () => {
  const endpoint = '/api/omega/categories';
  const method = 'get';
  const [payload, setPayload] = useState({});
  const [moreCategoriesAvailable, setMoreCategoriesAvailable] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentCategory, setCurrentCategory] = useState({});
  const [params, setParams] = useState({
    searchString: '',
    sortPropName: '',
    sortHeading: null,
    skip: 0,
    limit: itemsPerPage,
  });
  const [categories, setCategories] = useState([]);
  const [updateInProgress, setUpdateInProgress] = useState(false);

  const { data } = useFetch(payload);

  useEffect(() => {
    setPayload({ endpoint, method, params });
  }, [params, endpoint, method]);

  useEffect(() => {
    setUpdateInProgress(false);
    const newCategories = (data && data.data) || [];
    setCategories((categories) => {
      return [...categories, ...newCategories];
    });
  }, [data]);

  useEffect(() => {
    const count = data && data.count;
    const moreCategoriesAvailable = count && params.limit + params.skip < count;
    setMoreCategoriesAvailable(moreCategoriesAvailable);
  }, [data, params.limit, params.skip]);

  const showMore = () => {
    const skip = params.skip + itemsPerPage;
    return setParams({ ...params, skip });
  };

  const editCategory = (id) => {
    setCurrentCategory(
      categories[categories.findIndex((cat) => cat._id === id)]
    );
    setShowEditModal(true);
  };
  const deleteCategory = (id) => {
    setCurrentCategory(
      categories[categories.findIndex((cat) => cat._id === id)]
    );
    setShowDeleteModal(true);
  };

  const dropDownOptions = [
    {
      title: 'Edit',
      onClick: editCategory,
    },
    {
      title: 'Delete',
      onClick: deleteCategory,
      delete: true,
    },
  ];

  const sortByProp = (propName) => {
    setUpdateInProgress(true);
    if (!propName) return false;
    //clear categories array
    setCategories([]);
    const skip = params.sortPropName !== propName ? 0 : params.skip;

    //if third press on same heading, clear the sorting
    if (params.sortHeading === -1 && propName === params.sortPropName) {
      return setParams({ ...params, sortHeading: '', sortPropName: '', skip });
    }

    //if not cleared, heading will be set to 1 on first click and -1 on second click
    const sortHeading =
      propName === params.sortPropName && params.sortHeading === 1 ? -1 : 1;
    const sortPropName = propName;
    return setParams({ ...params, sortHeading, sortPropName, skip });
  };

  const searchEntered = (s) => {
    setUpdateInProgress(true);
    //clear categories array
    setCategories([]);
    const skip = 0;
    return setParams({ ...params, searchString: s, skip });
  };

  return (
    <>
      <Container>
        <Margin />
        <SearchBar
          searchString={params.searchString}
          onChange={searchEntered}
          placeholder={'Search'}
        />
        {!updateInProgress && categories && categories.length > 0 && (
          <StandardTable
            type='categories'
            items={categories}
            onHeaderClick={sortByProp}
            dropDownOptions={dropDownOptions}
          />
        )}
        {!!moreCategoriesAvailable && (
          <ButtonContainer>
            <ButtonWidth>
              <Button onClick={showMore} text='Show more' primary />
            </ButtonWidth>
          </ButtonContainer>
        )}

        <EditCategory
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          category={currentCategory}
        />
        <DeleteCategory
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          category={currentCategory}
        />
        {userHasAccess("CATEGORIES") === "crud" && (<AddCategory
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
        />)}
      </Container>
    </>
  );
};

const Margin = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.large};
`;

const Container = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.bgGrey};
  padding-bottom: 7rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 3rem;
`;

const ButtonWidth = styled.div`
  width: 20rem;
`;
