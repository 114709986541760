import React, { Fragment } from 'react';
import { RenderScatterplotRow } from './types/ScatterplotRow';
import { RenderPersonalityScores } from './types/PersonalityScores';
import { RenderFourFielder } from './types/FourFielder';

export const RenderSection = ({
  section,
  data,
  language,
  users,
  setUserSlug,
  userSlug,
  index,
}) => {
  const renderSection = () => {
    switch (section.type) {
      case 1:
        return (
          <RenderScatterplotRow
            key={index}
            section={section}
            sectionsData={data}
            language={language}
          />
        );

      case 2:
        return (
          <RenderFourFielder
            key={index}
            section={section}
            sectionsData={data}
            language={language}
          />
        );

      case 3:
      case 4:
      case 5:
        return (
          <RenderPersonalityScores
            key={index}
            section={section}
            sectionsData={data}
            language={language}
            users={users}
            setUserSlug={setUserSlug}
            userSlug={userSlug}
          />
        );

      default:
        return null;
    }
  };

  return <Fragment>{section && renderSection()}</Fragment>;
};
