import React from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';

export const NoResultsFound = () => {
  return (
    <Container>
      <Title>No results found</Title>
      {/* eslint-disable-next-line */}
      <Text>{'We couldn\'t find any results based on your search.'}</Text>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media ${device.mobileL} {
    padding: 0 4rem;
  }
`;

const Title = styled.p`
  margin-top: 15rem;
  margin-bottom: 4rem;
  font-weight: bold;
  font-size: 2.6rem;
  line-height: 3rem;
  text-align: center;
  color: ${(props) => props.theme.color.noResultsFound};
  @media ${device.mobileL} {
    font-size: 1.8rem;
    line-height: 2.1rem;
    margin-bottom: 3rem;
    margin-top: 6rem;
  }
`;

const Text = styled.p`
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: ${(props) => props.theme.color.noResultsFound};
  text-align: center;
  @media ${device.mobileL} {
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
`;
