import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import styled from 'styled-components';
import { Modal } from '../../../shared/modal/Modal';
import { Button } from '../../../shared/button/Button';
import history from '../../../../history';

export const CloneQuestion = ({ question, showModal, setShowModal }) => {
  const [payload, setPayload] = useState({
    title: '',
  });
  const { data, hasError } = useFetch(payload);
  const errorText = 'Error cloning question';

  useEffect(() => {
    if (data) {
      setShowModal(false);
      if (data && data.clone) {
        return history.push(`/admin/questions/edit-question/${data.slug}`, {
          slug: data.slug,
        });
      }
    }
  }, [data, setShowModal]);

  const cloneQuestion = () => {
    if (!question || !question._id) {
      return false;
    }

    setPayload({
      endpoint: '/api/omega/questions/clone-question/' + question._id,
      method: 'post',
    });
  };

  return (
    <>
      {showModal && (
        <Modal
          show={showModal}
          handleExit={setShowModal}
          title='Clone question'
          width={'40rem'}
        >
          {!hasError && (
            <>
              <Label>Are you sure you want to clone this question?</Label>
              <Margin />
              <Button
                type='submit'
                onClick={cloneQuestion}
                primary
                text='Clone'
              />

              <PaddingBetweenButton />
              <Button
                onClick={() => setShowModal(false)}
                secondary
                text='Cancel'
                type='reset'
              />
            </>
          )}
          {hasError && (
            <>
              <ErrorMessage>{errorText}</ErrorMessage>
              <Button secondary onClick={() => setShowModal(false)} text='OK' />
            </>
          )}
        </Modal>
      )}
    </>
  );
};

const Label = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
`;
const ErrorMessage = styled.div`
  color: red;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 30px;
`;

const Margin = styled.div`
  height: ${(props) => props.theme.spacing.small};
`;
const PaddingBetweenButton = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;
