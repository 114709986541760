import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { Tabs } from '../../../components/admin/candidates/Tabs';
import { Thumbnail } from '../../../components/shared/thumbnail/Thumbnail';
import { device } from '../../../styles/device';
import { SmallLabel } from '../../../components/shared/labels/SmallLabel';
import { CountDownActiveTest } from '../../../components/admin/candidates/CountDownActiveTest';
import { getCountdownString, calculateProgress } from '../../../helpers/common';

//TODO: actual progress percentage

export const Candidate = (props) => {
  const [payload, setPayload] = useState({});
  const [user, setUser] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [timeStarted, setTimeStarted] = useState(false);
  const [active, setActive] = useState(false);
  const [timeRunningOut, setTimeRunningOut] = useState(false);
  const [expired, setExpired] = useState(false);
  const slug = props.match.params.child;
  const endpoint = `/api/omega/users/?slug=${slug}`;
  const method = 'get';

  const { data } = useFetch(payload);

  useEffect(() => {
    setPayload({ endpoint, method });
  }, [endpoint]);

  useEffect(() => {
    if (data && data.data) {
      setUser(data.data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (user && user.ongoingTest && user.ongoingTest.dueDate && !timeStarted) {
      setTimeStarted(true);
      const setOngoingTestTime = () => {
        const dueDate = new Date(user.ongoingTest.dueDate);
        const msRemainging = dueDate - Date.now();
        const timeString = getCountdownString(msRemainging);

        if (msRemainging <= 0) {
          setTimeRemaining('00:00:00'); //when no more time
          return setExpired(true);
        } else if (msRemainging < 1000 * 15) {
          //set to expired (red) when less than 15 seconds
          setExpired(true);
        } else if (msRemainging < 1000 * 60 * 3) {
          //set time running out (orange) when less than 3 minutes
          setTimeRunningOut(true);
        } else {
          setActive(true);
        }

        setTimeRemaining(timeString);
        setTimeout(setOngoingTestTime, 1000);
      };
      setOngoingTestTime();
    }
  }, [user, timeStarted]);

  return (
    <Container>
      {user && timeRemaining && (
        <CountDownContainerTabletAndDown showWhenInTabletSize>
          <CountDownActiveTest
            stylingForTabletAndDown
            timeRemaining={timeRemaining}
            active={active}
            timeRunningOut={timeRunningOut}
            expired={expired}
          />
        </CountDownContainerTabletAndDown>
      )}
      {user && (
        <ProfileContainer>
          <ThumbnailContainer>
            <Thumbnail
              image={user.profileImage && user.profileImage.original}
              name={user.name}
              size='10'
              progress={calculateProgress(user)}
              signed={user.isSigned}
            />
          </ThumbnailContainer>
          <InnerContainer>
            <TextContainer>
              <Name>{user.name}</Name>
              <CurrentPosition>
                {user.info.position && user.info.position}
                {user.info.omegaConsultant && user.info.position && ' - '}
                {user.info.omegaConsultant && user.info.omegaConsultant.name}
              </CurrentPosition>
            </TextContainer>
            {user.isInternalRecruit && (
              <SmallLabel text='Internal' internal marginTop />
            )}
          </InnerContainer>
          {user && timeRemaining && (
            <CountDownWrapperDesktop hideWhenTabletSize>
              <CountDownActiveTest
                timeRemaining={timeRemaining}
                active={active}
                timeRunningOut={timeRunningOut}
                expired={expired}
              />
            </CountDownWrapperDesktop>
          )}
        </ProfileContainer>
      )}
      <Margin />

      {user && <Tabs user={user} setUser={setUser} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -${(props) => props.theme.spacing.extraLarge};
  margin-top: ${(props) => props.theme.spacing.extraLarge};
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  position: relative;
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
    margin-bottom: 0rem;
  }
`;

const CountDownContainerTabletAndDown = styled.div`
  display: none;
  ${(props) =>
    props.showWhenInTabletSize &&
    css`
      @media ${device.tablet} {
        display: block;
      }
    `}
`;

const CountDownWrapperDesktop = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  ${(props) =>
    props.hideWhenTabletSize &&
    css`
      @media ${device.tablet} {
        display: none;
      }
    `}
`;

const InnerContainer = styled.div`
  padding-left: ${(props) => props.theme.spacing.large};
`;

const Name = styled.p`
  font-size: 2rem;
  line-height: 2.3rem;
  font-weight: bold;
  @media ${device.mobileL} {
    font-size: 1.6rem;
  }
`;

const CurrentPosition = styled.p`
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-top: 1rem;
  @media ${device.mobileL} {
    font-size: 1.3rem;
  }
`;

const Margin = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.large};
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
//needed for sizing issue with flex on parent container, do not remove
const ThumbnailContainer = styled.div``;
