// The types of themplates to pick from
// _id = case/type
export const templateTypes = [
  { title: 'Scatterplot Row', _id: 1 },
  { title: 'Four fielder', _id: 2 },
  { title: 'Personality score scatterplot', _id: 3 },
  { title: 'Personality score diagram', _id: 4 },
  { title: 'Personality score text', _id: 5 },
];

// Test data for scatterplot
const scatterplotData = [
  [
    {
      x: 1,
      stanine: 1,
      name: 'LN',
      fullName: 'Lars Nilsson',
      bottomText: false,
    },
    {
      x: 1,
      stanine: 1.0452,
      name: 'SB',
      fullName: 'Sofia Bergström',
      bottomText: true,
    },
    {
      x: 1.15,
      stanine: 1.07312,
      name: 'JL',
      fullName: 'Johan Lundqvist',
      bottomText: false,
    },
    {
      x: 3,
      stanine: 3,
      name: 'JW',
      fullName: 'Josefin Wallin',
      bottomText: false,
    },
    {
      x: 4,
      stanine: 4,
      name: 'WJ',
      fullName: 'Wilhelm Johansson',
      bottomText: false,
    },
    {
      x: 5,
      stanine: 5,
      name: 'DL',
      fullName: 'David Lindström',
      bottomText: false,
    },
    {
      x: 5.2,
      stanine: 5.2,
      name: 'DS',
      fullName: 'Daniel Svensson',
      bottomText: false,
    },
    {
      x: 5.2,
      stanine: 5.24231,
      name: 'QW',
      fullName: 'Qarin Wallin',
      bottomText: true,
    },
    {
      x: 5.5,
      stanine: 5.5,
      name: 'EW',
      fullName: 'Eva Wikström',
      bottomText: false,
    },
    {
      x: 6,
      stanine: 6,
      name: 'PS',
      fullName: 'Patrik Svensson',
      bottomText: false,
    },
    {
      x: 7,
      stanine: 7,
      name: 'PL',
      fullName: 'Peter Larsson',
      bottomText: false,
    },
    {
      x: 8,
      stanine: 8,
      name: 'WS',
      fullName: 'Wilma Sandberg',
      bottomText: false,
    },
    {
      x: 8.2,
      stanine: 8.2,
      name: 'AS',
      fullName: 'Anders Söderberg',
      bottomText: false,
    },
    {
      x: 8.8,
      stanine: 8.8,
      name: 'SD',
      fullName: 'Sara Dahlberg',
      bottomText: false,
    },
    {
      x: 9,
      stanine: 9,
      name: 'DF',
      fullName: 'Daniel Forsberg',
      bottomText: false,
    },
  ],
  [
    {
      x: 1,
      stanine: 1,
      name: 'EW',
      fullName: 'Eva Wikström',
      bottomText: false,
    },
    {
      x: 1.2,
      stanine: 1.2,
      name: 'QW',
      fullName: 'Qarin Wallin',
      bottomText: false,
    },
    {
      x: 2,
      stanine: 2,
      name: 'DL',
      fullName: 'David Lindström',
      bottomText: false,
    },
    {
      x: 3,
      stanine: 3,
      name: 'PS',
      fullName: 'Patrik Svensson',
      bottomText: true,
    },
    {
      x: 3,
      stanine: 3,
      name: 'WJ',
      fullName: 'Wilhelm Johansson',
      bottomText: false,
    },
    {
      x: 3.5,
      stanine: 3.3,
      name: 'DF',
      fullName: 'Daniel Forsberg',
      bottomText: false,
    },
    {
      x: 4.2,
      stanine: 4.2,
      name: 'DS',
      fullName: 'Daniel Svensson',
      bottomText: false,
    },
    {
      x: 4.2,
      stanine: 4.2123,
      name: 'SD',
      fullName: 'Sara Dahlberg',
      bottomText: true,
    },
    {
      x: 5.2,
      stanine: 5.2,
      name: 'JW',
      fullName: 'Josefin Wallin',
      bottomText: false,
    },
    {
      x: 5.2,
      stanine: 5.21123,
      name: 'AS',
      fullName: 'Anders Söderberg',
      bottomText: true,
    },
    {
      x: 6,
      stanine: 6,
      name: 'WS',
      fullName: 'Wilma Sandberg',
      bottomText: false,
    },
    {
      x: 6,
      stanine: 6.0123,
      name: 'JL',
      fullName: 'Johan Lundqvist',
      bottomText: true,
    },
    {
      x: 6.6,
      stanine: 6,
      name: 'PL',
      fullName: 'Peter Larsson',
      bottomText: false,
    },
    {
      x: 7,
      stanine: 7,
      name: 'SB',
      fullName: 'Sofia Bergström',
      bottomText: false,
    },
    {
      x: 9,
      stanine: 9,
      name: 'LN',
      fullName: 'Lars Nilsson',
      bottomText: false,
    },
  ],
];

const users = [
  { name: 'Börje Jönsson', slug: 'Börje Jönsson', _id: 1 },
  { name: 'Daniel Lennartsson', slug: 'Daniel Lennartsson', _id: 2 },
  { name: 'Johan Andersson', slug: 'Johan Andersson', _id: 3 },
  { name: 'Johanna Johansson', slug: 'Kevin Johansson', _id: 4 },
  { name: 'Anders Andersson', slug: 'Anders Andersson', _id: 5 },
  { name: 'Hanna Stark', slug: 'Hanna Stark', _id: 6 },
];

const getFacetsData = (facets) => {
  const newFacets = [];
  facets.forEach((data, index) => {
    const newFacet = {
      _id: data.facet._id,
      title: data.facet.title,
      data: index % 2 ? scatterplotData[1] : scatterplotData[0],
    };

    newFacets.push(newFacet);
  });

  return newFacets;
};

export const getScatterplotRowData = (data) => {
  const testData = [];
  if (data && data.length)
    data.forEach((row, index) => {
      if (index % 2) {
        testData.push({
          cluster: scatterplotData[1],
          facets: getFacetsData(row.cluster.facets),
        });
      } else {
        testData.push({
          cluster: scatterplotData[0],
          facets: getFacetsData(row.cluster.facets),
        });
      }
    });
  return testData;
};

export const getFourFielderData = () => {
  const data = [];
  for (const key in scatterplotData[0]) {
    data.push({
      x: scatterplotData[0][key].x,
      stanine: scatterplotData[0][key].stanine,
      y: scatterplotData[1][key].x,
      stanineY: scatterplotData[1][key].stanine,
      name: scatterplotData[0][key].fullName,
      fullName: scatterplotData[0][key].fullName,
      bottomText:
        scatterplotData[0][key].bottomText &&
        scatterplotData[1][key].bottomText,
    });
  }
  return data;
};

export const getPreviewUsers = () => {
  return users;
};

export const getPersonalityScoreDiagramData = (slug, clusters) => {
  const data = {
    name: slug,
    clusters: [],
  };
  for (const cluster of clusters) {
    const newCluster = {
      title: cluster.title,
      facets: [],
    };

    for (const facet of cluster.facets) {
      const newFacet = {
        _id: facet.facet._id,
        title: facet.facet.title,
        titles: facet.facet.titles,
        rp: Math.floor(Math.random() * 39 + 1),
        stan: Math.floor(Math.random() * 9 + 1),
      };
      newCluster.facets.push(newFacet);
    }

    data.clusters.push(newCluster);
  }
  return data;
};

export const getPersonalityScoreTextmData = (clusters) => {
  const data = [];
  const bigestDiff = [];

  for (const cluster of clusters) {
    const newCluster = {
      title: cluster.title,
      data: [],
    };

    cluster.facets.sort(() => Math.random() - 0.5);

    for (const facet of cluster.facets) {
      if (facet.facet.descriptions) {
        if (bigestDiff.length < 3) {
          if (bigestDiff.length % 2) {
            bigestDiff.push({
              value: 'low',
              descriptions: facet.facet.descriptions,
            });
          } else {
            bigestDiff.push({
              value: 'high',
              descriptions: facet.facet.descriptions,
            });
          }
        }
        if (newCluster.data.length < 3) {
          if (newCluster.data.length % 2) {
            newCluster.data.push({
              value: 'low',
              descriptions: facet.facet.descriptions,
            });
          } else {
            newCluster.data.push({
              value: 'high',
              descriptions: facet.facet.descriptions,
            });
          }
        }
      }
    }

    data.push(newCluster);
  }
  return [{ data: bigestDiff }, ...data];
};
