import React from 'react';
import styled, { css } from 'styled-components';

export const LanguageItem = ({ flag, code, onClick, active }) => (
  <Container onClick={onClick} active={active}>
    <FlagContainer>
      <Flag src={flag} />
    </FlagContainer>
    <Code active={active}>{code}</Code>
  </Container>
);

const Container = styled.div`
  height: 7.7rem;
  width: 6.2rem;
  padding-top: ${(props) => props.theme.spacing.extraSmall};
  padding-bottom: ${(props) => props.theme.spacing.extraSmall};
  padding-left: ${(props) => props.theme.spacing.standard};
  padding-right: ${(props) => props.theme.spacing.standard};
  background-color: ${(props) => props.theme.color.bgGrey};
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 1.8rem;
  margin-bottom: 1rem;
  margin-right: 0.8rem;
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.color.offGrey};
    `}
`;

const FlagContainer = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 0.05rem 1rem rgba(0, 0, 0, 0.12);
`;

const Flag = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Code = styled.span`
  color: ${(props) => props.theme.color.textGrey};
  margin-top: ${(props) => props.theme.spacing.extraSmall};
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.color.white};
    `}
`;

/* WRAPPER START */
export const LanguageItemWrapper = ({ children }) => {
  return <WrapperContainer>{children}</WrapperContainer>;
};

const WrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: -1rem;
`;
/* WRAPPER END */
