import React from 'react';
import styled, { css } from 'styled-components';
import { plus } from '../assets/icons';

export const Button = ({
  text,
  onClick,
  close,
  primary,
  warning,
  success,
  add,
  type,
  isDisabled,
  submit,
  secondary,
  remove,
  small,
}) => {
  return (
    <Container
      onClick={onClick}
      close={close}
      primary={primary}
      secondary={secondary}
      warning={warning}
      success={success}
      add={add}
      type={type}
      isDisabled={isDisabled}
      submit={submit}
      remove={remove}
      small={small}
    >
      {text}
      {add && <img src={plus} alt='add' />}
    </Container>
  );
};

const Container = styled.button`
  display: block;
  border-radius: 0.4rem;
  padding: 2rem 0;
  font-size: ${(props) => props.theme.size.buttonText};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  line-height: 1.9rem;
  outline: none;
  border: 2px solid transparent;
  &:hover {
    cursor: pointer;
  }
  ${(props) =>
    props.close &&
    css`
      background-color: ${(props) => props.theme.color.lightBlue};
      color: ${(props) => props.theme.color.white};
    `} 
    ${(props) => props.small && 'width: 45%; padding: 1rem; min-width: 12rem'}
  ${(props) =>
    props.primary &&
    css`
      background-color: ${(props) => props.theme.color.primary};
      color: ${(props) => props.theme.color.white};
    `}
    ${(props) =>
      props.secondary &&
      css`
        background-color: ${(props) => props.theme.color.bgGrey};
        color: ${(props) => props.theme.color.mediumBlue};
      `}
    ${(props) =>
      props.warning &&
      css`
        background-color: ${(props) => props.theme.color.error};
        color: ${(props) => props.theme.color.white};
      `}
      ${(props) =>
        props.success &&
        css`
          color: ${(props) => props.theme.color.white};
        `}
        ${(props) =>
          props.remove &&
          css`
            color: ${(props) => props.theme.color.error};
            border: 2px solid ${(props) => props.theme.color.error};
            background-color: ${(props) => props.theme.color.white};
          `}
        ${(props) =>
          props.add &&
          css`
            position: fixed;
            bottom: 2rem;
            right: 2rem;
            width: 6rem;
            height: 6rem;
            z-index: 999;
            box-shadow: 0 1.5rem 1.6rem rgba(0, 0, 0, 0.2);
            background: ${(props) => props.theme.color.add};
            color: #fff;
            border-radius: 50%;
            padding: 0;
            border: none;
            cursor: pointer;
            text-transform: none;
            overflow: visible;
            font-size: 2rem;
          `}
`;
