import React from 'react';
import styled from 'styled-components';
import { amountMinus, amountPlus } from '../../../shared/assets/icons';
import { InputWrapper } from '../../../shared/input/InputWrapper';

export const QuestionsPerPage = ({ questionsPerPage, setQuestionsPerPage }) => {
  const onClick = (amount) => {
    const newAmount = questionsPerPage + amount;

    setQuestionsPerPage(newAmount > 0 ? newAmount : 1);
  };
  return (
    <>
      <InputWrapper marginTop title='Choose questions per page'>
        <>
          <AmountContainer>
            <AmountButton role='button' onClick={() => onClick(-1)}>
              <AmountIcon src={amountMinus} alt='minus' />
            </AmountButton>
            <Amount>{questionsPerPage || 3}</Amount>
            <AmountButton role='button' onClick={() => onClick(1)}>
              <AmountIcon src={amountPlus} alt='plus' />
            </AmountButton>
          </AmountContainer>
        </>
      </InputWrapper>
    </>
  );
};
const AmountContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const AmountButton = styled.div`
  cursor: pointer;
`;
const AmountIcon = styled.img``;
const Amount = styled.div`
  margin-top: 0.8rem;
  margin-right: ${(props) => props.theme.spacing.standard};
  margin-left: ${(props) => props.theme.spacing.standard};
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  height: 1rem;
  width: 2rem;
  text-align: center;
`;
