import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useFetch } from '../../../helpers/hooks/fetchData';
import history from '../../../history';
import { device } from '../../../styles/device';
import { ContentContext } from '../../../helpers/contentContext';

export const FinishTest = ({ index }) => {
  const content = useContext(ContentContext) || {};
  const [payload, setPayload] = useState({});

  const { data } = useFetch(payload);

  useEffect(() => {
    if (data && data.isCompleted) {
      return history.push(`/candidate/test/thank-you/${index}`);
    }
  }, [data, index]);

  const finishTest = () => {
    setPayload({
      method: 'get',
      params: { setComplete: true, index },
      endpoint: '/api/omega/candidate-test/finish-test',
    });
  };

  return (
    <FinishButton onClick={finishTest}>
      {content.finishTest || 'Finish'}
    </FinishButton>
  );
};

const FinishButton = styled.button`
  background: ${(props) => props.theme.color.darkBlue};
  color: ${(props) => props.theme.color.white};
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  font-style: normal;
  font-size: 2rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  font-family: 'futura-pt', sans-serif;
  height: 6.2rem;
  padding: 0 7rem;
  @media ${device.mobileL} {
    width: 100%;
  }
`;
