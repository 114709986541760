import React, { useState, useEffect, Fragment } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/shared/button/Button';
import { InputField } from '../../../components/shared/input/InputField';
import { Modal } from '../../../components/shared/modal/Modal';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { device } from '../../../styles/device';
import { InputWrapper } from '../../../components/shared/input/InputWrapper';
import {
  LanguageItem,
  LanguageItemWrapper,
} from '../../../components/admin/questions/edit-question/LanguageItem';
import { Checkboxes } from '../../../components/shared/input/Checkboxes';

export const EditCluster = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [payload, setPayload] = useState({});
  const [facetsPayload, setFacetsPayload] = useState({});
  const [languagePayload] = useState({ endpoint: '/api/omega/languages/' });
  const [languages, setLanguages] = useState(null);
  const [cluster, setCluster] = useState(null);
  const [chosenLanguage, setChosenLanguage] = useState([]);
  const paramsData = useParams();
  const slug =
    (paramsData && paramsData.slug) ||
    (props.location && props.location.state && props.location.state.slug);
  const endpoint = `/api/omega/clusters/slug/${slug}`;

  //TODO fixa så den passar kluster beskrivning
  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This is a required field.'),
  });

  const { data, isLoading, hasError } = useFetch(payload, true);
  const { data: facetsData } = useFetch(facetsPayload, true);
  const languagesData = useFetch(languagePayload);

  const initialValues = cluster && {
    title: cluster.title,
    titles: cluster.titles || {},
    facets: cluster.facets || [],
    standardDeviation: cluster.standardDeviation || 0,
    averageValue: cluster.averageValue || 15,
  };

  useEffect(() => {
    if (data && data.data) {
      data.data.availableLanguages = data.data.availableLanguages || [];
      setCluster(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (languagesData && languagesData.data && languagesData.data.data) {
      setLanguages(languagesData.data.data);
    }
  }, [languagesData]);

  useEffect(() => {
    setPayload({ endpoint, method: 'get' });
    setFacetsPayload({ endpoint: '/api/omega/facets', method: 'get' });
  }, [endpoint]);

  const addLanguage = (language, key) => {
    const newChosenLanguage = chosenLanguage;
    const langIx = cluster.availableLanguages.findIndex(
      (availableLanguage) => availableLanguage.code === language.code
    );
    const chosenIx = newChosenLanguage.findIndex(
      (chosenKey) => chosenKey === key
    );
    if (langIx === -1) {
      cluster.availableLanguages.push(language);
      newChosenLanguage.push(key);
    } else {
      cluster.availableLanguages.splice(langIx, 1);
      newChosenLanguage.splice(chosenIx, 1);
    }

    setChosenLanguage([...newChosenLanguage]);
  };

  useEffect(() => {
    if ((data && data.data && data.clusterSaved) || hasError) {
      setShowModal(true);
    }
  }, [data, hasError]);

  return (
    <>
      <Container>
        <InnerContainer>
          {!isLoading && cluster && (
            <>
              <MainTitle>Edit cluster</MainTitle>
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={async (values) => {
                  const availableLanguages = cluster.availableLanguages.map(
                    (lang) => lang._id
                  );
                  const newCluster = {
                    _id: cluster._id,
                    title: values.title,
                    titles: values.titles,
                    facets: values.facets,
                    standardDeviation: values.standardDeviation,
                    averageValue: values.averageValue,
                    availableLanguages,
                  };

                  setPayload({
                    method: 'patch',
                    data: newCluster,
                    endpoint: '/api/omega/clusters',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  });
                }}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <InnerForm>
                      <LeftColumn>
                        <InputWrapper key={1} title='Title'>
                          <InputField
                            label='Internal title'
                            id='title'
                            type='text'
                            name='title'
                            placeholder=''
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            error={errors.title && touched.title}
                            message={
                              errors.title && touched.title && `${errors.title}`
                            }
                          />
                          {cluster.availableLanguages.map((language) => (
                            <Fragment key={language.code}>
                              <Title>{language.language} title</Title>
                              <Field
                                as={InputField}
                                id={`title.${language.language}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.titles[language.code] || ''}
                                error={errors.titles && touched.titles}
                                type='text'
                                name={`titles.${language.code}`}
                                placeholder={`${language.language} title`}
                              />
                            </Fragment>
                          ))}
                        </InputWrapper>
                        <Margin small />

                        <InputWrapper key={2} title='Standard deviation'>
                          <InputField
                            id={'standardDeviation'}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.standardDeviation || 0}
                            error={
                              errors.standardDeviation &&
                              touched.standardDeviation
                            }
                            name={'standardDeviation'}
                            type='number'
                            step='any'
                          />
                        </InputWrapper>
                        <Margin small />
                        <InputWrapper title='Average value'>
                          <InputField
                            id='averageValue'
                            type='number'
                            name='averageValue'
                            placeholder=''
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.averageValue}
                            error={errors.averageValue && touched.averageValue}
                            message={
                              errors.averageValue &&
                              touched.averageValue &&
                              `${errors.averageValue}`
                            }
                          />
                        </InputWrapper>
                      </LeftColumn>
                      <RightColumn>
                        <InputWrapper key={1} title='Languages'>
                          <LanguageItemWrapper>
                            {languages &&
                              languages.length > 0 &&
                              languages.map((language, key) => (
                                <LanguageItem
                                  key={key}
                                  onClick={() => addLanguage(language, key)}
                                  code={language.code}
                                  flag={language.flag}
                                  active={cluster.availableLanguages.some(
                                    (pickedLanguage) =>
                                      pickedLanguage._id === language._id
                                  )}
                                />
                              ))}
                          </LanguageItemWrapper>
                        </InputWrapper>
                        <InputWrapper key={2} title='Facets'>
                          {facetsData && facetsData.data.length && (
                            <Checkboxes
                              onChange={({ target }) => {
                                const valueIx = values.facets.findIndex(
                                  (facet) => target.value === facet.facet
                                );
                                if (valueIx === -1) {
                                  values.facets.push({
                                    facet: target.value,
                                    factoryLoading: 0,
                                  });
                                } else {
                                  values.facets.splice(valueIx, 1);
                                }
                              }}
                              values={values.facets}
                              data={facetsData.data}
                              onBlur={handleBlur}
                              name='facet'
                              handleFactoryBlur={handleBlur}
                              handleFactoryChange={handleChange}
                              factoryLoading
                            />
                          )}
                        </InputWrapper>
                      </RightColumn>
                    </InnerForm>
                    <Margin />
                    <Button
                      primary
                      type='submit'
                      disabled={isSubmitting}
                      text={'Save cluster'}
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
        </InnerContainer>
      </Container>
      <Modal
        handleExit={() => setShowModal(false)}
        title={(hasError && 'Something went wrong') || 'Cluster saved'}
        show={showModal}
        width='40rem'
      >
        <ModalContent>
          <Button onClick={() => setShowModal(false)} text='OK' primary />
        </ModalContent>
      </Modal>
    </>
  );
};

const MainTitle = styled.p`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
`;

const Margin = styled.div`
  height: ${(props) =>
    props.small ? props.theme.spacing.small : props.theme.spacing.extraLarge};
`;

const LeftColumn = styled.div`
  flex: 1;
  height: 100%;
  max-width: 400px;
  width: 100%;
  @media ${device.tablet} {
    max-width: 100%;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 1rem;
  max-width: 400px;
  @media ${device.tablet} {
    max-width: 100%;
  }
`;

const Container = styled.div`
  background: ${(props) => props.theme.color.bgGrey};
  min-height: calc(100vh - 224px);
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${device.laptop} {
    padding: 0 ${(props) => props.theme.spacing.extraLarge};
  }
  @media ${device.mobileL} {
    padding: 0 ${(props) => props.theme.spacing.small};
  }
`;

const InnerContainer = styled.div`
  width: 804px;
//   padding-top: ${(props) => props.theme.spacing.standard};
`;

const InnerForm = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Title = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: ${(props) =>
    props.error
      ? `1px solid ${props.theme.color.error}`
      : `1px solid ${props.theme.color.darkBlue}`};
`;

const ModalContent = styled.div`
  font-size: 4rem;
  flex-direction: column;
`;
