import React from 'react';
import styled from 'styled-components';
import { FormLabel } from './FormLabel';
import { device } from '../../../styles/device';

export const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  icon,
  iconActive,
  text,
}) => {
  return (
    <InputContainer>
      {icon && (
        <>
          <IconRadio
            name={name}
            id={id}
            type='radio'
            value={id}
            checked={id === value}
            onChange={onChange}
            onBlur={onBlur}
            className='radio-button'
          />
          <LargeIconLabel htmlFor={id}>
            {icon &&
              iconActive &&
              (id === value ? (
                <LargeIcon src={iconActive} />
              ) : (
                <LargeIcon src={icon} />
              ))}
            {label}
          </LargeIconLabel>
        </>
      )}
      {text && (
        <>
          <Label htmlFor={id}>{label}</Label>
          <Radio
            name={name}
            id={id}
            type='radio'
            value={id}
            checked={id === value}
            onChange={onChange}
            onBlur={onBlur}
            className='radio-button'
          />
        </>
      )}
    </InputContainer>
  );
};

export const RadioButtonGroup = ({
  label,
  children,
  message,
  id,
  error,
  iconAfterLabel,
}) => {
  return (
    <GroupContainer id={id} error={error}>
      {label && (
        <FormLabel
          label={label}
          id={id}
          error={error}
          message={message}
          iconAfterLabel={iconAfterLabel}
        />
      )}
      <ButtonContainer>{children}</ButtonContainer>
    </GroupContainer>
  );
};

export const TypeRadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  icon,
  iconActive,
}) => {
  return (
    <IconLabel htmlFor={id}>
      <TypeIconRadio
        name={name}
        id={id}
        type='radio'
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className='radio-button'
        required
      />
      <InsideLabelWrapper>
        {icon &&
          iconActive &&
          (id === value ? <Icon src={iconActive} /> : <Icon src={icon} />)}
        <LabelText>{label}</LabelText>
      </InsideLabelWrapper>
    </IconLabel>
  );
};
export const TypeRadioButtonGroup = ({ children, id, error }) => {
  return (
    <TypeGroupContainer id={id} error={error}>
      {children}
    </TypeGroupContainer>
  );
};
const InputContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.small};
`;

const IconRadio = styled.input`
  visibility: hidden;
  height: 0;
  width: 0;
  cursor: pointer;
  &:checked + label {
    background-color: ${(props) => props.theme.color.selectedGrey};
    color: ${(props) => props.theme.color.white};
  }
`;

const LargeIconLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
  background-color: ${(props) => props.theme.color.darkGrey};
  color: ${(props) => props.theme.color.textGrey};
  width: 10rem;
  height: 11rem;
  border-radius: 1.8rem;
  @media ${device.mobileM} {
    width: 8rem;
    height: 9rem;
  }
  @media ${device.mobileS} {
    width: 7rem;
    height: 8rem;
  }
`;

const IconLabel = styled.label`
  width: 16.6rem;
  height: 5rem;
  cursor: pointer;
  background: ${(props) => props.theme.color.darkGrey};
  color: ${(props) => props.theme.color.textGrey};
  border-radius: 0.4rem;
  transition: background-color 0.1s ease-in-out;
  margin-bottom: ${(props) => props.theme.spacing.small};
  @media (max-width: 800px) {
    width: 15.5rem;
  }
  @media (max-width: 504px) {
    width: 16.6rem;
  }
  @media ${device.mobileL} {
    width: 16rem;
  }
  @media ${device.mobileM} {
    width: 15rem;
  }
  @media (max-width: 350px) {
    width: 14rem;
  }
  @media (max-width: 340px) {
    width: 13.5rem;
  }
  @media ${device.mobileS} {
    width: 13.5rem;
  }
`;

const TypeIconRadio = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + div > span {
    color: ${(props) => props.theme.color.white};
  }
  &:checked + div {
    background: ${(props) => props.theme.color.selectedGrey};
  }
`;

const GroupContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing.medium};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media ${device.mobileL} {
    font-size: 1.4rem;
    flex-wrap: nowrap;
  }
`;

const TypeGroupContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: -1.5rem;
`;

const LargeIcon = styled.img`
  height: 4rem;
  width: 4rem;
  margin-bottom: ${(props) => props.theme.spacing.small};
  @media ${device.mobileL} {
    height: 3rem;
    width: 3rem;
    margin-bottom: ${(props) => props.theme.spacing.extraSmall};
  }
`;

const Icon = styled.img`
  margin-right: ${(props) => props.theme.spacing.small};
  @media (max-width: 800px) {
    margin-right: ${(props) => props.theme.spacing.extraSmall};
  }
`;

const Label = styled.label``;
const Radio = styled.input``;

const LabelText = styled.span`
  font-size: 1.6rem;
  flex: 4;
  @media ${device.mobileL} {
    font-size: 1.4rem;
  }
`;

const InsideLabelWrapper = styled.div`
  display: flex;
  height: 5rem;
  width: 16.6rem;
  align-items: center;
  border-radius: 0.4rem;
  padding-left: ${(props) => props.theme.spacing.extraSmall};
  @media (max-width: 800px) {
    width: 15.5rem;
    padding-left: ${(props) => props.theme.spacing.small};
  }
  @media (max-width: 504px) {
    width: 16.6rem;
  }
  @media ${device.mobileL} {
    width: 16rem;
  }
  @media ${device.mobileM} {
    width: 15rem;
  }
  @media (max-width: 350px) {
    width: 14rem;
  }
  @media (max-width: 340px) {
    width: 13.5rem;
  }
  @media ${device.mobileS} {
    width: 13.5rem;
  }
`;
