import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from './button/Button';
import history from '../../history';

export const AccessDenied = ({ contact, center }) => {
  return (
    <Container center={center}>
      <Title>Access denied</Title>
      <Text>
        Contact{contact && ` ${contact} or`} admin for information and access
      </Text>
      <ButtonContainer>
        <Button
          text='Back'
          primary
          onClick={() => {
            history.goBack();
            history.push('/omega');
          }}
        />
      </ButtonContainer>
    </Container>
  );
};

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 700;
`;

const Text = styled.p`
  margin-top: 1.5rem;
  font-size: 1.6rem;
`;
const ButtonContainer = styled.div`
  margin-top: 2rem;
  width: 40rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 6rem;
  align-items: center;

  ${(props) =>
    props.center &&
    css`
      width: 100%;
      justify-content: center;
    `}
`;
