import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { getCountdownString } from '../../../helpers/common';
import { device } from '../../../styles/device';
import { Modal } from '../modal/Modal';
import { Button } from '../button/Button';
import { ContentContext } from '../../../helpers/contentContext';
import history from '../../../history';

export const ProgressBarTimer = ({
  time,
  notStarted,
  timeString,
  timeIsUp,
  submit,
  testName,
}) => {
  const [timeRemaining, setTimeRemaining] = useState('');
  const [timeStarted, setTimeStarted] = useState(false);
  const content = useContext(ContentContext) || {};

  const handleExit = () => {
    return history.push('/');
  };

  useEffect(() => {
    //Clear existing timeouts
    let id = window.setTimeout(function() {}, 0);

    while (id--) {
      window.clearTimeout(id);
    }
  }, [time]);

  useEffect(() => {
    if (!timeStarted && !notStarted) {
      setTimeStarted(true);

      const setOngoingTestTime = () => {
        if (!time) return false;
        const dueDate = new Date(time);
        const msRemaining = dueDate - Date.now();
        const timeString = getCountdownString(msRemaining);

        if (msRemaining <= 0) {
          return setTimeRemaining('00:00:00'); //when no more time
        }

        setTimeRemaining(timeString);
        setTimeout(setOngoingTestTime, 1000);
      };

      return setOngoingTestTime();
    } else if (!timeStarted && notStarted && timeString) {
      return setTimeRemaining(timeString);
    } else if (notStarted) {
      return setTimeRemaining(getCountdownString(time));
    }
  }, [timeStarted, time, notStarted, timeString]);

  if (timeRemaining === '00:00:00') submit();

  return (
    <Container>
      <Time notStarted={notStarted}>{timeRemaining}</Time>

      <Modal
        show={timeIsUp}
        handleExit={handleExit}
        width='42rem'
        removeOverFlowScroll
        skipTitleMargin
      >
        <ModalTitle>
          {content.timesUpThankYou || 'Time is up, thank you'}
        </ModalTitle>
        <ModalText>
          {`${content.thankYouText1} ${testName || content.test} ${
            content.thankYouText2
          }`}
        </ModalText>
        <Button
          onClick={handleExit}
          text={content.navigateToStartPage || 'Continue to start page'}
          primary
        />
      </Modal>
    </Container>
  );
};

const Container = styled.div``;

const Time = styled.span`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3.4rem;
  line-height: 160%;
  /* or 54px */
  letter-spacing: 0.01em;

  color: ${(props) => props.theme.color.black};
  ${(props) =>
    props.notStarted &&
    css`
      color: ${(props) => props.theme.color.mediumGrey};
    `}
  @media ${device.mobileL} {
    font-size: 2.2rem;
  }
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.1rem;
  text-align: center;
  margin-bottom: 1rem;
`;

const ModalText = styled.div`
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 2rem;
`;
