import React from 'react';
import styled, { css } from 'styled-components';

export const TableHeader = ({ data, columnWidths, customHeaders }) => {
  if (customHeaders) {
    return customHeaders.map((header, index) => {
      return (
        <HeadItem
          columnWidth={columnWidths ? columnWidths[index] : '100%'}
          key={header}
        >
          {header && header}
        </HeadItem>
      );
    });
  } else {
    let header = Object.keys(data[0]);
    return header.map((key, index) => {
      return (
        <HeadItem
          columnWidth={columnWidths ? columnWidths[index] : '100%'}
          key={index}
        >
          {key.toUpperCase()}
        </HeadItem>
      );
    });
  }
};

const HeadItem = styled.div`
  display: flex;
  font-weight: bold;
  font-style: italic;
  ${(props) =>
    props.columnWidth &&
    css`
        width ${(props) => props.columnWidth};
      `}

  ${(props) =>
    props.sortingColumn &&
    css`
        width ${(props) => props.sortingColumn};
        justify-content: flex-end;
      `}
`;
