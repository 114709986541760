import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ProjectOverview } from '../../../components/admin/reports/projects/ProjectOverview';
import { Settings } from '../../../components/admin/reports/projects/Settings';
import { Tabs } from '../../../components/admin/reports/projects/Tabs';
import { Button } from '../../../components/shared/button/Button';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { device } from '../../../styles/device';
import { projectAccess } from '../../../helpers/common';
import { userHasAccess } from '../../../helpers/permissions';
import { AccessDenied } from '../../../components/shared/AccessDenied';
import { SendInvitationModal } from '../../../components/admin/reports/projects/SendInvitationModal';
import { CopyTokenLinkModal } from '../../../components/admin/reports/projects/CopyTokenLinkModal';
import { CriticalSuccessFactors } from '../../../components/admin/reports/projects/CriticalSuccessFactors';

export const Project = (props) => {
  const [payload, setPayload] = useState({});
  const [users, setUsers] = useState([]);
  const [currentId, setCurrentId] = useState('0');
  const [project, setProject] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showListTokenModal, setShowListTokenModal] = useState(false);
  const [access, setAccess] = useState({ crud: false, read: false });
  const paramsData = useParams();
  const slug =
    (paramsData && paramsData.slug) ||
    (props.location && props.location.state && props.location.state.slug) ||
    props.history.location.pathname.substring(
      props.history.location.pathname.lastIndexOf('/') + 1
    );

  const endpoint = `/api/omega/projects/slug/${slug}`;
  const method = 'get';

  const { data } = useFetch(payload);

  useEffect(() => {
    setPayload({ endpoint, method });
  }, [endpoint]);

  const memorizedSetAccess = useCallback(() => {
    if (userHasAccess('PROJECTS') === 'crud' && !access.crud)
      setAccess({ crud: true });

    if (project.access && project.consultant && !access.crud && !access.read)
      setAccess(projectAccess(project, true));
  }, [project, access.crud, access.read]);

  useEffect(() => {
    if (project) {
      if (project.users && project.users.length) setUsers(project.users);
      memorizedSetAccess();
    }
  }, [project, memorizedSetAccess]);

  useEffect(() => {
    if (data && data.data) {
      setUsers(data.data.users);

      setProject(data.data);
    }
  }, [data]);

  const tabsArray = {
    0: 'Project overview',
    1: 'Critical success factors',
    2: 'Project settings',
  };

  const renderContent = () => {
    switch (currentId) {
      case '0':
        return (
          <ProjectOverview
            project={project}
            setProject={setProject}
            users={users}
            setUsers={setUsers}
          />
        );
      case '1':
        return (
          <CriticalSuccessFactors
            project={project}
            setProject={setProject}
            hasReadOnly={!access.crud}
          />
        );
      case '2':
        return (
          <Settings
            project={project}
            setProject={setProject}
            hasReadOnly={!access.crud}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Container>
      {project && (
        <ProfileContainer>
          <TextContainer>
            <Name>{project.title}</Name>
          </TextContainer>
          <ButtonContainer>
            {access.crud && users && users.length > 0 && (
              <>
                <Button
                  onClick={() => setShowListTokenModal(!showListTokenModal)}
                  text={'User access links'}
                  primary
                />
                <Button
                  onClick={() => setShowModal(!showModal)}
                  text={'Send invitations'}
                  primary
                />
              </>
            )}
            {(project.templates &&
              project.templates.length &&
              (access.read || access.crud) && (
                <Button
                  onClick={() => {
                    props.history.push(
                      `/admin/reports/show-report/${project.slug}`,
                      { slug: project.slug }
                    );
                  }}
                  text={'View report'}
                  primary
                />
              )) ||
              null}
          </ButtonContainer>
        </ProfileContainer>
      )}
      <Margin />
      <Margin />
      {((access.read || access.crud) && (
        <Tabs
          currentId={currentId}
          setCurrentId={setCurrentId}
          renderContent={renderContent}
          tabsObject={tabsArray}
        />
      )) || (
        <AccessDenied
          contact={
            project &&
            project.consultant &&
            project.consultant[0] &&
            project.consultant[0].name
          }
        />
      )}
      {users && users.length && showModal && (
        <SendInvitationModal
          users={users}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {users && users.length && (
        <CopyTokenLinkModal
          users={users}
          showModal={showListTokenModal}
          handleExit={() => setShowListTokenModal(false)}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
`;

const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%
  margin-bottom: -${(props) => props.theme.spacing.extraLarge};
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  position: relative;
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
    margin-bottom: 0rem;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${(props) => props.theme.spacing.large};
`;

const Name = styled.p`
  font-size: 2rem;
  line-height: 2.3rem;
  font-weight: bold;
  @media ${device.mobileL} {
    font-size: 1.6rem;
  }
`;

const Margin = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.large};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-self: end;
  margin-right: 0;
  margin-left: auto;
  text-decoration: none;
  width: 30%;
`;
