import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../../styles/device';

export const Type = ({ exercise, instruction }) => {
  return (
    <Container exercise={exercise} instruction={instruction}>
      <Text exercise={exercise} instruction={instruction}></Text>
    </Container>
  );
};

const Container = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 1.7rem;
  width: fit-content;
  ${(props) =>
    props.exercise &&
    css`
      background-color: ${(props) => props.theme.color.exercise};
    `}
  ${(props) =>
    props.instruction &&
    css`
      background-color: ${(props) => props.theme.color.instruction};
    `}
    @media ${device.tablet} {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
`;

const Text = styled.p`
  color: ${(props) => props.theme.color.white};
  font-size: ${(props) => props.theme.size.standard};
  @media ${device.tablet} {
    font-size: 1.2rem;
    line-height: 1.2rem;
  }
  ${(props) =>
    props.exercise &&
    css`
      &:after {
        content: 'Exercise';
      }
      @media ${device.tablet} {
        &:after {
          content: 'E';
        }
      }
    `}
  ${(props) =>
    props.instruction &&
    css`
      &:after {
        content: 'Instruction';
      }
      @media ${device.tablet} {
        &:after {
          content: 'I';
        }
      }
    `}
`;
