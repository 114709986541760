import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { sortArrowDown, sortArrowUp } from '../../../shared/assets/icons';

export const DropdownOptions = ({
  options,
  setAnswer,
  placeholder,
  dropdownAnswer,
  index,
  answerIx,
  selected,
}) => {
  const [selectedOption, setSelectedOption] = useState(dropdownAnswer);
  const [openDropdown, setOpenDropdown] = useState(false);

  const onClickOption = (option) => {
    setSelectedOption(option);
    setOpenDropdown(!openDropdown);
  };

  useEffect(() => {
    if (selectedOption !== selected) {
      setAnswer({ type: 'dropdown', value: selectedOption, index, answerIx });
    }
  }, [selectedOption, setAnswer, index, answerIx, selected]);

  const listOptions = options.map((option, key) => (
    <Option
      onClick={() => {
        onClickOption(option);
      }}
      key={key}
    >
      {option.text}
    </Option>
  ));
  return (
    <Wrapper>
      <HederContainer
        onClick={() => {
          setOpenDropdown(!openDropdown);
        }}
      >
        <HeaderText>
          {selectedOption ? selectedOption.text : placeholder}
        </HeaderText>
        <IconArrow src={openDropdown ? sortArrowUp : sortArrowDown} />
      </HederContainer>
      <OptionsContainer openDropdown={openDropdown}>
        {openDropdown && listOptions}
      </OptionsContainer>
    </Wrapper>
  );
};

const HederContainer = styled.div`
  padding: 0rem 2rem;
  height: 5.5rem;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${(props) => props.theme.color.bgGrey};
  border-radius: 0.4rem;
  cursor: pointer;
`;

const HeaderText = styled.p`
  font-family: 'futura-pt', sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;
  letter-spacing: 0.01em;

  color: ${(props) => props.theme.color.offGrey};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;
const IconArrow = styled.img`
  height: 0.6rem;
  width: 1.1rem;
`;

const OptionsContainer = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 1;
  padding: 0px 1rem;
  background-color: ${(props) => props.theme.color.bgGrey};

  ${(props) =>
    props.openDropdown &&
    css`
      max-height: 25rem;
      padding: 0px 1rem 1rem 1rem;
      overflow: scroll;
    `}
`;

const Option = styled.li`
  padding: 1rem;
  list-style: none;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;
  letter-spacing: 0.01em;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.color.darkGrey};
    border-radius: 0.4rem;
  }
`;
