import React from 'react';
import styled, { css } from 'styled-components';
import { device } from '../../../styles/device';

export const CategoryList = ({
  categories,
  setChosenCategory,
  chosenCategory,
}) => {
  const handleOnClick = (category) => {
    setChosenCategory(category);
  };
  if (categories && categories.length > 0) {
    return (
      <Container>
        <CategoryUl categories={categories}>
          {categories.map((category, index) => {
            const choosenCategoryId =
              (chosenCategory && chosenCategory._id) || chosenCategory || '';

            return (
              <CategoryLi
                active={choosenCategoryId === category._id}
                key={index}
                onClick={() => handleOnClick(category)}
              >
                {category.title}
              </CategoryLi>
            );
          })}
        </CategoryUl>
      </Container>
    );
  } else {
    return (
      <Container>
        <Text>
          No categories to choose from, please add new ones on the Categories
          page
        </Text>
      </Container>
    );
  }
};

const Container = styled.div``;

const CategoryUl = styled.ul`
  width: 100%;
  background: ${(props) => props.theme.color.bgGrey};
  font-size: 1.6rem;
  line-height: 4rem;
  border-radius: 0.4rem;
  overflow-y: scroll;
  height: 21.5rem;
  padding: ${(props) => props.theme.spacing.extraSmall};
  @media ${device.mobileM} {
    font-size: 1.4rem;
  }
`;
const CategoryLi = styled.li`
  width: 100%;
  height: 4rem;
  padding-left: ${(props) => props.theme.spacing.small};
  border-radius: 0.4rem;

  &:hover {
    background: ${(props) => props.theme.color.selectedGrey};
    color: ${(props) => props.theme.color.white};
    cursor: pointer;
    border-radius: 0.4rem;
  }

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.color.selectedGrey};
      color: ${(props) => props.theme.color.white};
      border-radius: 0.4rem;
    `}
`;
const Text = styled.h3`
  font-size: 1.6rem;
`;
