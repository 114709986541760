import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Header } from '../../components/candidate/navigation/Header';
import { Footer } from '../../components/candidate/navigation/Footer';
import { device } from '../../styles/device';
import { useFetch } from '../../helpers/hooks/fetchData';
import { InputPassword } from '../../components/candidate/landing/InputPassword';
import { useLocation, Redirect } from 'react-router-dom';

export const ChangePassword = () => {
  const { search } = useLocation();
  const [payload, setPayload] = useState({});
  const [user, setUser] = useState({});
  const { data, hasError, isLoading } = useFetch(payload);

  useEffect(() => {
    if (search) {
      const searchArray = search.split('?token=');
      const token = searchArray[searchArray.length - 1];
      setPayload({
        method: 'get',
        endpoint: `/api/omega/candidate-public/validate-token/${token}`,
      });
    }
  }, [search]);

  useEffect(() => {
    if (data) {
      setUser(data.user && data.user);
    }
  }, [data]);

  return (
    <>
      {isLoading !== undefined && !isLoading && !hasError && (
        <>
          <Header user={user} />
          <Container>
            <ContentContainer>
              <TextContainer>
                <Title>Change your password</Title>
              </TextContainer>
              <InputPassword changePassword />
            </ContentContainer>
          </Container>
          <Footer />
        </>
      )}

      {isLoading !== undefined && !isLoading && hasError && (
        <>
          <Redirect to='/omega' />
        </>
      )}
    </>
  );
};

const Container = styled.div`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 0 auto;
`;

const ContentContainer = styled.div`
  width: 65rem;
  padding: 8rem 0rem;
  @media ${device.tablet} {
    padding: 8rem 2rem;
    width: 100%;
  }
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.1rem;
  padding-bottom: 2.5rem;
`;

const TextContainer = styled.div`
  padding-bottom: 8rem;
  word-wrap: none;
  height: fit-content;
`;
