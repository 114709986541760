import { CandidateProfile } from '../../../pages/candidate/profile';
import {
  Test,
  DoTest,
  StartTest,
  ThankYouPage,
  Instructions,
  InfoPage,
} from '../../../pages/candidate/test';

export const routes = [
  {
    name: 'Home',
    path: '/candidate',
    hasSubMenu: true,
    routes: [
      {
        name: 'My test',
        path: '/candidate/home',
        component: CandidateProfile,
        isDynamic: false,
      },
    ],
  },
  {
    name: 'Test',
    path: '/test',
    component: Test,
    hasSubMenu: true,
    routes: [
      {
        name: 'My test',
        path: '/active-test/:index',
        component: DoTest,
        isDynamic: false,
      },
      {
        name: 'Start test',
        path: '/start-test/:index',
        component: StartTest,
        isDynamic: false,
      },
      {
        name: 'Thank you',
        path: '/thank-you',
        component: ThankYouPage,
        isDynamic: false,
      },
      {
        name: 'Instructions',
        path: '/instructions:index',
        component: Instructions,
        isDynamic: false,
      },
      {
        name: 'Info page',
        path: '/info-page',
        component: InfoPage,
        isDynamic: false,
      },
    ],
  },
];
