import React from 'react';
import styled, { css } from 'styled-components';
import {
  correctCheckGrey,
  correctCheckWhite,
  radioButtonBlack,
} from '../../../shared/assets/icons';
export const MultipleOptionsItemWrapper = ({
  marginTop,
  marginBottom,
  id,
  optionTitleCounter,
  optionIx,
  fimOption,
  finRowOptionIx,
  availableLanguages,
  toggleCorrectAnswer,
  values,
}) => {
  const type =
    values[availableLanguages[0].code][optionIx].type === 'fim' ? 'fim' : null;
  const icon = type === 'fim' ? radioButtonBlack : null;

  const correctAnswer =
    values[availableLanguages[0].code][0].fimRowOptions[fimOption][
      finRowOptionIx
    ] &&
    values[availableLanguages[0].code][0].fimRowOptions[fimOption][
      finRowOptionIx
    ].isCorrect;

  return (
    <Container
      id={id}
      marginTop={marginTop}
      marginBottom={marginBottom}
      correctAnswer={correctAnswer}
    >
      <TitleRowContent>
        <TitleIcon src={icon} />
        <TitleText>{optionTitleCounter}</TitleText>
        <RowOfIcons>
          <IconWrapper
            pointer
            onClick={toggleCorrectAnswer}
            correctAnswer={correctAnswer}
          >
            <Icon src={correctAnswer ? correctCheckWhite : correctCheckGrey} />
          </IconWrapper>
        </RowOfIcons>
      </TitleRowContent>
    </Container>
  );
};
const Container = styled.div`
  background: ${(props) => props.theme.color.white};
  border: 1px solid transparent;
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${(props) => props.theme.spacing.medium};
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.standard};
    `}
    border-radius: 0.4rem;
  ${(props) =>
    props.correctAnswer &&
    css`
      border: 1px solid ${(props) => props.theme.color.add};
    `}
`;
const TitleRowContent = styled.div`
  height: 6rem;
  position: relative;
  display: flex;
  align-items: center;
`;
const TitleIcon = styled.img`
  margin-left: ${(props) => props.theme.spacing.standard};
`;
const TitleText = styled.p`
  padding-left: ${(props) => props.theme.spacing.standard};
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
`;
const RowOfIcons = styled.div`
  position: absolute;
  top: -1.6rem;
  right: ${(props) => props.theme.spacing.large};
  display: flex;
  justify-content: flex-end;
`;
const IconWrapper = styled.div`
  box-shadow: 0px 0.5px 10px rgba(0, 0, 0, 0.12);
  height: 3.2rem;
  width: 3.2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.theme.spacing.small};
  background: ${(props) => props.theme.color.white};
  &:last-child {
    margin-right: 0;
  }
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
  ${(props) =>
    props.correctAnswer &&
    css`
      background: ${(props) => props.theme.color.add};
    `}
  ${(props) =>
    props.editMode &&
    css`
      background: ${(props) => props.theme.color.selectedGrey};
    `}
`;
const Icon = styled.img`
  object-fit: cover;
  ${(props) =>
    props.chevron &&
    css`
      transition: transform 0.2s ease-in-out;
      transform: ${(props) => (props.isRotated ? 'rotate(90deg)' : '')};
      height: 1.8rem;
      width: 1rem;
    `}
  ${(props) =>
    props.isCorrect &&
    css`
      width: 1.7rem;
      height: 1.3rem;
      cursor: default;
    `}
`;
