import React from 'react';
import styled from 'styled-components';
import { SubMenu } from '../../../components/admin/navigation/SubMenu';
import { Route, useRouteMatch, Redirect, Switch } from 'react-router-dom';
import { Main } from '../../../components/main/Main';

export const AdminSettings = ({ route }) => {
  const { path } = useRouteMatch();

  return (
    <Container>
      {route && route.hasSubMenu && <SubMenu route={route} />}
      <Switch>
        <Route path={`${path}/:child`}>
          <Main route={route} />
        </Route>
        <Redirect from='/admin/settings' to='/admin/settings/view-all' />
      </Switch>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  background: ${(props) => props.theme.color.bgGrey};
`;
