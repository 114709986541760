import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../helpers/hooks/fetchData';
import styled from 'styled-components';
import { Modal } from '../../shared/modal/Modal';
import { Formik, Field } from 'formik';
import { Button } from '../../shared/button/Button';
import * as Yup from 'yup';
import { InputField } from '../../shared/input/InputField';
import { DefaultLanguage } from './DefaultLanguage';
import { FormLabel } from '../../shared/input/FormLabel';
import {
  ToggleSwitch,
  ToggleSwitchGroup,
} from '../../shared/input/ToggleSwitch';
import { SelectInput } from '../../shared/input/SelectInput';

export const AddCandidate = ({ history }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('This is an invalid email format.')
      .required('This is a required field.'),
    name: Yup.string().required('This is a required field.'),
    title: Yup.string(),
    consultant: Yup.string(),
    imageUrl: Yup.string(),
    typeOfRecruit: Yup.string(),
  });

  const initialValues = {
    email: '',
    name: '',
    title: '',
    position: '',
    company: '',
    consultant: '',
    imageUrl: '',
    typeOfRecruit: 'external',
  };

  const [showModal, setShowModal] = useState(false);
  const [payload, setPayload] = useState({});
  const [consultantsPayload, setConsultantsPayload] = useState({});
  const [consultants, setConsultants] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState(null);

  const { data, hasError } = useFetch(payload, true);
  const { data: consultantsData } = useFetch(consultantsPayload, true);

  const resetPayload = () => {
    setPayload({});
  };

  useEffect(() => {
    if (data && data.data && data.data.slug) {
      return history.push('/admin/candidates/' + data.data.slug);
    }
    return setShowModal(false);
  }, [data, history]);

  useEffect(() => {
    const method = 'get';
    setConsultantsPayload({
      method,
      endpoint: '/api/omega/users/permissions',
    });
  }, []);

  useEffect(() => {
    if (consultantsData && consultantsData.data) {
      setConsultants(consultantsData.data);
    }
  }, [consultantsData]);

  return (
    <>
      <Button add onClick={() => setShowModal(true)} />
      <Modal
        show={showModal}
        handleExit={setShowModal}
        title='Create new candidate'
        width='40rem'
        add
      >
        {!hasError && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              const typeOfRecruit =
                values.typeOfRecruit === 'internal' ? true : false;

              values = {
                ...values,
                defaultLanguage: defaultLanguage ? defaultLanguage : null,
                isInternalRecruit: typeOfRecruit,
              };

              setPayload({
                endpoint: '/api/omega/users/',
                method: 'post',
                data: values,
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              });
              resetForm();
            }}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <InputField
                  label='Name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={errors.name && touched.name}
                  type='string'
                  name='name'
                  message={errors.name && touched.name && `${errors.name}`}
                />
                <InputField
                  label='Email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email && touched.email}
                  type='string'
                  name='email'
                  message={errors.email && touched.email && `${errors.email}`}
                />
                {/* {positions.length && (
                  <SelectInput
                    label='Position'
                    placeholder='Select position'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.position}
                    error={errors.position && touched.position}
                    type='string'
                    name='position'
                    data={positions}
                    message={
                      errors.position &&
                      touched.position &&
                      `${errors.position}`
                    }
                  />
                )} */}
                {consultants.length && (
                  <SelectInput
                    label='Consultant'
                    placeholder='Select consultant'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.consultant}
                    error={errors.consultant && touched.consultant}
                    type='string'
                    name='consultant'
                    data={consultants}
                    message={
                      errors.consultant &&
                      touched.consultant &&
                      `${errors.consultant}`
                    }
                  />
                )}
                {/* {companies.length && (
                  <SelectInput
                    label='Company'
                    placeholder='Select company'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company}
                    error={errors.company && touched.company}
                    type='string'
                    name='company'
                    data={companies}
                    message={
                      errors.company && touched.company && `${errors.company}`
                    }
                  />
                )} */}
                <InputField
                  label='Picture URL'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.imageUrl}
                  error={errors.imageUrl && touched.imageUrl}
                  type='string'
                  name='imageUrl'
                  message={
                    errors.imageUrl && touched.imageUrl && `${errors.imageUrl}`
                  }
                />
                <FormLabel label='Default language' />
                <DefaultLanguage
                  setDefaultLanguage={setDefaultLanguage}
                  newUser
                />
                <ToggleSwitchGroup
                  id='typeOfRecruit'
                  label='Type of recruitment'
                  value={values.typeOfRecruit}
                  touched={touched.typeOfRecruit}
                  error={errors.typeOfRecruit && touched.typeOfRecruit}
                  message={
                    errors.typeOfRecruit &&
                    touched.typeOfRecruit &&
                    `${errors.typeOfRecruit}`
                  }
                >
                  <Field
                    component={ToggleSwitch}
                    name='typeOfRecruit'
                    id='internal'
                    label='Internal'
                  />
                  <Field
                    component={ToggleSwitch}
                    name='typeOfRecruit'
                    id='external'
                    label='External'
                  />
                </ToggleSwitchGroup>
                <ButtonContainer>
                  <Button
                    type='submit'
                    disabled={isSubmitting}
                    primary
                    text='Create candidate'
                  />
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        )}
        {hasError && (
          <>
            <Error>
              Error adding new candidate <br />
              This email may already be in use
            </Error>
            <Button primary text='OK' onClick={() => resetPayload()} />
          </>
        )}
      </Modal>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.theme.spacing.small};
`;
const Error = styled.h3`
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing.medium};
`;
