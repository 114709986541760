import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { arrowBoxDown, arrowBoxUp, minusBox, plusBox } from '../assets/icons';
import { device } from '../../../styles/device';

export const TableData = ({
  data,
  columnWidths,
  moveRow,
  isSelected,
  setSelectedData,
  disabled,
  padding,
}) => {
  const [activeRow, setActiveRow] = useState(-1);
  const [update, setUpdate] = useState(false);

  const openRow = (e, index) => {
    if (activeRow === index && e.target.id !== 'arrow') {
      setActiveRow('');
    } else {
      setActiveRow(index);
    }
  };

  useEffect(() => {
    if (isSelected) {
      setSelectedData(data);
    }
  }, [data, update, isSelected, setSelectedData]);

  const sortRow = async (index, direction) => {
    if (activeRow + direction < 0 || activeRow + direction >= data.length)
      return false;
    await data.splice(index + direction, 0, data.splice(index, 1)[0]);
    setUpdate(!update);
    setActiveRow(activeRow + direction);
  };

  const dataValues = data.map((dataValue) => {
    return Object.values(dataValue);
  });

  return dataValues.map((value, index) => {
    return (
      <Row
        isActiveRow={activeRow === index}
        onClick={isSelected && ((e) => openRow(e, index))}
        key={`${value[0]}-${index}`}
      >
        {value.map((v, i) => {
          if (i === 0) {
            return null;
          } else {
            return (
              <DataItem
                padding={padding}
                hide={i > 1}
                columnWidth={columnWidths ? columnWidths[i] : '100%'}
                key={i}
              >
                {isSelected && i === 1 && <>{index + 1}. </>}
                {v}
              </DataItem>
            );
          }
        })}
        {(!disabled && (
          <DataItem sortingColumn={'20rem'}>
            {activeRow === index && (
              <>
                <Icon
                  id='arrow'
                  onClick={() => sortRow(index, -1)}
                  src={arrowBoxUp}
                />
                <Icon
                  id='arrow'
                  onClick={() => sortRow(index, +1)}
                  src={arrowBoxDown}
                />

                <Margin />
              </>
            )}
            <Icon
              onClick={() => moveRow(index)}
              src={isSelected ? minusBox : plusBox}
            />
          </DataItem>
        )) || <DataItem sortingColumn={'20rem'} />}
      </Row>
    );
  });
};

const Row = styled.div`
  /* tabort sen */
  padding: 1rem;

  margin: 1rem 0rem;
  &:last-child {
    margin-bottom: 0rem;
  }
  display: flex;

  border-radius: 0.4rem;

  ${(props) =>
    props.isActiveRow &&
    css`
      background: #e5e5e5;
    `}
`;

const DataItem = styled.div`
  display: flex;
  overflow-x: auto;

  ${(props) =>
    props.padding &&
    css`
      &:not(:first-child) {
        padding-left: 1.5rem;
      }
    `}

  @media ${device.tablet} {
    ${(props) =>
      props.hide &&
      css`
        display: none;
      `}
  }

  ${(props) =>
    props.columnWidth &&
    css`
        width ${(props) => props.columnWidth};
      `}
  ${(props) =>
    props.sortingColumn &&
    css`
        width ${(props) => props.sortingColumn};
        justify-content: flex-end;
      `}
`;

const Icon = styled.img`
  margin-right: 0.5rem;
  cursor: pointer;
  max-height: 2.3rem;
  &:last-child {
    margin-right: 0rem;
  }
`;

const Margin = styled.div`
  width: 1rem;
`;
