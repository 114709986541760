import React from 'react';
import styled from 'styled-components';

export const DefaultTemplate = () => {
  return <Container>default Template</Container>;
};

const Container = styled.div`
  width: 100%;
`;
