import React, { useContext } from 'react';
import styled from 'styled-components';
import { device } from '../../../styles/device';
import { ContentContext } from '../../../helpers/contentContext';

export const UnansweredSectionListItem = ({ onClick, section, language }) => {
  const content = useContext(ContentContext) || {};
  let text = '';
  section.title.forEach((title) => {
    if (title.language === language.code) {
      text = title.text;
    }
  });

  return (
    <Container>
      <SectionName>
        {text
          ? text
          : section.title[0].text ||
            (typeof section.title[0] === 'string' && section.title[0])}
      </SectionName>
      <Button onClick={() => onClick({ navigate: section.pages[0].page })}>
        {content.show}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  height: 5.7rem;
  width: 100%;
  border-radius: 0.4rem;
  transition-duration: 400ms;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  &:hover {
    background: ${(props) => props.theme.color.darkGrey};
  }
  @media ${device.tablet} {
    padding: 0;
  }
`;

const SectionName = styled.p`
  font-size: 2.2rem;
  line-height: 160%;
  letter-spacing: 0.01em;
`;

const Button = styled.button`
  height: 3.5rem;
  width: 12rem;
  background: ${(props) => props.theme.color.darkBlue};
  color: ${(props) => props.theme.color.white};
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  font-style: normal;
  font-size: 2rem;
  line-height: 100%;
  letter-spacing: 0.05em;
  font-family: 'futura-pt', sans-serif;
  @media ${device.tablet} {
    right: 0;
  }
`;
