import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useFetch } from '../../../helpers/hooks/fetchData';
import {
  Accordion,
  InitNewQuestion,
} from '../../../components/admin/questions/list-questions';
import { Button } from '../../../components/shared/button/Button';
import { SearchBar } from '../../../components/shared/search-bar/SearchBar';
import { device } from '../../../styles/device';
import { sortArrows } from '../../../components/shared/assets/icons';
import { NoResultsFound } from '../../../components/shared/error-page-components/NoResultsFound';
import { userHasAccess } from '../../../helpers/permissions';

//set as environment variable later
const itemsPerPage = 25;

export const ViewAllQuestions = () => {
  const [payload, setPayload] = useState({});
  const endpoint = '/api/omega/questions';
  const method = 'get';
  const [questions, setQuestions] = useState([]);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [moreQuestionsAvailable, setMoreQuestionsAvailable] = useState(false);
  const [params, setParams] = useState({
    searchString: '',
    sortPropName: '',
    sortHeading: null,
    skip: 0,
    limit: itemsPerPage,
    isSurvey:
      userHasAccess('TEXT') && !userHasAccess('QUESTIONS') ? true : false,
  });
  const { data } = useFetch(payload);
  const isCrud =
    userHasAccess('QUESTIONS') === 'crud' || userHasAccess('TEXT') === 'crud';

  useEffect(() => {
    setPayload({ endpoint, method, params });
  }, [params, endpoint, method]);

  useEffect(() => {
    setUpdateInProgress(false);
    const newQuestions = (data && data.data) || [];
    setQuestions((prevQuestions) => [...prevQuestions, ...newQuestions]);
  }, [data]);

  useEffect(() => {
    const count = data && data.count;
    const moreQuestionsAvailable = count && params.limit + params.skip < count;
    setMoreQuestionsAvailable(moreQuestionsAvailable);
  }, [data, params.limit, params.skip]);

  /**
   * Set sort name and heading of sort attribute.
   * @param {String} propName
   */
  const sortByProp = (propName) => {
    setUpdateInProgress(true);
    if (!propName) return false;
    //clear questions array
    setQuestions([]);
    const skip = params.sortPropName !== propName ? 0 : params.skip;

    //if third press on same heading, clear the sorting
    if (params.sortHeading === -1 && propName === params.sortPropName) {
      return setParams({ ...params, sortHeading: '', sortPropName: '', skip });
    }

    //if not cleared, heading will be set to 1 on first click and -1 on second click
    const sortHeading =
      propName === params.sortPropName && params.sortHeading === 1 ? -1 : 1;
    const sortPropName = propName;
    return setParams({ ...params, sortHeading, sortPropName, skip });
  };

  const searchEntered = (s) => {
    setUpdateInProgress(true);
    //clear questions array
    setQuestions([]);
    const skip = 0;
    return setParams({ ...params, searchString: s, skip });
  };

  const showMore = () => {
    const skip = params.skip + itemsPerPage;
    return setParams({ ...params, skip });
  };

  return (
    <>
      <SearchBar
        searchString={params.searchString}
        onChange={searchEntered}
        placeholder={'Search'}
      />
      <Container>
        <HeaderRow>
          <Section
            link
            onClick={() => {
              sortByProp('title');
            }}
          >
            Title <Arrow src={sortArrows} />
          </Section>
          <Section languages>Available languages</Section>
          <Section
            link
            onClick={() => {
              sortByProp('category.title');
            }}
          >
            Category
            <Arrow src={sortArrows} />
          </Section>
          <Section>Other</Section>
        </HeaderRow>
        {!updateInProgress && questions.length === 0 ? (
          <NoResultsFound />
        ) : (
          questions.map((questionData, index) => (
            <Accordion
              key={index}
              index={index}
              data={questionData}
              isCrud={isCrud}
            />
          ))
        )}
        {!!moreQuestionsAvailable && (
          <Button onClick={showMore} text='Show more' primary />
        )}
      </Container>

      {isCrud && <InitNewQuestion />}
    </>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-top: ${(props) => props.theme.spacing.large};
  margin-bottom: 7rem;
`;

const HeaderRow = styled.div`
  display: grid;
  background: ${(props) => props.theme.color.white};
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  @media ${device.tablet} {
    display: none;
  }
  grid-template-columns: 32% 16% 24% 28%;
  grid-template-rows: 7rem;
  align-items: center;
  box-shadow: 0px -1px 0px #d6d6d6, 0px 1px 0px #d6d6d6;
`;

const Section = styled.p`
  font-style: italic;
  font-weight: bold;
  margin-right: ${(props) => props.theme.spacing.standard};
  &:last-child {
    margin-right: 0px;
  }
  ${(props) =>
    props.languages &&
    css`
      @media ${device.laptop} {
        display: none;
      }
    `}
  ${(props) =>
    props.link &&
    css`
      cursor: pointer;
    `}
`;

const Arrow = styled.img`
  margin-left: 2.5rem;
`;
