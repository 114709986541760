import React from 'react';
import styled from 'styled-components';
import { plusBlack } from '../../../shared/assets/icons';

export const AddNewGroup = ({ onClick, title, isDisable }) => {
  return (
    <Container onClick={() => !isDisable && onClick()} disable={isDisable}>
      <Icon src={plusBlack} />
      <Text>{(title && title) || 'Add new group'}</Text>
    </Container>
  );
};

const Container = styled.div`
  height: 6rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 0.4rem;
  background: ${(props) => props.theme.color.white};
  cursor: pointer;
`;

const Icon = styled.img`
  height: 2rem;
  width: 2rem;
  margin-left: ${(props) => props.theme.spacing.standard};
`;

const Text = styled.p`
  padding-left: ${(props) => props.theme.spacing.standard};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
`;
