import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useFetch } from '../../../helpers/hooks/fetchData';
import * as Yup from 'yup';
import { Button } from './Button';
import { Formik } from 'formik';

export const InputPassword = ({
  agree,
  changePassword,
  isAdmin = false,
  setResponse,
}) => {
  const [payload, setPayload] = useState({});
  const { data, hasError } = useFetch(payload, true);

  useEffect(() => {
    if (data) {
      if (!isAdmin) window.location.assign('/');
      if (isAdmin && setResponse) setResponse('updated');
    }

    if (isAdmin && hasError && setResponse) setResponse('error');
  }, [data, hasError, setResponse, isAdmin]);

  function equalTo(ref) {
    return this.test({
      name: 'equalTo',
      exclusive: false,
      // eslint-disable-next-line quotes
      message: "Password doesn't match",
      params: {
        reference: ref.path,
      },
      test: function(value) {
        return value === this.resolve(ref);
      },
    });
  }

  Yup.addMethod(Yup.string, 'equalTo', equalTo);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Required'),
    passwordVerify: Yup.string().equalTo(Yup.ref('password')),
  });

  return (
    <>
      <Container>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ password: '', passwordVerify: '' }}
          onSubmit={(values) => {
            if (
              values.password === values.passwordVerify &&
              (agree || changePassword)
            ) {
              const endpoint = isAdmin
                ? '/api/omega/users/change-password'
                : '/api/omega/candidate-public/set-password';

              setPayload({
                method: 'post',
                endpoint,
                data: {
                  password: values.password,
                  passwordVerify: values.passwordVerify,
                },
              });
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Label agree={agree || changePassword}>
                {changePassword
                  ? ' Choose a new password'
                  : ' Choose a password for your account'}
                <Star
                  agree={agree || changePassword}
                  isError={errors.password && touched.password}
                >
                  *
                </Star>
              </Label>
              <Input
                agree={agree || changePassword}
                isError={errors.password && touched.password}
                onChange={handleChange}
                disabled={!agree && !changePassword}
                onBlur={handleBlur}
                type='password'
                value={values.password}
                id='password'
              />
              {errors.password &&
              touched.password &&
              (agree || changePassword) ? (
                <ErrorMessage>{errors.password}</ErrorMessage>
              ) : (
                <ErrorMessage />
              )}
              <Label agree={agree || changePassword}>
                {changePassword ? 'Confirm new password' : 'Confirm password'}
                <Star
                  agree={agree || changePassword}
                  isError={errors.passwordVerify && touched.passwordVerify}
                >
                  *
                </Star>
              </Label>
              <Input
                agree={agree || changePassword}
                isError={errors.passwordVerify && touched.passwordVerify}
                onChange={handleChange}
                disabled={!agree && !changePassword}
                onBlur={handleBlur}
                type='password'
                value={values.passwordVerify}
                name='passwordVerify'
                id='passwordVerify'
              />
              {errors.passwordVerify &&
              touched.passwordVerify &&
              (agree || changePassword) ? (
                <ErrorMessage>{errors.passwordVerify}</ErrorMessage>
              ) : (
                <ErrorMessage />
              )}

              <ButtonContainer>
                <Button
                  disabled={!agree && !changePassword}
                  type='submit'
                  text={changePassword ? 'Save password' : 'Register'}
                />
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 160%;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ErrorMessage = styled.div`
  min-height: 3.5rem;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;
  color: ${(props) => props.theme.color.error};
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: ${(props) => props.theme.spacing.standard};
  margin-top: 3rem;
`;

const Label = styled.label`
  margin-top: 2.5rem;
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 160%;
  ${(props) =>
    !props.agree &&
    css`
      color: ${(props) => props.theme.color.mediumGrey};
    `}
`;

const Input = styled.input`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  height: 5rem;
  width: 100%;
  border: 0rem;
  border-bottom: 0.1rem solid ${(props) => props.theme.color.mediumGrey};
  margin-bottom: 0.5rem;
  ${(props) =>
    props.isError &&
    css`
      border-bottom: 0.1rem solid ${(props) => props.theme.color.error};
    `}

  ${(props) =>
    !props.agree &&
    css`
      border-bottom: 0.1rem solid ${(props) => props.theme.color.mediumGrey};
      color: ${(props) => props.theme.color.mediumGrey};
    `}
`;

const Star = styled.span`
  ${(props) =>
    props.isError &&
    css`
      color: ${(props) => props.theme.color.error};
    `}
  ${(props) =>
    !props.agree &&
    css`
      color: ${(props) => props.theme.color.mediumGrey};
    `}
`;
