import React from 'react';
import styled, { css } from 'styled-components';
import { FormLabel } from './FormLabel';
import { device } from '../../../styles/device';

export const SelectInput = ({
  label,
  id,
  error,
  message,
  className,
  iconAfterLabel,
  iconInInput,
  isContactFormProfile,
  disabled,
  data,
  placeholder,
  ...inputProps
}) => {
  return (
    <>
      {label && (
        <FormLabel
          label={label}
          id={id}
          error={error}
          message={message}
          iconAfterLabel={iconAfterLabel}
        />
      )}
      <InputContainer isContactFormProfile={isContactFormProfile} flag>
        <Select
          {...inputProps}
          id={id}
          error={error}
          iconInInput={iconInInput}
          className={className}
          message={message}
          disabled={disabled}
        >
          {typeof placeholder === 'string' && (
            <option key={'placeholder'} value=''>
              {placeholder}
            </option>
          )}
          {data &&
            data.map((object) => (
              <option key={object._id} value={object._id}>
                {object.name || object.title}
              </option>
            ))}
        </Select>
      </InputContainer>
    </>
  );
};

const Select = styled.select`
  height: 100%;
  width: 100%;
  border-radius: 0.4rem;
  background-color: ${(props) => props.theme.color.bgGrey};
  font-size: 1.6rem;
  line-height: 1.8rem;
  border: none;
  padding-left: ${(props) =>
    props.iconInInput
      ? `${props.theme.spacing.extraLarge}`
      : `${props.theme.spacing.small}`};

  &:focus {
    border: 1px solid ${(props) => props.theme.color.shadow};
    box-shadow: 0 0 3px ${(props) => props.theme.color.shadow};
    outline: none;
  }
  ${(props) =>
    props.message &&
    css`
      border: 1px solid ${(props) => props.theme.color.error};
    `}

  ${(props) =>
    props.disabled &&
    css`
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    `}
    
  @media ${device.mobileL} {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
`;

const InputContainer = styled.div`
  height: 6rem;
  width: 100%;
  border-radius: 1rem;
  position: relative;
  ${(props) =>
    props.flag &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.standard};
      &:last-child {
        margin-bottom: 0px;
      }
    `}
  ${(props) =>
    props.isContactFormProfile &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.small};
    `}
`;
