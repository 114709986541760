import React from 'react';
import styled from 'styled-components';

export const QuestionInstruction = () => {
  return <Container>Question Instruction </Container>;
};

const Container = styled.div`
  width: 100%;
`;
