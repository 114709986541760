/**
 * return time string from MS number nicely formatted e.g. 5600000 --> 01:33h
 * @param {ms} ms
 * @param {Boolean} responseAsObject
 * @return string
 */
export const getNicelyFormattedTimeFromMS = (ms, responseAsObject) => {
  const portions = [];

  const msInHour = 1000 * 60 * 60;
  const hours = Math.trunc(ms / msInHour);

  portions.push(hours < 10 ? '0' + hours : hours);
  ms = ms - hours * msInHour;

  portions.push(':');

  const msInMinute = 1000 * 60;
  const minutes = Math.trunc(ms / msInMinute);
  portions.push(minutes < 10 ? '0' + minutes : minutes);
  ms = ms - minutes * msInMinute;

  portions.push(':');

  const seconds = Math.trunc(ms / 1000);
  ms = ms - seconds * 1000;
  portions.push(seconds < 10 ? '0' + seconds : seconds);

  portions.push('h');
  if (responseAsObject) {
    return { hours, minutes, seconds };
  }
  return portions.join('');
};

/**
 * return time string from MS number nicely formatted e.g. 5600000 --> 01:33:15
 * @param {ms} ms
s * @return string
 */

export const getCountdownString = (ms) => {
  const portions = [];

  const msInHour = 1000 * 60 * 60;
  const hours = Math.trunc(ms / msInHour);

  portions.push(hours < 10 ? '0' + hours : hours);
  ms = ms - hours * msInHour;

  portions.push(':');

  const msInMinute = 1000 * 60;
  const minutes = Math.trunc(ms / msInMinute);

  portions.push(minutes < 10 ? '0' + minutes : minutes);
  ms = ms - minutes * msInMinute;

  portions.push(':');

  const msInSecond = 1000;
  const seconds = Math.trunc(ms / msInSecond);

  portions.push(seconds < 10 ? '0' + seconds : seconds);

  return portions.join('');
};

/**
 * Get query params as object
 * @param {string} paramsString
 * @return {object}
 */
export const getQueryParams = (paramsString) => {
  try {
    if (!paramsString) return {};
    const queryArray = paramsString.split('?')[1].split('&');
    let queryParams = {};
    for (let i = 0; i < queryArray.length; i++) {
      const [key, val] = queryArray[i].split('=');
      queryParams[key] = val ? val : true;
    }

    return queryParams;
  } catch (e) {
    return {};
  }
};

/**
 * Calculate candidates test progress
 * @param {Object} user
 * @return {Number}
 */
export const calculateProgress = (user) => {
  const activeTests = user.omegaTests.filter((e) => !e.isDeactivated);
  const noOfTest = activeTests.length || 0;

  const testDone = activeTests
    .map((test) => {
      return test.isCompleted;
    })
    .filter(Boolean).length;

  return noOfTest && testDone ? Math.round((testDone / noOfTest) * 100) : false;
};

/**
 * Formats the date and time in a nice format.
 * @param {boolean} dateOnly - Optional. If true, only the date portion is included. Default is false.
 * @returns {string} - The formatted date and time string.
 */
// eslint-disable-next-line
Date.prototype.niceDateAndTime = function(dateOnly) {
  const mm = this.getMonth() + 1; // getMonth() is zero-based
  const dd = this.getDate();

  const hours = this.getHours();
  const minutes = this.getMinutes();

  const date = [
    this.getFullYear(),
    '-' + (mm > 9 ? '' : '0') + mm,
    '-' + (dd > 9 ? '' : '0') + dd,
  ].join('');

  if (dateOnly) return date;

  const time =
    (hours > 9 ? '' : '0') + hours + ':' + (minutes > 9 ? '' : '0') + minutes;

  return `${date} ${time}`;
};

/**
 * Returns a formatted date and time string based on the provided date.
 * @param {Date} date - The date object to display. If not provided, the current date is used.
 * @param {boolean} dateOnly - Optional. If true, only the date portion is displayed. Default is false.
 * @returns {string} - The formatted date and time string.
 */
export const displayDateAndTime = (date, dateOnly = false) => {
  try {
    if (!date) date = Date.now();
    var displayDate = new Date(date);
    return displayDate.niceDateAndTime(dateOnly);
  } catch (error) {
    return '';
  }
};

/**
 * Get index from path
 * @param {String} path
 * @return {String}
 */
export const getIndexFromPath = (path) => {
  return path.substring(path.lastIndexOf('/') + 1);
};

/**
 * Get the string of the object of a specific lang -> eng or nothing
 * @param {String} path
 * @return {String}
 */
export const getLanguageText = (object, langCode) => {
  if (object) {
    const returnText = object[langCode]
      ? object[langCode]
      : object['EN']
      ? object['EN']
      : '';

    return returnText;
  }

  return '';
};

/**
 * Checks if the current user has access to the project as either a leader or a minor.
 * @param {Object} project - The project to check access for.
 * @param {Boolean} isLeaderAndMinor - If true, checks if the current user is a leader or minor.
 * @returns {Object|Boolean} - Returns an object with the boolean values `leader` and `minor` if `isLeaderAndMinor` is true. Otherwise, returns a boolean value indicating if the user has access to the project.
 */
export const projectAccess = (project, isLeaderAndMinor) => {
  const userId = sessionStorage.getItem('token');
  if (isLeaderAndMinor) {
    const crud = project.consultant.find((obj) => obj._id === userId);
    const read = project.access.find((obj) => obj._id === userId);
    return { crud: crud ? true : false, read: read ? true : false };
  }

  const access = [...project.consultant, ...project.access];
  const user = access.find((obj) => obj._id === userId);

  return user ? true : false;
};

/**
 * Capitalizes the first letter of a string.
 *
 * @param {string} string - The input string.
 * @returns {string} - The input string with the first letter capitalized.
 */
export const capitalizeFirstLetter = (string) => {
  if (typeof string !== 'string' || string.length === 0) {
    return string;
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * A simple copy to clipboard function
 * @param {string|object} data
 */
export const copyToClipBoard = (data, titles) => {
  navigator.clipboard.writeText(data);
};