/**
 * Object representing different user roles with their corresponding IDs and titles.
 * Contains roles such as 'Candidate', 'Customer', 'Project leading consultant', etc.
 * The role IDs and titles can be accessed using the object structure: ROLES.ROLE_TYPE.ROLE_SUBTYPE.id/title.
 */
const ROLES = {
  CANDIDATE: { id: 2, title: 'Candidate' },
  CUSTOMER: { id: 4, title: 'Customer' },
  CONSULTANT: {
    CUSTOMROLE: { id: 6, title: 'Custom role' },
    CONSULTANT: { id: 12, title: 'Consultant' },
    PROJECTLEADING: { id: 7, title: 'Project leading consultant' },
    ASSOCIATE: { id: 8, title: 'Associate consultant' },
  },
  ADMIN: {
    COORDINATOR: { id: 9, title: 'Project coordinator' },
    QA: { id: 10, title: 'QA Admin' },
    SUPERUSER: { id: 11, title: 'Superuser' },
  },
  SUPERADMIN: { id: 68, title: 'Superadmin' },
};

/**
 * Retrieves the roles to pick from the available roles.
 *
 * @returns {Array} - The array of roles to pick from.
 */
export const rolesToPick = () => {
  const roles = [];

  for (const key in ROLES.CONSULTANT) {
    const role = ROLES.CONSULTANT[key];
    roles.push({ _id: role.id, title: role.title });
  }

  for (const key in ROLES.ADMIN) {
    const role = ROLES.ADMIN[key];
    roles.push({ _id: role.id, title: role.title });
  }
  return roles;
};

/**
 * Returns a user-friendly title for a given role ID in the Navigio system.
 *
 * @param {number} roleId - The role ID to get the title for.
 * @returns {string} A string representing the user-friendly title for the role.
 */
export const getNavigioRoleTitle = (roleId) => {
  switch (roleId) {
    case ROLES.CANDIDATE.id:
      //roleId: 2
      return ROLES.CANDIDATE.title;
    case ROLES.CUSTOMER.id:
      //roleId: 4
      return ROLES.CUSTOMER.title;
    case ROLES.CONSULTANT.CUSTOMROLE.id:
      //roleId: 6
      return ROLES.CONSULTANT.CUSTOMROLE.title;
    case ROLES.CONSULTANT.PROJECTLEADING.id:
      //roleId: 7
      return ROLES.CONSULTANT.PROJECTLEADING.title;
    case ROLES.CONSULTANT.CONSULTANT.id:
      //roleId: 12
      return ROLES.CONSULTANT.CONSULTANT.title;
    case ROLES.CONSULTANT.ASSOCIATE.id:
      //roleId: 8
      return ROLES.CONSULTANT.ASSOCIATE.title;
    case ROLES.ADMIN.COORDINATOR.id:
      //roleId: 9
      return ROLES.ADMIN.COORDINATOR.title;
    case ROLES.ADMIN.QA.id:
      //roleId: 10
      return ROLES.ADMIN.QA.title;
    case ROLES.ADMIN.SUPERUSER.id:
      //roleId: 11
      return ROLES.ADMIN.SUPERUSER.title;
    case ROLES.SUPERADMIN.id:
      //roleId: 68
      return ROLES.SUPERADMIN.title;
    default:
      return 'Unknown role';
  }
};

let userRoleId = 0;
let userCustomPermissions = null;

/**
 * Sets the user's role ID and custom permissions.
 *
 * @param {string} roleId - The role ID to set for the user.
 * @param {object} customPermissions - The custom permissions object to set for the user.
 */
export const setUserRoleId = (roleId, customPermissions) => {
  if (roleId) userRoleId = roleId;

  if (customPermissions) userCustomPermissions = customPermissions;
};

/**
 * Defines and returns the user access permissions based on the provided role ID and custom permissions.
 * The function uses a switch statement to determine the role ID and assigns corresponding access permissions to the userAccessObject.
 * Custom permissions can be optionally passed to override the default permissions defined for the role.
 *
 * @param {number} roleId - The ID of the user role.
 * @param {object} customPermissions - Custom permissions object to override default permissions (optional).
 * @returns {object} - User access permissions object.
 */
export function definePermissionsObject(roleId, customPermissions = null) {
  let userAccessObject = {};
  switch (roleId) {
    case ROLES.CANDIDATE.id:
      // Role ID 2 - Code for case CANDIDATE goes here
      break;

    case ROLES.CUSTOMER.id:
      // Role ID 4 - Code for case CUSTOMER goes here
      break;
    case ROLES.CONSULTANT.CUSTOMROLE.id:
      //Role ID 6 - Code for case ROLES.CUSTOMROLE.id goes here
      userAccessObject = {
        PROJECTS: 'read',
      };
      break;

    case ROLES.CONSULTANT.PROJECTLEADING.id:
    case ROLES.CONSULTANT.ASSOCIATE.id:
      // Role ID 8 - Code for case CONSULTANT.ASSOCIATE goes here
      userAccessObject = {
        CANDIDATES: 'read',
        REPORTS: 'read',
        PROJECTS: 'read',
      };
      break;

    case ROLES.CONSULTANT.CONSULTANT.id:
      // Role ID 12 - Code for case CONSULTANT.CONSULTANT goes here
      userAccessObject = {
        REPORTS: 'read',
        PROJECTS: 'read',
      };
      break;

    case ROLES.ADMIN.COORDINATOR.id:
      // Role ID 9 - Code for case ADMIN.COORDINATOR goes here
      userAccessObject = {
        PROJECTS: 'crud',
        CANDIDATES: 'crud',
        REPORTS: 'crud',
        QUESTIONS: 'crud',
        LANGUAGES: 'crud',
        CATEGORIES: 'crud',
      };
      break;

    case ROLES.ADMIN.QA.id:
      // Role ID 10 - Code for case ADMIN goes here
      userAccessObject = {
        PROJECTS: 'read',
        FACETS: 'crud',
        CLUSTERS: 'crud',
        QUESTIONS: 'crud',
        REPORTS: 'crud',
        TEMPLATES: 'crud',
        LANGUAGES: 'crud',
        CATEGORIES: 'crud',
      };
      break;

    case ROLES.ADMIN.SUPERUSER.id:
      // Role ID 11 - Code for case SUPERUSER goes here
      userAccessObject = {
        CANDIDATES: 'crud',
        TEXT: 'crud',
        PROJECTS: 'crud',
        USERS: 'crud',
      };
      break;

    case ROLES.SUPERADMIN.id:
      // Role ID 68 - Code for case SUPERUSER goes here
      userAccessObject = {
        CANDIDATES: 'crud',
        REPORTS: 'crud',
        TEMPLATES: 'crud',
        QUESTIONS: 'crud',
        CATEGORIES: 'crud',
        LANGUAGES: 'crud',
        PROJECTS: 'crud',
        USERS: 'crud',
        ROLES: 'crud',
        POSITIONS: 'crud',
        COMPANIES: 'crud',
        CLUSTERS: 'crud',
        FACETS: 'crud',
      };
      break;

    default:
      // Code for default case goes here
      userAccessObject = {}; // Define empty userAccessObject
      break;
  }

  if (customPermissions && Object.keys(customPermissions).length !== 0) {
    userAccessObject = includeCustomPermissions(
      customPermissions,
      userAccessObject
    );
  }

  /*if(customPermissions === null)
    return userAcessObject;
*/
  return userAccessObject;
}

/**
 * Returns a list of role based permissions.
 *
 * @return {Array} - An array containing the names of custom permissions.
 */
export const listOfUsersRolePermissions = (roleId) => {
  const id = typeof roleId === 'string' && roleId ? parseInt(roleId) : roleId;
  const rolebasedPermissons = definePermissionsObject(id);
  /*Object.entries(rolebasedPermissons).forEach(([key, value]) => {{
      userAccessObject[key] = value;
  }}*/
  return rolebasedPermissons;
};

/**
 * Returns a list of custom permission names.
 *
 * @return {Array} - An array containing the names of custom permissions.
 */
export const listAvailableCustomPermissions = (roleId) => {
  const id = typeof roleId === 'string' && roleId ? parseInt(roleId) : roleId;

  const usersPermissons = definePermissionsObject(id);

  const availablePermissions = {
    CANDIDATES: { objStructuredData: { CANDIDATES: '' } },
    REPORTS: { objStructuredData: { REPORTS: '' } },
    TEMPLATES: { objStructuredData: { TEMPLATES: '' } },
    QUESTIONS: { objStructuredData: { QUESTIONS: '' } },
    CATEGORIES: { objStructuredData: { CATEGORIES: '' } },
    LANGUAGES: { objStructuredData: { LANGUAGES: '' } },
    TEXT: { objStructuredData: { TEXT: '' } },
    PROJECTS: { objStructuredData: { CANDIDATES: '' } },
    USERS: { objStructuredData: { USERS: '' } },
    ROLES: { objStructuredData: { ROLES: '' } },
    POSITIONS: { objStructuredData: { POSITIONS: '' } },
    COMPANIES: { objStructuredData: { COMPANIES: '' } },
    CLUSTERS: { objStructuredData: { CLUSTERS: '' } },
    FACETS: { objStructuredData: { FACETS: '' } },
  };

  const customPermissions = () => {
    for (const key in usersPermissons) {
      if (usersPermissons[key] === 'crud') {
        delete availablePermissions[key];
      }
    }
    return availablePermissions;
  };

  return customPermissions();
};

/**
 * Merges custom permissions into an access object.
 *
 * @param {Object} customPermissions - An object containing custom permissions to be added to the access object.
 * @param {Object} userAccessObject - An object representing the access levels for various resources.
 * @return {Object} - An updated access object that includes the custom permissions.
 */
const includeCustomPermissions = (customPermissions, userAccessObject) => {
  const hierarchy = {
    crud: 1,
    read: 2,
    none: 3,
  };

  Object.entries(customPermissions).forEach(([key, value]) => {
    if (
      !userAccessObject[key] ||
      hierarchy[value] < hierarchy[userAccessObject[key]]
    ) {
      userAccessObject[key] = value;
    }
  });

  return userAccessObject;
};

/**
 * Checks if the user has permission to access the settings based on the userAccessObject and userAccessKeys.
 * It iterates through the settings array and checks if each setting is present in the userAccessKeys and if its corresponding value in the userAccessObject is not 'none'.
 * If any of the settings has valid permissions, it returns true. Otherwise, it returns false.
 * @param {object} userAccessObject - The user access object containing the permissions.
 * @param {array} userAccessKeys - The array of user access keys.
 * @returns {boolean} - True if the user has permission to access the settings, false otherwise.
 */
const checkSettingsPermission = (userAccessObject, userAccessKeys) => {
  // Available tabs currently under settings
  const settings = ['USERS', 'FACETS', 'CLUSTERS'];

  for (let i = settings.length - 1; 0 <= i; i--) {
    const setting = settings[i];

    if (
      userAccessKeys.includes(setting) &&
      userAccessObject[setting] !== 'none'
    )
      return true;
  }

  return false;
};

/**
 * Checks if the user has the specified permission needed.
 * It retrieves the user access object and user access keys using definePermissionsObject() function.
 * If the permission needed is 'SETTINGS', it calls the checkSettingsPermission() function to determine if the user has access to settings.
 * If the permission needed is found in the user access keys and its corresponding value in the user access object is not 'none', it returns the permission value.
 * Otherwise, it returns false.
 * @param {string} permissionNeeded - The permission needed to check.
 * @returns {boolean|string} - The permission value if the user has access, false otherwise.
 */
export const userHasAccess = (permissionNeeded) => {
  const userAccessObject = definePermissionsObject(
    userRoleId,
    userCustomPermissions
  );
  const userAccessKeys = Object.keys(userAccessObject);

  if (permissionNeeded === 'SETTINGS') {
    return checkSettingsPermission(userAccessObject, userAccessKeys);
  }

  if (
    userAccessKeys.includes(permissionNeeded) &&
    userAccessObject[permissionNeeded] !== 'none'
  ) {
    return userAccessObject[permissionNeeded];
  } else {
    return false;
  }
};
