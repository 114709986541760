import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  testCheckbox,
  testCheckboxChecked,
} from '../../../shared/assets/icons';
import { device } from '../../../../styles/device';

export const CheckboxOptions = ({
  options,
  setAnswer,
  answerCheckboxes,
  isInstruction,
  correctExercises,
  focus,
  allowedAnswers,
  index,
  category,
  answerIx,
}) => {
  const disable =
    isInstruction ||
    (answerCheckboxes.length >= allowedAnswers && allowedAnswers >= 2);

  //TODO: fix animation with state below
  const [doAnimation, setDoAnimation] = useState(true);
  const isVertical = category !== 'analogies' && category !== 'opposites';

  useEffect(() => {
    if (!doAnimation) {
      setDoAnimation(true);
    }
  }, [doAnimation]);

  return (
    <Container isVertical={isVertical}>
      {options.map((option, key) => {
        if (option.type !== 'checkbox') return false;

        const correctExercise =
          correctExercises &&
          correctExercises.findIndex((item) => item._id === option._id) !== -1;

        const allExercisesCorrect =
          options
            .map((option) => {
              return (
                option &&
                option.isCorrect &&
                correctExercises &&
                correctExercises.findIndex(
                  (item) => item._id === option._id
                ) === -1
              );
            })
            .indexOf(true) === -1;

        const checked = isInstruction
          ? option.isCorrect
          : answerCheckboxes.findIndex((item) => item._id === option._id) !==
            -1;
        return (
          <Wrapper
            disable={!checked && disable}
            key={key}
            onClick={
              !disable || (checked && disable)
                ? () =>
                    setAnswer({
                      type: 'checkbox',
                      value: option,
                      isCorrect: option.isCorrect,
                      index,
                      checked,
                      answerIx,
                    })
                : () => {}
            }
          >
            {allExercisesCorrect && correctExercise ? (
              <AnimationContainer doAnimation={doAnimation}>
                <Checkbox
                  id={option._id}
                  type='checkbox'
                  name={option.text}
                  value={option.text}
                  focus={focus}
                />
                {checked ? (
                  <Checkmark src={testCheckboxChecked} />
                ) : (
                  <Checkmark src={testCheckbox} />
                )}
                <Label htmlFor={option._id}>{option.text}</Label>
              </AnimationContainer>
            ) : (
              <>
                <Checkbox
                  id={option._id}
                  type='checkbox'
                  name={option.text}
                  value={option.text}
                />
                {checked ? (
                  <Checkmark src={testCheckboxChecked} />
                ) : (
                  <Checkmark src={testCheckbox} />
                )}
                <Label disable={!checked && disable} htmlFor={option._id}>
                  {option.text}
                </Label>
              </>
            )}
          </Wrapper>
        );
      })}
      <PreLoadImage src={testCheckboxChecked} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isVertical ? 'column' : 'row')};
  position: relative;
  margin-bottom: 1.2rem;
  font-size: 2.2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: ${(props) => props.theme.color.white};
  height: ${(props) => (props.isVertical ? 'auto' : '3.5rem')};
  align-items: center;
  ${(props) =>
    props.disable &&
    css`
      cursor: auto;
    `}
`;

const AnimationContainer = styled.div`
  width: fit-content;
  background: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem;
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: ${(props) => props.theme.color.exercise};
    transition: width 2.3s;
  }
  ${(props) =>
    props.doAnimation &&
    css`
      &:after {
        width: 100%;
      }
    `}

  justify-content: ${(props) =>
    props.manyOptions ? 'space-between' : 'flex-start'};
  justify-content: ${(props) =>
    props.hasImageQuestion && !props.manyOptions && 'center'};
  @media ${device.mobileL} {
    justify-content: flex-start;
  }
`;

const Wrapper = styled.div`
  margin-right: auto;
  cursor: pointer;
  margin-left: 0;
  display: flex;
  align-items: center;
  width: 100%;

  &:not(:first-child) {
    margin-left: 1rem;
  }

  ${(props) =>
    props.disable &&
    css`
      cursor: auto;
    `}
`;

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Label = styled.label`
  font-family: 'futura-pt', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.2rem;
  line-height: 160%;
  margin-left: 1rem;
  letter-spacing: 0.01em;
  cursor: pointer;
  color: ${(props) => props.theme.color.black};

  ${(props) =>
    props.disable &&
    css`
      cursor: auto;
    `}
`;

const PreLoadImage = styled.img`
  display: none;
`;

const Checkmark = styled.img``;
