import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Button } from '../../../shared/button/Button';
import { InputField } from '../../../shared/input/InputField';
import { Modal } from '../../../shared/modal/Modal';
import history from '../../../../history';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { SelectInput } from '../../../shared/input/SelectInput';
import { FormLabel } from '../../../shared/input/FormLabel';
import { Type } from '../../questions/edit-question/Type';

export const NewProject = ({ type, title }) => {
  const [showModal, setShowModal] = useState(false);
  const [payload, setPayload] = useState({});
  const [consultPayload, setConsultPayload] = useState({});
  const [isProject, setIsProject] = useState(true);
  const isTemplate = type === 'title-only';

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This is a required field.'),
    ...(!isTemplate && {
      consultant: Yup.string().required('This is a required field.'),
    }),
  });

  const initialValues = {
    title: '',
    consultant: '',
  };

  const { data, hasError } = useFetch(payload);
  const { data: consultants } = useFetch(consultPayload);

  useEffect(() => {
    if (data && data.data && !showModal) {
      const newProject = data.data;
      history.push(
        `/admin/reports/${(isTemplate && 'edit-template') ||
          (newProject.isProject && 'projects') ||
          'groups'}/${newProject.slug}`,
        {
          slug: newProject.slug,
        }
      );
    }
  }, [data, showModal, isTemplate]);

  useEffect(() => {
    if (data && data.data) {
      setShowModal(false);
    }
  }, [data]);

  return (
    <>
      <Button
        add
        onClick={() => {
          if (!isTemplate) {
            setConsultPayload({
              endpoint: '/api/omega/users/permissions',
              method: 'get',
            });
          }
          setShowModal(true);
        }}
      />

      <Modal
        show={showModal}
        handleExit={setShowModal}
        width={'40rem'}
        title={`Create new ${title}`}
      >
        {!hasError && (
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async (values, { resetForm }) => {
              const newProject = {
                title: values.title,
                ...(!isTemplate && {
                  consultant: values.consultant,
                  isProject:
                    type === 'both'
                      ? isProject
                      : type === 'projects'
                      ? true
                      : false,
                }),
              };

              setPayload({
                method: 'post',
                data: newProject,
                endpoint: `/api/omega/${(isTemplate && 'report-template') ||
                  'projects'}`,
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              resetForm();
            }}
          >
            {({
              touched,
              errors,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <InputField
                  label='Title'
                  id='title'
                  type='text'
                  name='title'
                  placeholder=''
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  error={errors.title && touched.title}
                  message={errors.title && touched.title && `${errors.title}`}
                />

                {type === 'both' && (
                  <>
                    <FormLabel label='Is this a project?' />
                    <Type
                      chosenType={isProject}
                      setChosenType={(target) => setIsProject(target)}
                      yesOrNoOption
                    />
                    <Margin small />
                  </>
                )}

                {!isTemplate && (
                  <SelectInput
                    label='Consultant'
                    id='consultant'
                    placeholder='Select consultant:'
                    name='consultant'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.consultant}
                    error={errors.consultant && touched.consultant}
                    message={
                      errors.consultant &&
                      touched.consultant &&
                      `${errors.consultant}`
                    }
                    data={consultants && consultants.data}
                  />
                )}
                <Margin />
                <Button
                  primary
                  type='submit'
                  disabled={isSubmitting}
                  text='Continue'
                />
              </Form>
            )}
          </Formik>
        )}
        {hasError && (
          <>
            <h2>Error creating {title}</h2>
            <Button text='OK' onClick={() => setShowModal(false)} />
          </>
        )}
      </Modal>
    </>
  );
};

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Margin = styled.div`
  height: ${(props) =>
    props.small ? props.theme.spacing.small : props.theme.spacing.extraLarge};
`;
