import React from 'react';
import styled from 'styled-components';

//TODO: Change name its radiobuttons not checkboxes xD
export const CheckboxGroup = ({
  options,
  selectedOptions,
  onChange,
  disabled,
}) => {
  const handleCheckboxChange = (option, level) => {
    const updatedOptions = {
      ...selectedOptions,
      [option]: level,
    };
    onChange(updatedOptions);
  };

  const getPermissionLevel = (option) => {
    if (
      selectedOptions &&
      Object.prototype.hasOwnProperty.call(selectedOptions, option)
    ) {
      return selectedOptions[option];
    }
    return 'none';
  };

  return (
    <>
      {Object.keys(options).map((option) => {
        const name = disabled ? option + 1 : option;
        return (
          <CheckboxWrapper key={option}>
            <PermissionLabel>{option}</PermissionLabel>
            <CheckboxLabel>
              <RadioInput
                type='radio'
                name={name}
                value='none'
                checked={getPermissionLevel(option) === 'none'}
                onChange={() => handleCheckboxChange(option, 'none')}
                disabled={disabled}
              />
              None
            </CheckboxLabel>
            <CheckboxLabel>
              <RadioInput
                type='radio'
                name={name}
                value='read'
                checked={getPermissionLevel(option) === 'read'}
                onChange={() => handleCheckboxChange(option, 'read')}
                disabled={disabled}
              />
              Read
            </CheckboxLabel>
            <CheckboxLabel>
              <RadioInput
                type='radio'
                name={name}
                value='crud'
                checked={getPermissionLevel(option) === 'crud'}
                onChange={() => handleCheckboxChange(option, 'crud')}
                disabled={disabled}
              />
              Full
            </CheckboxLabel>
          </CheckboxWrapper>
        );
      })}
    </>
  );
};

const CheckboxWrapper = styled.div`
  margin-bottom: 10px;
`;

const RadioInput = styled.input`
  margin-right: 5px;
`;

const PermissionLabel = styled.div`
  font-weight: bold;
`;

//Styled components
const CheckboxLabel = styled.label`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  cursor: default;
`;
