import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Header } from '../../components/main/Header';
import { MainNav } from '../../components/admin/navigation/MainNav';
import { Route, Redirect, Switch } from 'react-router-dom';
import { AdminCandidates } from './candidates/candidates';
import { AdminReports } from './reports/projects';
import { AdminTemplates } from './templates/templates';
import { AdminQuestions } from './questions/questions';
import { AdminLanguages } from './languages/languages';
import { updateRoutes } from '../../components/admin/navigation/routes';
import { AdminCategories } from './categories/categories';
import { AdminSettings } from './settings/settings';
import { AdminProjects } from './projects/your-projects';
import { userHasAccess } from '../../helpers/permissions';
import { AccessDenied } from '../../components/shared/AccessDenied';
import { AdminChangePassword } from './change-password';

export const AdminIndex = ({ history }) => {
  const [routeObject] = useState(updateRoutes());
  const [pathname, setPathname] = useState(history.location.pathname);
  const [deepRoute, setDeepRoute] = useState(pathname.split('/').length > 4);
  const mainComponent = `/${pathname.split('/')[2]}`;
  const routeIndex = routeObject.findIndex((r) => r.path === mainComponent);
  const [route, setRoute] = useState(routeObject[routeIndex]);
  // Handle 404 here in the future
  // if (!route) return <div> 404 </div>;

  const handlePopstate = useCallback(
    (_, action) => {
      if (action === 'POP' || action === 'PUSH') {
        const path = `/${history.location.pathname.split('/')[2]}`;
        setPathname(history.location.pathname);
        const routeIndex = routeObject.findIndex((r) => r.path === path);
        setRoute({ ...routeObject[routeIndex] });
      }
    },
    [routeObject, history]
  );

  useEffect(() => {
    const unlisten = history.listen(handlePopstate);
    return () => {
      unlisten();
    };
  }, [history, handlePopstate]);

  // TODO: Temporary fix until we have time to refactor the routing to not have a useeffect to listen on history
  if (
    route &&
    route.path !== mainComponent &&
    mainComponent !== '/change-password'
  ) {
    return (
      <MainContainer>
        <Header />
        <MainNav setRoute={setRoute} routes={routeObject} />
        <Container>
          <AccessDenied center />
        </Container>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <Header />
      <MainNav setRoute={setRoute} routes={routeObject} />
      <Container>
        <Switch>
          <Route path='/admin/candidates'>
            {userHasAccess('CANDIDATES') ? (
              <AdminCandidates route={route || routeObject[0]} />
            ) : (
              <AccessDenied center />
            )}
          </Route>
          <Route path='/admin/templates'>
            {userHasAccess('TEMPLATES') || userHasAccess('TEXT') ? (
              <AdminTemplates
                route={route}
                deepRoute={deepRoute}
                setDeepRoute={setDeepRoute}
              />
            ) : (
              <AccessDenied center />
            )}
          </Route>
          <Route path='/admin/questions'>
            {userHasAccess('QUESTIONS') || userHasAccess('TEXT') ? (
              <AdminQuestions
                route={route}
                deepRoute={deepRoute}
                setDeepRoute={setDeepRoute}
              />
            ) : (
              <AccessDenied center />
            )}
          </Route>
          <Route path='/admin/categories'>
            {userHasAccess('CATEGORIES') ? (
              <AdminCategories />
            ) : (
              <AccessDenied center />
            )}
          </Route>
          <Route path='/admin/languages'>
            {userHasAccess('LANGUAGES') === 'crud' ? (
              <AdminLanguages
                route={route}
                deepRoute={deepRoute}
                setDeepRoute={setDeepRoute}
              />
            ) : (
              <AccessDenied center />
            )}
          </Route>
          <Route path='/admin/reports'>
            {userHasAccess('REPORTS') || userHasAccess('PROJECTS') ? (
              <AdminReports
                route={route}
                deepRoute={deepRoute}
                setDeepRoute={setDeepRoute}
              />
            ) : (
              <AccessDenied center />
            )}
          </Route>
          <Route path='/admin/settings'>
            {userHasAccess('SETTINGS') ? (
              <AdminSettings
                route={route}
                deepRoute={deepRoute}
                setDeepRoute={setDeepRoute}
              />
            ) : (
              <AccessDenied center />
            )}
          </Route>
          <Route path='/admin/projects'>
            {userHasAccess('PROJECTS') ? (
              <AdminProjects history={history} />
            ) : (
              <AccessDenied center />
            )}
          </Route>
          <Route path='/admin/change-password'>
            <AdminChangePassword />
          </Route>
          <Redirect from='/admin' to='/admin/candidates' />
        </Switch>
      </Container>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-grow: 1;
  height: 100%;
`;
