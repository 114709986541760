import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { CreationProgress } from '../../../shared/progress-bar/CreationProgress';
import { InputWrapper } from '../../../shared/input/InputWrapper';
import { AddQuestions, AddNewGroup } from './';
import { Button } from '../../../shared/button/Button';
import { device } from '../../../../styles/device';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useFetch } from '../../../../helpers/hooks/fetchData';
import { closedSection } from '../../../shared/assets/icons';
import history from '../../../../history';
import { Modal } from '../../../shared/modal/Modal';

export const QuestionsTemplate = ({ template, setTemplate, setPage }) => {
  const [payload, setPayload] = useState({});
  const [sectionOpen, setSectionOpen] = useState(
    template.questionSections && template.questionSections.length > 0 ? 0 : -1
  );
  const [showModal, setShowModal] = useState(false);
  const { data } = useFetch(payload);
  useEffect(() => {
    if (data) {
      setShowModal(true);
    }
  }, [data, setPage, setTemplate, template]);

  const validationSchema = yup.object().shape({
    questionSections: yup.lazy(() =>
      yup.array().of(
        yup.object().shape({
          title: yup.string().required('Please enter a name for this group'),
        })
      )
    ),
  });

  const addNewGroup = () => {
    template.questionSections = template.questionSections || [];
    template.questionSections.push({
      title: [],
      description: '',
      questions: [],
    });

    setSectionOpen(template.questionSections.length - 1);
  };

  const openSection = (ix) => {
    return setSectionOpen(ix);
  };

  return (
    <Container>
      <CreationProgress
        step='third'
        firstStepText='General'
        secondStepText='Instruction'
        thirdStepText='Questions'
      />

      <InnerContainer>
        <Formik
          initialValues={template}
          values={template}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            setPayload({
              method: 'put',
              endpoint: `/api/omega/templates/${template._id}/`,
              data: values,
              headers: {
                'Content-Type': 'application/json',
              },
            });
          }}
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              {template.questionSections &&
                template.questionSections.map((section, sectionIndex) => {
                  if (sectionOpen === sectionIndex) {
                    return (
                      <React.Fragment key={`${sectionIndex}`}>
                        <InputWrapper marginTop noPadding>
                          <AddQuestions
                            template={template}
                            section={section}
                            touched={touched}
                            errors={errors}
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            sectionOpen={sectionOpen}
                            sectionIndex={sectionIndex}
                          />
                        </InputWrapper>
                      </React.Fragment>
                    );
                  } else {
                    let text = '';
                    values.questionSections[sectionIndex].title.forEach(
                      (title) => {
                        if (title.language === 'EN') {
                          text = title.text;
                        }
                      }
                    );
                    return (
                      <React.Fragment key={`${sectionIndex}`}>
                        <AddGroupContainer
                          marginTop
                          onClick={() => openSection(sectionIndex)}
                        >
                          <Icon src={closedSection} />
                          <Text>
                            {text
                              ? text
                              : values.questionSections[sectionIndex].title[0]
                                  .text}
                          </Text>
                        </AddGroupContainer>
                      </React.Fragment>
                    );
                  }
                })}
              <StandardSpacing />
              <AddNewGroup onClick={addNewGroup} />
              <StandardSpacing />
              <ButtonContainer>
                <Button
                  secondary
                  text='Back'
                  onClick={() => {
                    setPage(template.hasInstruction ? 2 : 1);
                  }}
                />
                <MarginButton />
                <Button primary text='Save' type='submit' />
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </InnerContainer>
      <Modal
        handleExit={() => setShowModal(false)}
        title='Changes have been saved'
        show={showModal}
        width='40rem'
      >
        <Button
          onClick={() => {
            setShowModal(false);
            history.push('/admin/templates/view-all');
          }}
          text='OK'
          primary
        />
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
const InnerContainer = styled.div`
  max-width: 804px;
`;
const StandardSpacing = styled.div`
  height: ${(props) => props.theme.spacing.standard};
`;

const ButtonContainer = styled.div`
  background: ${(props) => props.theme.color.white};
  display: flex;
  flex-direction: row;
  border-radius: 0.4rem;
  padding: ${(props) => props.theme.spacing.standard};
  margin-bottom: ${(props) => props.theme.spacing.standard};
  @media ${device.mobileL} {
    flex-direction: column;
  }
`;
const MarginButton = styled.div`
  width: 6rem;
  @media ${device.mobileL} {
    height: ${(props) => props.theme.spacing.small};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;
const Text = styled.p`
  padding-left: ${(props) => props.theme.spacing.standard};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
`;
const Icon = styled.img`
  height: 2rem;
  width: 2rem;
  margin-left: ${(props) => props.theme.spacing.standard};
`;
const AddGroupContainer = styled.div`
  height: 6rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 0.4rem;
  background: ${(props) => props.theme.color.white};
  cursor: pointer;
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${(props) => props.theme.spacing.standard};
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.standard};
    `}
  ${(props) =>
    props.errorMessage &&
    css`
      border: 1px solid ${(props) => props.theme.color.error};
    `}
`;
