import React from 'react';
import styled, { css } from 'styled-components';
import { plusBlack } from '../../../shared/assets/icons';

export const AddNewOption = ({
  onClick,
  marginTop,
  marginBottom,
  errorMessage,
}) => {
  return (
    <Container
      onClick={onClick}
      marginTop={marginTop}
      marginBottom={marginBottom}
      errorMessage={errorMessage}
    >
      {/* {errorMessage && <Error>{errorMessage}</Error>} */}
      <Icon src={plusBlack} />
      <Text>Add new option</Text>
    </Container>
  );
};

const Container = styled.div`
  height: 6rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 0.4rem;
  background: ${(props) => props.theme.color.white};
  cursor: pointer;
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${(props) => props.theme.spacing.standard};
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${(props) => props.theme.spacing.standard};
    `}
  ${(props) =>
    props.errorMessage &&
    css`
      border: 1px solid ${(props) => props.theme.color.error};
    `}
`;

const Icon = styled.img`
  height: 2rem;
  width: 2rem;
  margin-left: ${(props) => props.theme.spacing.standard};
`;

const Text = styled.p`
  padding-left: ${(props) => props.theme.spacing.standard};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
`;
// const Error = styled.span`
//   font-size: 1.2rem;
//   color: ${(props) => props.theme.color.error};
//   position: absolute;
//   top: 0.5rem;
//   right: 0.5rem;
// `;
