import React from 'react';
import styled, { css } from 'styled-components';

export const InputWrapper = ({
  children,
  errorMsg,
  marginTop,
  noPadding,
  noPaddingLeft,
  title,
  height,
  icon,
  addMore,
}) => {
  return (
    <Container
      errorMsg={errorMsg}
      marginTop={marginTop}
      noPadding={noPadding}
      noPaddingLeft={noPaddingLeft}
      height={height}
    >
      <Header>
        {icon && <Icon src={icon} />}
        <Title>{title}</Title>{' '}
      </Header>
      {addMore && (
        <ButtonContainer>
          <Button type='button' onClick={addMore}>
            +
          </Button>
        </ButtonContainer>
      )}
      <ErrorMessage>{errorMsg}</ErrorMessage>
      {children}
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.color.white};
  padding: ${(props) => props.theme.spacing.standard};
  border-radius: 0.4rem;
  position: relative;
  ${(props) =>
    props.errorMsg &&
    css`
      border: 1px solid ${(props) => props.theme.color.error};
    `}
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${(props) => props.theme.spacing.standard};
    `}

  ${(props) =>
    props.height &&
    css`
      height: ${props.height}rem;
    `}
  ${(props) =>
    props.noPadding &&
    css`
      padding-top: 0rem;
    `}
    ${(props) =>
      props.noPaddingLeft &&
      css`
        padding-left: 0rem;
      `}

`;

const ErrorMessage = styled.p`
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${(props) => props.theme.color.error};
  position: absolute;
  top: 0;
  right: 0;
  padding-top: ${(props) => props.theme.spacing.small};
  padding-right: ${(props) => props.theme.spacing.standard};
`;

const Title = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  color: ${(props) =>
    props.error
      ? `1px solid ${props.theme.color.error}`
      : `1px solid ${props.theme.color.darkBlue}`};
`;
const Icon = styled.img`
  margin-right: ${(props) => props.theme.spacing.extraSmall};
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${(props) => props.theme.spacing.standard};
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
  gap: 1rem;
`;

const Button = styled.button``;
