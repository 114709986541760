import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../helpers/hooks/fetchData';
import styled from 'styled-components';
import { Modal } from '../../shared/modal/Modal';
import { Formik } from 'formik';
import { InputField } from '../../shared/input/InputField';
import * as Yup from 'yup';
import { Button } from '../../shared/button/Button';
import { InputWrapper } from '../../shared/input/InputWrapper';
import { Type } from '../questions/edit-question/Type';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('This is a required field.'),
});

const initialValues = {
  title: '',
};

export const AddCategory = ({ showAddModal, setShowAddModal }) => {
  const [errorText, setErrorText] = useState('Error creating new category');
  const [payload, setPayload] = useState({});
  const { data } = useFetch(payload);
  const [hasError, setHasError] = useState(false);
  const [isSurvey, setIsSurvey] = useState(false);

  useEffect(() => {
    if (data && data.error) {
      setHasError(true);
      if (typeof data.error.error === 'string') {
        setErrorText(data.error.error);
      } else {
        setErrorText('Error creating new category');
      }
    } else if (data && data.data && data.data.slug) {
      setHasError(false);
      return window.location.reload();
    }
  }, [data]);

  return (
    <>
      <Button onClick={() => setShowAddModal(true)} add category />
      {showAddModal && (
        <Modal
          show={showAddModal}
          handleExit={setShowAddModal}
          title='Create new category'
          width={'40rem'}
        >
          {!hasError && (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values) => {
                  //change this to Omega api route later on. Setting role below is not good, this will be set backend.
                  values = {
                    ...values,
                  };
                  values.isSurvey = isSurvey;
                  setPayload({
                    endpoint: '/api/omega/categories',
                    method: 'post',
                    data: values,
                    headers: {
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                  });
                }}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <InputField
                      id='title'
                      label='Category name'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      error={errors.title && touched.title}
                      type='text'
                      name='title'
                      placeholder='Name'
                      message={
                        errors.title && touched.title && `${errors.title}`
                      }
                    />
                    <Spacing />
                    <InputWrapper marginTop title='Category is survey'>
                      <Type
                        chosenType={isSurvey}
                        setChosenType={setIsSurvey}
                        yesOrNoOption
                      />
                    </InputWrapper>
                    <Spacing />
                    <Button
                      type='submit'
                      disabled={isSubmitting}
                      primary
                      text='Add category'
                    />
                  </Form>
                )}
              </Formik>
            </>
          )}
          {hasError && (
            <>
              <ErrorMessage>{errorText}</ErrorMessage>
              <Button
                secondary
                onClick={() => setShowAddModal(false)}
                text='OK'
              />
            </>
          )}
        </Modal>
      )}
    </>
  );
};

const ErrorMessage = styled.div`
  color: ${(props) => props.theme.color.error};
  font-size: 2rem;
  text-align: center;
  margin-bottom: ${(props) => props.theme.spacing.medium};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Spacing = styled.div`
  margin-bottom: 4.6rem;
`;
