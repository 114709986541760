import React, { useEffect, useState } from 'react';
import { getIndexFromPath } from '../../../helpers/common';
import { useFetch } from '../../../helpers/hooks/fetchData';
import history from '../../../history';

export const StartTest = (location) => {
  const index = getIndexFromPath(location.location.pathname);

  const endpoint = '/api/omega/candidate-test/start-test';
  const method = 'post';
  const [payload, setPayload] = useState({});
  const { data, hasError } = useFetch(payload);

  useEffect(() => {
    setPayload({ endpoint, method, params: { index } });
  }, [endpoint, method, index]);

  useEffect(() => {
    if (hasError) {
      //error handling?
    }

    if (data) {
      if (data.hasInstruction) {
        history.push(`/candidate/test/instructions/${index}`);
      } else {
        history.push(`/candidate/test/active-test/${index}`);
      }
    }
  }, [data, hasError, index]);

  return <></>;
};
