import React, { useState } from 'react';
import styled from 'styled-components';
import { penWithBorderGrey, navigioLogo } from '../../shared/assets/icons';

export const EditPreviewEmail = ({
  language,
  emailTitle,
  setEmailTitle,
  emailContent,
  setEmailContent,
  linkDescription,
  setLinkDescription,
  finalGreeting,
  setFinalGreeting,
  emailSubject,
  setEmailSubject,
  disableEdit,
}) => {
  const [showEdit, setShowEdit] = useState(false);

  return (
    <>
      <EmailContainer>
        <EmailTitle style={{ display: 'block' }}>
          <span style={{}}>Language: {language}</span>
          <EditImg
            disableEdit={disableEdit}
            pointer
            onClick={() => setShowEdit(!showEdit)}
            src={penWithBorderGrey}
            alt='Edit text'
          />
        </EmailTitle>

        {showEdit ? (
          <>
            <EditLabel top>Subject</EditLabel>
            <EditInput
              value={emailSubject}
              type='text'
              onChange={(e) => setEmailSubject(e.target.value)}
            />
            <EditLabel>Title</EditLabel>
            <EditTextArea
              rows={2}
              value={emailTitle}
              type='text'
              onChange={(e) => setEmailTitle(e.target.value)}
            />
            <EditLabel>Content</EditLabel>
            <EditTextArea
              rows={5}
              value={emailContent}
              onChange={(e) => {
                setEmailContent(e.target.value);
              }}
            />
            <EditLabel>Link Description</EditLabel>
            <EditTextArea
              value={linkDescription}
              rows={2}
              onChange={(e) => setLinkDescription(e.target.value)}
            />
            <EditLabel>Final greetings</EditLabel>
            <EditInput
              bottom
              value={finalGreeting}
              type='text'
              onChange={(e) => setFinalGreeting(e.target.value)}
            />
          </>
        ) : (
          <>
            <EmailTitle>Subject: {emailSubject}</EmailTitle>

            <EditImg
              bottom='1'
              background='#031D2D'
              src={navigioLogo}
              alt='NAVIGIO LOGO'
            />
            <EmailTitle>{emailTitle}</EmailTitle>
            <EmailText bottom='1.5'>{emailContent}</EmailText>
            <EmailText bottom='1'>{linkDescription}</EmailText>
            <EmailText color='#EEA153' bottom='1'>
              https://omega.navigio.se/omega/xxxxx
            </EmailText>
            <EmailText bottom='2'>{finalGreeting}</EmailText>
          </>
        )}
      </EmailContainer>
    </>
  );
};

const EmailTitle = styled.div`
  font-weight: bold;
  margin-bottom: 2rem;
  white-space: pre-line;
`;

const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-Top: -4rem
  font-size: 15px;
  
`;

const EmailText = styled.div`
  margin-top: ${(props) => props.top + 'rem'};
  margin-bottom: ${(props) => props.bottom}rem;
  white-space: pre-line;
  ${(props) => props.color && `color: ${props.color}`}
`;

const EditImg = styled.img`
  ${(props) => props.pointer && 'cursor: pointer;'}
  ${(props) => props.disableEdit && 'display: none;'}
  ${(props) => props.background && `background: ${props.background};`}
  ${(props) => props.bottom && `margin-bottom: ${props.bottom}rem`};
  float: right;
`;

const EditLabel = styled.div`
  font-weight: bold;
  margin-top: ${(props) => (props.top ? '0rem' : '1rem')};
  margin-bottom: 0.5rem;
`;

const EditInput = styled.input`
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: ${(props) => props.bold && 'bold'};
  ${(props) => props.bottom && 'margin-bottom: 2rem'};
`;

const EditTextArea = styled.textarea`
  font-size: 15px;
  font-weight: ${(props) => props.bold && 'bold'};
  resize: none;
  font-family: 'Roboto', sans-serif;
`;
