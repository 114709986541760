import React, { useState } from 'react';
import styled from 'styled-components';
import { fileUploadGrey, fileUploadWhite } from '../assets/icons';

export const FileInput = ({
  value,
  onChange,
  onBlur,
  id,
  error,
  image,
  sound,
  flag,
  acceptedFormats,
  currentFileName,
}) => {
  const [fileValue, setFileValue] = useState(currentFileName || '');
  //const [fileError, setFileError] = useState(false);

  const setLabel = (event) => {
    if (event.target.files[0].name === '') {
      //setFileError(true);
    } else {
      setFileValue(event.target.files[0].name);
    }
  };

  return (
    <InputContainer value={value} error={error}>
      {flag && (
        <FlagWrapper>
          <Flag src={flag} />
        </FlagWrapper>
      )}
      <Input
        type='file'
        value={value}
        onChange={(event) => {
          setLabel(event);
          onChange(event, id);
        }}
        onBlur={onBlur}
        id={id}
        error={error}
        className='file-input'
        accept={acceptedFormats || '.png, .jpg, .jpeg, .gif'}
      />
      <IconContainer error={error} fileValue={fileValue}>
        {!fileValue ? (
          <Icon src={fileUploadGrey} />
        ) : (
          <Icon src={fileUploadWhite} />
        )}
      </IconContainer>
      <SpanContainer htmlFor={id}>
        {fileValue && !error && <Text>{fileValue}</Text>}
        {!fileValue && image && <Text>Choose an image...</Text>}
        {!fileValue && sound && <Text>Choose a sound file...</Text>}

        {error && <Text>Upload failed</Text>}
      </SpanContainer>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  height: 6rem;
  width: 100%;
  position: relative;
  font-size: 1.6rem;
  line-height: 1.8rem;
  border-radius: 0.4rem;
  display: flex;
  // padding: ${(props) => props.theme.spacing.small};
  background: ${(props) => props.theme.color.bgGrey};
  :not(:last-child) {margin-bottom: 2rem;}
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  width: 6rem;

  border-radius: 0.4rem 0 0 0.4rem;
  background: ${(props) =>
    props.fileValue
      ? `${props.theme.color.selectedGrey}`
      : `${props.theme.color.darkGrey}`};
`;
const SpanContainer = styled.label`
  display: flex;
  align-items: center;
  height: 6rem;
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  cursor: pointer;
`;

const Icon = styled.img``;
const Text = styled.span`
  margin-left: 2rem;
  color: ${(props) => props.theme.color.textGrey};
`;
const FlagWrapper = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -1.6rem;
  right: ${(props) => props.theme.spacing.standard};
  box-shadow: 0px 0.05rem 1rem rgba(0, 0, 0, 0.12);
`;

const Flag = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
