import React from 'react';
import styled from 'styled-components';
// import { useRouteMatch, NavLink } from 'react-router-dom';
import { device } from '../../../styles/device';

export const SubMenu = (/*{ route }*/) => {
  // const { url } = useRouteMatch();

  //Sub menu is for now commented out due to that is no in use

  return (
    <Container>
      <Ul>
        {/* {route &&
          route.routes.map((route) => {
            return !route.isDynamic ? (
              <Li key={route.name}>
                <StyledLink to={`${url}${route.path}`}>{route.name}</StyledLink>
              </Li>
            ) : null;
          })} */}
      </Ul>
    </Container>
  );
};

const Container = styled.nav`
  background: ${(props) => props.theme.color.bgGrey};
  height: 6rem;
  display: flex;
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: row;
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  overflow: auto;
  white-space: nowrap;
  height: 100%;
  align-items: center;
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
  }
`;

// const Li = styled.li`
//   font-size: ${(props) => props.theme.size.menu};
//   line-height: 2rem;
// `;
// const StyledLink = styled(NavLink)`
//   text-decoration: none;
//   padding-right: ${(props) => props.theme.spacing.extraLarge};
//   color: ${(props) => props.theme.color.offGrey};
//   &.active {
//     color: ${(props) => props.theme.color.offBlack};
//   }
// `;
