import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../shared/button/Button';
import './Spinner.css';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { FaRegCopy } from 'react-icons/fa';
import { SelectInput } from '../../shared/input/SelectInput';
import { isArray } from 'lodash';
import { copyToClipBoard } from '../../../helpers/common';

/**
 * Converts a nested object into a formatted string representation.
 * @param {Object} data - The object to be converted.
 * @param {Object} titles - Optional object containing titles for specific keys.
 * @returns {string} - The formatted string representation of the object.
 */
const convertObjectToString = (data, titles) => {
  const formattedData = [];
  for (const key in data) {
    const value = data[key];
    let title = '';

    // Check if there's a custom title for the key (e.g., positive/negative)
    if (key === 'positive' && titles && titles.positive)
      title = titles.positive;
    if (key === 'negative' && titles && titles.negative)
      title = titles.negative;

    // Check if the value is a string
    if (typeof value === 'string')
      formattedData.push(`${title || key}: ${value} \n`);

    // Check if the value is an array
    if (isArray(value)) {
      for (const item of value) {
        formattedData.push(`${item.factor}: ${item.evaluationText} \n`);
      }
    }
  }

  // Join the formatted data into a single string
  const resultString = formattedData.join('');

  return resultString;
};

/**
 * A simple copy to clipboard function
 * @param {string|object} data
 */
const copy = (data, titles) => {
  data = typeof data === 'object' ? convertObjectToString(data, titles) : data;
  copyToClipBoard(data);
};

export const OpenAi = ({ user, setUser }) => {
  // const exampleCSF = '';
  // 'Strong in building business and relations according to set goals and expectations. Ability to lead and coach in business development by walking-the-talk, hands-on as needed. Identify business opportunities by being updated on industry trends, understand the levers driving the businesses and in close connection with new and current clients. With enthusiasm and high energy inspire and engage the organization. With close proximity, interest and understanding of the people, lead the organization and build strong teams. Reviewed as a role model who delegates and wins trust as a leader of leader. With solid experience and understanding of what drives profitability assure efficient and profitable project deliveries. Assure profession in delivering projects; on-time, scope management, customer satisfaction, economy. Clear view on key KPIs and how projects perform on an ongoing basis. Take possible mitigating actions and decisions. Set structure and governance to gain operative efficiency of the country/region. With an entrepreneurial mindset, drive ongoing efforts and improvements of business operations. Manage the need to drive transformation to gain profitability and success.';
  const nonProject = {
    criticalSuccessFactors: [],
    title: 'Non project',
    _id: 'noProject',
  };

  const [criticalSuccessFactors, setCriticalSuccessFactors] = useState('');
  const [payload, setPayload] = useState({});
  const [isDataView, setIsDataView] = useState(true);
  const [selectedProject, setSelectedProject] = useState(nonProject);
  const [projects, setProjects] = useState([]);
  const [projectData, setProjectData] = useState(null);

  let endpoint = `/api/omega/users/chatgpt/${user._id}`;

  const { data, isLoading, errorText, resetErrorText } = useFetch(
    payload,
    true
  );

  const handleClick = () => {
    resetErrorText();
    setPayload({
      method: 'PATCH',
      endpoint,
      data: {
        criticalSuccessFactors,
        projectId: selectedProject && selectedProject._id,
      },
    });
  };

  const toggleView = () => {
    setIsDataView(!isDataView);
  };

  useEffect(() => {
    if (data && data.data) {
      setUser((user) => {
        user.openAi = data.data;
        return { ...user };
      });
    }
  }, [data, setUser]);

  const setCSF = (project) => {
    let CSF = '';

    if (project && project.criticalSuccessFactors) {
      CSF = project.criticalSuccessFactors.join('\n');
    }

    setCriticalSuccessFactors(CSF);
  };

  useEffect(() => {
    if (user && user.projects && user.projects.length) {
      setSelectedProject((selected) => {
        const project = user.projects[0];
        if (!selected && project) {
          setCSF(project);
          return project;
        }
        return selected;
      });
      setProjects(user.projects);
    }
  }, [user]);

  useEffect(() => {
    if (selectedProject) {
      setProjectData(user && user.openAi && user.openAi[selectedProject._id]);
    }
  }, [selectedProject, user]);

  const handleChange = ({ target }) => {
    setSelectedProject(() => {
      let project = projects.find((project) => project._id === target.value);

      if (!project) project = nonProject;

      setCSF(project);
      return project;
    });

    resetErrorText();
  };

  return (
    <>
      <Container>
        <InnerContainer>
          <OpenAiHeader>
            <OpenAiHeaderColumn>
              <SelectInput
                onChange={handleChange}
                type='text'
                placeholder={'Non project'}
                value={selectedProject._id}
                id='position'
                data={projects}
              />
            </OpenAiHeaderColumn>

            <OpenAiHeaderColumn>
              {projectData && projectData.hasData && (
                <SwitchButton onClick={toggleView}>
                  <Text>
                    {isDataView
                      ? 'Switch to Prompt View'
                      : 'Switch to Result View'}
                  </Text>
                </SwitchButton>
              )}
            </OpenAiHeaderColumn>
          </OpenAiHeader>

          {(isLoading && <Spinner text='Updating texts...' />) ||
            (errorText && !isLoading && (
              <ErrorMessage>
                <Title err>{errorText || 'Something went wrong.'} </Title>
              </ErrorMessage>
            )) ||
            (projectData && projectData.hasData && (
              <>
                {(isDataView && (
                  <>
                    <Information
                      title='Motivation'
                      dataTitles={{
                        positive: 'Motivates',
                        negative: 'Demotivates',
                      }}
                      data={projectData.motivation}
                      twoColumns={true}
                    />
                    <Information
                      title='Leadership'
                      data={projectData.leadership}
                    />
                    <Information
                      title='Risk Management'
                      data={projectData.riskManagement}
                    />
                    <Information
                      title='Social relations'
                      data={projectData.relations}
                    />
                    <Information
                      title='Problem solving'
                      data={projectData.problemSolving}
                    />
                    <CriticalSuccessFactors
                      title='Critical Success Factors'
                      data={projectData.criticalSuccessFactors}
                    />
                  </>
                )) || (
                  <InformationContainer twoColumns={true}>
                    <Title sub>Prompts</Title>
                    <GPTPromptsContainer>
                      {projectData.prompts.map((prompt, i) => {
                        return <GPTPrompt prompt={prompt} key={i} />;
                      })}
                    </GPTPromptsContainer>
                  </InformationContainer>
                )}
              </>
            ))}
          <InformationContainer twoColumns={true}>
            <Title sub>
              Generate {projectData && projectData.hasData && 'new'} text by
              candidate tests
            </Title>
            <Title minor>Project Critical Success Factors</Title>
            <StyledTextarea
              name='criticalSuccessFactors'
              value={criticalSuccessFactors}
              onChange={(event) =>
                setCriticalSuccessFactors(event.target.value)
              }
            />
            <ButtonContainer isDisabled={isLoading}>
              <Button
                text='Generate'
                primary={!isLoading}
                secondary={isLoading}
                onClick={handleClick}
              />
            </ButtonContainer>
          </InformationContainer>
        </InnerContainer>
      </Container>
    </>
  );
};

const Information = ({ title, dataTitles, data, twoColumns }) => {
  const titles = {
    positive: 'Strength',
    negative: 'Weakness',
  };
  return (
    <InformationContainer twoColumns={twoColumns}>
      <Title sub>
        {title}
        <CopyIcon onClick={() => copy(data, dataTitles || titles)} />
      </Title>
      {data && (
        <DataContainer>
          <Title minor>
            {(dataTitles && dataTitles.positive) || 'Strength'}:
          </Title>
          <TextContiner level='Strong'>
            <Text onClick={() => copy(data.positive)} copy>
              <CopyIcon />
              {data.positive}
            </Text>
          </TextContiner>
          <Title minor>
            {(dataTitles && dataTitles.negative) || 'Weakness'}:
          </Title>
          <TextContiner level='Weak'>
            <Text onClick={() => copy(data.negative)} copy>
              <CopyIcon />
              {data.negative}
            </Text>
          </TextContiner>
        </DataContainer>
      )}
    </InformationContainer>
  );
};

/**
 * Renders the critical success factor element.
 *
 * Input data should be an object with specific data:
 * {array} success_factor.
 * {string} conclusion
 *
 * However there is fallback to data possibly being a simple string as well.
 *
 * @param {string} title
 * @param {object} data
 */
const CriticalSuccessFactors = ({ title, data }) => {
  const successFactors = data.successFactors;
  const conclusion = data.conclusion;
  return (
    <InformationContainer twoColumns={true}>
      <Title sub>
        {title}
        <CopyIcon onClick={() => copy(data)}></CopyIcon>
      </Title>
      <div>
        {(Array.isArray(successFactors) &&
          successFactors.map((successFactor, index) => {
            return (
              <div key={index}>
                <Title minor>
                  {successFactor.factor}
                  {successFactor.evaluationResult && (
                    <span> ({successFactor.evaluationResult})</span>
                  )}
                </Title>
                {successFactor.evaluationResult && (
                  <TextContiner level={successFactor.evaluationResult}>
                    <Text
                      copy
                      onClick={() => copy(successFactor.evaluationText)}
                    >
                      <CopyIcon />
                      {successFactor.evaluationText}
                    </Text>
                  </TextContiner>
                )}

                {!successFactor.evaluationResult && (
                  /** If data is returned without any evaluation result */
                  <Text>{successFactor.evaluationText}</Text>
                )}
              </div>
            );
          })) || <Text>{data}</Text>}
        {conclusion && (
          <>
            <Title successfactor>
              Conclusion
              <CopyIcon onClick={() => copy(conclusion)} />
            </Title>
            <Text>{conclusion}</Text>
          </>
        )}
      </div>
    </InformationContainer>
  );
};

const Spinner = ({ text }) => {
  return (
    <SpinnerContainer>
      <Title sub>{text}</Title>
      <svg className='spinner' viewBox='0 0 50 50'>
        <circle
          className='path'
          cx='25'
          cy='25'
          r='20'
          fill='none'
          strokeWidth='6'
        />
      </svg>
    </SpinnerContainer>
  );
};

const GPTMessage = ({ role, content }) => {
  return (
    <GPTMessageContainer>
      <Text>
        <b>{role}</b>
      </Text>
      <Text>{content}</Text>
    </GPTMessageContainer>
  );
};

const GPTPrompt = ({ prompt }) => {
  return (
    <GPTPromptContainer>
      {prompt.messages.map((message, i) => {
        return (
          <GPTMessage key={i} role={message.role} content={message.content} />
        );
      })}
    </GPTPromptContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  border-radius: 0.4rem;
  padding: 0rem 10rem;
  margin-top: 5rem;
  align-items: center;
  max-width: 1360px;
  margin: auto;
`;

const OpenAiHeader = styled.div`
  display: flex;
  grid-column: span 2;
  flex-flow: row nowrap;
  width: 100%;
  gap: 2rem;
  margin-left: 1rem;
  padding-right: 2rem;
  align-content: end;
`;

const OpenAiHeaderColumn = styled.div`
  display: flex;
  height: fit-content;
  width: 100%;
  justify-content: end;
  align-self: flex-end;
`;

const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  width: 100%;
  padding: 5rem 6rem;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 2rem;
  ${(props) => props.twoColumns && 'grid-column: span 2;'}
  border: 1px solid #ddd;
  padding: 2rem;
  margin: 1rem;
  border-radius: 0.75rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const TextContiner = styled.div`
  ${(props) => props.level === 'Weak' && 'background-color: mistyrose;'}
  ${(props) => props.level === 'Strong' && 'background-color: #dcffdc;'}
  ${(props) => props.level === 'Neutral' && 'background-color: lemonchiffon;'}

`;

const ButtonContainer = styled.div`
  max-width: 15rem;
  width: 100%;
  align-self: center;
  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
    `}
`;

const DataContainer = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  grid-column-gap: 3rem;
  grid-row-gap: 2rem;
  margin-bottom: 1rem;
  align-items: baseline;
`;

const GPTPromptsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 20px;
`;

const GPTPromptContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  row-gap: 15px;
  border: 1px solid #ddd;
  padding: 2rem;
  border-radius: 0.75rem;
`;

const GPTMessageContainer = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1fr;
`;

const Title = styled.h1`
  ${(props) => props.main && 'font-size: 3rem; margin-bottom: 2rem;'}
  ${(props) =>
    props.sub &&
    css`
      font-size: 2.3rem;
      margin-bottom: 1rem;
      font-family: sans-serif;
      color: ${(props) => props.theme.color.white};
      background-color: ${(props) => props.theme.color.mediumBlue};
      padding: 1rem;
      padding-left: 2rem;
    `}
  ${(props) =>
    props.minor &&
    css`
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
      margin-top: 1.3rem;
      font-family: sans-serif;
    `}
  ${(props) => props.err && 'font-size: 2.2rem;'}
  ${(props) =>
    props.successfactor &&
    css`
      font-size: 1.9rem;
      margin-top: 3.5rem;
      margin-bottom: 0.7rem;
      font-family: sans-serif;
      color: ${(props) => props.theme.color.white};
      background-color: ${(props) => props.theme.color.instruction};
      padding: 0.7rem 2rem;
    `}
  font-weight: 700;
`;

const Text = styled.p`
  postion: relative;
  font-size: 1.6rem;
  word-spacing: 0.1rem;
  line-height: 2.2rem;
  padding: 0.5rem 1.2rem;

  ${(props) =>
    props.copy &&
    css`
      &:hover {
        background-color: ghostwhite;
        cursor: pointer;
      }
    `}
`;

const CopyIcon = styled(FaRegCopy)`
  position: relative;
  float: right;
  opacity: 0.1;

  ${Text}:hover & {
    opacity: 1;
  }

  :hover {
    opacity: 1;
    cursor: pointer;
  }
`;

const SpinnerContainer = styled.div`
  display: grid;
  padding: 5rem 6rem;
  justify-items: center;
  grid-column: span 2;
`;

const ErrorMessage = styled.div`
  background: #f8c1bf;
  color: #c62c2c;
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.75rem;
  text-align: center;
  grid-column: span 2;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 30rem;
  resize: none;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 0.75rem;
  background-color: #f8f8f8;
  font-size: 1.6rem;
`;

const SwitchButton = styled.button`
  border-radius: 1.65rem;
  margin-right: 10px;
  border: 2px solid ${(props) => props.theme.color.orange};
  background-color: ${(props) => props.theme.color.white};
  color: ${(props) => props.theme.color.orange};
  cursor: pointer;
  outline: none;
  transition: background-color 0.5s ease;
  justify-self: flex-end;
  grid-column: span 2;

  &:hover {
    background-color: ${(props) => props.theme.color.orange};
    color: ${(props) => props.theme.color.white};
  }
`;
