import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { SetQuestion } from '../../../components/admin/questions/edit-question/SetQuestion';
import { SetOptions } from '../../../components/admin/questions/edit-question/SetOptions';
import { device } from '../../../styles/device';

export const EditQuestion = (props) => {
  const paramsData = useParams();
  const [method] = useState('get');
  const [params] = useState({ useSlug: true });
  const [page, setPage] = useState(1);
  const [question, setQuestion] = useState(null);
  const slug =
    (paramsData && paramsData.slug) ||
    (props.location && props.location.state && props.location.state.slug);
  const [endpoint] = useState(`/api/omega/questions/slug/${slug}`);
  const [payload] = useState({ endpoint, method, params });
  const { data, isLoading } = useFetch(payload);

  useEffect(() => {
    if (data && data.data && data.data.length) {
      setQuestion(data.data[0]);
    }
  }, [data]);

  const renderContent = () => {
    switch (page) {
      case 1:
        return (
          <SetQuestion
            question={question}
            setPage={setPage}
            setQuestion={setQuestion}
          />
        );
      case 2:
        return (
          <SetOptions
            question={question}
            setPage={setPage}
            setQuestion={setQuestion}
          />
        );
      default:
        return <SetQuestion question={question} setPage={setPage} />;
    }
  };

  return (
    <Container>
      <InnerContainer>
        {question && renderContent()}
        {!isLoading && !question && <div>No question, redirect?</div>}
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  background: ${(props) => props.theme.color.bgGrey};
  min-height: calc(100vh - 224px);
  display: flex;
  justify-content: center;
  @media ${device.laptop} {
    padding: 0 ${(props) => props.theme.spacing.extraLarge};
  }
  @media ${device.mobileL} {
    padding: 0 ${(props) => props.theme.spacing.small};
  }
`;

const InnerContainer = styled.div`
  width: 804px;
  padding-top: ${(props) => props.theme.spacing.standard};
`;
