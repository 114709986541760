import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { device } from '../../../styles/device';
import { GeneralTabContent } from '../../../components/admin/languages/GeneralTabContent';
import { EmailTabContent } from '../../../components/admin/languages/EmailTabContent';
import { useParams } from 'react-router-dom';
import { DeleteLanguage } from '../../../components/admin/languages/DeleteLanguage';

export const SingleLanguage = () => {
  const [errorText, setErrorText] = useState('');
  const [payload, setPayload] = useState({});
  const [currentId, setCurrentId] = useState(0);
  const [active, setActive] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [language, setLanguage] = useState({});

  const { slug } = useParams();

  const endpoint = `/api/omega/languages/slug/${slug}`;
  const method = 'get';

  useEffect(() => {
    setPayload({ endpoint, method });
  }, [endpoint, method]);

  const { data, hasError } = useFetch(payload);

  useEffect(() => {
    if (data) {
      setLanguage(data.data[0]);
    }
    if (hasError) {
      setErrorText('An error occurred.');
    }
  }, [data, hasError]);

  const tabsArray = ['General copy', 'Email'];

  const renderTabs = () => {
    return tabsArray.map((tab, index) => (
      <Tab
        active={active === index}
        key={index}
        // eslint-disable-next-line
        onClick={() => (setCurrentId(index), setActive(index ? index : 0))}
      >
        {tab}
      </Tab>
    ));
  };

  const renderDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const renderContent = () => {
    switch (currentId) {
      case 0:
        return (
          <GeneralTabContent
            onDeleteLanguageOnClick={() => renderDeleteModal()}
            language={data.data[0]}
          />
        );
      case 1:
        return (
          <EmailTabContent language={language} setLanguage={setLanguage} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {data && data.data && (
        <Container>
          <Info>
            <FlagContainer>
              <Flag src={data.data[0].flag} />
            </FlagContainer>
            <TextContainer>
              <Language>{data.data[0].language}</Language>
              <Code>{data.data[0].code}</Code>
            </TextContainer>
          </Info>
          <TabsContainer>{renderTabs()}</TabsContainer>
          <ContentContainer>{renderContent()}</ContentContainer>
          <ErrorMessage>{errorText}</ErrorMessage>
          <DeleteLanguage
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            _id={data.data[0]._id}
          />
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  margin-bottom: 5rem;
`;

const ErrorMessage = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  height: 2rem;
  margin-bottom: ${(props) => props.theme.spacing.small};
  color: ${(props) => props.theme.color.error};
`;

const TabsContainer = styled.ul`
  font-size: 4rem;
  display: flex;
  flex-direction: row;
  margin-left: 7rem;
  @media ${device.mobileL} {
    margin-left: ${(props) => props.theme.spacing.extraSmall};
  }
`;

const Tab = styled.li`
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0 100%);
  height: 4.5rem;
  min-width: 15rem;
  font-size: 1.6rem;
  padding: ${(props) => props.theme.spacing.standard};
  padding-right: ${(props) => props.theme.spacing.large};
  @media ${device.mobileL} {
    clip-path: none;
    height: 4rem;
    min-width: 4rem;
    font-size: 1.4rem;
    padding-top: ${(props) => props.theme.spacing.extraSmall};
    padding-bottom: ${(props) => props.theme.spacing.extraSmall};
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
  border-radius: 9px 9px 0px 0px;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  color: ${(props) => props.theme.color.textGrey};
  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.color.white};
      color: ${(props) => props.theme.color.black};
    `}
`;

const ContentContainer = styled.div`
  background: ${(props) => props.theme.color.white};
  height: 100%;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem 0 4.5rem 0;
`;

const FlagContainer = styled.div`
  height: 11rem;
  width: 11rem;
  border-radius: 50%;
  background: ${(props) => props.theme.color.white};
  margin-left: 7rem;
  margin-right: 6rem;
  overflow: hidden;
  @media ${device.mobileL} {
    margin-left: ${(props) => props.theme.spacing.extraSmall};
    margin-right: ${(props) => props.theme.spacing.standard};
  }
`;

const Flag = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const TextContainer = styled.div`
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`;

const Language = styled.p`
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.3rem;
  margin-bottom: 1.3rem;
`;

const Code = styled.p`
  font-size: 1.6rem;
  line-height: 1.9rem;
`;
