import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Chevron, DeleteQuestion, CloneQuestion } from './';
import { device } from '../../../../styles/device';
import { mic, camera } from '../../../shared/assets/icons';
import { Type } from './Type';
import { DropDownMenu } from '../../../shared/drop-downs/DropDownMenu';
import { SingleOption } from './SingleOption';
import history from '../../../../history';
import moment from 'moment';

export const Accordion = ({ data, isCrud }) => {
  const [setActive, setActiveState] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);

  const [languageState, setLanguageState] = useState(
    (data.availableLanguages &&
      data.availableLanguages[0] &&
      data.availableLanguages[0].code) ||
      null
  );

  function toggleAccordion(e) {
    if (e.target.id) {
      return;
    } else {
      return setActiveState(!setActive);
    }
  }

  const dropDownOptions = [
    {
      title: 'Edit',
      onClick: () => {
        history.push(`/admin/questions/edit-question/${data.slug}`, {
          slug: data.slug,
        });
      },
    },
    {
      title: 'Clone',
      onClick: () => {
        setShowCloneModal(true);
      },
    },
    {
      title: 'Preview',
      onClick: () => {},
    },
    {
      title: 'Delete',
      delete: true,
      onClick: () => {
        setShowDeleteModal(true);
      },
    },
  ];
  return (
    <>
      <Container key={data.id} setActive={setActive}>
        <Content onClick={(e) => toggleAccordion(e)} setActive={setActive}>
          <Section hideInMobile internalTitle>
            {data.title}
          </Section>
          <Section languages>
            {/* // TODO: klickar man på ett språk ska det inte stängas */}
            {data.availableLanguages.map((language, key) => (
              <Code
                id={language.code}
                key={key}
                onClick={(e) => setLanguageState(e.target.id)}
                active={true}
                selected={languageState === language.code}
              >
                {language.code}
              </Code>
            ))}
          </Section>
          <Section hideInMobile category>
            {data.category && data.category.title && (
              <Cat>{data.category.title}</Cat>
            )}
          </Section>
          <Section hideInMobile img>
            {data.soundFileExercise ? <Icon src={mic} /> : ''}
            {data.type.image ? <Icon src={camera} /> : ''}
          </Section>
          <SectionType hideInMobile>
            {data.isExercise ? <Type exercise /> : ''}
            {data.isInstruction ? <Type instruction /> : ''}
          </SectionType>
          {/* FlexContener is ONLY visible in mobile */}
          <FlexContainer>
            <Section showInMobile internalTitle>
              {data.title}
            </Section>
            <Section showInMobile categories img>
              {data.category && data.category.title && (
                <Cat>{data.category.title}</Cat>
              )}
              <IconContainer>
                {data.isExercise ? <Type exercise /> : ''}
                {data.isInstruction ? <Type instruction /> : ''}
                {data.soundFileExercise ? <Icon src={mic} /> : ''}
                {data.type.image ? <Icon src={camera} /> : ''}
              </IconContainer>
            </Section>
          </FlexContainer>
          {/* FlexContener is ONLY visible in mobile */}

          <ChevContainer>
            {isCrud && (
              <>
                <DropDownBox>
                  <DropDownMenu
                    dontRender={!setActive}
                    dropDownOptions={dropDownOptions}
                  />
                </DropDownBox>

                <DropDownBoxDesktop setActive={setActive}>
                  <DropDownMenu dropDownOptions={dropDownOptions} />
                </DropDownBoxDesktop>
              </>
            )}

            <Chevron isRotated={setActive} />
          </ChevContainer>
        </Content>
        <HiddenContent setActive={setActive}>
          <HiddenInner>
            {data.question && languageState && data.question[languageState] && (
              <Row>
                <QuestionBox>
                  <Text headline>Question</Text>

                  <Question>{data.question[languageState].text}</Question>
                </QuestionBox>
              </Row>
            )}
            <Row>
              <LanguagesBox>
                <Text headline>Available languages</Text>
                {data.availableLanguages.map((language, key) => {
                  return (
                    <Code
                      id={language.code}
                      mobile
                      selected={languageState === language.code}
                      key={key}
                      onClick={(e) => setLanguageState(e.target.id)}
                    >
                      {language.code}
                    </Code>
                  );
                })}
              </LanguagesBox>
              <OptionsBox>
                <Text headline>Options</Text>
                {data.options &&
                  languageState &&
                  data.options[languageState] &&
                  data.options[languageState].map((option, i) => {
                    return (
                      <SingleOption
                        key={option._id}
                        altCount={`${i + 1}`}
                        optionText={option.text}
                        correctAnswer={option.isCorrect}
                      />
                    );
                  })}
              </OptionsBox>
              <CreatedBox>
                <Text headline>Created</Text>
                <Text>{moment(data.created).format('YYYY-MM-DD')}</Text>
              </CreatedBox>
            </Row>
          </HiddenInner>
        </HiddenContent>
      </Container>
      <CloneQuestion
        showModal={showCloneModal}
        setShowModal={setShowCloneModal}
        question={data}
      />
      <DeleteQuestion
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        question={data}
      />
    </>
  );
};

const Container = styled.div`
  box-shadow: 0px -1px 0px #d6d6d6, 0px 1px 0px #d6d6d6;
  background-color: ${(props) => (props.setActive ? '#f7f7f7' : 'white')};
`;
const FlexContainer = styled.div`
  display: none;
  flex-direction: column;
  width: 100%;
  padding-right: ${(props) => props.theme.spacing.medium};
  @media ${device.tablet} {
    display: flex;
  }
`;
const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-left: 1.2rem;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 32% 16% 24% 10% 18%;
  min-height: 7rem;
  align-items: center;
  cursor: pointer;
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  @media ${device.tablet} {
    grid-template-columns: 100%;
    display: flex;
    min-height: 8rem;
  }
  @media ${device.mobileL} {
    padding: ${(props) => props.theme.spacing.small};
  }
  position: relative;
`;

const Code = styled.a`
  cursor: pointer;
  text-decoration: underline;
  &:after {
    content: ',';
    padding-right: 0.5rem;
  }
  &:last-child:after {
    content: '';
    padding-right: 0;
  }
  &:active {
    font-weight: bold;
  }
  ${(props) =>
    props.selected &&
    css`
      font-weight: bold;
    `}
`;

const Section = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; 
  margin-right: ${(props) => props.theme.spacing.standard};
  &:last-child {
    margin-right: 0;
  }

  ${(props) =>
    props.languages &&
    css`
      @media ${device.laptop} {
        display: none;
      }
    `}
  ${(props) =>
    props.category &&
    css`
      @media ${device.tablet} {
        padding-top: 0.5rem;
      }
    `}
    ${(props) =>
      props.internalTitle &&
      css`
        @media ${device.tablet} {
          font-weight: 500;
          padding-bottom: -${(props) => props.theme.spacing.extraSmall};
        }
      `}
    ${(props) =>
      props.img &&
      css`
        min-width: 5.5rem;
      `}
      ${(props) =>
        props.showInMobile &&
        css`
          display: none;

          @media ${device.tablet} {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        `}
      ${(props) =>
        props.hideInMobile &&
        css`
          @media ${device.tablet} {
            display: none;
          }
        `}
 
`;
const SectionType = styled.div`
  ${(props) =>
    props.hideInMobile &&
    css`
      @media ${device.tablet} {
        display: none;
      }
    `}
`;

const Cat = styled.p``;

const Icon = styled.img`
  margin-left: 1.2rem;
`;

const ChevContainer = styled.div`
  position: absolute;
  right: ${(props) => props.theme.spacing.extraLarge};
  display: flex;
  flex-direction: row;
  @media ${device.mobileL} {
    right: ${(props) => props.theme.spacing.standard};
  }
`;

//TODO: fix nice transition when accordion opens
const HiddenContent = styled.div`
  opacity: ${(props) => (props.setActive ? '100%' : '0%')};
  display: ${(props) => (props.setActive ? 'flex' : 'none')};
  padding-bottom: ${(props) => props.theme.spacing.large};
  padding-left: ${(props) => props.theme.spacing.extraLarge};
  padding-right: ${(props) => props.theme.spacing.extraLarge};
  width: 100%;
  position: relative;
  @media ${device.tablet} {
    padding-bottom: ${(props) => props.theme.spacing.standard};
  }
  @media ${device.mobileL} {
    padding-left: ${(props) => props.theme.spacing.small};
    padding-right: ${(props) => props.theme.spacing.small};
  }
`;

const HiddenInner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${(props) => props.theme.spacing.large};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const OptionsBox = styled.div`
  width: 40%;
  @media ${device.tablet} {
    width: 100%;
  }
`;

const QuestionBox = styled.div`
  width: 100%;
  @media ${device.tablet} {
    width: 100%;
  }
  margin-bottom: ${(props) => props.theme.spacing.standard};
`;
const Question = styled.div``;

const CreatedBox = styled.div`
  width: 28%;
  margin-left: 32%;
  @media ${device.tablet} {
    padding-top: ${(props) => props.theme.spacing.extraLarge};
    width: 100%;
  }
  @media ${device.laptop} {
    margin-left: 0%;
  }
`;

const LanguagesBox = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
    width: 32%;
    padding-right: ${(props) => props.theme.spacing.medium};
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
  @media ${device.tablet} {
    padding-bottom: ${(props) => props.theme.spacing.extraLarge};
    width: 100%;
  }
`;

const DropDownBox = styled.div`
  display: none;
  margin-right: ${(props) => props.theme.spacing.medium};
  @media only screen and (max-width: 1000px) {
    display: block;
    justify-content: flex-end;
    margin-left: auto;
  }
`;

const DropDownBoxDesktop = styled.div`
  display: none;
  ${(props) =>
    props.setActive &&
    css`
      display: block;
      padding-right: ${(props) => props.theme.spacing.standard};
      @media only screen and (max-width: 1000px) {
        display: none;
      }
      @media ${device.tablet} {
        display: none;
      }
    `}
`;

const Text = styled.p`
  font-size: ${(props) => props.theme.size.medium};
  line-height: 1.6rem;
  ${(props) =>
    props.headline &&
    css`
      padding-bottom: ${(props) => props.theme.spacing.extraSmall};
      font-weight: 600;
      font-style: italic;
    `}
`;
