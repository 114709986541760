import React from 'react';
import { Field } from 'formik';
import { TextareaField } from '../../../shared/input/Textarea';
import styled from 'styled-components';
import { FormLabel } from '../../../shared/input/FormLabel';
import { InputField } from '../../../shared/input/InputField';

export const Languages = ({
  availableLanguages,
  onChange,
  onBlur,
  values,
  errors,
  touched,
  question,
  exerciseQuestion,
  omegaQuestion,
  option,
  optionIx,
  addOptionIx,
  editOption,
  type,
  id,
  instruction,
  addQuestion,
  addQuestions,
  setAddQuestions,
  endText,
  facet,
  reportSectionTitle,
  scatterplotHigh,
  scatterplotLow,
  sectionIndex,
  rowIndex,
}) => {
  const handleAddquestion = (event, quest) => {
    event.preventDefault();
    if (quest >= 0) {
      if (values.addQuestion) {
        availableLanguages.forEach((lang) => {
          values.addQuestion[lang.code].questions.splice(quest, 1);
        });

        const tempAdd = [...addQuestions];
        tempAdd.splice(quest, 1);

        if (tempAdd.length) setAddQuestions(tempAdd);
        else setAddQuestions([0]);
      }
    } else {
      if (!values.addQuestion) {
        values.addQuestion = {};
        availableLanguages.forEach((lang) => {
          values.addQuestion[lang.code] = {
            questions: [],
          };
        });
      }

      setAddQuestions([...addQuestions, addQuestions.length]);
    }
  };

  const renderFactionPart = (lang, part, label) => {
    const shortId = `descriptions.${lang.code}.${part}.shortDescription`;
    const longId = `descriptions.${lang.code}.${part}.longDescription`;

    return (
      <>
        <FormLabel label={label} />
        <Field
          as={TextareaField}
          id={shortId}
          onChange={onChange}
          onBlur={onBlur}
          value={
            (values &&
              values.descriptions &&
              values.descriptions[lang.code] &&
              values.descriptions[lang.code][part] &&
              values.descriptions[lang.code][part].shortDescription) ||
            ''
          }
          name={id}
          placeholder={`Short description (${lang.code})`}
          textarea
          rows='2'
          error={errors[id] && touched[id]}
          flag={lang.flag}
        />
        {errors && errors.descriptions && errors.descriptions[lang.code] && (
          <SmallErrorMessage>
            {errors.descriptions[lang.code][part].shortDescription}
          </SmallErrorMessage>
        )}
        <Field
          as={TextareaField}
          id={longId}
          onChange={onChange}
          onBlur={onBlur}
          value={
            (values &&
              values.descriptions &&
              values.descriptions[lang.code] &&
              values.descriptions[lang.code][part] &&
              values.descriptions[lang.code][part].longDescription) ||
            ''
          }
          name={id}
          placeholder={`Long description (${lang.code})`}
          textarea
          rows='2'
          error={errors[id] && touched[id]}
          flag={lang.flag}
        />
        {errors && errors.descriptions && errors.descriptions[lang.code] && (
          <SmallErrorMessage>
            {errors.descriptions[lang.code][part].longDescription}
          </SmallErrorMessage>
        )}
      </>
    );
  };

  if (question) {
    return (
      <>
        {availableLanguages.map((lang, index) => {
          const id = `question.${lang.code}.text`;
          return (
            <React.Fragment key={index}>
              <Field
                as={TextareaField}
                id={id}
                onChange={onChange}
                onBlur={onBlur}
                value={
                  (values &&
                    values.question &&
                    values.question[lang.code] &&
                    values.question[lang.code].text) ||
                  ''
                }
                name={id}
                placeholder={`Question (${lang.code})`}
                textarea
                rows='2'
                error={errors[id] && touched[id]}
                flag={lang.flag}
              />
              {errors && errors.question && errors.question[lang.code] && (
                <SmallErrorMessage>
                  {errors.question[lang.code].text}
                </SmallErrorMessage>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  } else if (facet) {
    return (
      <>
        {availableLanguages.map((lang, index) => {
          return (
            <React.Fragment key={index}>
              {renderFactionPart(lang, 'lowValue', 'Low value')}
              {renderFactionPart(lang, 'highValue', 'High value')}
            </React.Fragment>
          );
        })}
      </>
    );
  } else if (reportSectionTitle) {
    return (
      <>
        {availableLanguages.map((lang, index) => {
          return (
            <React.Fragment key={index}>
              <Field
                as={InputField}
                onChange={({ target }) =>
                  onChange(target, sectionIndex, lang, 'title')
                }
                onBlur={onBlur}
                value={
                  (values &&
                    values.title &&
                    values.title[lang.code] &&
                    values.title[lang.code]) ||
                  ''
                }
                name={index}
                placeholder={`Section title (${lang.code})`}
                text
                rows='1'
                flag={lang.flag}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  } else if (scatterplotHigh || scatterplotLow) {
    return (
      <>
        {availableLanguages.map((lang, index) => {
          return (
            <React.Fragment key={index}>
              <Field
                as={InputField}
                onChange={({ target }) =>
                  onChange(
                    target,
                    sectionIndex,
                    lang,
                    (scatterplotLow && 'left') || 'right',
                    rowIndex
                  )
                }
                onBlur={onBlur}
                value={
                  (scatterplotLow &&
                    values &&
                    values.lowValue &&
                    values.lowValue[lang.code] &&
                    values.lowValue[lang.code]) ||
                  (scatterplotHigh &&
                    values &&
                    values.highValue &&
                    values.highValue[lang.code] &&
                    values.highValue[lang.code]) ||
                  ''
                }
                name={index}
                placeholder={`${(scatterplotLow && 'Low') || 'High'} value (${
                  lang.code
                })`}
                text
                rows='1'
                flag={lang.flag}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  } else if (instruction) {
    return (
      <>
        {availableLanguages.map((lang, index) => {
          const id = `instruction.${lang.code}.${endText ? 'endText' : 'text'}`;
          return (
            <React.Fragment key={index}>
              {errors &&
                errors.instruction &&
                touched.instruction &&
                errors.instruction[lang.code] &&
                touched.instruction[lang.code] && (
                  <InstructionError>
                    {endText
                      ? errors.instruction[lang.code].endText
                      : errors.instruction[lang.code].text}
                  </InstructionError>
                )}
              <Field
                as={TextareaField}
                id={id}
                onChange={onChange}
                onBlur={onBlur}
                value={
                  endText
                    ? values &&
                      values.instruction &&
                      values.instruction[lang.code] &&
                      values.instruction[lang.code].endText
                    : values &&
                      values.instruction &&
                      values.instruction[lang.code]
                    ? values.instruction[lang.code].text
                    : ''
                }
                name={id}
                placeholder={`Instruction ${endText ? 'end text' : 'text'} (${
                  lang.language
                })`}
                rows='5'
                error={errors[id] && touched[id]}
                flag={lang.flag}
                message={
                  errors &&
                  errors.instruction &&
                  errors.instruction[lang.code] &&
                  (endText
                    ? errors.instruction[lang.code].endText
                    : errors.instruction[lang.code].text)
                }
              />
            </React.Fragment>
          );
        })}
      </>
    );
  } else if (option) {
    return (
      <>
        {availableLanguages.map((lang, index) => {
          return (
            <React.Fragment key={index}>
              <Field
                as={TextareaField}
                id={`${id}.${lang.code}`}
                onChange={(e) => {
                  editOption({
                    e,
                    lang: lang.code,
                    index: optionIx,
                    index2: addOptionIx,
                  });
                }}
                onBlur={onBlur}
                value={
                  (values &&
                  values[lang.code] &&
                  values[lang.code][optionIx] &&
                  values[lang.code][optionIx].addOptions &&
                  values[lang.code][optionIx].addOptions[addOptionIx]
                    ? values[lang.code][optionIx].addOptions[addOptionIx].text
                    : values[lang.code][optionIx].text) || ''
                }
                name={`question.${lang.code}.text`}
                placeholder={
                  type === 'radio' || type === 'checkbox'
                    ? `Option (${lang.code}) leave empty for no. ${optionIx +
                        1}`
                    : `Option (${lang.code})`
                }
                textarea
                rows='2'
                error={
                  errors.options &&
                  errors.options[lang.code] &&
                  errors.options[lang.code][optionIx] &&
                  errors.options[lang.code][optionIx].text
                }
                flag={lang.flag}
              />
              {errors.options &&
                errors.options[lang.code] &&
                errors.options[lang.code][optionIx] && (
                  <SmallErrorMessage>
                    {errors.options[lang.code][optionIx].text}
                  </SmallErrorMessage>
                )}
            </React.Fragment>
          );
        })}
      </>
    );
  } else if (omegaQuestion) {
    return (
      <>
        {availableLanguages.map((lang, index) => {
          return [1, 2, 3].map((questionNumber, index2) => {
            const id = `omegaQuestions.${lang.code}.text${questionNumber}`;
            return (
              <React.Fragment key={index + index2}>
                <Field
                  as={TextareaField}
                  id={id}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={
                    (values &&
                      values.omegaQuestions &&
                      values.omegaQuestions[lang.code] &&
                      values.omegaQuestions[lang.code][
                        `text${questionNumber}`
                      ]) ||
                    ''
                  }
                  name={id}
                  placeholder={`Question ${questionNumber} (${lang.code})`}
                  textarea
                  rows='2'
                  error={errors[id] && touched[id]}
                  flag={lang.flag}
                />
                {errors &&
                  errors.omegaQuestions &&
                  errors.omegaQuestions[lang.code] &&
                  errors.omegaQuestions[lang.code][`text${questionNumber}`] && (
                    <SmallErrorMessage>
                      {errors.question[lang.code][`text${questionNumber}`]}
                    </SmallErrorMessage>
                  )}
              </React.Fragment>
            );
          });
        })}
      </>
    );
  } else if (addQuestion) {
    return (
      <>
        <button
          value='+'
          style={{ marginBottom: '2rem' }}
          onClick={handleAddquestion}
        >
          +
        </button>
        {availableLanguages.map((lang) => {
          return (
            <React.Fragment key={`add ${lang.code}`}>
              <Field
                key={'title'}
                as={TextareaField}
                id={`addQuestion.${lang.code}.title`}
                onChange={onChange}
                onBlur={onBlur}
                value={
                  (values &&
                    values.addQuestion &&
                    values.addQuestion[lang.code] &&
                    values.addQuestion[lang.code].title) ||
                  ''
                }
                name={`addQuestion.${lang.code}.title`}
                placeholder={`Title (optional) (${lang.code})`}
                textarea
                rows='2'
                error={
                  errors[`addQuestion.${lang.code}.title`] &&
                  touched[`addQuestion.${lang.code}.title`]
                }
                flag={lang.flag}
              />
              {errors && errors.question && errors.question[lang.code] && (
                <SmallErrorMessage>
                  {errors.question[lang.code].text}
                </SmallErrorMessage>
              )}
              {addQuestions.map((questionNumber, index2) => {
                const id = `addQuestion.${lang.code}.questions.${index2}`;
                return (
                  <React.Fragment key={index2}>
                    <Field
                      as={TextareaField}
                      id={id}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={
                        (values &&
                          values.addQuestion &&
                          values.addQuestion[lang.code] &&
                          values.addQuestion[lang.code].questions &&
                          values.addQuestion[lang.code].questions[
                            `${index2}`
                          ]) ||
                        ''
                      }
                      name={id}
                      placeholder={`Question (${lang.code})`}
                      textarea
                      rows='2'
                      error={errors[id] && touched[id]}
                      flag={lang.flag}
                    />
                    {errors &&
                      errors.question &&
                      errors.question[lang.code] && (
                        <SmallErrorMessage>
                          {errors.question[lang.code].text}
                        </SmallErrorMessage>
                      )}
                    <button
                      style={{ marginBottom: '3rem' }}
                      onClick={(e) => {
                        handleAddquestion(e, index2);
                      }}
                    >
                      -
                    </button>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}
      </>
    );
  }
  if (exerciseQuestion) {
    return (
      <>
        {availableLanguages.map((lang, index) => {
          const id = `exerciseText.${lang.code}.text`;
          //values.exerciseText[lang.code] = { };
          return (
            <React.Fragment key={index}>
              <Field
                as={TextareaField}
                id={id}
                onChange={onChange}
                onBlur={onBlur}
                value={
                  (values &&
                    values.exerciseText &&
                    values.exerciseText[lang.code] &&
                    values.exerciseText[lang.code].text) ||
                  ''
                }
                name={id}
                placeholder={`Question (${lang.code})`}
                textarea
                rows='2'
                error={errors[id] && touched[id]}
                flag={lang.flag}
              />
              {errors && errors.question && errors.question[lang.code] && (
                <SmallErrorMessage>
                  {errors.question[lang.code].text}
                </SmallErrorMessage>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  }
};

const SmallErrorMessage = styled.span`
  color: ${(props) => props.theme.color.error};
`;
const InstructionError = styled.p`
  color: ${(props) => props.theme.color.error};
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
`;
