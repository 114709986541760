import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NoResultsFound } from '../../../components/shared/error-page-components/NoResultsFound';
import { Button } from '../../../components/shared/button/Button';
import { SearchBar } from '../../../components/shared/search-bar/SearchBar';
import { NewProject } from '../../../components/admin/reports/projects/NewProject';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { StandardTable } from '../../../components/shared/standard-table/StandardTable';
import { Tabs } from '../../../components/admin/reports/projects/Tabs';
import { ArchiveModal } from '../../../components/admin/settings/ArchiveModal';
import { userHasAccess } from '../../../helpers/permissions';

//set as environment variable later
const itemsPerPage = 25;

export const ViewAllProjects = ({ history }) => {
  const [payload, setPayload] = useState({});
  const [endpoint, setEndpoint] = useState('');
  const method = 'get';
  const [projects, setProjects] = useState([]);
  const [moreObjectsAvailable, setMoreObjectsAvailable] = useState(false);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [currentObjectId, setCurrentObjectId] = useState('');
  const [archiveEndpoint, setArchiveEndpoint] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [type, setType] = useState('');
  const [tabsObject, setTabsObject] = useState({});
  const [params, setParams] = useState({
    searchString: '',
    sortPropName: '',
    sortHeading: null,
    skip: 0,
    limit: itemsPerPage,
    isProject: true,
  });

  const { data, isLoading } = useFetch(payload);

  useEffect(() => {
    let tabs = {};

    if (userHasAccess('PROJECTS'))
      tabs = { projects: 'Projects', groups: 'Groups' };

    if (userHasAccess('REPORTS') === 'crud')
      tabs = { ...tabs, templates: 'Templates' };

    setTabsObject(tabs);
    setCurrentId(Object.keys(tabs)[0]);
  }, []);

  useEffect(() => {
    setPayload({ endpoint, method, params });
  }, [params, endpoint, method]);

  useEffect(() => {
    setUpdateInProgress(false);
    if (data && data.data) {
      setProjects((projects) =>
        data.data.length
          ? [...projects, ...data.data]
          : projects.length
          ? projects
          : []
      );
    }
  }, [data]);

  useEffect(() => {
    const count = data && data.count;
    const moreQuestionsAvailable = count && params.limit + params.skip < count;
    setMoreObjectsAvailable(moreQuestionsAvailable);
  }, [data, params.skip, params.limit]);

  useEffect(() => {
    if (currentId === 'projects' || currentId === 'groups') {
      setEndpoint('/api/omega/projects');
      setProjects([]);
      setParams((params) => ({
        ...params,
        isProject: currentId === 'projects' ? true : false,
        skip: 0,
        limit: itemsPerPage,
      }));
    }

    if (currentId === 'templates') {
      setEndpoint('/api/omega/report-template');
      setProjects([]);
      setParams((params) => ({
        ...params,
        skip: 0,
        limit: itemsPerPage,
      }));
    }

    setType(
      currentId === 'projects' || currentId === 'groups'
        ? currentId
        : 'title-only'
    );
  }, [currentId]);

  const sortByProp = (propName) => {
    setUpdateInProgress(true);
    if (!propName) return false;
    //clear project array
    setProjects([]);
    const skip = params.sortPropName !== propName ? 0 : params.skip;

    //if third press on same heading, clear the sorting
    if (params.sortHeading === -1 && propName === params.sortPropName) {
      return setParams({ ...params, sortHeading: '', sortPropName: '', skip });
    }

    //if not cleared, heading will be set to 1 on first click and -1 on second click
    const sortHeading =
      propName === params.sortPropName && params.sortHeading === 1 ? -1 : 1;
    const sortPropName = propName;
    return setParams({ ...params, sortHeading, sortPropName, skip });
  };

  const searchEntered = (s) => {
    setUpdateInProgress(true);
    //clear project array
    setProjects([]);
    const skip = 0;
    return setParams({ ...params, searchString: s, skip });
  };

  const openEdit = (id) => {
    const object = projects.find((obj) => obj._id === id);
    const slug = object.slug;

    switch (currentId) {
      case 'projects':
      case 'groups':
        return history.push(`/admin/reports/${type}/${slug}`, { slug });
      case 'templates':
        return history.push(`/admin/reports/edit-template/${slug}`, { slug });
      default:
        return null;
    }
  };

  const openDeleteModal = (id) => {
    setArchiveEndpoint(`${endpoint}/archive/${id}`);
    setShowDeleteModal(true);
    setCurrentObjectId(id);
  };

  const dropDownOptions = [
    {
      title:
        currentId === 'projects' || currentId === 'groups'
          ? 'View participants'
          : 'Edit',
      onClick: openEdit,
    },
    {
      title: 'Delete',
      onClick: openDeleteModal,
      delete: true,
    },
  ];

  const renderContent = () => {
    const title =
      currentId === 'projects'
        ? 'project'
        : currentId === 'groups'
        ? 'group'
        : 'template';

    const showCreate =
      (title !== 'template' && userHasAccess('PROJECTS') === 'crud') ||
      title === 'template';

    return (
      <>
        <ListContainer>
          {projects.length > 0 && (
            <StandardTable
              type={type}
              items={projects}
              onHeaderClick={sortByProp}
              dropDownOptions={dropDownOptions}
            />
          )}
          {!updateInProgress && projects.length === 0 && !isLoading && (
            <NoResultsFound />
          )}
        </ListContainer>

        {showCreate && <NewProject type={type} title={title} />}
        {showDeleteModal && (
          <ArchiveModal
            showModal={showDeleteModal}
            setShowModal={setShowDeleteModal}
            title={title}
            endpoint={archiveEndpoint}
            id={currentObjectId}
            setObjects={setProjects}
          />
        )}
        {(moreObjectsAvailable && (
          <Button onClick={showMore} text='Show more' primary />
        )) ||
          null}
      </>
    );
  };

  const showMore = () => {
    const skip = params.skip + itemsPerPage;
    return setParams({ ...params, skip });
  };

  return (
    <Container>
      <SearchBar
        searchString={params.searchString}
        onChange={searchEntered}
        placeholder={'Search'}
      />
      {currentId && (
        <Tabs
          tabsObject={tabsObject}
          currentId={currentId}
          setCurrentId={setCurrentId}
          renderContent={renderContent}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  flex-direction: column;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 7rem;
`;

const ListContainer = styled.div`
  width: 100%;
  margin-bottom: 7rem;
`;
