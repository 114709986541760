import React from 'react';
import styled from 'styled-components';
import { CircleProgress } from '../../shared/progress-bar/CircleProgress';

//TODO: actual progress percentage
export const Thumbnail = ({ image, name, size, progress, signed }) => {
  if (!name) return '';
  const getInitials = (string) => {
    const names = string.trim().split(' ');
    if (names.length === 1) {
      const name = names[0];
      return name[0] + (name[1] ? name[1] : name[0]);
    } else if (names.length === 2) {
      return names[0][0] + names[1][0];
    } else {
      return names[0][0] + names[names.length - 1][0];
    }
  };
  const initials = getInitials(name);
  if (image) {
    return (
      <ThumbnailFigure size={size}>
        {progress && (
          <CircleProgress percentage={progress} size={size} signed={signed} />
        )}
        <ThumbnailPic src={image} alt={name} size={size} />
      </ThumbnailFigure>
    );
  }

  return (
    <ThumbnailFigure size={size}>
      {progress && (
        <CircleProgress percentage={progress} size={size} signed={signed} />
      )}
      {size === '5.5' && <SmallInitialsSpan>{initials}</SmallInitialsSpan>}
      {size === '8' && <LargeInitialsSpan>{initials}</LargeInitialsSpan>}
      {size === '10' && <LargeInitialsSpan>{initials}</LargeInitialsSpan>}
    </ThumbnailFigure>
  );
};

const ThumbnailPic = styled.img`
  width: ${(props) => (props.size ? `${props.size}rem` : '5.5rem')};
  height: ${(props) => (props.size ? `${props.size}rem` : '5.5rem')};
  object-fit: cover;
`;

const ThumbnailFigure = styled.div`
  width: ${(props) => (props.size ? `${props.size}rem` : '5.5rem')};
  height: ${(props) => (props.size ? `${props.size}rem` : '5.5rem')};
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.color.noImgGrey};
`;

const SmallInitialsSpan = styled.span`
  font-size: 1.8rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.white};
`;
const LargeInitialsSpan = styled.span`
  font-size: 2.5rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.white};
`;
