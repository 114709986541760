import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { InputField } from '../../shared/input/InputField';
import { TextareaField } from '../../shared/input/Textarea';
import { device } from '../../../styles/device';
import { EmailFieldGroup } from './EmailFieldGroup';
import { Button } from '../../shared/button/Button';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useFetch } from '../../../helpers/hooks/fetchData';
import { Modal } from '../../shared/modal/Modal';

export const EmailTabContent = ({ language, setLanguage }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [payload, setPayload] = useState({});
  const { data, hasError } = useFetch(payload);

  useEffect(() => {
    if (data) {
      setModalTitle('Email content successfully saved');
      setShowModal(true);
      setLanguage(data);
    }
  }, [data, setLanguage]);

  useEffect(() => {
    if (hasError) {
      setModalTitle('Error saving language email content');
      setShowModal(true);
    }
  }, [hasError]);

  const validationSchema = yup.object().shape({
    emailContent: yup.object().shape({
      subject: yup.string().required('This is a required field.'),
      title: yup.string().required('This is a required field.'),
      content: yup.string().required('This is a required field.'),
      activationLink: yup.string().required('This is a required field.'),
      finalGreeting: yup.string(),
      optionalInfo1: yup.string(),
      optionalInfo2: yup.string(),
      optionalInfo3: yup.string(),
      optionalInfo4: yup.string(),
      sender: yup
        .string()
        .required('This is a required field.')
        .email('This is not a valid email format.'),
    }),
  });

  return (
    <Container>
      <InnerContainer>
        <Formik
          initialValues={language}
          values={language}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={(values, { resetForm }) => {
            setPayload({
              endpoint: `/api/omega/languages/${language._id}`,
              method: 'put',
              data: values,
            });
            resetForm();
          }}
        >
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <EmailFieldGroup
                  label='Subject'
                  previewText='This is an invitation e-mail.'
                >
                  <InputField
                    placeholder='Subject'
                    name='emailContent.subject'
                    id='emailContent.subject'
                    value={values.emailContent.subject || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text'
                    error={
                      errors.emailContent &&
                      errors.emailContent.subject &&
                      touched.emailContent &&
                      touched.emailContent.subject
                    }
                    message={errors.emailContent && errors.emailContent.subject}
                  />
                </EmailFieldGroup>
                <Space />
                <EmailFieldGroup
                  label='Title'
                  previewText='Hello {{NAME}}, we are considering you for a new position.'
                >
                  <InputField
                    placeholder='Title'
                    name='emailContent.title'
                    id='emailContent.title'
                    value={values.emailContent.title || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text'
                    error={
                      errors.emailContent &&
                      errors.emailContent.title &&
                      touched.emailContent &&
                      touched.emailContent.title
                    }
                    message={errors.emailContent && errors.emailContent.title}
                  />
                </EmailFieldGroup>
                <Space />
                <EmailFieldGroup
                  textarea
                  label='Content'
                  previewText='Globally promote fully tested collaboration and idea-sharing for inexpensive initiatives. Dynamically reinvent customized portals before resource maximizing action items. Progressively redefine process-centric experiences with 24/365 alignments. Professionally build out-of-the-box alignments through high-quality sources. Proactively implement diverse total linkage vis-a-vis enterprise-wide communities.'
                >
                  <TextareaField
                    rows='4'
                    placeholder='Content'
                    name='emailContent.content'
                    id='emailContent.content'
                    value={values.emailContent.content || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text'
                    error={
                      errors.emailContent &&
                      errors.emailContent.content &&
                      touched.emailContent &&
                      touched.emailContent.content
                    }
                    message={errors.emailContent && errors.emailContent.content}
                  />
                </EmailFieldGroup>
                <Space />
                <EmailFieldGroup
                  label='Activation link'
                  previewText='Here is your activation link. Click it to access the test page.'
                >
                  <InputField
                    placeholder='Activation link'
                    name='emailContent.activationLink'
                    id='emailContent.activationLink'
                    value={values.emailContent.activationLink || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text'
                    error={
                      errors.emailContent &&
                      errors.emailContent.activationLink &&
                      touched.emailContent &&
                      touched.emailContent.activationLink
                    }
                    message={
                      errors.emailContent && errors.emailContent.activationLink
                    }
                  />
                </EmailFieldGroup>
                <Space />
                <EmailFieldGroup
                  label='Final greeting'
                  previewText='We are looking forward to hearing from you soon! '
                >
                  <InputField
                    placeholder='Final greeting'
                    name='emailContent.finalGreeting'
                    id='emailContent.finalGreeting'
                    value={values.emailContent.finalGreeting || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text'
                    error={
                      errors.emailContent &&
                      errors.emailContent.finalGreeting &&
                      touched.emailContent &&
                      touched.emailContent.finalGreeting
                    }
                    message={
                      errors.emailContent && errors.emailContent.finalGreeting
                    }
                  />
                </EmailFieldGroup>
                <Space />
                <EmailFieldGroup
                  label='Optional info'
                  previewText='Kind regards'
                >
                  <InputField
                    placeholder='Optional info'
                    name='emailContent.optionalInfo1'
                    id='emailContent.optionalInfo1'
                    value={values.emailContent.optionalInfo1 || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text'
                    error={
                      errors.emailContent &&
                      errors.emailContent.optionalInfo1 &&
                      touched.emailContent &&
                      touched.emailContent.optionalInfo1
                    }
                    message={
                      errors.emailContent && errors.emailContent.optionalInfo1
                    }
                  />
                </EmailFieldGroup>

                <EmailFieldGroup previewText='Astrid Karlsson'>
                  <InputField
                    placeholder='Optional info'
                    name='emailContent.optionalInfo2'
                    id='emailContent.optionalInfo2'
                    value={values.emailContent.optionalInfo2 || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text'
                    error={
                      errors.emailContent &&
                      errors.emailContent.optionalInfo2 &&
                      touched.emailContent &&
                      touched.emailContent.optionalInfo2
                    }
                    message={
                      errors.emailContent && errors.emailContent.optionalInfo2
                    }
                  />
                </EmailFieldGroup>

                <EmailFieldGroup previewText='Navigio'>
                  <InputField
                    placeholder='Optional info'
                    name='emailContent.optionalInfo3'
                    id='emailContent.optionalInfo3'
                    value={values.emailContent.optionalInfo3 || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text'
                    error={
                      errors.emailContent &&
                      errors.emailContent.optionalInfo3 &&
                      touched.emailContent &&
                      touched.emailContent.optionalInfo3
                    }
                    message={
                      errors.emailContent && errors.emailContent.optionalInfo3
                    }
                  />
                </EmailFieldGroup>

                <EmailFieldGroup previewText='0706789849'>
                  <InputField
                    placeholder='Optional info'
                    name='emailContent.optionalInfo4'
                    id='emailContent.optionalInfo4'
                    value={values.emailContent.optionalInfo4 || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text'
                    error={
                      errors.emailContent &&
                      errors.emailContent.optionalInfo4 &&
                      touched.emailContent &&
                      touched.emailContent.optionalInfo4
                    }
                    message={
                      errors.emailContent && errors.emailContent.optionalInfo4
                    }
                  />
                </EmailFieldGroup>
                <Space />
                <EmailFieldGroup label='Sender' previewText='astrid@navigio.se'>
                  <InputField
                    placeholder='Sender'
                    name='emailContent.sender'
                    id='emailContent.sender'
                    value={values.emailContent.sender || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type='text'
                    error={
                      errors.emailContent &&
                      errors.emailContent.sender &&
                      touched.emailContent &&
                      touched.emailContent.sender
                    }
                    message={errors.emailContent && errors.emailContent.sender}
                  />
                </EmailFieldGroup>

                <ButtonWrapper>
                  <Button
                    primary
                    type='submit'
                    disabled={isSubmitting}
                    text='Save'
                  />
                </ButtonWrapper>
              </Form>
            );
          }}
        </Formik>
      </InnerContainer>
      <Modal
        handleExit={() => setShowModal(false)}
        title={modalTitle}
        show={showModal}
        width='40rem'
      >
        <ModalContent>
          <Button onClick={() => setShowModal(false)} text='Done' primary />
        </ModalContent>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media ${device.tablet} {
    padding: 0 1.5rem;
  }
`;

const InnerContainer = styled.div`
  width: 615px;
  padding-top: 7rem;
  padding-bottom: 4.5rem;
`;

const Space = styled.div`
  height: 4.5rem;
  @media ${device.tablet} {
    height: 3rem;
  }
`;

const ButtonWrapper = styled.div`
  width: 31rem;
  padding-top: 8.5rem;
  margin-left: auto;
  margin-right: 0;
  @media ${device.mobileL} {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding-top: 4.5rem;
  }
`;
const Form = styled.form``;

const ModalContent = styled.div`
  font-size: 4rem;
  flex-direction: column;
`;
